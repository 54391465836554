import * as constants from './constants';
import * as messages from './messages';
import {one} from 'admin-ng/api/programs';
import {updateRouteName} from 'admin-ng/components/app/actions';

export const requestProgramDetails = id => ({
  type: constants.REQUEST_PROGRAM_DETAILS,
  id,
});

export const requestProgramDetailsSuccess = (details = null) => ({
  type: constants.REQUEST_PROGRAM_DETAILS_SUCCESS,
  details,
});

export const requestProgramDetailsFail = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_PROGRAM_DETAILS_FAIL,
  error,
});

export const fetchProgramDetails = (id, history) => {
  return function (dispatch) {
    dispatch(requestProgramDetails(id));

    return one(id)
      .then((res = {}) => {
        dispatch(requestProgramDetailsSuccess(res.body));
        dispatch(updateRouteName('PROGRAM_DETAIL', res.body.name));
      })
      .catch((err = {}) => {
        if (err.response && err.response.statusCode === 404) {
          history.push(`/page-not-found?message=${messages.PROGRAM_NOT_FOUND}`);
        } else {
          dispatch(requestProgramDetailsFail(err.message));
        }
      });
  };
};

export const resetState = () => ({
  type: constants.RESET_STATE,
});
