import ImageNavigateBefore from '@mui/icons-material/NavigateBefore';
import ImageNavigateNext from '@mui/icons-material/NavigateNext';
import React from 'react';
import ReactPaginate from 'react-paginate';
import {func, number} from 'prop-types';

const Pagination = props => {
  if (!props.itemsTotal) {
    return null;
  }

  const _handlePageChange = event => {
    if (event && event.hasOwnProperty('selected')) {
      const offset = props.itemsLimit * event.selected;
      props.onPageChange({offset});
    }
  };

  const pageCount = Math.ceil(props.itemsTotal / props.itemsLimit);
  const marginPagesDisplayed = 2;
  const pageRangeDisplayed = 2;
  // Custom pagination elements
  const previousLabel = <ImageNavigateBefore className="pl-pagination-icon" />;
  const nextLabel = <ImageNavigateNext className="pl-pagination-icon" />;
  const breakLabel = <span className="ellipsis">…</span>;

  return (
    <div className="pl-pagination clearfix">
      <p className="pl-pagination--summary">
        {`Total: ${props.itemsTotal} entries`}
      </p>
      <nav className="pl-pagination--switcher" data-qa-id="pagination-switcher">
        <ReactPaginate
          activeClassName="pl-page-active"
          breakLabel={breakLabel}
          marginPagesDisplayed={marginPagesDisplayed}
          nextLabel={nextLabel}
          onPageChange={_handlePageChange}
          pageCount={pageCount}
          pageRangeDisplayed={pageRangeDisplayed}
          previousLabel={previousLabel}
        />
      </nav>
    </div>
  );
};

Pagination.propTypes = {
  itemsLimit: number,
  itemsTotal: number,
  onPageChange: func.isRequired,
};

export default Pagination;
