import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import SelectField from 'admin-ng/components/common/select-field';
import {func, number} from 'prop-types';

const options = [20, 30, 50, 100];

const ItemsPerPageSelect = props => {
  const _renderDisplayOptions = () =>
    options.map((option, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <MenuItem key={key} value={option}>
        {option}
      </MenuItem>
    ));

  return (
    <div className="pl-items-per-page">
      <SelectField
        className="pl-items-per-page--select"
        onChange={event => props.onFilter({limit: event.target.value})}
        style={{margin: 0}}
        value={props.itemsPerPageLimit}
      >
        {_renderDisplayOptions()}
      </SelectField>
    </div>
  );
};

ItemsPerPageSelect.propTypes = {
  itemsPerPageLimit: number,
  onFilter: func.isRequired,
};

export default ItemsPerPageSelect;
