import Button from '@mui/material/Button';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {arrayOf, bool, func, node, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import DocumentCreateContainer from '../document-create/document-create-container';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import getDocumentsTableDataSchema from './table-data-schema';
import {ROLES} from 'admin-ng/constants';

export default class DocumentsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreateDialogShown: false,
    };

    this._showCreateDialog = this._showCreateDialog.bind(this);
    this._hideCreateDialog = this._hideCreateDialog.bind(this);
  }

  componentDidMount() {
    this.props.resetView();
  }

  _showCreateDialog() {
    this.setState({
      isCreateDialogShown: true,
    });
  }

  _hideCreateDialog() {
    this.setState({
      isCreateDialogShown: false,
    });
  }

  render() {
    if (this.props.auth.user.claims.role_level < ROLES.planetAdmin) {
      return <Redirect to="page-not-found" />;
    }

    const createDocumentButton = (
      <Button
        className="pl-table-controls--add-btn"
        color="primary"
        key={1}
        onClick={this._showCreateDialog}
        variant="contained"
      >
        Create
      </Button>
    );

    const {
      children,
      documents,
      documentsTotalCount,
      error,
      isRequestPending,
      onOpen,
      viewParams,
    } = this.props;

    return (
      <Card>
        <div className="pl-table-card-content">
          <TableControlsPanel
            actions={[createDocumentButton]}
            filters={[]}
            itemsPerPageLimit={viewParams.limit}
            onFilter={onOpen}
          />

          <DocumentCreateContainer
            isOpen={this.state.isCreateDialogShown}
            onClose={this._hideCreateDialog}
          />

          <Notification
            message={error}
            title="Oops, documents request has failed"
            type="error"
          />

          <Table
            className="pl-table-index"
            dataSchema={getDocumentsTableDataSchema(this.props)}
            isRequestPending={isRequestPending}
            onSort={onOpen}
            qaId="documents-table"
            sortBy={viewParams.sort}
            srcData={documents}
          />

          <Pagination
            itemsLimit={viewParams.limit}
            itemsOffset={viewParams.offset}
            itemsTotal={documentsTotalCount}
            onPageChange={onOpen}
          />

          {children}
        </div>
      </Card>
    );
  }
}

DocumentsIndex.propTypes = {
  auth: object.isRequired,
  children: node,
  documents: arrayOf(object),
  documentsTotalCount: number.isRequired,
  error: string,
  isRequestPending: bool.isRequired,
  onOpen: func.isRequired,
  resetView: func.isRequired,
  viewParams: object.isRequired,
};
