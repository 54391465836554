const NAME = 'PROGRAM-CREATE';

// Uniqueness check for program name
export const REQUEST_NAME_CHECK = `${NAME}/REQUEST_NAME_CHECK`;
export const REQUEST_NAME_CHECK_SUCCESS = `${NAME}/REQUEST_NAME_CHECK_SUCCESS`;
export const REQUEST_NAME_CHECK_FAIL = `${NAME}/REQUEST_NAME_CHECK_FAIL`;

// Update fields with user input
export const UPDATE_NAME = `${NAME}/UPDATE_NAME`;
export const UPDATE_DESCRIPTION = `${NAME}/UPDATE_DESCRIPTION`;
export const UPDATE_INVITATION_TEXT = `${NAME}/UPDATE_INVITATION_TEXT`;
export const UPDATE_DOCUMENTS = `${NAME}/UPDATE_DOCUMENTS`;

// Search for documents, triggered by autocomplete field
export const REQUEST_DOCUMENT_SEARCH = `${NAME}/REQUEST_DOCUMENT_SEARCH`;
export const REQUEST_DOCUMENT_SEARCH_SUCCESS = `${NAME}/REQUEST_DOCUMENT_SEARCH_SUCCESS`;
export const REQUEST_DOCUMENT_SEARCH_FAIL = `${NAME}/REQUEST_DOCUMENT_SEARCH_FAIL`;

// Submit POST request to create program
export const REQUEST_PROGRAM_CREATE = `${NAME}/REQUEST_PROGRAM_CREATE`;
export const REQUEST_PROGRAM_CREATE_SUCCESS = `${NAME}/REQUEST_PROGRAM_CREATE_SUCCESS`;
export const REQUEST_PROGRAM_CREATE_FAIL = `${NAME}/REQUEST_PROGRAM_CREATE_FAIL`;

export const RESET_STATE = `${NAME}/RESET_STATE`;
