export const intervals = [
  {
    label: 'Monthly (on 1st)',
    primaryText: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Access Period',
    primaryText: 'Access Period',
    value: 'access_period',
  },
];

export const getPrimaryText = key => {
  const quotaValue = key === null ? 'access_period' : key;
  const interval = intervals.find(interval => interval.value === quotaValue);
  return interval ? interval.primaryText : '';
};
