import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import * as actions from './actions';
import ProgramEdit from './program-edit';

const mapStateToProps = state => state.programs.edit;
const mapDispatchToProps = (dispatch, props) => ({
  onClose: () => {
    props.onClose();
    return dispatch(actions.resetState());
  },
  onDescriptionChange: description =>
    dispatch(actions.updateDescription(description)),
  onNameChange: name => dispatch(actions.updateName(name)),
  onInviteEmailTextChange: inviteEmailText =>
    dispatch(actions.updateInviteEmailText(inviteEmailText)),
  onNameCheck: name => dispatch(actions.checkName(name)),
  onSubmit: () => dispatch(actions.saveProgram(props.history)),
  requestDocuments: name => dispatch(actions.searchDocuments(name)),
  updateDocuments: documents => dispatch(actions.updateDocuments(documents)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProgramEdit)
);
