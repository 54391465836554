import React from 'react';
import {arrayOf, object, string} from 'prop-types';

import PlanOrgsTable from '../common/plan-orgs-table';
import PlanSummary from '../common/plan-summary';

const PlanAssignConfirmOrgs = props => (
  <div>
    <PlanSummary
      destinationPlanName={props.destinationPlanName}
      sourcePlanName={props.sourcePlanName}
    />
    <p className="pl-wizard-step-instructions">
      You are about to reassign the following organization(s):
    </p>
    <PlanOrgsTable
      className="pl-table-index"
      srcData={props.selectedOrgs || []}
    />
  </div>
);

PlanAssignConfirmOrgs.propTypes = {
  destinationPlanName: string,
  selectedOrgs: arrayOf(object),
  sourcePlanName: string,
};

export default PlanAssignConfirmOrgs;
