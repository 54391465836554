import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import PlanCommon from './plan-common';
import {fetchPlan} from './actions';

const mapStateToProps = state => state.plans.instance.common;

const mapDispatchToProps = (dispatch, props) => ({
  onOpen: () => dispatch(fetchPlan(props.match.params.planId, props.history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, undefined, {
    pure: false,
  })(PlanCommon)
);
