// import * as constants from './constants';
import {handleActions} from 'redux-actions';

import {
  basemapTileQuotaActions,
  quadQuotaActions,
  sceneTileQuotaActions,
} from './actions';
import {infiniteQuotaCap} from '../quota/reducer';
import {normalizeRefresh} from './intervals';

const initial = () => ({
  enabled: false,
  infinite: false,
  limit: 0,
  overage: false,
  refresh: '',
});

const initialState = {
  data: initial(),
  ui: initial(),
  intervalError: null,
  quotaUsage: null,
};

function quotaReducer(actions) {
  return handleActions(
    {
      [actions.toggleEnabled]: state => ({
        ...state,
        ui: {
          ...state.ui,
          enabled: !state.ui.enabled,

          // If enabling limit is 'state.data.limit'
          // If enabling and infinite, limit must be infiniteQuotaCap
          // If disabling limit must be 0;
          limit:
            !state.ui.enabled === true
              ? state.ui.infinite
                ? infiniteQuotaCap
                : state.data.limit
              : 0,
        },
      }),
      [actions.toggleInfinite]: state => ({
        ...state,
        ui: {
          ...state.ui,
          infinite: !state.ui.infinite,
          // Set overage to true if isInfinite is true, or use data fallback
          overage: !state.ui.infinite || state.data.overage,
          // Set limit to a high number if infinite
          limit:
            !state.ui.infinite === true
              ? infiniteQuotaCap
              : state.data.limit || initialState.data.limit,
        },
      }),
      [actions.updateLimit]: (state, {payload}) => ({
        ...state,
        ui: {
          ...state.ui,
          limit: payload,
          infinite: payload >= infiniteQuotaCap,
          overage: payload >= infiniteQuotaCap ? true : state.data.overage,
        },
      }),
      [actions.updateOverage]: (state, {payload}) => ({
        ...state,
        ui: {...state.ui, overage: payload},
      }),
      [actions.updateRefresh]: (state, {payload}) => ({
        ...state,
        ui: {...state.ui, refresh: payload},
      }),
      [actions.reset]: () => ({...initialState}),
      [actions.receiveSubscriptionDetails]: (
        state = initialState,
        {payload}
      ) => {
        const tq = payload || initial();
        const refresh = normalizeRefresh(tq.refresh);
        const {limit, overage} = tq;
        return {
          ...state,
          data: {
            ...state.data,
            enabled: limit > 0,
            infinite: limit >= infiniteQuotaCap,
            limit,
            overage,
            refresh,
          },
          ui: {
            ...state.ui,
            enabled: limit > 0,
            infinite: limit >= infiniteQuotaCap,
            limit,
            overage,
            refresh,
          },
          quotaUsage: tq.current,
        };
      },
    },
    initialState
  );
}

export const basemapTileQuota = quotaReducer(basemapTileQuotaActions);
export const sceneTileQuota = quotaReducer(sceneTileQuotaActions);
export const basemapQuadQuota = quotaReducer(quadQuotaActions);
