import assign from 'deep-extend';
import {connect} from 'react-redux';

import * as actions from './actions';
import OrgCreate from './org-create';
import {fetchPrograms} from '../../programs/programs-index/actions';

const mapStateToProps = (state, props) =>
  assign({}, state.orgs.create, {
    programs: state.programs.index.programs,
    isOpen: props.isOpen,
  });

const mapDispatchToProps = (dispatch, props) => ({
  onClose: () => {
    props.onClose();
    return dispatch(actions.resetOrgCreateState());
  },
  onOpen: () => dispatch(fetchPrograms({limit: 100})),
  onOrgNameChange: orgName => dispatch(actions.updateOrgName(orgName)),
  onOrgNameCheck: orgName => dispatch(actions.checkOrgName(orgName)),
  onOrgProgramChange: program => dispatch(actions.updateOrgProgram(program)),
  onOrgSfdcCheck: sfdc => dispatch(actions.checkOrgSfdc(sfdc)),
  onOrgSubmit: () => dispatch(actions.createNewOrg()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgCreate);
