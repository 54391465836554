import moment from 'moment';
import {createAction as createReduxAction} from 'redux-actions';
import {isEmpty} from 'lodash';

import * as constants from './constants';
import * as types from './types';
import {ROLES} from 'admin-ng/constants';
import {
  receiveSubscriptionDetails as accessPeriodFetch,
  updateActiveFrom as updateAccessPeriodFrom,
  updateActiveTo as updateAccessPeriodTo,
} from '../access-period/actions';
import {
  basemapTileQuotaActions,
  sceneTileQuotaActions,
} from '../tile-quota/actions';
import {
  resetBasemapTileQuota as basemapTileQuotaReset,
  create as createPlanSubscription,
  all as fetchPlanSubscriptions,
  quotaUsage as getQuotaUsage,
  one as getSubscriptionDetails,
  update as updatePlanSubscription,
} from 'admin-ng/api/subscriptions';
import {all as getPlans} from 'admin-ng/api/plans';
import {infiniteQuotaCap} from '../quota/reducer';
import {
  receiveSubscriptionDetails as quotaFetch,
  receiveQuotaUsage as quotaUsageFetch,
} from '../quota/actions';

export const updatePlanId = createReduxAction(types.UPDATE_PLAN_ID);

const addTileQuotaParams = (params, ui, key) => {
  params[key] = {
    limit: ui.isInfinite ? infiniteQuotaCap : ui.enabled ? ui.limit : 0,
    refresh: ui.refresh,
    enabled: true,
    overage: ui.enabled ? ui.overage : false,
  };
};

const buildSaveParams = organizationId => (dispatch, getState) => {
  const {subscriptions} = getState();
  const {planId, reference} = subscriptions.instance;
  const {activeFrom: from, activeTo: to} = subscriptions.accessPeriod.ui;
  const {ui: quotaUi} = subscriptions.quota;
  const {ui: basemapTileQuotaUI} = subscriptions.basemapTileQuota;
  const {ui: sceneTileQuotaUI} = subscriptions.sceneTileQuota;

  const params = {
    quota_enable_overage: subscriptions.quota.ui.overage,
    organization_id: organizationId,
    plan_id: planId,
    active_from: moment(from).toISOString(),
    active_to: to ? moment(to).toISOString() : null,
    quota_enabled: true,
    quota_interval: quotaUi.interval,
    quota_style: quotaUi.style,
    quota_sqkm: quotaUi.amount || 0,
  };

  // Add Tile Quotas to params
  addTileQuotaParams(params, basemapTileQuotaUI, 'basemap_tile_quota');
  addTileQuotaParams(params, sceneTileQuotaUI, 'scene_tile_quota');

  if (reference) {
    params.reference = reference;
  }

  return params;
};

export const validateAccessPeriod = () => (dispatch, getState) => {
  const {
    activeFrom: from,
    activeTo: to,
    hasNoEndDate,
    isActiveFromToday,
  } = getState().subscriptions.accessPeriod.ui;
  if (!from) {
    dispatch(
      updateAccessPeriodFrom(
        from,
        isActiveFromToday,
        constants.ACTIVE_FROM_DATE_NOT_SPECIFIED
      )
    );
    return false;
  }
  if (!to && !hasNoEndDate) {
    dispatch(
      updateAccessPeriodTo(
        to,
        hasNoEndDate,
        constants.ACTIVE_TO_DATE_NOT_SPECIFIED
      )
    );
    return false;
  }
  if (to) {
    if (moment(to).diff(moment(from), 'days') <= 0) {
      dispatch(
        updateAccessPeriodTo(
          to,
          hasNoEndDate,
          constants.TOI_RANGE_VALIDATION_ERROR_MESSAGE
        )
      );
      return false;
    }
  }
  return true;
};

export const createAction = createReduxAction(types.CREATE);
export const updateAction = createReduxAction(types.UPDATE);

const createSaveAction =
  type => (organizationId, id, userClaims) => async (dispatch, getState) => {
    const update = type === 'update' || type === 'reset-used-quota';
    const action = update ? updateAction : createAction;

    if (!organizationId) {
      return dispatch(action(new Error(constants.ORG_ID_IS_MISSING_ERROR_MSG)));
    }
    if (update && !id) {
      return dispatch(
        updateAction(new Error(constants.NO_SUBSCRIPTION_ID_MESSAGE))
      );
    }

    const {planId, reference, referenceValid} =
      getState().subscriptions.instance;

    if (type === 'create' && !planId) {
      return dispatch(
        updatePlanId(new Error(constants.PLAN_IS_MISSING_ERROR_MESSAGE))
      );
    }

    if (!userClaims) {
      return dispatch(
        action(new Error(constants.AUTHSTATE_IS_MISSING_ERROR_MSG))
      );
    }

    const {role_level, organization_id: userOrganizationId} = userClaims;

    const isOwnOrgAdmin =
      role_level === ROLES.orgAdmin && organizationId === userOrganizationId;
    if (isOwnOrgAdmin) {
      return dispatch(action(new Error(constants.PERMISSION_ERROR_MSG)));
    }

    const isPlanetAdmin = role_level >= ROLES.planetAdmin;
    if (isPlanetAdmin) {
      if (!reference) {
        return dispatch(action(new Error(constants.OPPORTUNITY_ID_IS_MISSING)));
      }

      if (!referenceValid) {
        return dispatch(action(new Error(constants.OPPORTUNITY_ID_INVALID)));
      }
    }

    const isAccessPeriodValid = dispatch(validateAccessPeriod());
    if (!isAccessPeriodValid) {
      return dispatch(action(new Error(constants.VALIDATION_ERROR)));
    }

    const params = dispatch(buildSaveParams(organizationId, userClaims));

    if (type === 'reset-used-quota') {
      params.quota_reset = true;
    }

    const {basemap_tile_quota, scene_tile_quota, quota_sqkm} = params;

    // NOTE: at least one type of quota must be enabled;
    const downloadQuota = quota_sqkm > 0;
    const basemapTileQuota = basemap_tile_quota.limit > 0;
    const sceneTileQuota = scene_tile_quota.limit > 0;

    if (downloadQuota || basemapTileQuota || sceneTileQuota) {
      dispatch(action());
      try {
        const {body: subscription} = await (update
          ? updatePlanSubscription(id, params)
          : createPlanSubscription(params));
        return dispatch(action(subscription));
      } catch (err) {
        return dispatch(action(err));
      }
    } else {
      return dispatch(action(new Error(constants.NO_QUOTA_ERROR)));
    }
  };

export const create = createSaveAction('create');
export const update = createSaveAction('update');

export const fetchQuotaUsageAction = createReduxAction(types.FETCH_QUOTA_USAGE);
export const fetchQuotaUsage = id => async dispatch => {
  try {
    const {body} = await getQuotaUsage([id]);
    if (!isEmpty(body)) {
      dispatch(quotaUsageFetch(body));
    }
  } catch (err) {
    dispatch(fetchQuotaUsageAction(err));
  }
};

export const fetchAction = createReduxAction(types.FETCH);
export const fetch = id => async dispatch => {
  if (!id) {
    dispatch(fetchAction(new Error(constants.NO_SUBSCRIPTION_ID_MESSAGE)));
    return;
  }
  dispatch(fetchAction({isFetchingSubscription: true}));
  try {
    const {body: subscription} = await getSubscriptionDetails(id);
    const {
      plan: {id: planId, name: planName},
      organization: {id: organizationId, name: organizationName},
      reference,
      state,
    } = subscription;
    const basemapTileQuota = subscription.basemap_tile_quota;
    const sceneTileQuota = subscription.scene_tile_quota;

    dispatch(
      fetchAction({
        basemapTileQuota,
        organizationId,
        organizationName,
        planId,
        planName,
        reference,
        sceneTileQuota,
        state,
        subscriptionId: parseInt(id),
      })
    );
    dispatch(accessPeriodFetch(subscription));
    dispatch(quotaFetch(subscription));
    dispatch(basemapTileQuotaActions.receiveSubscriptionDetails(subscription));
    dispatch(sceneTileQuotaActions.receiveSubscriptionDetails(subscription));
    dispatch(fetchQuotaUsage(id));
  } catch (err) {
    dispatch(fetchAction(err));
  }
};

export const fetchSubscriptionsAction = createReduxAction(
  types.FETCH_SUBSCRIPTIONS
);
export const fetchSubscriptions = organizationId => async dispatch => {
  if (!organizationId) {
    return dispatch(
      fetchSubscriptionsAction(new Error(constants.ORG_ID_IS_MISSING_ERROR_MSG))
    );
  }
  dispatch(fetchSubscriptionsAction({isFetchingSubscriptions: true}));
  try {
    const {
      body: {results: subscriptions},
    } = await fetchPlanSubscriptions({
      organization_id: organizationId,
      state__in: 'active,inactive',
      fields: 'plan',
    });
    dispatch(fetchSubscriptionsAction({subscriptions}));
  } catch (err) {
    dispatch(fetchSubscriptionsAction(err));
  }
};

export const fetchPlansAction = createReduxAction(types.FETCH_PLANS);
export const fetchPlans =
  (organizationId, search) => async (dispatch, getState) => {
    if (!organizationId) {
      return dispatch(
        fetchPlansAction(new Error(constants.ORG_ID_IS_MISSING_ERROR_MSG))
      );
    }
    let {subscriptions} = getState().subscriptions.instance;
    try {
      if (subscriptions.length === 0) {
        await dispatch(fetchSubscriptions(organizationId));
        subscriptions = getState().subscriptions.instance.subscriptions;
      }
      const params = {
        limit: 999,
        state__in: 'active',
        search,
      };

      dispatch(fetchPlansAction({isFetchingPlans: true}));
      const {
        body: {results: plans},
      } = await getPlans(params);

      dispatch(fetchPlansAction({plans}));
    } catch (err) {
      dispatch(fetchPlansAction(err));
    }
  };

export const resetBasemapTileQuota = (id, claims) => dispatch => {
  basemapTileQuotaReset(id).then(() => dispatch(fetch(id, claims)));
};

export const updateNewPlanName = createReduxAction(types.UPDATE_NEW_PLAN_NAME);
export const updateReference = createReduxAction(types.UPDATE_REFERENCE);
export const reset = createReduxAction(types.RESET);
