import React, {Component} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import IconButton from 'admin-ng/components/common/icon-button';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import Sidebar from 'admin-ng/components/common/sidebar';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import {DATE_FORMAT} from 'admin-ng/constants';

const ROLE_MAP = {
  100: 'Organization User',
  1000: 'Organization Admin',
  2000: 'Planet Admin',
};

export default class Invitations extends Component {
  state = {
    toDelete: null,
  };

  componentDidMount() {
    this.props.onMount();
  }

  /**
   * This function returns data structure for organization to be correctly
   * displayed in the a reusable component Table
   *
   * A single object represents a table column.
   *
   *     field - (required) property name in the data object (the same which is returned by api)
   *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
   *                          different from the field name.
   *     label - (required) column name.
   *     width - (required) column width to be set in the styles in the table header and table body
   *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
   *     contentCreator - [optional] - func - can be used for creating custom column content
   */
  _getDataSchema() {
    return [
      {
        field: 'id',
        label: 'ID',
        width: '7.5%',
      },
      {
        field: 'recipient_email',
        label: 'Email',
        width: '17%',
        contentCreator: this._recipientEmailContentCreator,
      },
      {
        field: 'organization_name',
        label: 'Organization',
        width: '17%',
        contentCreator: this._organizationNameContentCreator,
      },
      {
        field: 'organization_role_level',
        isSortable: false,
        label: 'Role',
        width: '12%',
        contentCreator: this._organizationRoleContentCreator,
      },
      {
        field: 'sender_email',
        label: 'Sender',
        width: '17%',
        contentCreator: this._senderContentCreator,
      },
      {
        field: 'created_at',
        label: 'Created',
        width: '8%',
        contentCreator: this._createdAtContentCreator,
      },
      {
        field: 'redeemed_at',
        label: 'Redeemed',
        width: '8%',
        contentCreator: this._redeemedAtContentCreator,
      },
      {
        field: 'deleted_at',
        label: 'Deleted',
        width: '8%',
        contentCreator: this._deletedAtContentCreator,
      },
      {
        field: null,
        label: '',
        width: '5%',
        contentCreator: this._tableActionsColumnCreator.bind(this),
      },
    ];
  }

  _recipientEmailContentCreator(invite) {
    return (
      <a
        href={`mailto:${invite.recipient_email}`}
        title={invite.recipient_email}
      >
        {invite.recipient_email}
      </a>
    );
  }

  _organizationNameContentCreator(invite) {
    if (!invite.organization) {
      return null;
    }
    const displayName = invite.organization.name.replace(/\s\s+/g, ' ');
    return (
      <Link title={displayName} to={`/organizations/${invite.organization.id}`}>
        {displayName}
      </Link>
    );
  }

  _organizationRoleContentCreator(invite) {
    return ROLE_MAP[invite.organization_role_level];
  }

  _senderContentCreator(invite) {
    return (
      <a
        href={`mailto://${invite.sender.email}`}
        title={`${invite.sender.full_name} <${invite.sender.email}>`}
      >
        {invite.sender.email}
      </a>
    );
  }

  _redeemedAtContentCreator(invite) {
    return invite.redeemed_at
      ? moment(invite.redeemed_at).format(DATE_FORMAT)
      : '';
  }

  _createdAtContentCreator(invite) {
    return moment(invite.created_at).format(DATE_FORMAT);
  }

  _deletedAtContentCreator(invite) {
    return invite.deleted_at
      ? moment(invite.deleted_at).format(DATE_FORMAT)
      : '';
  }

  _tableActionsColumnCreator(invitation) {
    const canDelete =
      !invitation.deleted_at &&
      !invitation.redeemed_at &&
      !invitation.expire_at;
    return (
      canDelete && (
        <IconButton
          icon="delete"
          onClick={this._onDelete.bind(this, invitation)}
        />
      )
    );
  }

  _onDelete = ({id, recipient_email: email}) => {
    this.setState({toDelete: {id, email}});
  };

  _onDeleteConfirmed = () => {
    this.props.onDelete(this.state.toDelete.id);
    this._onDeleteClose();
  };

  _onDeleteClose = () => {
    this.setState({toDelete: null});
  };

  render() {
    const {
      error,
      filters: activeFilters,
      invitations,
      isFetching,
      limit,
      offset,
      onChange,
      sort,
      total,
    } = this.props;
    const filters = ['active', 'deleted', 'redeemed'];
    const email = this.state.toDelete ? this.state.toDelete.email : null;

    return (
      <div className="pl-flex-wrapper">
        <Sidebar />
        <div className="pl-app--content">
          <Card>
            <div className="pl-table-card-content">
              <TableControlsPanel
                activeFilters={activeFilters}
                filters={filters}
                itemsPerPageLimit={limit}
                onFilter={onChange}
              />
              <Notification
                message={error}
                title="Oops, invitations request has failed"
                type="error"
              />
              <ConfirmationMessage
                onClose={this._onDeleteClose}
                onSubmit={this._onDeleteConfirmed}
                open={!!this.state.toDelete}
                title="Delete invite"
              >
                Are you sure you want to delete invite for {email}?
              </ConfirmationMessage>

              <Table
                className="pl-table-index"
                dataSchema={this._getDataSchema()}
                isRequestPending={isFetching}
                onSort={onChange}
                qaId="invitations-table"
                sortBy={sort}
                srcData={invitations}
              />

              <Pagination
                itemsLimit={limit}
                itemsOffset={offset}
                itemsTotal={total}
                onPageChange={onChange}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

Invitations.propTypes = {
  error: string,
  filters: string.isRequired,
  invitations: arrayOf(object).isRequired,
  isFetching: bool.isRequired,
  limit: number.isRequired,
  offset: number.isRequired,
  onChange: func.isRequired,
  onDelete: func.isRequired,
  onMount: func.isRequired,
  sort: string.isRequired,
  total: number.isRequired,
};

Invitations.defaultProps = {
  error: null,
};
