import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import * as actions from './actions';
import OrgMemberInstance from './org-member-instance';
import {deleteOrg} from '../../../orgs-index/actions';
import {fetchOrgDetails} from '../../org-general/org-details/actions';
import {fetchUsers} from '../org-members-index/actions';

const mapStateToProps = (
  {
    orgs: {
      instance: {
        general: {
          details: {initDetails: parentOrgDetails},
        },
        users: {
          instance,
          index: {itemsTotalCount: parentOrgMemberCount},
        },
      },
    },
  },
  {
    auth: {
      user: {claims},
    },
  }
) => ({
  ...instance,
  user: claims,
  userRoleLevel: claims.role_level,
  parentOrgDetails: parentOrgDetails ? parentOrgDetails : {},
  parentOrgMemberCount,
});

const mapDispatchToProps = (dispatch, props) => {
  const orgId = parseInt(props.match.params.orgId);
  const memberId = parseInt(props.match.params.memberId);
  return {
    onApiKeyReset: () => dispatch(actions.resetUserApiKey({auth: props.auth})),
    onCancel: () => dispatch(actions.cancelUserDetailsUpdates()),
    onChangeOrg: newParentOrgId =>
      dispatch(actions.changeOrg(orgId, memberId, newParentOrgId)),
    onDataChange: params => dispatch(actions.updateData(params)),
    onDeleteOldOrg: id => dispatch(deleteOrg(id)),
    onEmailUpdate: email => dispatch(actions.checkEmail(email)),
    onOpen: () =>
      dispatch(actions.fetchUserDetails(orgId, memberId, props.history)),
    onParentOrgDataRequest: () => dispatch(fetchOrgDetails(orgId)),
    onPasswordMatchingCheck: (password, repeat) =>
      dispatch(actions.checkPasswordMatching(password, repeat)),
    onPasswordValidityCheck: password =>
      dispatch(actions.checkPasswordValidity(password)),
    onRequestOrgs: name => dispatch(actions.fetchOrgs(orgId, name)),
    onRequestOrgUsers: orgId => dispatch(fetchUsers(orgId)),
    onSave: () => dispatch(actions.updateUserDetails(orgId, memberId)),
    resetPassword: () => dispatch(actions.resetPassword()),
    resetState: () => dispatch(actions.resetState()),
    saveNewPassword: () => dispatch(actions.saveNewPassword()),
  };
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(OrgMemberInstance)
);
