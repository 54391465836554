import DayPicker, {DateUtils} from 'react-day-picker';
import React, {Component} from 'react';
import {func} from 'prop-types';

// import 'react-day-picker/lib/style.css';
// import '../styles/range.css';

export default class DateRangePicker extends Component {
  static propTypes = {
    onRangeSelected: func,
  };

  constructor(props) {
    super(props);

    this.emitOnRangeSelected = this.emitOnRangeSelected.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.state = {
      isSelectingLastDay: false,
      from: null,
      to: null,
    };
  }

  emitOnRangeSelected() {
    const {from, to} = this.state;
    this.props.onRangeSelected(from && to ? {from, to} : null);
  }

  handleDayClick(day) {
    const {from, isSelectingLastDay} = this.state;
    // Don't do anything if day is in future
    if (DateUtils.isFutureDay(day)) {
      return;
    }
    let state = {};
    // Enable day selection on mouse enter
    if (!isSelectingLastDay) {
      state = {
        from: day,
        to: null,
        isSelectingLastDay: true,
      };
    } else {
      if (DateUtils.isSameDay(day, from)) {
        // Reset everything if the clicked day is the same as the current from-day
        state = {
          from: null,
          to: null,
          isSelectingLastDay: false,
        };
      }
      if (from && day < from) {
        state = {
          from: day,
          to: null,
        };
      }
      state.isSelectingLastDay = false;
    }
    this.setState(state);
    this.emitOnRangeSelected();
  }

  handleDayMouseEnter(day) {
    const {isSelectingLastDay, from} = this.state;
    if (
      !isSelectingLastDay ||
      (from && day < from) ||
      DateUtils.isSameDay(day, from)
    ) {
      return;
    }
    this.setState({
      to: day,
    });
  }

  render() {
    // TODO: support for locking future/time-ranges
    const {from, to} = this.state;

    return (
      <DayPicker
        className="Range"
        disabledDays={DateUtils.isFutureDay}
        modifiers={{
          from: day => DateUtils.isSameDay(day, from),
          to: day => DateUtils.isSameDay(day, to),
        }}
        numberOfMonths={2}
        onDayClick={this.handleDayClick}
        onDayMouseEnter={this.handleDayMouseEnter}
        selectedDays={day => DateUtils.isDayInRange(day, {from, to})}
        toMonth={DateUtils.addMonths(new Date(), 1)}
      />
    );
  }
}
