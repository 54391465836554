import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import React from 'react';
import Select from '@mui/material/Select';
import {bool, string} from 'prop-types';

import {FormFieldStyles} from 'admin-ng/components/common/form-field-styles';

const styles = {...FormFieldStyles};

const PlSelectField = props => {
  let {general, changed} = styles;

  if (props.style) {
    general = {...styles.general, ...props.style};
    changed = {...general, ...styles.changed};
  }

  const style = props.isChanged ? {...general, ...changed} : general;

  return (
    <FormControl
      style={{...style, width: '100%'}}
      {...(props.variant ? {variant: props.variant} : {})}
    >
      <InputLabel>{props.label}</InputLabel>

      <Select
        className={props.className}
        SelectDisplayProps={{'data-qa-id': props.qaId}}
        onChange={props.onChange}
        value={props.value || ''}
        {...(props.disabled ? {disabled: true} : {})}
      >
        {props.children}
      </Select>

      <FormHelperText>{props.errorText}</FormHelperText>
    </FormControl>
  );
};

PlSelectField.propTypes = {
  ...Select.propTypes,
  errorText: string,
  isChanged: bool,
  qaId: string,
};

PlSelectField.defaultProps = {
  errorText: null,
  variant: 'standard',
};

export default PlSelectField;
