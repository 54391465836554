import {connect} from 'react-redux';

import * as actions from './actions';
import OrgCommentAdd from './org-comment-add';

const mapStateToProps = state => state.orgs.instance.general.comments.add;
const mapDispatchToProps = (dispatch, props) => ({
  addComment: () => dispatch(actions.createComment(props.orgId)),
  onCommentUpdate: comment => dispatch(actions.updateNewComment(comment)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgCommentAdd);
