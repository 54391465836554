import React, {Component} from 'react';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import OrgCommentAdd from '../org-comment-add';
import OrgCommentInstance from '../org-comment-instance';
import Pagination from 'admin-ng/components/common/pagination';

export default class OrgCommentsIndex extends Component {
  componentDidMount() {
    const {isPlanetAdmin, onOpen} = this.props;
    if (isPlanetAdmin) {
      onOpen();
    }
  }

  componentWillReceiveProps({orgId, isPlanetAdmin, onOpen}) {
    if (orgId !== this.props.orgId && isPlanetAdmin) {
      onOpen();
    }
  }

  render() {
    const {
      comments,
      commentsTotalCount,
      onOpen,
      orgId,
      userId,
      isPlanetAdmin,
      viewParams: {limit, offset},
    } = this.props;
    return (
      isPlanetAdmin && (
        <Card style={{marginBottom: '25px'}}>
          <div className="pl-form--section">
            <div className="pl-form--section-header">
              <h3 className="pl-form--section-title">Comments</h3>
            </div>
            <OrgCommentAdd orgId={orgId} />
            {comments && comments.length ? (
              <ul className="pl-comments-list">
                {comments.map((comment, i) => (
                  <OrgCommentInstance
                    comment={comment}
                    isReadOnly={comment.author_user_id !== userId}
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    orgId={orgId}
                  />
                ))}
              </ul>
            ) : (
              <p>There are no comments yet</p>
            )}
            <Pagination
              itemsLimit={limit}
              itemsOffset={offset}
              itemsTotal={commentsTotalCount}
              onPageChange={onOpen}
            />
          </div>
        </Card>
      )
    );
  }
}

OrgCommentsIndex.propTypes = {
  comments: arrayOf(object),
  commentsTotalCount: number,
  isPlanetAdmin: bool.isRequired,
  onOpen: func.isRequired,
  orgId: string,
  userId: number.isRequired,
  viewParams: object,
};
