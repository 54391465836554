import * as constants from './constants';
import {REFERENCE_PL_NUMBER_REGEX, REFERENCE_THEMES} from 'admin-ng/constants';
import {RESET_VIEW} from '../plan-subscription-bulk-create-general/constants';

const initial = {
  startDate: null,
  startDateError: null,
  endDate: null,
  endDateError: null,
  isActiveFromToday: false,
  hasNoEndDate: false,
  reference: '',
  referenceError: null,
  referenceValid: false,
};

const validateReference = (state, {reference, error}) => {
  let referenceValid = false;
  if (
    reference.match(REFERENCE_PL_NUMBER_REGEX) ||
    REFERENCE_THEMES.includes(reference)
  ) {
    referenceValid = true;
  }
  return {...state, reference, referenceValid, referenceError: error};
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case constants.UPDATE_START_DATE:
      return {
        ...state,
        startDate: action.payload.date,
        isActiveFromToday: action.payload.isActiveFromToday,
        startDateError: action.payload.error,
      };

    case constants.UPDATE_END_DATE:
      return {
        ...state,
        endDate: action.payload.date,
        hasNoEndDate: action.payload.hasNoEndDate,
        endDateError: action.payload.error,
      };

    case constants.UPDATE_REFERENCE:
      return validateReference(state, action.payload);

    case RESET_VIEW:
      return {...initial};

    default:
      return state;
  }
};
