import React, {useEffect} from 'react';
import {Link, Route, useRouteMatch} from 'react-router-dom';
import {func} from 'prop-types';

const APIKeysHeader = ({updateTitle}) => {
  const rootPath = useRouteMatch('/api-keys');

  useEffect(() => {
    if (rootPath && rootPath.isExact) {
      updateTitle('API keys');
    }
  }, [updateTitle, rootPath]);

  return (
    <Route exact path="/api-keys">
      <Link to="/api-keys">API keys</Link>
    </Route>
  );
};

APIKeysHeader.propTypes = {
  updateTitle: func.isRequired,
};

export default APIKeysHeader;
