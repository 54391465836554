import join from 'url-join';

const BASE_API = import.meta.env.VITE_BASE_API_URL;
const DATA_API = import.meta.env.VITE_DATA_API_URL;

export const API = import.meta.env.VITE_PL_API_URL;
export const COMPLIANCE_V0_API = import.meta.env.VITE_PL_COMPLIANCE_V0_API_URL;
export const V0_API = import.meta.env.VITE_PL_V0_API_URL;

export const API_KEYS = join(API, 'api-keys', '/');
export const ASSET_TYPES = join(DATA_API, 'asset-types', '/');
export const AUDIT_LOGS = join(API, 'audit-logs', '/');
export const BASEMAPS = join(BASE_API, 'basemaps', 'v1', 'mosaics');
export const COMMENTS = join(API, 'comments', '/');
export const CONVERT = join(API, 'convert', '/');
export const DOCUMENTS = join(API, 'documents', '/');
export const INVITATIONS = join(API, 'invitations', '/');
export const ITEM_TYPES = join(DATA_API, 'item-types', '/');
export const ORGANIZATION_COMPLIANCE = join(
  API,
  'compliance',
  'organization',
  '/'
);
export const ORGANIZATIONS = join(API, 'organizations', '/');
export const PLANS = join(API, 'plans', '/');
export const PROGRAMS = join(API, 'programs', '/');
export const RE_REPORTS = join(API, 're-reports', '/');
export const REPORTS = join(API, 'reports', '/');
export const SERIES = join(BASE_API, 'basemaps', 'v1', 'series', '/');
export const SUBSCRIPTIONS = join(API, 'subscriptions', '/');
export const USER_COMPLIANCE = join(API, 'compliance', 'user', '/');
export const USERS = join(API, 'users', '/');

export default {
  API,
  COMPLIANCE_V0_API,
  V0_API,
  API_KEYS,
  ASSET_TYPES,
  AUDIT_LOGS,
  BASEMAPS,
  COMMENTS,
  CONVERT,
  DOCUMENTS,
  INVITATIONS,
  ITEM_TYPES,
  ORGANIZATION_COMPLIANCE,
  ORGANIZATIONS,
  PLANS,
  PROGRAMS,
  RE_REPORTS,
  REPORTS,
  SERIES,
  SUBSCRIPTIONS,
  USER_COMPLIANCE,
  USERS,
};
