import {createTheme} from '@mui/material/styles';

export const TEAL = {
  50: '#E6F5F7',
  100: '#99D8E0',
  300: '#4DBAC8',
  500: '#009DB1', // "Official Planet Teal" / main
  600: '#018899',
  800: '#017382',
  900: '#025E6A',
  A100: 'rgba(0,157,177,0.18)',
  A200: 'rgba(0,157,177,0.36)',
  A300: 'rgba(0,157,177,0.72)',
};

const theme = createTheme({
  palette: {
    primary: {
      dark: TEAL[800],
      light: TEAL[100],
      lighter: TEAL[50],
      main: TEAL[500],
    },
  },
  typography: {
    fontFamily: '"Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif',
  },
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: 'white',
          fontSize: 14,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {padding: 8},
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },
});

export default theme;
