import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FolderIcon from '@mui/icons-material/Folder';
import React, {Component} from 'react';
import {array, bool, func, string} from 'prop-types';

import Autocomplete from 'admin-ng/components/common/autocomplete';
import TextField from 'admin-ng/components/common/text-field';
import Textarea from 'admin-ng/components/common/textarea';

const chipStyles = {
  margin: 4,
};

const autoCompleteMenuCloseDelay = 300; // ms. Default value of material-ui's <Autocomplete>

export default class ProgramCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentName: '', // Search query for document names in autocomplete field
    };

    this._removeDocument = this._removeDocument.bind(this);
    this._selectDocument = this._selectDocument.bind(this);
    this._requestDocumentsByName = this._requestDocumentsByName.bind(this);
    this._renderSuccessDialog = this._renderSuccessDialog.bind(this);
    this._renderSelectedDocuments = this._renderSelectedDocuments.bind(this);
    this._isSubmitAllowed = this._isSubmitAllowed.bind(this);
    this._renderCreateDialog = this._renderCreateDialog.bind(this);
    this._onUpdateDocumentSearch = this._onUpdateDocumentSearch.bind(this);
  }

  _onUpdateDocumentSearch(documentName) {
    this.setState({documentName});
  }

  _requestDocumentsByName() {
    if (this.state.documentName && this.state.documentName.length > 2) {
      this.props.requestDocuments(this.state.documentName.trim());
    }
  }

  _selectDocument(event, document) {
    if (!document) return;

    if (!this.props.documents.find(id => id === document.id)) {
      this.props.updateDocuments([...this.props.documents, document]);
    }

    // Clear search text. Needs to happen after <Autocomplete> has closed.
    setTimeout(
      () => this._onUpdateDocumentSearch(''),
      autoCompleteMenuCloseDelay + 1
    );
  }

  _removeDocument(document) {
    const documents = this.props.documents.slice(0);
    const documentIndex = documents
      .map(document => document.id)
      .indexOf(document.id);

    if (documentIndex !== -1) {
      documents.splice(documentIndex, 1);
      this.props.updateDocuments(documents);
    }
  }

  _renderSuccessDialog() {
    return (
      <Dialog fullWidth maxWidth="sm" open={this.props.isOpen}>
        <DialogTitle>Create a new program</DialogTitle>

        <DialogContent>
          <DialogContentText>A new program has been created.</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            focusRipple
            onClick={this.props.onClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // Only allow submission if name and description are valid and the request isn't already in progress.
  _isSubmitAllowed() {
    const isNameValid =
      !this.props.isCheckingName &&
      this.props.isNameUnique &&
      this.props.name.length >= 1;
    const isDescriptionValid = this.props.description.length >= 1;
    return isNameValid && isDescriptionValid && !this.props.isCreating;
  }

  _renderSelectedDocuments() {
    const documents = this.props.documents.map((document, index) => (
      <Chip
        icon={<FolderIcon />}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        label={document.name}
        onDelete={this._removeDocument.bind(this, document)}
        style={chipStyles}
      />
    ));

    return (
      <div className="pl-program-instance-documents-wrapper pl-flex-wrapper">
        {documents}
      </div>
    );
  }

  _renderCreateDialog() {
    const disabled = !this._isSubmitAllowed();

    const nameErrorText =
      this.props.isNameUnique === false
        ? 'A program with this name already exists.'
        : this.props.nameError;

    return (
      <Dialog
        onClose={this.props.onClose}
        open={this.props.isOpen}
        scroll="paper"
      >
        <DialogTitle>Create a new program</DialogTitle>

        <DialogContent dividers>
          <TextField
            error={!!nameErrorText}
            helperText={nameErrorText}
            label="Program name *"
            isInputValid={this.props.isNameUnique}
            onChange={this.props.onNameChange}
            onChangeCompleted={this.props.onNameCheck}
            value={this.props.name}
          />

          <TextField
            label="Description *"
            onChange={this.props.onDescriptionChange}
            value={this.props.description}
          />

          <Textarea
            fullWidth
            label="Invitation email text"
            maxRows={4}
            minRows={4}
            onChange={this.props.onInvitationTextChange}
            value={this.props.invitationText}
          />

          <Autocomplete
            error={!!this.props.documentSearchError}
            freeSolo
            helperText={this.props.documentSearchError}
            inputValue={this.state.documentName}
            label="Documents"
            onInputChange={this._onUpdateDocumentSearch}
            onInputCompleted={this._requestDocumentsByName}
            onChange={this._selectDocument}
            options={this.props.documentSearchResults}
            sx={{marginTop: 1}}
            userHintBottomText="Start typing a name of a document (min 3 characters)."
          />

          {this._renderSelectedDocuments()}
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={this.props.onClose}>
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={disabled}
            onClick={!disabled ? this.props.onSubmit : null}
            variant="contained"
          >
            Create program
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return this.props.isNewProgramCreated
      ? this._renderSuccessDialog()
      : this._renderCreateDialog();
  }
}

ProgramCreate.propTypes = {
  description: string.isRequired,
  documentSearchError: string,
  documentSearchResults: array.isRequired,
  documents: array.isRequired,
  invitationText: string.isRequired,
  isCheckingName: bool.isRequired,
  isCreating: bool.isRequired,
  isNameUnique: bool,
  isNewProgramCreated: bool,
  isOpen: bool.isRequired,
  name: string.isRequired,
  nameError: string,
  onClose: func.isRequired,
  onDescriptionChange: func.isRequired,
  onInvitationTextChange: func.isRequired,
  onNameChange: func.isRequired,
  onNameCheck: func.isRequired,
  onSubmit: func.isRequired,
  requestDocuments: func.isRequired,
  updateDocuments: func.isRequired,
};
