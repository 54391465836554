import * as constants from './constants';

const initialState = {
  error: null,
  items: [],
  itemsTotalCount: 0,
  isRequestPending: false,
  isMembershipDeletePending: false,
  viewParams: {
    filters: 'active,pending',
    limit: 20,
    offset: 0,
    sort: 'user.email',
    search: '',
  },
};

export default function orgMembersIndexReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.ORG_MEMBERS_REQUEST:
      return Object.assign({}, state, {
        isRequestPending: true,
        viewParams: Object.assign({}, state.viewParams, action.params),
      });

    case constants.ORG_MEMBERS_SUCCESS:
      return Object.assign({}, state, {
        isRequestPending: false,
        items: action.items,
        itemsTotalCount: action.itemsTotalCount,
        error: null,
      });

    case constants.ORG_MEMBERS_FAIL:
      return Object.assign({}, state, {
        isRequestPending: false,
        error: action.error,
      });

    case constants.ORG_MEMBERS_INVITATION_DELETE_REQUEST:
      return Object.assign({}, state, {
        isMembershipDeletePending: true,
      });

    case constants.ORG_MEMBERS_INVITATION_SUCCESS:
      return Object.assign({}, state, {
        isMembershipDeletePending: false,
      });

    case constants.ORG_MEMBERS_INVITATION_FAIL:
      return Object.assign({}, state, {
        isMembershipDeletePending: false,
        error: action.error,
      });

    case constants.ORG_MEMBERS_RESET_VIEW_STATE:
      return Object.assign({}, state, {
        viewParams: initialState.viewParams,
      });

    default:
      return Object.assign({}, state);
  }
}
