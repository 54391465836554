import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import PlansIndex from './plans-index';
import {deletePlan, fetchPlans, resetView, toggleOneOrgPlans} from './actions';

const mapStateToProps = (state, ownProps) => {
  const plansIndexState = state.plans.index;

  return {
    plansRequestError: plansIndexState.error,
    isPlansRequestPending: plansIndexState.isPlansRequestPending,
    plans: plansIndexState.items,
    plansTotalCount: plansIndexState.itemsTotalCount,
    viewParams: plansIndexState.viewParams,
    user: ownProps.auth.user.claims,
  };
};

const mapDispatchToProps = dispatch => ({
  onRequestPlans: params => dispatch(fetchPlans(params)),
  onDeletePlan: id => dispatch(deletePlan(id)),
  onResetView: () => dispatch(resetView()),
  onToggleOneOrgPlans: () => dispatch(toggleOneOrgPlans()),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(PlansIndex)
);
