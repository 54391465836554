import React, {Component} from 'react';
import {arrayOf, func, number, object, shape, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';

export default class PlanSubscriptionsBulkCreateSelectOrgsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAllSelected: false,
    };

    this._handleOrgSelect = this._handleOrgSelect.bind(this);
    this._onPageChange = this._onPageChange.bind(this);
  }

  componentWillMount() {
    this.props.onRequestOrgs();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.orgs &&
      nextProps.orgs[0] &&
      this.props.orgs &&
      this.props.orgs[0]
    ) {
      if (nextProps.orgs[0].id !== this.props.orgs[0].id) {
        this._uncheckSelectAll();
      }
    }
  }

  _uncheckSelectAll() {
    // The Table component of material-ui framework requires a setTimeout to properly render selected/unselected rows
    setTimeout(() => {
      if (this.state.isAllSelected) {
        // dirty fix to force the table to programmatically uncheck the rows on the new page,
        // otherwise the rows stay selected on all pages even though the number of selected items
        // equals only this.props.viewParams.limit
        const input = document.querySelector('.pl-table--header input');
        input.click();

        this.setState({
          isAllSelected: false,
        });
      }
    });
  }

  _getOrgDataSchema() {
    return [
      {
        field: 'id',
        label: 'ID',
        width: '10%',
      },
      {
        field: 'name',
        label: 'Name',
        width: '90%',
      },
    ];
  }

  _onPageChange(params) {
    this.props.onRequestOrgs(params);
  }

  _handleOrgSelect(items) {
    this.setState({
      isAllSelected: items === 'all',
    });

    setTimeout(() => {
      this.props.onOrgsSelect(items);
    });
  }

  render() {
    const isSelectable = true;
    const selectedOrgsCount =
      this.props.selectedOrgs && this.props.selectedOrgs.length
        ? this.props.selectedOrgs.length
        : '0';

    return (
      <div>
        <p className="pl-wizard-step-instructions">
          Please select the organizations which should subscribe to this plan
        </p>

        <Notification
          message={this.props.error}
          title="Oops, organizations request has failed"
          type="error"
        />

        <div className="pl-data-table-selectable">
          <div className="pl-data-table-selectable--panel">
            {`${selectedOrgsCount} selected`}
          </div>

          <div className="pl-data-table-selectable--panel-controls">
            <TableControlsPanel
              itemsPerPageLimit={this.props.viewParams.limit}
              onFilter={this.props.onRequestOrgs}
            />
          </div>

          <Table
            className="pl-table-index"
            dataSchema={this._getOrgDataSchema()}
            onRowSelection={this._handleOrgSelect}
            onSort={this.props.onRequestOrgs}
            qaId="plan-subscriptions-bulk-create-select-organization-table"
            selectable={isSelectable}
            selectedOrgs={this.props.selectedOrgs}
            sortBy={this.props.viewParams.sort}
            srcData={this.props.orgs}
          />

          <Pagination
            itemsLimit={this.props.viewParams.limit}
            itemsOffset={this.props.viewParams.offset}
            itemsTotal={this.props.orgsTotalCount}
            onPageChange={this._onPageChange}
          />
        </div>
      </div>
    );
  }
}

PlanSubscriptionsBulkCreateSelectOrgsView.propTypes = {
  error: string,
  onOrgsSelect: func.isRequired,
  onRequestOrgs: func.isRequired,
  orgs: arrayOf(object),
  orgsTotalCount: number,
  selectedOrgs: arrayOf(object),
  viewParams: shape({
    limit: number,
    offset: number,
    sort: string,
  }),
};
