import assign from 'deep-extend';
import {connect} from 'react-redux';

import * as actions from './actions';
import PlanSubscriptionBulkCreateSummaryView from './plan-subscription-bulk-create-summary-view';

const mapStateToProps = state => {
  const _state = state.plans.instance.subscriptions.bulkCreate;
  return assign({}, _state.summary, {
    selectedOrgs: _state.selectOrgs.selectedOrgs,
  });
};
const mapDispatchToProps = (dispatch, props) => {
  const planId = parseInt(props.params.planId);
  return {
    submitBulkCreate: () => dispatch(actions.submitBulkCreate(planId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanSubscriptionBulkCreateSummaryView);
