export const quotaStyles = [
  {
    label: 'Starter',
    primaryText: 'Starter',
    value: 'starter',
  },
  {
    label: 'Preferred',
    primaryText: 'Preferred',
    value: 'preferred',
  },
  {
    label: 'Premium',
    primaryText: 'Premium',
    value: 'premium',
  },
  {
    label: 'Global Area Under Management',
    primaryText: 'Global Area Under Management',
    value: 'global_area_under_management',
  },
  {
    // We disabled these because we don't want anyone using the UI to apply any of these values
    disabled: true,
    label: 'Charge first download starter',
    primaryText: 'Charge first download starter',
    value: 'cfd_starter',
  },
  {
    disabled: true,
    label: 'Charge first download preferred',
    primaryText: 'Charge first download preferred',
    value: 'cfd_preferred',
  },
  {
    disabled: true,
    label: 'Charge first download premium',
    primaryText: 'Charge first download premium',
    value: 'cfd_premium',
  },
];

export const getQuotaStylePrimaryText = key => {
  if (key === null) {
    return key;
  }
  const quotaStyle = quotaStyles.find(quotaStyle => quotaStyle.value === key);
  return quotaStyle ? quotaStyle.primaryText : '';
};
