import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import {createAction as create} from 'redux-actions';

import * as types from './types';
import {PARAM_KEYS} from 'admin-ng/constants';
import {all, del as deleteInvitation} from 'admin-ng/api/invitations';

export const fetchAction = create(types.FETCH);
export const fetch = () => async (dispatch, getState) => {
  dispatch(fetchAction({isFetching: true}));
  const params = pickBy(pick(getState().invitations, PARAM_KEYS));
  if (params.filters) {
    params.state__in = params.filters;
    delete params.filters;
  }
  try {
    const {
      body: {
        results: invitations,
        meta: {count: total},
      },
    } = await all(params);
    dispatch(fetchAction({invitations, total}));
  } catch (err) {
    dispatch(fetchAction(err));
  }
};

export const setParams = create(types.SET_PARAMS);
export const reset = create(types.RESET);

export const deleteAction = create(types.DELETE);
export const del = id => async dispatch => {
  dispatch(deleteAction({isDeleting: true}));
  try {
    await deleteInvitation(id);
    dispatch(deleteAction());
    dispatch(fetch());
  } catch (err) {
    dispatch(deleteAction(err));
  }
};
