import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {HashRouter, Link, withRouter} from 'react-router-dom';
import {arrayOf, bool, func, number, object} from 'prop-types';

import Autocomplete from 'admin-ng/components/common/autocomplete';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import PLCheckbox from 'admin-ng/components/common/checkbox';

class MemberChangeOrg extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteEmptyOrg: false,
      isConfirmationOpen: false,
      isDialogOpen: false,
      parentOrgName: '',
      parentOrgId: null,
    };

    this._openDialog = this._openDialog.bind(this);
    this._onOrgNameChange = this._onOrgNameChange.bind(this);
    this._requestOrgsByName = this._requestOrgsByName.bind(this);
    this._selectOrg = this._selectOrg.bind(this);
    this._confirmChangeOrganization =
      this._confirmChangeOrganization.bind(this);

    this._openConfirmation = this._openConfirmation.bind(this);
    this._cancelDialog = this._cancelDialog.bind(this);
    this._closeConfirmation = this._closeConfirmation.bind(this);
    this._setDeleteStatus = this._setDeleteStatus.bind(this);
  }

  componentWillUnmount() {
    this.props.resetState();
  }

  _openDialog() {
    this.setState({
      isDialogOpen: true,
    });
  }

  _selectOrg(event, org) {
    this.setState({
      parentOrgId: org?.id ?? null,
    });
  }

  _onOrgNameChange(value) {
    this.setState({
      parentOrgName: value,
      parentOrgId: null,
    });
  }

  _requestOrgsByName() {
    if (this.state.parentOrgName && this.state.parentOrgName.length > 2) {
      this.props.requestOrgs(this.state.parentOrgName);
    }
  }

  _cancelDialog() {
    this.setState({
      isDialogOpen: false,
      parentOrgName: '',
      parentOrgId: null,
    });
  }

  _openConfirmation() {
    this.setState({
      isConfirmationOpen: true,
    });
  }

  _confirmChangeOrganization() {
    this.props.onChangeOrg(this.state.parentOrgId);
    if (this.state.deleteEmptyOrg) {
      this.props.onDeleteOldOrg(this.props.oldParentOrg.id);
    }

    this.setState({
      isDialogOpen: false,
      parentOrgName: '',
      parentOrgId: null,
    });
  }

  _closeConfirmation() {
    this.setState({
      isConfirmationOpen: false,
    });
  }

  _setDeleteStatus() {
    this.setState(prevState => ({
      deleteEmptyOrg: !prevState.deleteEmptyOrg,
    }));
  }

  render() {
    const {oldParentOrg, oldParentOrgMemberCount, userRoleLevel} = this.props;
    const deleteOrgOption =
      oldParentOrgMemberCount === 1 &&
      userRoleLevel === 2000 &&
      oldParentOrg.state !== 'deleted';
    const redirectPath = this.state.deleteEmptyOrg
      ? '/organizations'
      : '/organizations/' + this.props.params.orgId + '/members';

    if (this.props.isOrgChanged) {
      setTimeout(() => {
        this.props.history.push(redirectPath);
      });
    }
    const disabled = this.state.parentOrgId === null;

    const userHint = (
      <span className="pl-field-instructions">
        Start typing a name of an organization (min 3 characters).
        <br />
        <HashRouter>
          <Link target="_blank" to="/organizations">
            See all organizations
          </Link>
        </HashRouter>
      </span>
    );

    return (
      <>
        <Dialog fullWidth maxWidth="sm" open={this.state.isDialogOpen}>
          <DialogTitle>Change member organization</DialogTitle>

          <DialogContent>
            <Autocomplete
              label="Organization"
              onInputChange={this._onOrgNameChange}
              onInputCompleted={this._requestOrgsByName}
              onChange={this._selectOrg}
              options={this.props.organizations || []}
              userHintBottomText={userHint}
            />

            {deleteOrgOption && (
              <Box marginTop={2}>
                <PLCheckbox
                  checked={this.state.deleteEmptyOrg}
                  fieldInstructions={`Moving this user will result in this organization having 0 members. Select this option to also remove the vacant organization ${this.props.oldParentOrg.name}.`}
                  label="Delete Organization with no users?"
                  onChange={this._setDeleteStatus}
                  qaId="checkbox-delete-empty-org"
                />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            <Button color="inherit" onClick={this._cancelDialog}>
              Cancel
            </Button>

            <Button
              color="primary"
              disabled={disabled}
              focusRipple
              onClick={!disabled ? this._openConfirmation : null}
              variant="contained"
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmationMessage
          onClose={this._closeConfirmation}
          onSubmit={this._confirmChangeOrganization}
          open={this.state.isConfirmationOpen}
          title="Change member organization"
        >
          Move the member to the new organization?
        </ConfirmationMessage>

        <Button
          className="pl-btn-flat--primary"
          color="primary"
          disabled={this.props.isReadonly}
          onClick={!this.props.isReadonly ? this._openDialog : null}
          variant="outlined"
        >
          Change Organization
        </Button>
      </>
    );
  }
}

MemberChangeOrg.propTypes = {
  history: object.isRequired,
  isOrgChanged: bool,
  isReadonly: bool,
  oldParentOrg: object,
  oldParentOrgMemberCount: number,
  onChangeOrg: func,
  onDeleteOldOrg: func,
  organizations: arrayOf(object),
  params: object,
  requestOrgs: func,
  resetState: func,
  userRoleLevel: number,
};

export default withRouter(MemberChangeOrg);
