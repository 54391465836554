import React from 'react';
import {array, bool, func, object, shape, string} from 'prop-types';

import MultiCheckbox from 'admin-ng/components/common/multi-checkbox';
import Notification from 'admin-ng/components/common/notification';

const AssetTypes = ({
  availableAssetTypes,
  error,
  isAssetTypesChanged,
  itemType: _itemType,
  onUpdateAssetTypes,
  selectedAssetTypes,
}) => {
  const itemType = _itemType && _itemType.value;

  return (
    <div className="pl-constraint-data--asset-types">
      <h3 className="pl-download-types-title">Asset Types</h3>

      {itemType && (
        <p className="pl-form--footnote pl-asset-types-footnote">
          {`Only assets corresponding to the ${itemType} item type will be available.`}
        </p>
      )}

      {error && (
        <Notification message={error} title="Asset Types Error" type="error" />
      )}

      <MultiCheckbox
        changed={isAssetTypesChanged}
        items={availableAssetTypes}
        onUpdate={onUpdateAssetTypes}
        selectedItems={selectedAssetTypes.items}
      />
    </div>
  );
};

AssetTypes.propTypes = {
  availableAssetTypes: array.isRequired,
  error: string,
  isAssetTypesChanged: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  itemType: object,
  onUpdateAssetTypes: func,
  selectedAssetTypes: shape({
    items: array,
    newItems: array,
  }),
};

export default AssetTypes;
