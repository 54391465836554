import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import React, {useCallback, useEffect, useState} from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {Link, useLocation} from 'react-router-dom';
import {useAuth} from '@apps-common/auth-react';

import APIKeysHeader from './api-keys';
import ComplianceHeader from './compliance';
import DocumentsHeader from './documents';
import InvitationsHeader from './invitations';
import OrgsHeader from './organizations';
import PlansHeader from './plans';
import ProgramsHeader from './programs';
import SignetIcon from 'admin-ng/components/common/signet-icon';
import SubscriptionsHeader from './subscriptions';
import UsersHeader from './users';
import {ROLES} from 'admin-ng/constants';

const Header = () => {
  const location = useLocation();
  const auth = useAuth();
  const notFound = location.pathname === '/page-not-found';
  const [title, setTitle] = useState(notFound ? 'Page not found' : '');

  const updateTitle = useCallback(
    title => {
      if (notFound) {
        return;
      }

      setTitle(title);
      document.title = title;
    },
    [notFound]
  );

  useEffect(() => {
    if (notFound) {
      setTitle('Page not found');
    }
  }, [notFound]);

  // TODO: We don't have a member id, so we can't link to the logged in user's profile

  return (
    <div>
      <AppBar position="static">
        <Toolbar sx={{paddingY: 2}}>
          <Box sx={{mr: 2}}>
            <Link className="anim" to="/organizations">
              <SignetIcon />
            </Link>
          </Box>
          <Box sx={{flexGrow: 1}}>
            <div className="pl-breadcrumbs">
              <OrgsHeader updateTitle={updateTitle} />
              <PlansHeader updateTitle={updateTitle} />
              <SubscriptionsHeader updateTitle={updateTitle} />
              <InvitationsHeader updateTitle={updateTitle} />
              <UsersHeader updateTitle={updateTitle} />
              <APIKeysHeader updateTitle={updateTitle} />
              <ComplianceHeader updateTitle={updateTitle} />
              <ProgramsHeader updateTitle={updateTitle} />
              <DocumentsHeader updateTitle={updateTitle} />
              {notFound && 'Page not found'}
            </div>
            <Typography component="h1" variant="h5">
              {title}
            </Typography>
          </Box>

          <Box sx={{display: 'flex', alignItems: 'center'}}>
            {auth.user.claims.role_level === ROLES.planetAdminViewOnly && (
              <Alert severity="warning">Planet Admin View Only!</Alert>
            )}

            <Button
              color="inherit"
              component={Link}
              sx={{textTransform: 'lowercase'}}
              to={`/organizations/${auth.user.claims.organization_id}/members`}
            >
              {auth.user.claims.email}
            </Button>

            <Button
              color="inherit"
              onClick={() => auth.signOut()}
              startIcon={<PowerSettingsNewIcon />}
              data-testid="logout-button"
            >
              Log out
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
