import * as constants from './constants';
import {ROLES} from 'admin-ng/constants';

const role = ROLES.orgUser;
const initial = {
  error: null,
  isEmailUnique: false,
  isInviteRequested: false,
  isInviteSent: false,
  isEmailCheckRequested: false,
  emailError: null,
  roleError: null,
  role,
  email: '',
};

export default function orgMemberInviteReducer(state = initial, action = {}) {
  switch (action.type) {
    case constants.SEND_USER_INVITE_REQUEST:
      return Object.assign({}, state, {
        isInviteRequested: true,
        error: null,
      });

    case constants.COMPLETE_USER_INVITE_REQUEST:
      return Object.assign({}, state, {
        isInviteRequested: false,
        isInviteSent: true,
      });

    case constants.USER_INVITE_REQUEST_FAILED:
      return Object.assign({}, state, {
        isInviteRequested: false,
        error: action.error,
      });

    case constants.REQUEST_USER_EMAIL_CHECK:
      return Object.assign({}, state, {
        isEmailCheckRequested: true,
      });

    case constants.COMPLETE_USER_EMAIL_CHECK:
      return Object.assign({}, state, {
        isEmailCheckRequested: false,
        isEmailUnique: action.isEmailUnique,
        emailError: action.error || null,
      });

    case constants.UPDATE_EMAIL:
      return Object.assign({}, state, {
        email: action.email,
        emailError: action.error,
        isEmailUnique: initial.isEmailUnique,
      });

    case constants.UPDATE_ROLE:
      return Object.assign({}, state, {
        role: action.role,
        roleError: action.error,
      });

    case constants.RESET_INVITE_STATE:
      return Object.assign({}, initial);

    default:
      return Object.assign({}, state);
  }
}
