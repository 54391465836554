import React, {useCallback} from 'react';
import {Client as AuthClient, issuers} from '@apps-common/auth';
import {AuthProvider} from '@apps-common/auth-react';
import {arrayOf, node, oneOfType} from 'prop-types';
import {identify} from '../analytics';
import {setAuthToken} from '@apps-common/js-client/api/request';

const redirectUri = `${window.location.origin}${window.location.pathname}`;

export const authClient = new AuthClient({
  clientId: import.meta.env.VITE_AUTH_CLIENT_ID,
  issuer:
    import.meta.env.VITE_AUTH_ISSUER === 'production'
      ? issuers.production
      : issuers.staging,
  redirectUri,
  postLogoutRedirectUri: redirectUri,
});

const AuthStore = ({children}) => {
  const onAuthChange = useCallback(state => {
    setAuthToken(state.user?.accessToken || '');

    if (!state.pending && !state.error && state.user) {
      identify(state.user.claims);
    }
  }, []);

  return (
    <AuthProvider client={authClient} onChange={onAuthChange} private>
      {children}
    </AuthProvider>
  );
};

AuthStore.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export default AuthStore;
