import * as constants from './constants';
import * as messages from '../plan-subscription-bulk-create-general/messages';
import {bulkCreate} from 'admin-ng/api/subscriptions';

export const requestBulkCreate = params => ({
  type: constants.REQUEST_BULK_CREATE,
  payload: params,
});

export const requestBulkCreateSuccess = () => ({
  type: constants.REQUEST_BULK_CREATE_SUCCESS,
});

export const requestBulkCreateFailed = (
  message = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_BULK_CREATE_FAILED,
  payload: message,
  error: true,
});

const addTileQuotaParams = (params, ui, key) => {
  if (!ui.enabled) {
    return;
  }
  params[key] = {
    limit: ui.limit,
    refresh: ui.refresh,
    enabled: ui.enabled,
    overage: ui.overage,
  };
};

export const submitBulkCreate = plan_id => {
  return (dispatch, getState) => {
    const state = getState();
    const bulkCreateState = state.plans.instance.subscriptions.bulkCreate;
    const detailsState = bulkCreateState.details;
    const selectOrgsState = bulkCreateState.selectOrgs;
    const reference = detailsState.reference;
    const {
      ui: {
        interval: quota_interval,
        amount: quota_sqkm,
        isEnabled: quota_enabled,
        style: quota_style,
      },
    } = state.subscriptions.quota;
    const {ui: basemapTileQuotaUI} = state.subscriptions.basemapTileQuota;
    const {ui: sceneTileQuotaUI} = state.subscriptions.sceneTileQuota;

    const params = {
      active_from: detailsState.startDate,
      active_to: detailsState.endDate,
      organization_ids: selectOrgsState.selectedOrgs.map(org => org.id),
      plan_id,
      quota_enabled,
    };
    addTileQuotaParams(params, basemapTileQuotaUI, 'basemap_tile_quota');
    addTileQuotaParams(params, sceneTileQuotaUI, 'scene_tile_quota');

    if (quota_enabled) {
      params.quota_interval = quota_interval;
      params.quota_sqkm = quota_sqkm;
      params.quota_style = quota_style;
    }

    if (reference) {
      params.reference = reference;
    }

    dispatch(requestBulkCreate(params));

    return bulkCreate(params)
      .then(() => {
        dispatch(requestBulkCreateSuccess());
      })
      .catch((err = {}) => {
        dispatch(requestBulkCreateFailed(err.messages));
      });
  };
};
