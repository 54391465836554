import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {bool, func, string} from 'prop-types';

import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import Notification from 'admin-ng/components/common/notification';
import TextField from 'admin-ng/components/common/text-field';

class MemberChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDialogOpen: false,
      isConfirmationOpen: false,
    };

    this._openDialog = this._openDialog.bind(this);
    this._openConfirmation = this._openConfirmation.bind(this);
    this._cancelDialog = this._cancelDialog.bind(this);
    this._closeConfirmation = this._closeConfirmation.bind(this);
    this._confirmChangePassword = this._confirmChangePassword.bind(this);
    this._handleTextDataChange = this._handleTextDataChange.bind(this);
    this._onPasswordCheck = this._onPasswordCheck.bind(this);
    this._onPasswordMatchingCheck = this._onPasswordMatchingCheck.bind(this);
  }

  _openDialog() {
    this.setState({
      isDialogOpen: true,
    });
  }

  _cancelDialog() {
    this.setState({
      isDialogOpen: false,
    });

    this.props.reset();
  }

  _openConfirmation() {
    this.setState({
      isConfirmationOpen: true,
    });
  }

  _closeConfirmation() {
    this.setState({
      isConfirmationOpen: false,
    });
  }

  _confirmChangePassword() {
    this.props.saveNewPassword();
  }

  _onPasswordCheck() {
    this.props.onPasswordValidityCheck(this.props.password);

    if (this.props.passwordRepeat) {
      this.props.onPasswordMatchingCheck(
        this.props.password,
        this.props.passwordRepeat
      );
    }
  }

  _onPasswordMatchingCheck() {
    if (this.props.password) {
      this.props.onPasswordMatchingCheck(
        this.props.password,
        this.props.passwordRepeat
      );
    }
  }

  _handleTextDataChange(field, value) {
    this.props.onDataChange({
      field,
      value,
    });
  }

  render() {
    const canSubmit =
      this.props.password &&
      this.props.passwordRepeat &&
      this.props.password === this.props.passwordRepeat &&
      !this.props.passwordError &&
      !this.props.passwordRepeatError;

    return (
      <>
        {this.props.isPasswordChanged ? (
          <Dialog fullWidth maxWidth="sm" open={this.props.isPasswordChanged}>
            <DialogTitle>Save New Password</DialogTitle>

            <DialogContent>
              <DialogContentText>
                The password has been successfully changed
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button focusRipple onClick={this._cancelDialog}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <>
            <Dialog fullWidth maxWidth="sm" open={this.state.isDialogOpen}>
              <DialogTitle>New Password</DialogTitle>

              <DialogContent>
                <Notification
                  message={this.props.passwordRequestError}
                  title="Oops, could not save new password"
                  type="error"
                />

                <TextField
                  error={!!this.props.passwordError}
                  helperText={this.props.passwordError}
                  label="Password"
                  onChange={this._handleTextDataChange.bind(this, 'password')}
                  onChangeCompleted={this._onPasswordCheck}
                  type="password"
                  value={this.props.password || ''}
                />

                <TextField
                  error={!!this.props.passwordRepeatError}
                  helperText={this.props.passwordRepeatError}
                  label="Repeat password"
                  onChange={this._handleTextDataChange.bind(
                    this,
                    'passwordRepeat'
                  )}
                  onChangeCompleted={this._onPasswordMatchingCheck}
                  type="password"
                  value={this.props.passwordRepeat || ''}
                />
              </DialogContent>

              <DialogActions>
                <Button color="inherit" onClick={this._cancelDialog}>
                  Cancel
                </Button>

                <Button
                  color="primary"
                  disabled={!canSubmit}
                  focusRipple
                  label="Submit"
                  onClick={canSubmit ? this._openConfirmation : null}
                  variant="contained"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>

            <ConfirmationMessage
              onClose={this._closeConfirmation}
              onSubmit={this._confirmChangePassword}
              open={this.state.isConfirmationOpen}
              title="New Password"
            >
              Save new password?
            </ConfirmationMessage>
          </>
        )}

        <Button
          className="pl-btn-flat--primary"
          color="primary"
          disabled={this.props.isReadonly}
          onClick={!this.props.isReadonly ? this._openDialog : null}
          variant="outlined"
        >
          Change password
        </Button>
      </>
    );
  }
}

MemberChangePassword.propTypes = {
  isPasswordChanged: bool,
  isReadonly: bool,
  onDataChange: func.isRequired,
  onPasswordMatchingCheck: func.isRequired,
  onPasswordValidityCheck: func.isRequired,
  password: string,
  passwordError: string,
  passwordRepeat: string,
  passwordRepeatError: string,
  passwordRequestError: string,
  reset: func,
  saveNewPassword: func,
};

export default MemberChangePassword;
