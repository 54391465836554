import React, {Component} from 'react';
import classNames from 'classnames';
import {func, object, string} from 'prop-types';

import Checkbox from 'admin-ng/components/common/checkbox';

const checkboxStyle = {
  label: {
    whiteSpace: 'normal',
  },
};

class OrganizationsCountLabel extends Component {
  constructor(props) {
    super(props);

    this._onToggleOneOrgPlans = this._onToggleOneOrgPlans.bind(this);
  }

  _onToggleOneOrgPlans(event) {
    event.stopPropagation();
    this.props.onToggleOneOrgPlans();
  }

  _handleFilterClick(event) {
    event.stopPropagation();
  }

  render() {
    const filterClass = classNames({
      filter: true,
      active: this.props.viewParams.excludeOneOrgPlans,
    });

    return (
      <div className="organization-count-label">
        <span>{this.props.columnLabel}</span>
        <div className={filterClass} onClick={this._handleFilterClick}>
          <div className="filter-dropdown">
            <div className="filter-dropdown--content">
              <Checkbox
                checked={this.props.viewParams.excludeOneOrgPlans}
                label={this.props.excludeLabel}
                onChange={this._onToggleOneOrgPlans}
                size="small"
                style={checkboxStyle.label}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrganizationsCountLabel.propTypes = {
  columnLabel: string.isRequired,
  excludeLabel: string.isRequired,
  onToggleOneOrgPlans: func.isRequired,
  viewParams: object.isRequired,
};

export default OrganizationsCountLabel;
