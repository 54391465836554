import React, {Component} from 'react';
import {bool, number, oneOfType, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import Table from 'admin-ng/components/common/table';
import {all} from 'admin-ng/api/audit-logs';

export default class AuditLog extends Component {
  static propTypes = {
    isPlanetAdmin: bool.isRequired,
    modelId: oneOfType([string, number]),
    modelName: string.isRequired, // eslint-disable-line react/no-unused-prop-types
  };

  state = {
    entries: [],
    error: null,
    fetchStarted: false,
    offset: 0,
    limit: 10,
  };

  componentDidMount() {
    if (this.props.isPlanetAdmin && this.props.modelId) {
      this._fetch(this.props);
    }
  }

  componentDidUpdate({modelId, modelName}) {
    if (
      this.props.isPlanetAdmin &&
      modelId &&
      (modelId !== this.props.modelId || !this.state.fetchStarted)
    ) {
      this._fetch({modelId, modelName});
    }
  }

  _formatEntry = (value, index) => (
    <div data-qa-id={`entry-${index}`} key={`entry-${index}`}>
      {String(value)}
    </div>
  );

  _formatRow = ({changeset, created_at: createdAt, changed_by: name}) => {
    const names = Object.keys(changeset).filter(
      property => property !== 'updated_at'
    );
    let labels = names.map(this._formatEntry);
    if (labels.length === 0) {
      labels = <div>created</div>;
    }
    const oldStates = names.map((property, index) =>
      this._formatEntry(changeset[property][0], index)
    );
    const newStates = names.map((property, index) =>
      this._formatEntry(changeset[property][1], index)
    );
    return {createdAt, name, properties: labels, oldStates, newStates};
  };

  _fetch = async ({modelName, modelId}) => {
    this.setState({fetchStarted: true});
    const {offset, limit} = this.state;
    try {
      const {
        body: {
          results,
          meta: {count},
        },
      } = await all(modelName, modelId, {
        offset,
        limit,
        sort: '-created_at',
      });
      const entries = results.map(this._formatRow);
      this.setState({entries, count});
    } catch ({message: error}) {
      this.setState({error});
    }
  };

  // setState is asynchronous so we have to make the statements sequential
  _onPageChange = ({offset}) =>
    this.setState({offset}, () => this._fetch(this.props));

  get _dataSchema() {
    return [
      {
        field: 'createdAt',
        label: 'Edited At',
        width: '25%',
        isSortable: false,
      },
      {
        field: 'name',
        label: 'Edited By',
        width: '15%',
        isSortable: false,
      },
      {
        field: 'properties',
        label: 'Property Names',
        width: '15%',
        isSortable: false,
      },
      {
        field: 'oldStates',
        label: 'Old State',
        width: '20%',
        isSortable: false,
      },
      {
        field: 'newStates',
        label: 'New State',
        width: '20%',
        isSortable: false,
      },
    ];
  }

  render() {
    const {isPlanetAdmin} = this.props;
    const {count, entries, error, limit, offset} = this.state;

    return isPlanetAdmin ? (
      <Card title="Audit Log" qaId="audit-log">
        <Notification
          message={error}
          title="Oops, Audit Log request has failed"
          type="error"
        />

        <Table
          className="pl-audit-log-table"
          dataSchema={this._dataSchema}
          qaId="audit-log-table"
          srcData={entries}
        />

        <Pagination
          itemsLimit={limit}
          itemsOffset={offset}
          itemsTotal={count}
          onPageChange={this._onPageChange}
        />
      </Card>
    ) : null;
  }
}
