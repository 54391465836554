import React, {useEffect} from 'react';
import {Link, Route, useParams, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {func, object} from 'prop-types';

const DocumentLink = ({details}) => {
  const {documentId} = useParams();
  return (
    <Link to={`/documents/${documentId}`}>{details ? details.name : ''}</Link>
  );
};

DocumentLink.propTypes = {
  details: object,
};

const DocumentsHeader = ({details, updateTitle}) => {
  const rootPath = useRouteMatch('/documents');
  const detailPath = useRouteMatch('/documents/:documentId');

  useEffect(() => {
    if (rootPath && rootPath.isExact) {
      updateTitle('Documents');
    }
    if (detailPath && detailPath.isExact) {
      updateTitle(details ? details.name : '');
    }
  }, [details, updateTitle, rootPath, detailPath]);

  return (
    <Route path="/documents">
      <Link to="/documents">Documents</Link>
      <Route path="/documents/:documentId">
        {' / '}
        <DocumentLink details={details} />
      </Route>
    </Route>
  );
};

const mapStateToProps = ({
  documents: {
    instance: {details},
  },
}) => ({
  details,
});

DocumentsHeader.propTypes = {
  details: object,
  updateTitle: func.isRequired,
};

export default connect(mapStateToProps)(DocumentsHeader);
