import React from 'react';
import {NavLink} from 'react-router-dom';
import {node, object, string} from 'prop-types';

const Tab = props => (
  <NavLink
    activeClassName="active"
    className="pl-tab"
    data-qa-id={props.qaId}
    style={props.style}
    to={props.link}
  >
    {props.children}
  </NavLink>
);

Tab.propTypes = {
  children: node,
  link: string,
  qaId: string,
  style: object,
};

export default Tab;
