import React, {Component} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {func, object} from 'prop-types';

import BulkCreate from '../plan-subscriptions/plan-subscription-bulk-create/plan-subscription-bulk-create-general';
import General from '../plan-general/plan-general-container';
import Notification from 'admin-ng/components/common/notification';
import Policies from '../plan-policies/plan-policies-container';
import Sidebar from 'admin-ng/components/common/sidebar';
import SubscriptionInstance from 'admin-ng/components/common/subscription-details/instance';
import SubscriptionsIndex from '../plan-subscriptions/plan-subscriptions-index';
import Tab from 'admin-ng/components/common/tab';
import Tabs from 'admin-ng/components/common/tabs';

export default class PlanCommon extends Component {
  componentDidMount() {
    this.props.onOpen();
  }

  render() {
    let stateWarning = '';
    if (this.props.details && this.props.details.state !== 'active') {
      stateWarning = (
        <Notification
          message={`This package is in “${this.props.details.state}” state!`}
          title="Warning"
          type="warning"
        />
      );
    }

    return (
      <div className="pl-flex-wrapper">
        <Tabs className="pl-top-level-tabs">
          <Tab
            link={`/packages/${this.props.match.params.planId}/general`}
            qaId="plan-tab-general"
          >
            General
          </Tab>
          <Tab
            link={`/packages/${this.props.match.params.planId}/permissions`}
            qaId="plan-tab-policies"
          >
            Permissions
          </Tab>
          <Tab
            link={`/packages/${this.props.match.params.planId}/plans`}
            qaId="plan-tab-subscriptions"
          >
            Plans
          </Tab>
        </Tabs>

        <Sidebar />

        <div className="pl-app--content">
          {stateWarning}

          <Switch>
            <Route component={General} exact path="/packages/:planId/general" />

            <Route
              component={Policies}
              exact
              path="/packages/:planId/permissions"
            />

            <Route
              component={BulkCreate}
              exact
              path="/packages/:planId/plans/bulk-create"
            />

            <Route
              component={SubscriptionInstance}
              exact
              path="/packages/:planId/plans/:subscriptionId"
            />

            <Route
              component={SubscriptionsIndex}
              exact
              path="/packages/:planId/plans"
            />

            <Redirect to="/packages/:planId/general" />
          </Switch>
        </div>
      </div>
    );
  }
}

PlanCommon.propTypes = {
  details: object,
  match: object.isRequired,
  onOpen: func.isRequired,
};
