import assign from 'deep-extend';

import * as constants from './constants';

const initialState = {
  error: null,
  suborgs: [],
  suborgsTotalCount: 0,
  isSuborgsRequestPending: false,

  viewParams: {
    filters: 'active,disabled',
    limit: 20,
    offset: 0,
    sort: '-id',
    search: '',
  },
};

export default function suborgsIndexReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.SUBORGS_LIST_REQUEST:
      return assign({}, state, {
        isSuborgsRequestPending: true,
        viewParams: action.params,
      });

    case constants.SUBORGS_LIST_SUCCESS:
      return assign({}, state, {
        isSuborgsRequestPending: false,
        suborgs: action.items,
        suborgsTotalCount: action.itemsTotalCount,
        error: null,
      });

    case constants.SUBORGS_LIST_FAIL:
      return assign({}, state, {
        isSuborgsRequestPending: false,
        error: action.error,
      });

    case constants.SUBORGS_LIST_UNLINK_SUBORG_FAIL:
      return assign({}, state, {
        error: action.error,
      });

    case constants.SUBORGS_LIST_RESET_STATE:
      return assign({}, state, initialState);

    default:
      return assign({}, state);
  }
}
