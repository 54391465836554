import {handleActions as create} from 'redux-actions';

import * as types from './types';
import {ERROR_MESSAGE} from 'admin-ng/constants';

const initial = {
  apiKeys: [],
  error: null,
  filters: 'active,disabled',
  isFetching: false,
  limit: 20,
  offset: 0,
  search: '',
  sort: '-id',
  total: 0,
};

export const handlers = {
  [types.FETCH]: {
    next: (state, {payload: {isFetching = false, ...payload} = {}}) => ({
      ...state,
      ...payload,
      error: null,
      isFetching,
    }),
    throw: (state, {payload: {message: error}}) => ({
      ...state,
      isFetching: false,
      error: error || ERROR_MESSAGE,
    }),
  },

  [types.SET_PARAMS]: (state, {payload: params}) => ({...state, ...params}),
  [types.RESET]: () => ({...initial}),
};

export default create(handlers, initial);
