import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {bool, func, node, string} from 'prop-types';

class ConfirmationMessage extends Component {
  static propTypes = {
    children: node,
    onClose: func,
    onSubmit: func,
    open: bool.isRequired,
    qaId: string,
    title: string,
  };

  constructor(props) {
    super(props);

    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleSubmit() {
    this.props.onSubmit();
    this.props.onClose();
  }

  render() {
    return (
      <Dialog
        data-qa-id={this.props.qaId && this.props.qaId + '-dialog'}
        onClose={this.props.onClose}
        open={this.props.open}
      >
        <DialogTitle>{this.props.title}</DialogTitle>

        <DialogContent>
          <DialogContentText>{this.props.children}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="inherit"
            data-qa-id={this.props.qaId && this.props.qaId + '-cancel'}
            onClick={this.props.onClose}
          >
            No
          </Button>

          <Button
            data-qa-id={this.props.qaId && this.props.qaId + '-confirm'}
            focusRipple
            onClick={this._handleSubmit}
            color="primary"
            variant="contained"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmationMessage;
