import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React, {Component} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {array, bool, func, number} from 'prop-types';

import PolicyNameDialog from './policy-name-dialog';
import Table from 'admin-ng/components/common/table';

const orgSummaryTable = {
  wrapperStyle: {
    marginTop: '15px',
    maxHeight: '317px',
  },
};

class PolicyList extends Component {
  static propTypes = {
    onAddPolicy: func.isRequired,
    onNewNameSubmit: func,
    onOrgsRequest: func,
    onRemove: func.isRequired,
    onRename: func.isRequired,
    onSelectPolicy: func.isRequired,
    openPolicyNameDialog: bool,
    policies: array,
    selectedPolicy: number,
    subscriptions: array,
    subscriptionsTotalCount: number,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuAnchorEl: null,
      needPolicyDeleteConfirmation: false,
      policy: {
        name: null,
      },
    };

    this._isPolicyNameUnique = this._isPolicyNameUnique.bind(this);
    this._handleRemove = this._handleRemove.bind(this);
    this._closeDeleteConfirmation = this._closeDeleteConfirmation.bind(this);
    this._confirmPolicyDelete = this._confirmPolicyDelete.bind(this);
    this._renderRemovePolicyDialog = this._renderRemovePolicyDialog.bind(this);
    this._handleMenuClick = this._handleMenuClick.bind(this);
    this._handleMenuClose = this._handleMenuClose.bind(this);
  }

  _handleRemove(policy) {
    this.setState({
      needPolicyDeleteConfirmation: true,
      policy,
    });
  }

  _handleMenuClick(event) {
    event.stopPropagation();
    this.setState({menuAnchorEl: event.currentTarget});
  }

  _handleMenuClose() {
    this.setState({menuAnchorEl: null});
  }

  _isPolicyNameUnique(value) {
    return !this.props.policies
      .filter((policy, index) => index !== this.props.selectedPolicy)
      .map(policy => policy.name)
      .includes(value.trim());
  }

  _handlePolicySelect(index) {
    this.props.onSelectPolicy(index);
    this.setState({policy: this.props.policies.at(index)});
  }

  _confirmPolicyDelete() {
    this.props.onRemove();
    this.setState({
      needPolicyDeleteConfirmation: false,
    });
  }

  _closeDeleteConfirmation() {
    this.setState({
      needPolicyDeleteConfirmation: false,
      policy: {
        name: null,
      },
    });
  }

  _getPolicyName(policy) {
    return (
      <span className="pl-policy-name">{policy.newName || policy.name}</span>
    );
  }

  _getOrgDataSchema() {
    return [
      {
        field: 'name',
        sortKey: 'organization.name',
        width: '80%',
        label: 'Organization Name',
        contentCreator: item => {
          return (
            <Link target="_blank" to={`/organizations/${item.organization.id}`}>
              {item.organization.name}
            </Link>
          );
        },
        isSortable: false,
      },
    ];
  }

  /*
   * Ask for confirmation before removing a permission. List orgs that are subscribed to this plan.
   * Inform the user that removing this permission will affect them.
   */
  _renderRemovePolicyDialog() {
    const subs = this.props.subscriptions;
    const isSubscribedTo =
      subs && subs.length >= 1 && this.props.subscriptionsTotalCount >= 1;
    const confirmationMsg = `Are you sure you want to delete "${this.state.policy.name}"? This will immediately remove it from the package.`;
    const affectedOrgsMsg = `${this.props.subscriptionsTotalCount} organization(s) will be affected:`;

    return (
      <Dialog
        onClose={this._closeDeleteConfirmation}
        open={this.state.needPolicyDeleteConfirmation}
      >
        <DialogTitle>Remove Permission</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {confirmationMsg} {isSubscribedTo && affectedOrgsMsg}
          </DialogContentText>

          {isSubscribedTo && (
            <Table
              className="pl-confirmation-table"
              dataSchema={this._getOrgDataSchema()}
              onSort={this.props.onOrgsRequest}
              qaId="delete-policy-organizations-table"
              sortBy="organization.name"
              srcData={this.props.subscriptions}
              wrapperStyle={orgSummaryTable.wrapperStyle}
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="inherit"
            key={1}
            onClick={this._closeDeleteConfirmation}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            focusRipple
            onClick={this._confirmPolicyDelete}
            variant="contained"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    let itemClassName, isActive;

    return (
      <>
        {this._renderRemovePolicyDialog()}

        <List className="pl-policies-list">
          {this.props.policies.map((policy, index) => {
            isActive = index === this.props.selectedPolicy;

            itemClassName = classNames({
              'pl-policies-list--item': true,
              'is-changed': policy.newName,
              active: isActive,
            });

            return (
              <ListItem
                ContainerProps={{className: itemClassName}}
                button
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                onClick={this._handlePolicySelect.bind(this, index)}
              >
                <ListItemText primary={this._getPolicyName(policy)} />

                <ListItemSecondaryAction>
                  <IconButton
                    onClick={event => {
                      this._handleMenuClick(event);

                      // When you click the menu icon for a different policy,
                      // we want to select that policy (otherwise the rename
                      // functionality breaks)
                      this._handlePolicySelect.call(this, index);
                    }}
                    size="small"
                  >
                    <MoreVertIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}

          <Divider />

          <ListItem
            button
            data-qa-id="plan-add-policy"
            onClick={this.props.onAddPolicy}
          >
            <ListItemText primary="Add New" />
          </ListItem>
        </List>

        <Menu
          anchorEl={this.state.menuAnchorEl}
          anchorOrigin={{horizontal: 'left', vertical: 'top'}}
          className="pl-policy-menu-button"
          onClose={this._handleMenuClose}
          open={Boolean(this.state.menuAnchorEl)}
        >
          <MenuItem
            onClick={() => {
              this._handleMenuClose();
              this.props.onRename();
            }}
          >
            Rename
          </MenuItem>

          <MenuItem
            disabled={this.props.policies.length === 1}
            onClick={() => {
              this._handleMenuClose();
              this._handleRemove.call(this, this.state.policy);
            }}
          >
            Remove
          </MenuItem>
        </Menu>

        <PolicyNameDialog
          onNewNameSubmit={this.props.onNewNameSubmit}
          onValidate={this._isPolicyNameUnique}
          open={this.props.openPolicyNameDialog}
          originalName={
            this.props.policies.length && this.props.selectedPolicy !== null
              ? this.props.policies[this.props.selectedPolicy].name
              : ''
          }
        />
      </>
    );
  }
}

export default PolicyList;
