import assign from 'deep-extend';

import * as constants from './constants';
import {
  PLAN_DETAILS_RESET_STATE,
  RECEIVE_PLAN_DETAILS,
} from '../plan-common/constants';
import {PLAN_RESET_STATE} from 'admin-ng/components/plans/plan-instance/plan-create-workflow/constants';

const initialState = {
  authorOrganization: null,
  created: null,
  updated: null,
  error: null,
  originalPlanName: '', // Used for change detection on edit page, where plan name is saved independently
  planName: '',
  planNameError: null,
  isPlanNameUnique: null,
  isPlanNameChecking: false,
  isPlanSaveRunning: false,
};

export default function planGeneralReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_PLAN_DETAILS:
      return assign({}, state, {
        originalPlanName: action.details.name,
        planName: action.details.name,
        authorOrganization: action.details.author_organization,
        createdAt: action.details.created_at,
        updatedAt: action.details.updated_at,
      });

    case constants.UPDATE_NAME:
      return assign({}, state, {
        planName: action.name,
        isPlanNameUnique: null,
        planNameError: action.error,
      });

    case constants.REQUEST_NAME_CHECK:
      return assign({}, state, {
        planNameError: null,
        isPlanNameChecking: true,
      });

    case constants.NAME_CHECK_SUCCESS:
      return assign({}, state, {
        planNameError: null,
        isPlanNameChecking: false,
        isPlanNameUnique: true,
      });

    case constants.NAME_CHECK_FAIL:
      return assign({}, state, {
        planNameError: action.payload,
        isPlanNameChecking: false,
        isPlanNameUnique: false,
      });

    case PLAN_RESET_STATE:
    case constants.RESET_GENERAL_PLAN_DETAILS:
      return assign({}, state, {
        planName: '',
        originalPlanName: '',
        planNameError: null,
        isPlanNameChecking: false,
        isPlanNameUnique: null,
      });

    case constants.REQUEST_PLAN_NAME_SAVE:
      return assign({}, state, {
        error: null,
        isPlanSaveRunning: true,
      });

    case constants.PLAN_NAME_SAVE_REQUEST_COMPLETED:
      return assign({}, state, {
        isPlanSaveRunning: false,
        planName: action.name,
        originalPlanName: action.name,
      });

    case constants.PLAN_NAME_SAVE_REQUEST_FAILED:
      return assign({}, state, {
        isPlanSaveRunning: false,
        error: action.error,
      });

    case PLAN_DETAILS_RESET_STATE:
      return assign({}, initialState);

    default:
      return state;
  }
}
