import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import {bool, func, number, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import TextField from 'admin-ng/components/common/text-field';

const SuborgCreate = ({
  isNameUnique,
  isOpen,
  isSuborgCreated,
  name,
  nameError,
  onClose,
  onNameChange,
  onNameCheck,
  onSubmit,
  parentOrgProgramId,
  suborgCreateError,
}) => {
  const canSubmit = parentOrgProgramId !== null;

  const defaultActions = [
    <Button color="inherit" key={1} onClick={onClose}>
      Cancel
    </Button>,
    <Button
      color="primary"
      disabled={!canSubmit}
      key={2}
      onClick={canSubmit ? onSubmit.bind() : null}
      variant="contained"
    >
      Submit
    </Button>,
  ];

  const successActions = [
    <Button color="primary" key={1} onClick={onClose} variant="contained">
      Close
    </Button>,
  ];

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" onClose={onClose} open={isOpen}>
        <DialogTitle>Create a new suborganization</DialogTitle>

        <DialogContent>
          {isSuborgCreated ? (
            <DialogContentText>
              A new suborganization has been created.
            </DialogContentText>
          ) : (
            <>
              <Notification
                message={suborgCreateError}
                title="Oops, could not create a suborganization"
                type="error"
              />

              <TextField
                error={!!nameError}
                helperText={nameError}
                label="Suborganization Name *"
                isInputValid={isNameUnique}
                onChange={onNameChange}
                onChangeCompleted={onNameCheck}
                value={name}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          {isSuborgCreated ? successActions : defaultActions}
        </DialogActions>
      </Dialog>
    </div>
  );
};

SuborgCreate.propTypes = {
  isNameUnique: bool,
  isOpen: bool.isRequired,
  isSuborgCreated: bool,
  name: string,
  nameError: string,
  onClose: func.isRequired,
  onNameChange: func.isRequired,
  onNameCheck: func.isRequired,
  onSubmit: func.isRequired,
  parentOrgProgramId: number,
  suborgCreateError: string,
};

export default SuborgCreate;
