import assign from 'deep-extend';

import * as constants from './constants';
import * as messages from './messages';
import {all} from 'admin-ng/api/comments';

export const requestOrgComments = (params = {}) => ({
  type: constants.REQUEST_COMMENTS,
  params,
});

export const receiveOrgComments = (json = {}) => ({
  type: constants.RECEIVE_COMMENTS,
  items: json.results || null,
  itemsCount: json.meta ? json.meta.count : 0,
});

export const commentsRequestFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.COMMENTS_REQUEST_FAILED,
  error,
});

export const fetchComments = (orgId, params = {}) => {
  return (dispatch, getState) => {
    const state = getState(),
      commentsState = state.orgs.instance.general.comments.index;

    dispatch(requestOrgComments(params));

    const requestParams = assign({}, commentsState.viewParams, params);

    return all(orgId, requestParams).then(
      (res = {}) => {
        dispatch(receiveOrgComments(res.body));
      },
      (err = {}) => {
        dispatch(commentsRequestFailed(err.message));
      }
    );
  };
};
