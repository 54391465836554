import React from 'react';
import {bool, func, object, shape} from 'prop-types';

import Checkbox from 'admin-ng/components/common/checkbox';
import FormCard from 'admin-ng/components/common/form-card';

const DeliveryOptionsConstraint = props => {
  const onCheckGeoTiff = () => {
    props.onUpdateDeliveryOptions(
      'geoTIFF',
      !props.deliveryOptions.allowDeliveryGEOTIFF
    );
  };

  const onCheckWebtile = () => {
    props.onUpdateDeliveryOptions(
      'webtile',
      !props.deliveryOptions.allowDeliveryWebtile
    );
  };

  const showOptions = props.showOptions;

  return (
    <FormCard title="Delivery Options">
      <p className="pl-form--footnote pl-asset-types-footnote">
        Planet Explorer is always available as a delivery option.
      </p>

      {showOptions.download && (
        <Checkbox
          checked={props.deliveryOptions.allowDeliveryGEOTIFF}
          data-qa-id="mosaics-geo-tiff-checkbox"
          label="GeoTIFF Download"
          onChange={onCheckGeoTiff}
        />
      )}

      {showOptions.tiles && (
        <Checkbox
          checked={props.deliveryOptions.allowDeliveryWebtile}
          data-qa-id="mosaics-webtile-checkbox"
          label="Webtile Option (Non-Explorer)"
          onChange={onCheckWebtile}
        />
      )}
    </FormCard>
  );
};

DeliveryOptionsConstraint.propTypes = {
  deliveryOptions: shape({
    allowDeliveryGEOTIFF: bool,
    allowDeliveryWebtile: bool,
  }),
  onUpdateDeliveryOptions: func.isRequired,
  showOptions: object,
};

export default DeliveryOptionsConstraint;
