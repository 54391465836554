import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import {arrayOf, bool, func, number, object, string} from 'prop-types';
import {withRouter} from 'react-router-dom';

import Notification from 'admin-ng/components/common/notification';
import SelectField from 'admin-ng/components/common/select-field';
import TextField from 'admin-ng/components/common/text-field';

class OrgCreate extends Component {
  constructor(props) {
    super(props);

    this._renderPrograms = this._renderPrograms.bind(this);
    this._handleProgramChange = this._handleProgramChange.bind(this);
    this._onCloseSuccess = this._onCloseSuccess.bind(this);
  }

  componentWillMount() {
    this.props.onOpen();
  }

  _handleProgramChange(event) {
    const {value} = event.target;
    this.props.onOrgProgramChange(value);
  }

  _onCloseSuccess() {
    this.props.onClose();
    this.props.history.push(`/organizations/${this.props.newOrgId}/general`);
  }

  _renderPrograms() {
    let options = [];

    if (this.props.programs && this.props.programs.length) {
      options = this.props.programs.map((program, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={i} value={program.id}>
            {program.name}
          </MenuItem>
        );
      });
    }

    return options;
  }

  render() {
    return (
      <div>
        {this.props.isNewOrgCreated ? (
          <Dialog fullWidth maxWidth="sm" open={this.props.isOpen}>
            <DialogTitle>Create a new organization</DialogTitle>

            <DialogContent>
              <DialogContentText>
                A new organization has been created.
              </DialogContentText>

              {this.props.additionalMessage ? (
                <Notification
                  message={this.props.additionalMessage}
                  type="warning"
                />
              ) : null}
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                data-qa-id="org-create-close"
                focusRipple
                onClick={this._onCloseSuccess}
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={this.props.onClose}
            open={this.props.isOpen}
          >
            <DialogTitle>Create a new organization</DialogTitle>

            <DialogContent>
              <Notification
                data-qa-id="org-create-error"
                message={this.props.orgCreateError}
                title="Oops, could not create an organization"
                type="error"
              />

              <TextField
                data-qa-id="org-create-name"
                error={!!this.props.orgNameError}
                helperText={this.props.orgNameError}
                label="Organization Name *"
                isInputValid={this.props.isOrgNameUnique}
                onChange={this.props.onOrgNameChange}
                onChangeCompleted={this.props.onOrgNameCheck}
                value={this.props.orgName}
              />

              <TextField
                data-qa-id="org-create-SFDC"
                error={!!this.props.orgSfdcError}
                helperText={this.props.orgSfdcError}
                label="SFDC Account ID"
                placeholder="A customer's Salesforce ID is the last 15 digits of their SFDC account page URL."
                onChange={this.props.onOrgSfdcCheck}
                value={this.props.sfdc}
              />

              <SelectField
                error={!!this.props.orgProgramError}
                helperText={this.props.orgProgramError}
                label="Program *"
                onChange={this._handleProgramChange}
                qaId="org-create-program"
                value={this.props.orgProgramId}
              >
                {this._renderPrograms()}
              </SelectField>
            </DialogContent>

            <DialogActions>
              <Button
                color="inherit"
                data-qa-id="org-create-cancel"
                onClick={this.props.onClose}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                data-qa-id="org-create-submit"
                focusRipple
                onClick={this.props.onOrgSubmit}
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    );
  }
}

OrgCreate.propTypes = {
  additionalMessage: string,
  history: object.isRequired,
  isNewOrgCreated: bool,
  isOpen: bool.isRequired,
  isOrgNameUnique: bool,
  newOrgId: number,

  onClose: func.isRequired,
  onOpen: func.isRequired,
  onOrgNameChange: func.isRequired,
  onOrgNameCheck: func.isRequired,
  onOrgProgramChange: func.isRequired,
  onOrgSfdcCheck: func.isRequired,
  onOrgSubmit: func.isRequired,

  orgCreateError: string,
  orgName: string,
  orgNameError: string,
  orgProgramError: string,
  orgProgramId: number,
  orgSfdcError: string,
  programs: arrayOf(object),
  sfdc: string,
};

export default withRouter(OrgCreate);
