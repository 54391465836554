import assign from 'deep-extend';
import sortBy from 'lodash/sortBy';

import * as constants from './constants';
import {DEFAULT_ERROR_MSG} from '../plan-common/messages';
import {
  PLAN_DETAILS_RESET_STATE,
  RECEIVE_PLAN_DETAILS,
} from '../plan-common/constants';
import {PLAN_RESET_STATE} from '../plan-create-workflow/constants';
import {
  additionalItemTypes,
  doesRequireAoi,
  doesRequireToi,
} from 'admin-ng/util/item-types';
import {parsePolicies} from 'admin-ng/util/policies/parsers';

const initialAoi = {
  value: null,
  error: null,
  isValid: null,
};

const initialToi = {
  start: null,
  end: null,
  hasNoStartDate: false,
  hasNoEndDate: false,
  startError: null,
  endError: null,
  isValid: null,
};

export const initialItemType = {
  value: null,
  error: null,
  availableAssetTypes: [],
  selectedAssetTypes: {
    items: [],
    error: null,
  },
  isExpanded: true,
};

const initialPolicy = {
  name: null,
  error: null,
  aoiError: null,
  toiError: null,

  /**
   * data is a copy of the original ui state used for change detection
   * changes are only made to the ui array
   */
  itemType: {
    data: [{...initialItemType}],
    ui: [{...initialItemType}],
  },

  aoi: {
    data: [{...initialAoi}],
    ui: [{...initialAoi}],
  },

  toi: {
    data: [{...initialToi}],
    ui: [{...initialToi}],
    originalDelay: 0,
    delay: 0,
    originalDelayUnits: 'days',
    delayUnits: 'days',
    delayError: null,
  },

  deliveryOptions: {
    data: {
      allowDeliveryGEOTIFF: false,
      allowDeliveryWebtile: false,
    },
    ui: {
      allowDeliveryGEOTIFF: false,
      allowDeliveryWebtile: false,
    },
  },
};

const initialState = {
  items: [assign({}, initialPolicy, {name: 'New permission 1'})],
  selectedPolicy: 0,
  previouslySelectedPolicy: null,
  availableItemTypes: additionalItemTypes(),
  queryForNewName: false,
  itemTypesRequestError: null,
  isSaveRequestCompleted: false,
  isCopyPlanNameUnique: null,
  isPlanChanged: false,
  copyPlanError: null,
};

const getOrderedAssetTypes = (items = []) =>
  items.slice().sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

export default function planPoliciesReducer(state = initialState, action = {}) {
  let newState;
  let policy;
  let policyItemType;

  switch (action.type) {
    // ASSET TYPES
    case constants.PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES:
      newState = assign({}, state);
      policy = newState.items[action.policyIndex || newState.selectedPolicy];
      policyItemType = policy.itemType.ui[action.itemTypeIndex];

      policyItemType.selectedAssetTypes.error = null;
      policyItemType.availableAssetTypes = [];

      return newState;

    case constants.PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES_SUCCESS:
      newState = assign({}, state);
      policy = newState.items[action.policyIndex || newState.selectedPolicy];
      policyItemType = policy.itemType.ui[action.itemTypeIndex];
      policyItemType.availableAssetTypes = getOrderedAssetTypes(action.items);
      if (
        policyItemType.availableAssetTypes &&
        policyItemType.availableAssetTypes.length
      ) {
        policyItemType.availableAssetTypes.forEach(availableAssetType => {
          if (
            policyItemType.selectedAssetTypes.items.indexOf(
              availableAssetType.id
            ) !== -1
          ) {
            availableAssetType.selected = true;
          } else {
            availableAssetType.selected = false;
          }
        });
      }

      return newState;

    case constants.PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES_FAIL:
      newState = assign({}, state);
      policyItemType =
        newState.items[newState.selectedPolicy].itemType.ui[
          action.itemTypeIndex
        ];

      policyItemType.selectedAssetTypes.error = action.error;

      return newState;

    case constants.PLAN_DETAILS_SET_ASSET_TYPE_ERROR:
      newState = assign({}, state);
      policyItemType =
        newState.items[action.policyIndex || newState.selectedPolicy].itemType
          .ui[action.itemTypeIndex];
      policyItemType.selectedAssetTypes.error = action.error;

      return newState;

    case constants.PLAN_DETAILS_UPDATE_SELECTED_ASSET_TYPES:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];
      policyItemType = policy.itemType.ui[action.index];

      if (
        policyItemType.availableAssetTypes &&
        policyItemType.availableAssetTypes.length
      ) {
        const availableAssetTypes = policyItemType.availableAssetTypes.slice(0);
        let selectedAssetTypes = [];

        if (Array.isArray(action.items)) {
          availableAssetTypes.forEach(type => {
            type.selected = action.items.indexOf(type.id) !== -1;

            if (type.selected) {
              selectedAssetTypes.push(type.id);
            }
          });
        } else if (action.items === 'all') {
          availableAssetTypes.forEach(type => {
            type.selected = true;
            selectedAssetTypes.push(type.id);
          });
        } else if (action.items === 'none') {
          availableAssetTypes.forEach(type => {
            type.selected = false;
          });

          selectedAssetTypes = [];
        }

        policyItemType.selectedAssetTypes.items = selectedAssetTypes;
        policyItemType.selectedAssetTypes.error = null;
        policy.availableAssetTypes = availableAssetTypes;
      }

      return newState;

    case constants.PLAN_DETAILS_UPDATE_SELECTED_MOSAICS:
      newState = assign({}, state);
      policyItemType =
        newState.items[newState.selectedPolicy].itemType.ui[action.index];

      policyItemType.selectedAssetTypes.items = action.items;
      policyItemType.selectedAssetTypes.error = null;

      return newState;

    case constants.PLAN_DETAILS_UPDATE_DELIVERY_GEOTIFF: {
      newState = assign({}, state);
      const deliveryOptions =
        newState.items[newState.selectedPolicy].deliveryOptions.ui;
      deliveryOptions.allowDeliveryGEOTIFF = action.payload;
      return newState;
    }

    case constants.PLAN_DETAILS_UPDATE_DELIVERY_WEBTILE: {
      newState = assign({}, state);
      const deliveryOptions =
        newState.items[newState.selectedPolicy].deliveryOptions.ui;
      deliveryOptions.allowDeliveryWebtile = action.payload;
      return newState;
    }
    // ITEM TYPES
    case constants.PLAN_DETAILS_FETCH_AVAILABLE_ITEM_TYPES: {
      if (action.error) {
        return assign({}, state, {
          availableItemTypes: [],
          itemTypesRequestError:
            (action.payload && action.payload.message) || DEFAULT_ERROR_MSG,
        });
      }

      newState = assign({}, state);

      if (!action.payload) {
        return newState;
      }

      policy = newState.items[newState.selectedPolicy];

      newState.availableItemTypes = sortBy(
        action.payload.items,
        'display_name'
      );

      return newState;
    }

    case constants.PLAN_DETAILS_POLICY_ADD_ITEM_TYPE:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      // Add new item to start, so it appears at the top of the view
      policy.itemType.data.unshift({...initialItemType});
      policy.itemType.ui.unshift({...initialItemType});

      return newState;

    case constants.PLAN_DETAILS_POLICY_UPDATE_ITEM_TYPE: {
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      const newItemType = newState.availableItemTypes.find(
        itemType => itemType.id === action.itemType
      );
      const category = newItemType ? newItemType.category : null;
      policy.itemType.ui[action.index] = {
        value: action.itemType,
        error: action.error,
        availableAssetTypes: [],
        selectedAssetTypes: {
          items: [],
          error: null,
        },
        isExpanded: true,
        category,
      };

      // Don't modify AOI/TOI if it's an error
      if (action.error) {
        return newState;
      }

      const doesPolicyRequireAoi = policy.itemType.ui.some(itemType =>
        doesRequireAoi(itemType.category)
      );

      if (doesPolicyRequireAoi) {
        if (policy.aoi.ui.length === 0) {
          policy.aoi.data.push({...initialAoi});
          policy.aoi.ui.push({...initialAoi});
        }
      } else {
        policy.aoi.ui = [];
      }

      const doesPolicyRequireToi = policy.itemType.ui.some(itemType =>
        doesRequireToi(itemType.category)
      );

      if (doesPolicyRequireToi) {
        if (policy.toi.ui.length === 0) {
          policy.toi.data.push({...initialToi});
          policy.toi.ui.push({...initialToi});
        }
      } else {
        policy.toi.ui = [];
      }

      policy.error = null;

      return newState;
    }

    case constants.PLAN_DETAILS_POLICY_DELETE_ITEM_TYPE: {
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.itemType.data.splice(action.index, 1);
      policy.itemType.ui.splice(action.index, 1);

      return newState;
    }

    case constants.PLAN_DETAILS_POLICY_TOGGLE_ITEM_TYPE: {
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      const {index} = action;
      const dataItemType = policy.itemType.data[index];
      const uiItemType = policy.itemType.ui[index];

      dataItemType.isExpanded = !dataItemType.isExpanded;
      uiItemType.isExpanded = !uiItemType.isExpanded;

      return newState;
    }

    case constants.PLAN_DETAILS_POLICY_TOGGLE_ITEM_TYPE_ALL:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.itemType.data.forEach(itemType => {
        itemType.isExpanded = action.toggle;
      });

      policy.itemType.ui.forEach(itemType => {
        itemType.isExpanded = action.toggle;
      });

      return newState;
    // AOI
    case constants.PLAN_DETAILS_POLICY_ADD_AOI:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      // Add new item to start, so it appears at the top of the view
      policy.aoi.data.unshift({...initialAoi});
      policy.aoi.ui.unshift({...initialAoi});

      return newState;

    case constants.PLAN_DETAILS_POLICY_UPDATE_AOI:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.aoi.ui[action.index] = {
        error: action.error,
        isValid: action.isValid,
      };

      if (action.aoi) {
        policy.aoi.ui[action.index].value = action.aoi;
      }

      policy.aoiError = null;

      return newState;

    case constants.PLAN_DETAILS_POLICY_DELETE_AOI:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.aoi.data.splice(action.index, 1);
      policy.aoi.ui.splice(action.index, 1);

      return newState;

    case constants.PLAN_DETAILS_POLICY_SET_AOI_ERROR:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.aoiError = action.error;
      return newState;
    // TOI
    case constants.PLAN_DETAILS_POLICY_ADD_TOI:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      // Add new item to start, so it appears at the top of the view
      policy.toi.data.unshift({...initialToi});
      policy.toi.ui.unshift({...initialToi});

      return newState;

    case constants.PLAN_DETAILS_POLICY_DELETE_TOI:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.toi.data.splice(action.index, 1);
      policy.toi.ui.splice(action.index, 1);

      return newState;

    case constants.PLAN_DETAILS_POLICY_UPDATE_TOI_START:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.toi.ui[action.index] = {
        ...policy.toi.ui[action.index],
        start: action.date,
        hasNoStartDate: action.hasNoStartDate,
        startError: action.error,
        isValid: !action.error && policy.toi.ui[action.index].endError,
      };

      policy.toiError = null;

      return newState;

    case constants.PLAN_DETAILS_POLICY_UPDATE_TOI_END:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.toi.ui[action.index] = {
        ...policy.toi.ui[action.index],
        end: action.date,
        hasNoEndDate: action.hasNoEndDate,
        endError: action.error,
        isValid:
          action.error === null &&
          policy.toi.ui[action.index].startError === null,
      };

      policy.toiError = null;

      return newState;

    case constants.PLAN_DETAILS_POLICY_UPDATE_TOI_DELAY:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.toi.delay = action.delay;
      policy.toi.delayError = action.error;

      return newState;

    case constants.PLAN_DETAILS_POLICY_UPDATE_TOI_DELAY_UNITS:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.toi.delayUnits = action.unit;

      return newState;

    case constants.PLAN_DETAILS_POLICY_SET_TOI_ERROR:
      newState = assign({}, state);
      policy = newState.items[newState.selectedPolicy];

      policy.toiError = action.error;

      return newState;
    // WORKFLOW
    case constants.PLAN_DETAILS_POLICY_GET_NEW_NAME:
      return assign({}, state, {
        selectedPolicy: null,
        previouslySelectedPolicy: state.selectedPolicy,
        queryForNewName: true,
      });

    case constants.PLAN_DETAILS_POLICY_SET_NEW_NAME: {
      newState = assign({}, state, {
        queryForNewName: false,
        selectedPolicy: state.previouslySelectedPolicy,
        previouslySelectedPolicy: null,
      });

      if (!action.name) {
        return newState;
      }

      const newPolicyName = action.name.trim();

      if (state.selectedPolicy === null) {
        // Assign name to new policy
        const newPolicy = assign({}, initialPolicy, {
          name: newPolicyName,
        });

        newState.items.push(newPolicy);
        newState.selectedPolicy = newState.items.length - 1;
      } else {
        // Assign name to existing, selected policy
        newState.items[state.selectedPolicy].name = newPolicyName;
      }

      return newState;
    }

    case constants.PLAN_DETAILS_POLICY_RENAME:
      newState = assign({}, state, {
        previouslySelectedPolicy: state.selectedPolicy,
        queryForNewName: true,
      });

      return newState;

    case constants.PLAN_DETAILS_POLICY_SELECT:
      newState = assign({}, state, {
        selectedPolicy: action.index,
      });
      return newState;

    case constants.PLAN_DETAILS_POLICY_REMOVE:
      newState = assign({}, state);
      newState.items.splice(state.selectedPolicy, 1);
      newState.selectedPolicy = newState.items.length ? 0 : null;
      return newState;
    // PLAN_RESET_STATE is also called in general and workflow reducers
    // so that the whole plan is reset
    case PLAN_RESET_STATE:
    case PLAN_DETAILS_RESET_STATE:
      newState = assign({}, initialState);
      return newState;
    // INSTANCE SPECIFIC ACTIONS
    case constants.PLAN_DETAILS_SET_GLOBAL_ERROR:
      newState = assign({}, state, {
        error: action.error,
      });

      policy = newState.items[newState.selectedPolicy];
      policy.error = action.error;

      return newState;

    case constants.PLAN_DETAILS_RESET_GLOBAL_ERROR:
      return assign({}, state, {
        error: null,
      });

    case constants.PLAN_DETAILS_SAVE_REQUEST_COMPLETED:
      return assign({}, state, {
        newSaveAsPlanId: action.newSaveAsPlanId,
        isSaveRequestCompleted: true,
      });

    case constants.PLAN_DETAILS_SAVE_REQUEST_FAILED:
      return assign({}, state, {
        error: action.error,
      });

    case constants.PLAN_DETAILS_RESET_SAVED_STATE:
      return assign({}, state, {
        isSaveRequestCompleted: false,
      });

    case constants.PLAN_DETAILS_PLAN_NAME_CHECK:
      return assign({}, state, {
        isCopyPlanNameUnique: null,
      });

    case constants.PLAN_DETAILS_COMPLETE_PLAN_NAME_CHECK:
      return assign({}, state, {
        isCopyPlanNameUnique: action.isCopyPlanNameUnique,
        copyPlanError: action.error,
      });

    case constants.PLAN_DETAILS_CHANGED:
      return assign({}, state, {
        isPlanChanged: action.isPlanChanged,
      });

    case RECEIVE_PLAN_DETAILS: {
      const items = parsePolicies(
        action.details.policies,
        state.availableItemTypes
      );
      return assign({}, state, {items});
    }

    default:
      return assign({}, state);
  }
}
