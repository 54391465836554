import * as constants from './constants';

const initialState = {
  commentsRequested: false,

  newCommentAddRequested: false,
  newCommentAdded: false,
  newCommentError: null,
  newComment: null,

  error: null,
};

export default function orgCommentAddReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.UPDATE_NEW_COMMENT:
      return {
        ...state,
        newComment: action.comment,
        newCommentAdded: false,
      };

    case constants.REQUEST_ADD_COMMENT:
      return {
        ...state,
        error: null,
        newCommentAddRequested: true,
      };

    case constants.ADD_COMMENT_COMPLETED:
      return {
        ...state,
        error: null,
        newCommentAddRequested: false,
        newCommentAdded: true,
        newComment: null,
      };

    case constants.ADD_COMMENT_FAILED:
      return {
        ...state,
        error: action.error,
        newCommentAddRequested: false,
      };

    default:
      return state;
  }
}
