import assign from 'deep-extend';
import {isEmpty} from 'lodash';

import * as constants from './constants';
import * as messages from '../../plan-common/messages';
import {all, quotaUsage} from 'admin-ng/api/subscriptions';

export const requestPlanSubscriptions = params => ({
  type: constants.REQUEST_PLAN_SUBSCRIPTIONS,
  params,
});

export const planSubscriptionsRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.PLAN_SUBSCRIPTIONS_REQUEST_FAILED,
  error,
});

export const receivePlanSubscriptions = (json = {}) => ({
  type: constants.RECEIVED_PLAN_SUBSCRIPTIONS,
  items: json.results || [],
  itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
});

const getQueryParams = (planId, params, defaultParams) => {
  const requestParams = assign({}, defaultParams, params);

  requestParams.plan_id = planId;

  if (requestParams.hasOwnProperty('search') && requestParams.search) {
    requestParams['organization.name__icontains'] = requestParams.search;
  }

  delete requestParams.search;

  if (requestParams.filters) {
    requestParams.state__in = requestParams.filters;
    delete requestParams.filters;
  }

  return requestParams;
};

export const updateQuotaUsage = usages => ({
  type: constants.UPDATE_SUBSCRIPTIONS_QUOTA_USAGE,
  payload: usages,
});

export const quotaUsageRequestFailed = error => ({
  type: constants.QUOTA_USAGE_REQUEST_FAILED,
  error,
});

export const fetchQuotaUsage =
  (res = {}) =>
  dispatch => {
    if (!isEmpty(res.items)) {
      quotaUsage(res.items.map(subscription => subscription.id))
        .then((usageResponse = {}) => {
          if (!isEmpty(usageResponse.body)) {
            dispatch(updateQuotaUsage(usageResponse.body));
          }
        })
        .catch((err = {}) => {
          dispatch(quotaUsageRequestFailed(err.message));
        });
    }
  };

export const fetchSubscriptions = (planId, params = {}) => {
  return (dispatch, getState) => {
    dispatch(requestPlanSubscriptions(params));

    if (planId) {
      const state = getState(),
        planSubscriptionsState = state.plans.instance.subscriptions.index,
        stateViewParams = planSubscriptionsState.viewParams;

      const requestParams = getQueryParams(planId, params, stateViewParams);

      return all(requestParams)
        .then((res = {}) => {
          return dispatch(receivePlanSubscriptions(res.body));
        })
        .then(res => {
          dispatch(fetchQuotaUsage(res));
        })
        .catch((err = {}) => {
          dispatch(planSubscriptionsRequestFailed(err.message));
        });
    } else {
      dispatch(
        planSubscriptionsRequestFailed(messages.PLAN_ID_IS_MISSING_ERROR_MSG)
      );
    }
  };
};
