export const NAMESPACE = 'SUBSCRIPTION_DETAILS/';
export const CREATE = `${NAMESPACE}CREATE`;
export const FETCH = `${NAMESPACE}FETCH`;
export const FETCH_PLANS = `${NAMESPACE}FETCH_PLANS`;
export const FETCH_QUOTA_USAGE = `${NAMESPACE}FETCH_QUOTA_USAGE`;
export const FETCH_SUBSCRIPTIONS = `${NAMESPACE}FETCH_SUBSCRIPTIONS`;
export const FETCH_OPERATIONS = `${NAMESPACE}FETCH_OPERATIONS`;
export const RESET = `${NAMESPACE}RESET`;
export const UPDATE = `${NAMESPACE}UPDATE`;
export const UPDATE_PLAN_ID = `${NAMESPACE}UPDATE_PLAN_ID`;
export const UPDATE_NEW_PLAN_NAME = `${NAMESPACE}UPDATE_NEW_PLAN_NAME`;
export const UPDATE_REFERENCE = `${NAMESPACE}UPDATE_REFERENCE`;
