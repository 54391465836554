import React from 'react';
import classNames from 'classnames';
import {NavLink, useLocation} from 'react-router-dom';
import {bool, func} from 'prop-types';
import {useAuth} from '@apps-common/auth-react';

import MenuIcon from 'admin-ng/components/common/menu-icon';
import flippers from 'admin-ng/flippers';
import {ROLES} from 'admin-ng/constants';

const Sidebar = props => {
  const auth = useAuth();
  const location = useLocation();

  const isUserOrgAdmin = auth.user.claims.role_level >= ROLES.orgAdmin;
  const isUserPlanetAdmin = auth.user.claims.role_level >= ROLES.planetAdmin;

  const sidebarClassName = classNames('pl-app--sidebar', {
    visible: props.visible,
  });

  const links = [
    {
      label: 'My Organization',
      to: `/organizations/${auth.user.claims.organization_id}`,
      icon: 'person',
    },
    {
      label: 'Organizations',
      to: '/organizations',
      icon: 'account_balance',
      noActive: location.pathname.startsWith(
        `/organizations/${auth.user.claims.organization_id}`
      ),
    },
    {
      label: 'Packages',
      to: '/packages',
      icon: 'layers',
    },
    {
      label: 'Plans',
      to: '/plans',
      icon: 'device_hub',
    },
  ];

  if (flippers.ENABLE_INVITATIONS) {
    links.push({
      label: 'Invitations',
      to: '/invitations',
      icon: 'mail_outline',
    });
  }
  if (isUserOrgAdmin) {
    links.push({
      label: 'Users',
      to: '/users',
      icon: 'people',
    });
  }

  links.push({
    label: 'Api Keys',
    to: '/api-keys',
    icon: 'vpn_key',
  });

  if (isUserPlanetAdmin) {
    if (flippers.ENABLE_COMPLIANCE) {
      links.push({
        label: 'Compliance',
        to: '/compliance',
        icon: 'lock_open',
      });
    }
    links.push(
      {
        label: 'Programs',
        to: '/programs',
        icon: 'business_center',
      },
      {
        label: 'Documents',
        to: '/documents',
        icon: 'folder',
      }
    );
  }

  return (
    <aside className={sidebarClassName}>
      <nav className="pl-app--main-menu">
        <ul>
          {links.map(link => (
            <li key={link.to}>
              <NavLink
                activeClassName={link.noActive ? '' : 'active'}
                className="anim"
                title={link.label}
                to={link.to}
                data-qa-id={`menu-${link.label.toLowerCase()}`}
              >
                <MenuIcon name={link.icon} />

                <span className="pl-menu-label">{link.label}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className="toggle" onClick={props.toggle}>
        {props.visible ? (
          <MenuIcon key="0" name="first_page" />
        ) : (
          <MenuIcon name="last_page" />
        )}
        <span className="label" key="1">
          Collapse menu
        </span>
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  toggle: func.isRequired,
  visible: bool,
};

export default Sidebar;
