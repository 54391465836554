/**
 * This module returns a data structure for programs to be correctly displayed in the a reusable component Table.
 *
 * A single object represents a table column.
 *
 *     field - (required) property name in the data object (the same which is returned by api)
 *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
 *                          different from the field name.
 *     label - (required) column name.
 *     width - (required) column width to be set in the styles in the table header and table body
 *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
 *     contentCreator - [optional] - func - can be used for creating custom column content
 */
import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';

import {DATE_FORMAT} from 'admin-ng/constants';

// Content creators
const name = (
  program // eslint-disable-line react/no-multi-comp
) => (
  <Link title={program.name} to={`/programs/${program.id}`}>
    {program.name}
  </Link>
);

const description = program => (
  <span
    title={
      program.description // eslint-disable-line react/no-multi-comp
    }
  >
    {program.description}
  </span>
);

const renderDate = (program, key) => {
  // eslint-disable-line react/no-multi-comp
  const date = program[key] ? moment(program[key]) : false;
  const title = date ? date.toISOString() : '';
  const displayedDate = date ? date.format(DATE_FORMAT) : '';
  return <span title={title}>{displayedDate}</span>;
};

const getProgramsTableDataSchema = () => [
  {
    field: 'name',
    label: 'Name',
    width: '15%',
    contentCreator: name,
  },
  {
    field: 'description',
    label: 'Description',
    width: '55%',
    contentCreator: description,
  },
  {
    field: 'updated_at',
    label: 'Last updated',
    width: '15%',
    contentCreator: program => renderDate(program, 'updated_at'),
  },
  {
    field: 'created_at',
    label: 'Created',
    width: '15%',
    contentCreator: program => renderDate(program, 'created_at'),
  },
];

export default getProgramsTableDataSchema;
