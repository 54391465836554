import {connect} from 'react-redux';

import * as actions from './actions';
import MemberCreate from './member-create';

const mapStateToProps = ({
  orgs: {
    instance: {
      users: {create},
    },
  },
}) => ({
  ...create,
});

const mapDispatchToProps = (dispatch, {onSubmit, params: {orgId}}) => ({
  onEmailCheck: email => dispatch(actions.checkEmail(email)),
  onPasswordValidityCheck: password =>
    dispatch(actions.checkPasswordValidity(password)),
  onPasswordMatchingCheck: (password, repeat) =>
    dispatch(actions.checkPasswordMatching(password, repeat)),
  onSubmit: async () => {
    await dispatch(actions.createUser(orgId));
    if (onSubmit) {
      onSubmit();
    }
  },
  reset: () => dispatch(actions.reset()),
  setUserProperty: (key, value) =>
    dispatch(actions.setUserProperty(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberCreate);
