import * as constants from './constants';

// Big Int
export const infiniteQuotaCap = Math.pow(2, 32) / 2 - 1;

const initialState = {
  data: {
    interval: 'access_period',
    isEnabled: false,
    isInfinite: false,
    amount: 0,
    style: 'starter',
    overage: false,
  },
  ui: {
    interval: 'access_period',
    isEnabled: false,
    isInfinite: false,
    amount: 0,
    style: 'starter',
    overage: false,
  },
  intervalError: null,
  quotaUsage: null,
};

// Returns a new amount based on the toggle of "infinite"
function getNewAmount(isInfinite, initialAmount) {
  if (isInfinite) {
    return infiniteQuotaCap;
  }
  if (initialAmount) {
    return initialAmount < infiniteQuotaCap
      ? initialAmount
      : initialState.data.amount;
  }
  return initialState.data.amount;
}

export default function quotaReducer(
  state = initialState,
  {type, payload, error} = {}
) {
  switch (type) {
    case constants.TOGGLE_INFINITE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isInfinite: !state.ui.isInfinite,
          // Set overage to true if isInfinite is true, or use data fallback
          overage: !state.ui.isInfinite || state.ui.overage,
          amount: getNewAmount(!state.ui.isInfinite, state.data.amount),
        },
      };

    case constants.TOGGLE_ENABLED:
      return {
        ...state,
        ui: {
          ...state.ui,
          isEnabled: !state.ui.isEnabled,
          // If enabling amount is 'state.data.amount'
          // If enabling and infinite, amount must be infiniteQuotaCap
          // If disabling amount must be 0;
          amount:
            !state.ui.isEnabled === true
              ? state.ui.isInfinite
                ? infiniteQuotaCap
                : state.data.amount
              : 0,
          overage: !state.ui.isEnabled === true ? state.ui.overage : false,
        },
      };

    case constants.RECEIVE_SUBSCRIPTION_DETAILS: {
      return {
        ...state,
        data: {
          ...state.data,
          amount: payload.quota_sqkm || initialState.data.amount,
          isEnabled: payload.quota_sqkm > 0,
          isInfinite: payload.quota_sqkm >= infiniteQuotaCap,
          interval: payload.quota_interval,
          style: payload.quota_style || initialState.data.style,
          overage: payload.quota_enable_overage || initialState.data.overage,
        },
        ui: {
          ...state.ui,
          amount: payload.quota_sqkm || initialState.ui.amount,
          isEnabled: payload.quota_sqkm > 0,
          isInfinite: payload.quota_sqkm >= infiniteQuotaCap,
          interval: payload.quota_interval,
          style: payload.quota_style || initialState.ui.style,
          overage: payload.quota_enable_overage || initialState.ui.overage,
        },
        intervalError: null,
      };
    }

    case constants.UPDATE_AMOUNT:
      return {
        ...state,
        ui: {
          ...state.ui,
          amount: payload,
          isInfinite: payload >= infiniteQuotaCap,
          overage: payload >= infiniteQuotaCap ? true : state.ui.overage,
        },
      };

    case constants.UPDATE_QUOTA_OVERAGE:
      return {
        ...state,
        ui: {
          ...state.ui,
          overage: payload,
        },
      };

    case constants.UPDATE_QUOTA_STYLE:
      return {
        ...state,
        ui: {
          ...state.ui,
          style: payload,
        },
      };

    case constants.UPDATE_INTERVAL:
      return {
        ...state,
        ui: {
          ...state.ui,
          interval: !error ? payload : null,
        },
        intervalError: error ? payload : null,
      };

    case constants.UPDATE_QUOTA_USAGE:
      return {
        ...state,
        quotaUsage: payload.used,
        updated_at: payload.last_run,
        status: payload.status,
      };

    case constants.RESET: {
      return {...initialState};
    }
    default:
      return state;
  }
}
