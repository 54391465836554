import Button from '@mui/material/Button';
import React from 'react';
import {arrayOf, bool, func, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import PlanOrgsTable from '../common/plan-orgs-table';

const PlanAssignSummary = props => (
  <div>
    {props.isReassigned ? (
      <div>
        <Notification
          message="The following organization(s) have been reassigned"
          title="Success!"
          type="success"
        />
        <PlanOrgsTable
          className="pl-table-index"
          srcData={props.selectedOrgs || []}
        />
      </div>
    ) : (
      <div>
        {props.error ? (
          <div>
            <Notification
              message={props.error}
              title="Oops, plans request failed"
              type="error"
            />
            <div className="pl-wizard-resubmit-btn">
              <Button color="primary" onClick={props.onResubmit}>
                Try again
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    )}
  </div>
);

PlanAssignSummary.propTypes = {
  error: string,
  isReassigned: bool,
  onResubmit: func,
  selectedOrgs: arrayOf(object),
};

export default PlanAssignSummary;
