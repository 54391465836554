import DayPicker, {DateUtils} from 'react-day-picker';
import Popover from '@mui/material/Popover';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import moment from 'moment';
import {bool, func, instanceOf, string} from 'prop-types';

import TextField from 'admin-ng/components/common/text-field';
import YearMonthForm from './year-month-form';
import {DATE_FORMAT} from 'admin-ng/constants';

const inputSequencePattern = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /-/,
  /[01]/,
  /\d/,
  /-/,
  /[0123]/,
  /\d/,
];
const WEEKDAYS_SHORT = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

class DatePicker extends Component {
  static _createUTCDateFromLocalDate(localDate) {
    // localDate is a Date object selected by the user. If you parse
    // this object in the user's time zone, the resulting year/month/day
    // will be whatever "YYYY-MM-DD" the user intended to click on.
    // However, the user actually meant that date to be in UTC, so we'll
    // create and save a UTC Date that has the same year/month/day.

    const year = localDate.getFullYear();
    const month = localDate.getMonth();
    const day = localDate.getDate();

    const utcDate = new Date(Date.UTC(year, month, day));
    return utcDate;
  }

  static _getYearMonthDayStringFromUTCDate(utcDate) {
    // Take in a UTC Date() object, parse out and return the precious 'YYYY-MM-DD'.
    // We do that WITHOUT converting to local timezone, since doing that could
    // change the displayed date.
    return moment.utc(utcDate).format(DATE_FORMAT);
  }

  /**
   * Check if dateStr complies with the expected dateFormat, but allow it to be partially entered.
   * For example '2016-12-' is valid, but '2016-13' isn't. Does not take leap years into account.
   */
  // eslint-disable-next-line react/sort-comp
  static isValidDate(dateStr) {
    const chars = [...dateStr];

    if (chars.length > inputSequencePattern.length) {
      return false;
    }

    // Make sure every character is what we expect (4 digits followed by a dash, etc.).
    if (!chars.every((char, index) => inputSequencePattern[index].test(char))) {
      return false;
    }

    // Make sure month is between 1 and 12.
    if (chars.length >= 7) {
      const month = parseInt(dateStr.substr(5, 2));
      if (month < 1 || month > 12) {
        return false;
      }
    }

    // Make sure day is between 1 and 31.
    if (chars.length === inputSequencePattern.length) {
      const day = parseInt(dateStr.substr(8, 2));
      if (day < 1 || day > 31) {
        return false;
      }
    }

    return true;
  }

  constructor(props) {
    super(props);

    // Reference to TextField's DOM node after rendering. Used to attach popover to it.
    this.textField = null;

    this.state = {
      isDayPickerVisible: false,

      // The Popover containing the DayPicker is attaching itself to this element.
      popoverAnchorElem: null,

      // The string shown in the text input. No other characters than what we expect in dateFormat can be entered.
      inputValue: props.value
        ? DatePicker._getYearMonthDayStringFromUTCDate(props.value)
        : '',

      // Which month is shown in the day picker (result of user navigating through years/months)
      initialMonth: props.value || null,
    };

    this._onBlurInput = this._onBlurInput.bind(this);
    this._onChangeInput = this._onChangeInput.bind(this);
    this._onDayPicked = this._onDayPicked.bind(this);
    this._clearDate = this._clearDate.bind(this);
    this._onChangeYearMonth = this._onChangeYearMonth.bind(this);
    this._showDayPicker = this._showDayPicker.bind(this);
    this._hideDayPicker = this._hideDayPicker.bind(this);
  }

  componentDidMount() {
    this.textField = ReactDOM.findDOMNode(this.refs.textField);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        inputValue: nextProps.value
          ? DatePicker._getYearMonthDayStringFromUTCDate(nextProps.value)
          : '',
        initialMonth: nextProps.value,
      });
    }
  }

  _onChangeInput(rawValue) {
    const value = rawValue.trim();

    // Reject invalid dates
    if (!DatePicker.isValidDate(value)) {
      return;
    }

    if (value.length === DATE_FORMAT.length) {
      // Date string is complete, now check for leap years etc..
      const momentDate = moment(value, DATE_FORMAT, true);
      const date = momentDate.toDate();

      if (momentDate.isValid()) {
        // Accept manually entered date and propagate back to onChange
        const utcDate = DatePicker._createUTCDateFromLocalDate(date);

        this.setState(
          {
            inputValue: value,
            initialMonth: date,
          },
          () => {
            this.props.onChange && this.props.onChange(null, utcDate);
          }
        );
      }
    } else {
      // Date string is incomplete but appears valid so far.
      this.setState({
        inputValue: value,
      });
    }
  }

  _onDayPicked(date) {
    const utcDate = DatePicker._createUTCDateFromLocalDate(date);
    this.setState(
      {
        isDayPickerVisible: false,
      },
      () => {
        this.props.onChange && this.props.onChange(null, utcDate);
      }
    );
  }

  // Check if we need to undo changes to the input or clear the date.
  _onBlurInput() {
    if (this.state.inputValue.length < DATE_FORMAT.length) {
      if (this.state.inputValue.length === 0) {
        this._clearDate();
      } else {
        // Don't show incomplete input values, always reset to the last valid date, or an empty string.
        this.setState({
          inputValue: this.props.value
            ? DatePicker._getYearMonthDayStringFromUTCDate(this.props.value)
            : '',
        });
      }
    }
  }

  _clearDate() {
    this.setState(
      {
        initialMonth: null,
        inputValue: '',
      },
      () => {
        this.props.onChange(null, null);
        this.props.onClear && this.props.onClear();
      }
    );
  }

  // Handle navigation changes in year/month dropdowns.
  _onChangeYearMonth(initialMonth) {
    this.setState({initialMonth});
  }

  _showDayPicker() {
    if (!this.props.disabled) {
      this.setState({
        isDayPickerVisible: true,
        popoverAnchorElem: this.textField,
      });
    }
  }

  // When the DayPicker disappears, reset its year and month dropdowns to match the last valid date.
  // Unless the input was cleared, then also clear initialMonth.
  _hideDayPicker() {
    this.setState({
      isDayPickerVisible: false,
    });
  }

  render() {
    const {
      dateFormatConfirmation,
      disabled,
      error,
      label,
      isChanged,
      qaId,
      value,
      disabledDays,
    } = this.props;

    // Convert YYYY-MM-DD string to Date object. Resulting Date will be midnight in user's TZ.
    // Ex: '2024-02-03' converts to Date(Date.parse('2024-02-03T00:00:00-8:00')) if you're in SF.
    const inputMoment = moment(this.state.inputValue, DATE_FORMAT, true);
    const inputDate = inputMoment.toDate();

    const today = new Date(),
      initialMonth = this.state.initialMonth || today,
      isDaySelected = day => DateUtils.isSameDay(inputDate, day),
      dateConfirmation = inputMoment
        ? inputMoment.format(dateFormatConfirmation)
        : null;

    const datePickerFieldClassName = classNames({
      'pl-date-picker-field': true,
      disabled,
    });

    return (
      <div className="pl-date-picker">
        <div className={datePickerFieldClassName}>
          <TextField
            data-qa-id={qaId}
            disabled={disabled}
            error={!!error}
            helperText={error}
            label={label}
            placeholder={DATE_FORMAT}
            isChanged={isChanged}
            onBlur={this._onBlurInput}
            onChange={this._onChangeInput}
            ref="textField"
            value={this.state.inputValue}
          />

          {!disabled && value && (
            <span
              className="pl-date-picker-field--icon-clear"
              onClick={this._clearDate}
            />
          )}

          <span
            className="pl-date-picker-field--icon-calendar"
            onClick={this._showDayPicker}
          />

          <Popover
            anchorEl={this.state.popoverAnchorElem}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            onClose={this._hideDayPicker}
            open={this.state.isDayPickerVisible}
            transformOrigin={{horizontal: 'left', vertical: 'top'}}
          >
            <DayPicker
              captionElement={
                <YearMonthForm onChange={this._onChangeYearMonth} />
              }
              disabledDays={disabledDays}
              fromMonth={today}
              initialMonth={initialMonth}
              month={initialMonth}
              onDayClick={this._onDayPicked}
              selectedDays={isDaySelected}
              weekdaysShort={WEEKDAYS_SHORT.map(day => day)}
            />
          </Popover>

          {dateConfirmation && !error && (
            <div className="pl-date-picker-date-confirmation">
              {dateConfirmation}
            </div>
          )}
        </div>
      </div>
    );
  }
}

DatePicker.propTypes = {
  ...DayPicker.propTypes,
  dateFormatConfirmation: string,
  disabled: bool,
  error: string,
  label: string,
  isChanged: bool,
  onChange: func.isRequired,
  onClear: func.isRequired,
  qaId: string,
  value: instanceOf(Date),
};

DatePicker.defaultProps = {
  dateFormatConfirmation: 'D MMMM Y',
};

export default DatePicker;
