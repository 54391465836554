import Button from '@mui/material/Button';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {arrayOf, bool, func, node, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import ProgramCreateContainer from '../program-create/program-create-container';
import Sidebar from 'admin-ng/components/common/sidebar';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import getProgramsTableDataSchema from './table-data-schema';
import {ROLES} from 'admin-ng/constants';

export default class ProgramsIndex extends Component {
  static propTypes = {
    auth: object.isRequired,
    children: node,
    error: string,
    isRequestPending: bool.isRequired,
    onOpen: func.isRequired,
    programs: arrayOf(object),
    programsTotalCount: number.isRequired,
    resetView: func.isRequired,
    viewParams: object.isRequired,
  };

  static defaultProps = {
    children: null,
    error: null,
    programs: null,
  };

  constructor(props) {
    super(props);
  }

  state = {isCreateDialogShown: false};

  componentDidMount() {
    this.props.resetView();
  }

  componentWillUnmount() {
    this.props.resetView();
  }

  _showCreateDialog = () => this.setState({isCreateDialogShown: true});

  _hideCreateDialog = () => this.setState({isCreateDialogShown: false});

  render() {
    if (this.props.auth.user.claims.role_level < ROLES.planetAdmin) {
      return <Redirect to="/page-not-found" />;
    }

    return (
      <div className="pl-programs-page">
        <div className="pl-flex-wrapper">
          <Sidebar />
          <div className="pl-app--content">
            <Card>
              <div className="pl-table-card-content">
                <TableControlsPanel
                  actions={[
                    <Button
                      className="pl-table-controls--add-btn"
                      color="primary"
                      key={1}
                      onClick={this._showCreateDialog}
                      variant="contained"
                    >
                      Create
                    </Button>,
                  ]}
                  filters={[]}
                  itemsPerPageLimit={this.props.viewParams.limit}
                  onFilter={this.props.onOpen}
                />

                <ProgramCreateContainer
                  isOpen={this.state.isCreateDialogShown}
                  onClose={this._hideCreateDialog}
                />

                <Notification
                  message={this.props.error}
                  title="Oops, programs request has failed"
                  type="error"
                />

                <Table
                  className="pl-table-index"
                  dataSchema={getProgramsTableDataSchema(this.props)}
                  isRequestPending={this.props.isRequestPending}
                  onSort={this.props.onOpen}
                  qaId="programs-table"
                  sortBy={this.props.viewParams.sort}
                  srcData={this.props.programs}
                />

                <Pagination
                  itemsLimit={this.props.viewParams.limit}
                  itemsOffset={this.props.viewParams.offset}
                  itemsTotal={this.props.programsTotalCount}
                  onPageChange={this.props.onOpen}
                />

                {this.props.children}
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
