import React from 'react';
import {string} from 'prop-types';

import OrgCommentsIndex from './org-comments-index';

const OrgComments = ({orgId}) => (
  <div>
    <OrgCommentsIndex orgId={orgId} />
  </div>
);

OrgComments.propTypes = {
  orgId: string,
};

export default OrgComments;
