import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import React, {Component} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {func, object, string} from 'prop-types';
import {withAuth} from '@apps-common/auth-react';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import DataState from 'admin-ng/components/common/data-state';
import Notification from 'admin-ng/components/common/notification';
import OrgEditContainer from '../org-edit/org-edit-container';
import {DATETIME_FORMAT, ROLES} from 'admin-ng/constants';

class OrgDetails extends Component {
  static propTypes = {
    apiMasterKey: object,
    auth: object,
    error: string,
    initDetails: object,
    onResetApiKey: func.isRequired,
    orgId: string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      needApiKeyResetConfirmation: false,
      isEditOpen: false,
    };

    this._handleResetApiKey = this._handleResetApiKey.bind(this);
    this._confirmResetApiKey = this._confirmResetApiKey.bind(this);
    this._closeApiKeyResetConfirmation =
      this._closeApiKeyResetConfirmation.bind(this);
    this._onEditOpen = this._onEditOpen.bind(this);
    this._onEditClose = this._onEditClose.bind(this);
  }

  _handleResetApiKey() {
    this.setState({
      needApiKeyResetConfirmation: true,
    });
  }

  _confirmResetApiKey() {
    this.setState({
      needApiKeyResetConfirmation: false,
    });

    this.props.onResetApiKey();
  }

  _closeApiKeyResetConfirmation() {
    this.setState({
      needApiKeyResetConfirmation: false,
    });
  }

  _onEditOpen() {
    this.setState({
      isEditOpen: true,
    });
  }

  _onEditClose() {
    this.setState({
      isEditOpen: false,
    });
  }

  render() {
    const {isEditOpen, needApiKeyResetConfirmation} = this.state;
    const {apiMasterKey, error, initDetails, orgId} = this.props;
    const {
      created_at,
      name,
      parent_organization,
      parent_organization_id,
      program,
      sfdc,
      state,
    } = initDetails || {};
    const {name: apiMasterKeyName} = apiMasterKey || {};
    const {name: programName} = program || {};
    const {name: parentOrgName, id: parentOrgId} = parent_organization || {};
    const user = this.props.auth.user.claims;
    const isPlanetAdmin = user.role_level === ROLES.planetAdmin;

    const isOrgAdmin = user.role_level === ROLES.orgAdmin;
    const canEdit =
      isPlanetAdmin ||
      (isOrgAdmin &&
        initDetails &&
        parent_organization_id === user.organization_id);

    return (
      <Card style={{marginBottom: '25px'}}>
        {needApiKeyResetConfirmation ? (
          <ConfirmationMessage
            onClose={this._closeApiKeyResetConfirmation}
            onSubmit={this._confirmResetApiKey}
            open={needApiKeyResetConfirmation}
            title="Reset API Key"
          >
            Reset organization master key?
          </ConfirmationMessage>
        ) : null}

        <div className="pl-form--section pl-org-general">
          <div className="pl-form--section-header">
            <h3 className="pl-form--section-title">Details</h3>

            {initDetails && canEdit ? (
              <div className="pl-form--section-menu">
                <Button
                  data-qa-id="pl-org-details-edit"
                  onClick={this._onEditOpen}
                  startIcon={<EditIcon />}
                  variant="outlined"
                >
                  Edit
                </Button>
              </div>
            ) : null}
          </div>

          <Notification
            message={error}
            title="Oops, something went wrong"
            type="error"
          />

          {initDetails ? (
            <div>
              <ul className="pl-org-details pl-details-list clearfix">
                <li className="item">
                  <div className="pl-details-list--title" title="Name">
                    Name
                  </div>
                  <div
                    className="pl-details-list--value"
                    data-qa-id="pl-details-list-name"
                    title={name}
                  >
                    {name || '—'}
                  </div>
                </li>

                <li className="item-right">
                  <div className="pl-details-list--title" title="Program">
                    Program
                  </div>
                  <div
                    className="pl-details-list--value"
                    data-qa-id="pl-details-list-program"
                    title={program ? programName : ''}
                  >
                    {program ? programName : '—'}
                  </div>
                </li>

                <li className="item">
                  <div className="pl-details-list--title" title="State">
                    State
                  </div>
                  <div
                    className="pl-details-list--value"
                    data-qa-id="pl-details-list-state"
                  >
                    <DataState state={state} />
                  </div>
                </li>

                <li className="item">
                  <div
                    className="pl-details-list--title"
                    title="Parent Organization"
                  >
                    Parent Organization
                  </div>
                  <div
                    className="pl-details-list--value"
                    data-qa-id="pl-details-list-parent-organization"
                  >
                    {parent_organization ? (
                      <span className="pl-suborg">
                        <span className="pl-suborg--name">
                          <Link
                            target="_blank"
                            title={parentOrgName}
                            to={`/organizations/${parentOrgId}`}
                          >
                            {parentOrgName}
                          </Link>
                        </span>
                      </span>
                    ) : (
                      '—'
                    )}
                  </div>
                </li>

                <li className="item">
                  <div className="pl-details-list--title" title="Created">
                    Created
                  </div>
                  <div
                    className="pl-details-list--value"
                    data-qa-id="pl-details-list-created"
                  >
                    {created_at
                      ? moment(created_at).utc().format(DATETIME_FORMAT)
                      : '—'}
                  </div>
                </li>

                {isPlanetAdmin && (
                  <li className="item">
                    <div className="pl-details-list--title" title="SFDC">
                      SFDC Account ID
                    </div>
                    <div
                      className="pl-details-list--value"
                      data-qa-id="pl-details-list-SFDC"
                    >
                      {sfdc || '—'}
                    </div>
                  </li>
                )}

                <li className="item-fullwidth">
                  <div className="pl-details-list--title" title="Api Key">
                    Api Key
                  </div>
                  <div className="pl-details-list--value">
                    {apiMasterKey && apiMasterKeyName ? (
                      <div>
                        <span
                          className="pl-org-details--api-key"
                          data-qa-id="pl-details-list-api-key"
                        >
                          {apiMasterKeyName}
                        </span>

                        <button
                          className="pl-org-details--api-key-reset"
                          onClick={this._handleResetApiKey}
                        >
                          Reset
                        </button>
                      </div>
                    ) : (
                      '—'
                    )}
                  </div>
                </li>
              </ul>

              <OrgEditContainer
                details={initDetails}
                onCancel={this._onEditClose}
                open={isEditOpen}
                orgId={orgId}
              />
            </div>
          ) : null}
        </div>
      </Card>
    );
  }
}

export default withAuth(OrgDetails);
