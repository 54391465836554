import * as constants from './constants';

const initial = {
  deprecated: false,
  documentCreateError: null,
  externalName: '',
  isCheckingName: false,
  isCreateRequestPending: false,
  isNameUnique: null,
  isNewDocumentCreated: null,
  name: '',
  nameError: null,
  requiredForSignup: false,
  separate: false,
  text: '',
};

export default function documentCreateReducer(state = initial, action = {}) {
  const {externalName, isNameUnique, name, text} = action;
  switch (action.type) {
    case constants.UPDATE_NAME:
      return {
        ...state,
        name,
      };

    case constants.UPDATE_EXTERNAL_NAME:
      return {
        ...state,
        externalName,
      };

    case constants.UPDATE_TEXT:
      return {
        ...state,
        text,
      };

    case constants.TOGGLE_CHECKBOX: {
      const newState = {...state};
      newState[action.boxname] = !state[action.boxname];
      return newState;
    }

    case constants.REQUEST_NAME_CHECK:
      return {
        ...state,
        isCheckingName: true,
        nameError: null,
      };

    case constants.REQUEST_NAME_CHECK_SUCCESS:
      return {
        ...state,
        isCheckingName: false,
        nameError: null,
        isNameUnique,
      };

    case constants.REQUEST_NAME_CHECK_FAIL:
      return {
        ...state,
        isCheckingName: false,
        nameError: action.error,
      };

    case constants.REQUEST_CREATE:
      return {
        ...state,
        isCreateRequestPending: true,
      };

    case constants.REQUEST_CREATE_SUCCESS:
      return {
        ...state,
        isCreateRequestPending: false,
        isNewDocumentCreated: true,
      };

    case constants.REQUEST_CREATE_FAIL:
      return {
        ...state,
        isCreateRequestPending: false,
        isNewDocumentCreated: false,
        documentCreateError: action.error,
      };

    case constants.RESET_STATE:
      return {...initial};

    default:
      return state;
  }
}
