import {connect} from 'react-redux';

import List from './list';
import {FLAG_CONFIRMED_BAD, FLAG_FALSE_POSITIVE} from '../constants';
import {fetch, reset} from './actions';
import {update} from '../record/actions';

const mapStateToProps = ({compliance: {index}}) => ({...index});

const mapDispatchToProps = (dispatch, {entity}) => {
  const boundFetch = fetch.bind(this, entity);
  const boundUpdate = update.bind(this, entity);
  return {
    fetch: params => dispatch(boundFetch(params)),
    flagConfirmedBad: id =>
      dispatch(boundUpdate(id, FLAG_CONFIRMED_BAD)).then(() =>
        dispatch(boundFetch())
      ),
    flagFalsePositive: id =>
      dispatch(boundUpdate(id, FLAG_FALSE_POSITIVE)).then(() =>
        dispatch(boundFetch())
      ),
    reset: () => dispatch(reset()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(List);
