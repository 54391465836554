import * as constants from './constants';

const initialState = {
  error: null,
  items: [],
  itemsTotalCount: 0,
  isPlansRequestPending: false,
  viewParams: {
    filters: 'active',
    limit: 20,
    offset: 0,
    sort: '-id',
    search: '',
    excludeOneOrgPlans: false,
  },
};

export default function plansIndexReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.REQUEST_PLANS:
      return Object.assign({}, state, {
        error: null,
        isPlansRequestPending: true,
        viewParams: Object.assign({}, state.viewParams, action.params),
      });

    case constants.RECEIVE_PLANS:
      return Object.assign({}, state, {
        isPlansRequestPending: false,
        items: action.items,
        itemsTotalCount: action.itemsTotalCount,
      });

    case constants.PLANS_REQUEST_FAILED:
      return Object.assign({}, state, {
        isPlansRequestPending: false,
        error: action.error,
      });

    case constants.DELETE_PLAN_REQUEST_FAILED:
      return Object.assign({}, state, {
        error: action.error,
      });

    case constants.RESET_PLANS_VIEW_STATE:
      return Object.assign({}, state, {
        viewParams: initialState.viewParams,
      });

    case constants.PLANS_TOGGLE_ONE_ORG_PLANS: {
      const newState = Object.assign({}, state);
      newState.viewParams.excludeOneOrgPlans =
        !newState.viewParams.excludeOneOrgPlans;
      return newState;
    }

    default:
      return state;
  }
}
