import moment from 'moment';
import {connect} from 'react-redux';

import AccessPeriod from './access-period';
import {resetState, updateActiveFrom, updateActiveTo} from './actions';

const isDayDifferent = (first, second) =>
  first && second
    ? !moment.utc(first).isSame(moment.utc(second), 'day')
    : false;

const mapStateToProps = (state, {type}) => {
  const {data, ui, activeFromError, activeToError} =
    state.subscriptions.accessPeriod;
  let isActiveFromChanged = false;
  let isActiveToChanged = false;

  if (type === 'edit') {
    isActiveFromChanged = isDayDifferent(data.activeFrom, ui.activeFrom);
    isActiveToChanged = isDayDifferent(data.activeTo, ui.activeTo);
  }

  return {
    ...ui,
    activeFrom: ui.activeFrom ? moment.utc(ui.activeFrom) : null,
    activeTo: ui.activeTo ? moment.utc(ui.activeTo) : null,
    activeFromError,
    activeToError,
    isActiveFromChanged,
    isActiveToChanged,
  };
};
const mapDispatchToProps = {
  updateActiveFrom,
  updateActiveTo,
  resetState,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessPeriod);
