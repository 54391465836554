import * as constants from './constants';

const initialState = {
  isOrgDetailsRequestPending: false,
  initDetails: null,
  apiMasterKey: null,
  error: null,
};

export default function orgDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.REQUEST_ORG_DETAILS:
      return {
        ...state,
        isOrgDetailsRequestPending: true,
      };

    case constants.RECEIVE_ORG_DETAILS:
      return {
        ...state,
        error: initialState.error,
        initDetails: action.details,
        isOrgDetailsRequestPending: false,
      };

    case constants.ORG_DETAILS_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
        isOrgDetailsRequestPending: false,
      };

    case constants.RECEIVE_API_KEY:
      return {
        ...state,
        apiMasterKey: action.apiMasterKey,
        error: initialState.error,
      };

    case constants.API_KEY_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case constants.RESET_API_KEY_REQUEST_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case constants.RESET_API_KEY_REQUEST_COMPLETED:
      return {
        ...state,
        apiMasterKey: action.apiMasterKey,
        error: initialState.error,
      };

    default:
      return {...state};
  }
}
