export const REQUEST_ORG_DETAILS = 'REQUEST_ORG_DETAILS';
export const RECEIVE_ORG_DETAILS = 'RECEIVE_ORG_DETAILS';
export const ORG_DETAILS_REQUEST_FAILED = 'ORG_DETAILS_REQUEST_FAILED';
export const ORG_NAME_UPDATED = 'ORG_NAME_UPDATED';
export const ORG_SFDC_UPDATED = 'ORG_SFDC_UPDATED';
export const ORG_PROGRAM_UPDATED = 'ORG_PROGRAM_UPDATED';
export const ORG_STATE_UPDATED = 'ORG_STATE_UPDATED';
export const ORG_DETAILS_UPDATE_REQUESTED = 'ORG_DETAILS_UPDATE_REQUESTED';
export const ORG_DETAILS_UPDATE_COMPLETED = 'ORG_DETAILS_UPDATE_COMPLETED';
export const ORG_DETAILS_UPDATE_FAILED = 'ORG_DETAILS_UPDATE_FAILED';
export const ORG_DETAILS_UPDATES_CANCEL = 'ORG_DETAILS_UPDATES_CANCEL';
export const REQUEST_ORG_NAME_CHECK = 'REQUEST_ORG_NAME_CHECK';
export const COMPLETE_ORG_NAME_CHECK = 'COMPLETE_ORG_NAME_CHECK';
