import * as constants from './constants';

// Remove already selected documents from the autocomplete search results (they can't be added again).
const filterResults = (selectedDocs, newDocs) => {
  const ids = new Set(selectedDocs.map(doc => doc.id));
  return newDocs.filter(doc => !ids.has(doc.id));
};

const initial = {
  createError: null,
  description: '',
  documents: [],
  documentSearchResults: [],
  documentSearchError: null,
  invitationText: '',
  isCheckingName: false,
  isCreating: false,
  isNameUnique: null,
  name: '',
  nameError: null,
  isNewProgramCreated: null,
};

export default function programCreateReducer(state = initial, action = {}) {
  switch (action.type) {
    case constants.UPDATE_NAME:
      return {
        ...state,
        isNameUnique: null,
        name: action.name,
      };

    case constants.UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };

    case constants.UPDATE_INVITATION_TEXT:
      return {
        ...state,
        invitationText: action.invitationText,
      };

    case constants.UPDATE_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
        documentSearchResults: filterResults(
          action.documents,
          state.documentSearchResults
        ),
      };

    case constants.REQUEST_NAME_CHECK:
      return {
        ...state,
        isCheckingName: true,
        isNameUnique: null,
      };

    case constants.REQUEST_NAME_CHECK_SUCCESS:
      return {
        ...state,
        isCheckingName: false,
        isNameUnique: action.isNameUnique,
      };

    case constants.REQUEST_NAME_CHECK_FAIL:
      return {
        ...state,
        isCheckingName: false,
        nameError: action.error,
      };

    case constants.REQUEST_DOCUMENT_SEARCH:
      return {
        ...state,
        documentSearchResults: [],
        documentSearchError: null,
      };

    case constants.REQUEST_DOCUMENT_SEARCH_SUCCESS:
      return {
        ...state,
        documentSearchResults: filterResults(state.documents, action.documents),
        documentSearchError: null,
      };

    case constants.REQUEST_DOCUMENT_SEARCH_FAIL:
      return {
        ...state,
        documentSearchResults: [],
        documentSearchError: action.error,
      };

    case constants.REQUEST_PROGRAM_CREATE:
      return {
        ...state,
        createError: null,
        isCreating: true,
      };
    case constants.REQUEST_PROGRAM_CREATE_SUCCESS:
      return {
        ...state,
        createError: null,
        isCreating: false,
        isNewProgramCreated: true,
      };
    case constants.REQUEST_PROGRAM_CREATE_FAIL:
      return {
        ...state,
        createError: action.error,
        isCreating: false,
        isNewProgramCreated: false,
      };

    case constants.RESET_STATE:
      return {...initial};

    default:
      return state;
  }
}
