import * as constants from './constants';
import {RESET_ASSIGN_STATE} from '../plan-assign-general/constants';

const initialState = {
  error: null,
  isOrgsRequestPending: false,
  orgs: null,
  orgsViewParams: {
    sort: 'id',
  },
  selectedOrgs: [],
};

export default function selectOrgsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.REQUEST_PLAN_SUBSCRIPTIONS:
      return Object.assign({}, state, {
        isOrgsRequestPending: true,
        error: null,
        selectedOrgs: [],
        orgsViewParams: Object.assign({}, state.orgsViewParams, action.params),
      });

    case constants.RECEIVED_PLAN_SUBSCRIPTIONS: {
      const orgs = [];
      if (action.items) {
        orgs.push(
          ...action.items.filter(
            item =>
              item && item.hasOwnProperty('state') && item.state !== 'deleted'
          )
        );
      }
      return Object.assign({}, state, {
        isOrgsRequestPending: false,
        orgs,
      });
    }

    case constants.PLAN_SUBSCRIPTIONS_REQUEST_FAILED:
      return Object.assign({}, state, {
        isOrgsRequestPending: false,
        error: action.error,
      });

    case constants.UPDATE_SELECTED_ORGS: {
      if (!state.orgs || (state.orgs && !state.orgs.length)) {
        return state;
      }
      let orgs = state.orgs.slice(0);
      let selectedOrgs;

      if (Array.isArray(action.items)) {
        orgs = orgs.map((org, i) => ({
          ...org,
          selected: action.items.indexOf(i) !== -1,
        }));
        selectedOrgs = orgs.filter(org => org.selected);
      } else if (action.items === 'all') {
        selectedOrgs = orgs;
      }
      return Object.assign({}, state, {
        error: null,
        orgs,
        selectedOrgs,
      });
    }

    case constants.ORGS_SELECT_NOT_COMPLETED:
      return Object.assign({}, state, {error: action.error});

    case RESET_ASSIGN_STATE:
      return Object.assign({}, initialState);

    default:
      return state;
  }
}
