export const NAMESPACE = 'ORGANIZATION_SUBSCRIPTIONS/';
export const DELETE_SUBSCRIPTION_REQUEST_COMPLETED = `${NAMESPACE}DELETE_SUBSCRIPTION_REQUEST_COMPLETED`;
export const DELETE_SUBSCRIPTION_REQUEST_FAILED = `${NAMESPACE}DELETE_SUBSCRIPTION_REQUEST_FAILED`;
export const FETCH_PLANS_FAIL = `${NAMESPACE}FETCH_PLANS_FAIL`;
export const FETCH_PLANS_START = `${NAMESPACE}FETCH_PLANS_START`;
export const FETCH_PLANS_SUCCESS = `${NAMESPACE}FETCH_PLANS_SUCCESS`;
export const PLAN_SUBSCRIPTIONS_REQUEST_FAILED = `${NAMESPACE}PLAN_SUBSCRIPTIONS_REQUEST_FAILED`;
export const QUOTA_USAGE_REQUEST_FAILED = `${NAMESPACE}QUOTA_USAGE_REQUEST_FAILED`;
export const RECEIVE_PLAN_SUBSCRIPTIONS = `${NAMESPACE}RECEIVE_PLAN_SUBSCRIPTIONS`;
export const REQUEST_PLAN_SUBSCRIPTIONS = `${NAMESPACE}REQUEST_PLAN_SUBSCRIPTIONS`;
export const REQUEST_SUBSCRIPTION_DELETE = `${NAMESPACE}REQUEST_SUBSCRIPTION_DELETE`;
export const RESET = `${NAMESPACE}RESET`;
export const UPDATE_SUBSCRIPTIONS_QUOTA_USAGE = `${NAMESPACE}UPDATE_SUBSCRIPTIONS_QUOTA_USAGE`;
