import {connect} from 'react-redux';

import ProgramGeneral from './program-general';
import {fetchProgramDetails} from './actions';
import {initializeDetails} from '../program-edit/actions';

const mapStateToProps = state => state.programs.instance;

const mapDispatchToProps = (dispatch, props) => ({
  fetchProgramDetails: programId =>
    dispatch(fetchProgramDetails(programId, props.history)),
  prefillEditDialog: details => dispatch(initializeDetails(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramGeneral);
