import flippers from 'admin-ng/flippers';

const categoryToSectionMap = {
  data_v1: {
    assetTypes: true,
    mosaics: false,
    deliveryOptions: {tiles: true},
    aoi: true,
    toi: true,
  },
  mosaic: {
    assetTypes: false,
    mosaics: true,
    deliveryOptions: null,
    aoi: false,
    toi: false,
  },
  basemap: {
    assetTypes: false,
    mosaics: true,
    deliveryOptions: {tiles: true, download: true},
    aoi: true,
    toi: false,
  },
  series: {
    assetTypes: false,
    mosaics: true,
    deliveryOptions: {tiles: true, download: true},
    aoi: true,
    toi: true,
  },
};

export const additionalItemTypes = () => {
  const itemTypes = [];

  if (!flippers.DISABLE_BASEMAPS) {
    itemTypes.push({
      display_description: 'Planet Basemap Mosaic',
      display_name: 'Planet Basemap Mosaic',
      id: 'basemap',
      category: 'basemap',
    });
  }

  if (!flippers.DISABLE_SERIES) {
    itemTypes.push({
      display_description: 'Planet Series Mosaic',
      display_name: 'Planet Series Mosaic',
      id: 'series',
      category: 'series',
    });
  }

  return itemTypes;
};

export const doesRequireAssetTypes = category =>
  Boolean(category) && categoryToSectionMap[category].assetTypes;

export const doesRequireMosaics = category =>
  Boolean(category) && categoryToSectionMap[category].mosaics;

export const requiredDeliveryOptions = category =>
  Boolean(category) && categoryToSectionMap[category].deliveryOptions;

export const doesRequireAoi = category =>
  Boolean(category) && categoryToSectionMap[category].aoi;

export const doesRequireToi = category =>
  Boolean(category) && categoryToSectionMap[category].toi;
