import React from 'react';
import amber from '@mui/material/colors/amber';
import cyan from '@mui/material/colors/cyan';
import grey from '@mui/material/colors/grey';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';
import {number, string} from 'prop-types';

import IndicatorBar from 'admin-ng/components/common/indicator-bar';
import {localizeNumber} from 'admin-ng/util/formatting';

const backgroundColor = grey['400'];
const amber500 = amber['500'];
const cyan500 = cyan['500'];
const pink900 = pink['900'];
const red500 = red['500'];

const QuotaUsage = ({usage, total, units}) => {
  const percent = Math.round((usage / total) * 100) / 100;
  const displayText = localizeNumber(percent, {style: 'percent'});
  const localizedUsage = localizeNumber(usage, {maximumFractionDigits: 0});
  const localizedTotal = localizeNumber(total, {maximumFractionDigits: 0});
  const hoverText = `${localizedUsage} of ${localizedTotal} ${units || 'km²'}`;
  const textStyle = {color: 'white'};

  let color = cyan500;
  if (percent > 1) {
    color = pink900;
  } else if (percent > 0.9) {
    color = red500;
  } else if (percent > 0.5) {
    color = amber500;
  }

  return (
    <IndicatorBar
      barStyle={{backgroundColor}}
      icolor={color}
      displayText={displayText}
      hoverText={hoverText}
      textStyle={textStyle}
      value={100 * percent}
    />
  );
};

QuotaUsage.propTypes = {
  total: number.isRequired,
  units: string,
  usage: number.isRequired,
};

export default QuotaUsage;
