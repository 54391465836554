import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import SelectField from 'admin-ng/components/common/select-field';
import TextField from 'admin-ng/components/common/text-field';
import {ROLES} from 'admin-ng/constants';

export default class OrgDetails extends Component {
  static propTypes = {
    details: object.isRequired,
    error: string,
    isCheckingOrgName: bool.isRequired,
    isOrgDetailsUpdateFinished: bool.isRequired,
    isOrgNameUnique: bool.isRequired,
    isOrgSfdcValid: func.isRequired,
    newDetails: object,
    onCancel: func.isRequired,
    onDataChange: func.isRequired,
    onOpen: func,
    onOrgNameCheck: func.isRequired,
    onSave: func.isRequired,
    open: bool.isRequired,
    orgId: string.isRequired,
    orgNameError: string,
    orgSfdcError: string,
    programs: arrayOf(object).isRequired,
    userRoleLevel: number.isRequired,
  };

  state = {
    isNameChanged: false,
    isSfdcChanged: false,
    isProgramChanged: false,
    isStateChanged: false,
  };

  componentDidMount() {
    this.props.onOpen(this.props.orgId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOrgDetailsUpdateFinished) {
      this.props.onCancel();
    }
  }

  _handleNameChange = newName => {
    this.props.onDataChange({field: 'name', value: newName});
    this.setState({isNameChanged: true});
  };

  _handleSfdcChange = newSfdc => {
    this.props.onDataChange({field: 'sfdc', value: newSfdc});
    this.setState({isSfdcChanged: true});
  };

  _getOrgNameValue = () => {
    const {details, newDetails} = this.props;
    if (newDetails && newDetails.hasOwnProperty('name')) {
      return newDetails.name;
    }
    return details && details.name ? details.name : null;
  };

  _getSfdcValue = () => {
    const {details, newDetails} = this.props;
    if (newDetails && newDetails.hasOwnProperty('sfdc')) {
      return newDetails.sfdc;
    }
    return details && details.sfdc ? details.sfdc : '';
  };

  _getProgramValue = () => {
    const {details, newDetails} = this.props;
    if (newDetails && newDetails.program) {
      return newDetails.program.id;
    }
    return details && details.program ? details.program.id : null;
  };

  _getStateValue = () => {
    const {details, newDetails} = this.props;
    if (newDetails && newDetails.state) {
      return newDetails.state;
    }
    return details && details.state ? details.state : null;
  };

  _canSubmit = () => {
    const {isCheckingOrgName, isOrgNameUnique} = this.props;
    const {isNameChanged, isSfdcChanged, isProgramChanged, isStateChanged} =
      this.state;
    const updatedFields = isSfdcChanged || isProgramChanged || isStateChanged;
    const updatedName = isNameChanged && !isCheckingOrgName && isOrgNameUnique;
    return updatedFields || updatedName;
  };

  _handleProgramChange = event => {
    const {value} = event.target;
    this.props.onDataChange({
      field: 'program',
      value: {
        id: value,
      },
    });
    this.setState({isProgramChanged: true});
  };

  _handleStateChange = event => {
    const {value} = event.target;
    this.props.onDataChange({
      field: 'state',
      value,
    });
    this.setState({isStateChanged: true});
  };

  _handleSubmit = () => {
    if (!this._canSubmit()) {
      return;
    }
    this.setState({
      isNameChanged: false,
      isSfdcChanged: false,
      isProgramChanged: false,
      isStateChanged: false,
    });
    this.props.onSave();
  };

  _handleCancel = () => {
    this.props.onCancel();
    this.setState({
      isNameChanged: false,
      isSfdcChanged: false,
      isProgramChanged: false,
      isStateChanged: false,
    });
  };

  _renderStates = () => {
    const states = ['Active', 'Disabled'];
    if (this.props.details.state === 'deleted') {
      states.push('Deleted');
    }
    return states.map((state, i) => (
      <MenuItem
        // eslint-disable-next-line react/no-array-index-key
        key={`item${i}`}
        value={state.toLowerCase()}
      >
        {state}
      </MenuItem>
    ));
  };

  _renderPrograms = () => {
    let options = [];
    if (this.props.programs && this.props.programs.length) {
      options = this.props.programs.map((program, i) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={i} value={program.id}>
            {program.name}
          </MenuItem>
        );
      });
    }
    return options;
  };

  render() {
    const isPlanetAdmin = this.props.userRoleLevel === ROLES.planetAdmin;
    const canSubmit = this._canSubmit();

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={this._handleCancel}
        open={this.props.open}
      >
        <DialogTitle>Edit Organization Details</DialogTitle>

        <DialogContent>
          <Notification
            message={this.props.error}
            title="Oops, something went wrong"
            type="error"
          />

          <TextField
            data-qa-id="org-edit-name"
            error={!!this.props.orgNameError}
            helperText={this.props.orgNameError}
            label="Name"
            isChanged={this.state.isNameChanged}
            isInputValid={this.props.isOrgNameUnique}
            onChange={this._handleNameChange}
            onChangeCompleted={this.props.onOrgNameCheck}
            value={this._getOrgNameValue()}
          />

          {isPlanetAdmin && (
            <TextField
              data-qa-id="org-edit-SFDC"
              error={!!this.props.orgSfdcError}
              helperText={this.props.orgSfdcError}
              label="SFDC Account ID"
              placeholder="A customer's Salesforce ID is the last 15 digits of their SFDC account page URL."
              isChanged={this.state.isSfdcChanged}
              onChange={this._handleSfdcChange}
              onChangeCompleted={this.props.isOrgSfdcValid}
              value={this._getSfdcValue()}
            />
          )}

          {isPlanetAdmin && (
            <SelectField
              data-qa-id="org-edit-program"
              label="Program"
              isChanged={this.state.isProgramChanged}
              onChange={this._handleProgramChange}
              value={this._getProgramValue()}
            >
              {this._renderPrograms()}
            </SelectField>
          )}

          <SelectField
            data-qa-id="org-edit-state"
            label="State"
            isChanged={this.state.isStateChanged}
            onChange={this._handleStateChange}
            value={this._getStateValue()}
          >
            {this._renderStates()}
          </SelectField>
        </DialogContent>

        <DialogActions>
          <Button
            color="inherit"
            data-qa-id="pl-org-edit-button-cancel"
            onClick={this._handleCancel}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            data-qa-id="pl-org-edit-button-save"
            disabled={!canSubmit}
            focusRipple
            onClick={canSubmit ? this._handleSubmit : null}
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
