import React from 'react';
import classnames from 'classnames';
import {string} from 'prop-types';

const Signet = ({className, ...props}) => (
  <svg
    aria-label="planet-signet"
    className={classnames('signet', className)}
    type="logo"
    version="1.1"
    viewBox="0 0 214.3 214.3"
    {...props}
  >
    <path
      className="letter"
      d="M115.1 58.8c-10.3 0-21.4 4.1-28.7 14.6H86.1A14.2 14.2 0 0 0 71.9 59.2H71.5v97A16 16 0 0 0 87.4 172.1v-42H87.7c3.1 5.1 11.5 13.7 28.4 13.7 25.5 0 40.8-21 40.8-41.4C157 80.8 142.5 58.8 115.1 58.8ZM114.5 130c-15.3 0-27.4-12.4-27.4-28.7 0-14.9 11.1-28.6 27-28.6 16.5 0 27.7 14.4 27.7 28.9C141.8 118.6 128.8 130 114.5 130Z"
    />
    <path
      className="circle"
      d="M107.2 214.3A107.2 107.2 0 1 1 214.3 107.2 107.3 107.3 0 0 1 107.2 214.3Zm0-204.1a97 97 0 1 0 97 97A97.1 97.1 0 0 0 107.2 10.2Z"
    />
  </svg>
);

Signet.propTypes = {
  className: string,
};

export default Signet;
