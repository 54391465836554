import React, {Component} from 'react';
import moment from 'moment';
import {arrayOf, bool, object, string} from 'prop-types';

import Table from 'admin-ng/components/common/table';
import {DATE_FORMAT} from 'admin-ng/constants';
import {getPrimaryText} from 'admin-ng/components/common/subscription-details/quota/intervals';
import {localizeNumber} from 'admin-ng/util/formatting';

const tableHeight = '432px';

class PlanOrgsTable extends Component {
  constructor(props) {
    super(props);

    this._quotaColumnContentCreator =
      this._quotaColumnContentCreator.bind(this);
  }

  _quotaColumnContentCreator(item) {
    if (item.quota_enabled && item.quota_sqkm) {
      const quotaInterval = getPrimaryText(item.quota_interval);
      return `${localizeNumber(item.quota_sqkm)} km² / ${quotaInterval}`;
    } else {
      return '-';
    }
  }

  _getOrgDataSchema() {
    const isSortable = typeof this.props.sortBy !== 'undefined';

    const columns = [
      {
        field: 'id',
        label: 'Plan ID',
        width: '10%',
        isSortable,
      },
      {
        field: 'name',
        sortKey: 'organization.name',
        label: 'Organization Name',
        width: '25%',
        contentCreator: item => item.organization.name,
        isSortable,
      },
      {
        field: 'active_from',
        label: 'Access Period Start',
        width: '20%',
        isSortable,
        contentCreator(item) {
          return item.active_from
            ? moment(item.active_from).format(DATE_FORMAT)
            : '—';
        },
      },
      {
        field: 'active_to',
        label: 'Access Period End',
        width: '20%',
        isSortable,
        contentCreator(item) {
          return item.active_to
            ? moment(item.active_to).format(DATE_FORMAT)
            : '—';
        },
      },
    ];

    columns.push({
      field: 'quota_sqkm',
      label: 'Quota',
      width: '20%',
      isSortable,
      contentCreator: this._quotaColumnContentCreator,
    });

    return columns;
  }

  render() {
    return (
      <div className="pl-data-table-selectable">
        {this.props.selectable ? (
          <div className="pl-data-table-selectable--panel">
            {this.props.selectedOrgs.length} selected
          </div>
        ) : (
          <div className="pl-data-table-selectable--panel">
            Total: {this.props.srcData.length}
          </div>
        )}

        <Table
          {...this.props}
          dataSchema={this._getOrgDataSchema()}
          qaId="plan-organizations-table"
          wrapperStyle={{maxHeight: tableHeight}}
        />
      </div>
    );
  }
}

PlanOrgsTable.propTypes = {
  selectable: bool,
  selectedOrgs: arrayOf(object),
  sortBy: string,
  srcData: arrayOf(object).isRequired,
};

export default PlanOrgsTable;
