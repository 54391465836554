import * as constants from './constants';
import * as messages from '../plan-assign-general/messages';
import {reassign} from 'admin-ng/api/subscriptions';

export const requestOrgsReassign = (params = null) => {
  return {
    type: constants.REQUEST_ORGS_REASSIGN,
    params,
  };
};

export const orgsReassignRequestCompleted = () => {
  return {
    type: constants.ORGS_REASSIGN_REQUEST_COMPLETED,
  };
};

export const orgsReassignRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => {
  return {
    type: constants.ORGS_REASSIGN_REQUEST_FAILED,
    error,
  };
};

const getSubscriptionIds = srcItems => {
  let ids = [];

  if (srcItems && srcItems.length) {
    ids = srcItems.map(item => item.id);
  }

  return ids;
};

export const reassignOrgs = () => {
  return (dispatch, getState) => {
    const state = getState(),
      selectPlanState = state.plans.assign.selectPlan,
      selectOrgsState = state.plans.assign.selectOrgs,
      params = {
        plan_id: selectPlanState.destinationPlanId,
        subscription_ids: getSubscriptionIds(selectOrgsState.selectedOrgs),
      };

    dispatch(requestOrgsReassign(params));

    return reassign(params)
      .then(() => {
        dispatch(orgsReassignRequestCompleted());
      })
      .catch((err = {}) => {
        dispatch(orgsReassignRequestFailed(err.message));
      });
  };
};
