import React from 'react';
import {Card, CardContent, CardHeader, Divider} from '@mui/material';
import {bool, node, string} from 'prop-types';

const PlCard = ({title, children, qaId, divider, ...props}) => {
  return (
    <Card data-qa-id={qaId} {...props}>
      {title && (
        <>
          <CardHeader
            title={title}
            titleTypographyProps={{variant: 'h5', sx: {fontWeight: 300}}}
            sx={{paddingBottom: 1}}
          />

          {divider && <Divider variant="middle" />}
        </>
      )}

      <CardContent>{children}</CardContent>

      <div />

      {/*This exists to prevent the bottomPadding variable on material-ui/Card from being applied, due to us shoehorning all PLCard content into the CardText component*/}
    </Card>
  );
};

PlCard.propTypes = {
  ...Card.propTypes,
  children: node,
  qaId: string,
  title: string,
  divider: bool,
};

export default PlCard;
