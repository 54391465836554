import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import Switch from '@mui/material/Switch';
import {bool, func, number, object, oneOfType, string} from 'prop-types';
import {grey} from '@mui/material/colors';
import {isFinite} from 'lodash';
import {styled} from '@mui/material/styles';

import Checkbox from 'admin-ng/components/common/checkbox';
import FormCard from 'admin-ng/components/common/form-card';
import SelectField from 'admin-ng/components/common/select-field';
import TextField from 'admin-ng/components/common/text-field';
import {formatLargeNumber, localizeNumber} from 'admin-ng/util/formatting';
import {intervals} from './intervals';
import {quotaStyles} from './quota-styles';

const PlSwitch = styled(Switch)(({theme}) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: theme.palette.primary.light,
    },
    '&.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'white',
    },
  },
}));

export const sanitizeAmount = amount => {
  if (amount || amount === 0) {
    if (parseInt(amount, 10) < 0) {
      return 0;
    } else {
      //remove everything that is not a digit, then transform back to integer
      const value = parseInt(String(amount).replace(/[\D]+/g, ''), 10);
      return value > 0 ? value : 0;
    }
  }
  return '';
};

export default class OrgSubscriptionsQuota extends Component {
  static propTypes = {
    amount: oneOfType([string, number]),
    data: object.isRequired,
    interval: string,
    intervalError: string,
    isAmountChanged: bool.isRequired,
    isEnabled: bool.isRequired,
    isInfinite: bool.isRequired,
    isIntervalChanged: bool.isRequired,
    isOverageChanged: bool.isRequired,
    isPlanetAdmin: bool.isRequired,
    isStyleChanged: bool.isRequired,
    onAmountChange: func.isRequired,
    onIntervalChange: func.isRequired,
    onOverageChange: func.isRequired,
    onStyleChange: func.isRequired,
    overage: bool.isRequired,
    quotaUsage: number,
    style: string,
    toggleEnable: func.isRequired,
    toggleInfinite: func.isRequired,
  };

  constructor(props) {
    super(props);

    this._remainingQuota = this._remainingQuota.bind(this);
    this._onIntervalChange = this._onIntervalChange.bind(this);
    this._onStyleChange = this._onStyleChange.bind(this);
    this._onOverageChange = this._onOverageChange.bind(this);
  }

  state = {
    downloadMessage: false,
    intervalMessage: false,
  };

  _remainingQuota() {
    if (isFinite(this.props.data.amount) && isFinite(this.props.quotaUsage)) {
      const remaining = this.props.data.amount - this.props.quotaUsage;
      return `${formatLargeNumber(remaining)} remaining`;
    }
    return '';
  }

  _onIntervalChange(event, key, manualValue) {
    const value = manualValue ?? event?.target?.value;

    this.props.onIntervalChange(value);

    if (
      this.props.style === 'global_area_under_management' &&
      value !== 'access_period'
    ) {
      this._onStyleChange(null, null, 'starter');
      this.setState({intervalMessage: false, downloadMessage: true});
    } else {
      this.setState({intervalMessage: false, downloadMessage: false});
    }
  }

  // Download plan dropdown changes
  _onStyleChange(event, key, manualValue) {
    const value = manualValue ?? event?.target?.value;

    if (this.props.style !== value) {
      this.props.onStyleChange(value);

      if (
        value === 'global_area_under_management' &&
        this.props.interval !== 'access_period'
      ) {
        // AUM must have an access period interval
        this._onIntervalChange(null, null, 'access_period');
        this.setState({intervalMessage: true, downloadMessage: false});
      } else {
        this.setState({intervalMessage: false, downloadMessage: false});
      }

      if (value === 'global_area_under_management' && !this.props.overage) {
        // AUM must allow overage
        this._onOverageChange(null, null, 'true');
      }
    }
  }

  _getLocalizedAmount = amount => {
    if (amount) {
      return localizeNumber(amount);
    } else {
      if (amount === 0) {
        return '0';
      } else {
        return '';
      }
    }
  };

  // Allow Overage dropdown changes
  _onOverageChange(event, key, manualValue) {
    const value = manualValue ?? event?.target?.value;
    const v = value === 'true';

    if (this.props.overage !== v) {
      if (!v) {
        // We can't use AUM if the user is setting overage to false
        if (this.props.style === 'global_area_under_management') {
          if (this.props.data.style !== 'global_area_under_management') {
            this._onStyleChange(null, null, this.props.data.style);
          } else {
            this._onStyleChange(null, null, 'starter');
          }
        }
      }
      this.props.onOverageChange(v);
    }
  }

  _getStyle = style => (style === null ? quotaStyles[0] : style);

  render() {
    const {
      amount,
      interval,
      intervalError,
      isAmountChanged,
      isEnabled,
      isIntervalChanged,
      isOverageChanged,
      isPlanetAdmin,
      isStyleChanged,
      onAmountChange,
      overage,
      style,
      toggleEnable,
      toggleInfinite,
      isInfinite,
    } = this.props;

    const overageString = overage.toString();

    return (
      <FormCard
        iconElementRight={
          <FormControlLabel
            control={
              <PlSwitch
                checked={isEnabled}
                color="primary"
                onChange={toggleEnable.bind(this)}
                data-qa-id="quota-toggle"
              />
            }
            label={isEnabled ? 'Enabled' : 'Disabled'}
            labelPlacement="start"
          />
        }
        style={isEnabled ? {} : {backgroundColor: grey['400']}}
        title="Scene Download Quota"
      >
        {isEnabled && (
          <div>
            <div className="pl-subscription-grid">
              <div className="pl-subscription-grid-cell pl-quota-amount-wrapper">
                <TextField
                  helperText={!isInfinite && this._remainingQuota()}
                  isChanged={isAmountChanged}
                  label="Amount (in km²)"
                  onChange={newValue =>
                    onAmountChange(sanitizeAmount(newValue))
                  }
                  qaId="quota-amount"
                  value={
                    isInfinite ? 'Unlimited' : this._getLocalizedAmount(amount)
                  }
                  variant="standard"
                />
                {isPlanetAdmin && (
                  <Checkbox
                    checked={isInfinite}
                    label="Unlimited Quota"
                    onChange={toggleInfinite}
                    qaId="unlimited-quota-checkbox"
                  />
                )}
              </div>

              <div className="pl-subscription-grid-cell">
                <SelectField
                  data-qa-id="quota-interval"
                  error={isEnabled && !!intervalError}
                  helperText={isEnabled && intervalError}
                  isChanged={isIntervalChanged}
                  label="Interval"
                  onChange={this._onIntervalChange}
                  value={interval}
                >
                  {intervals.map((item, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={key} value={item.value}>
                      {item.primaryText}
                    </MenuItem>
                  ))}
                </SelectField>
              </div>

              <div className="pl-subscription-grid-cell">
                <SelectField
                  data-qa-id="quota-style"
                  isChanged={isStyleChanged}
                  label="Download plan"
                  onChange={this._onStyleChange}
                  value={this._getStyle(style)}
                >
                  {quotaStyles.map((item, key) =>
                    item.disabled ? null : (
                      <MenuItem
                        disabled={item.disabled}
                        // eslint-disable-next-line react/no-array-index-key
                        key={key}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    )
                  )}
                </SelectField>
              </div>

              <div className="pl-subscription-grid-cell">
                <SelectField
                  qaId="quota-overage"
                  disabled={isInfinite}
                  isChanged={isOverageChanged}
                  label="Allow Overage"
                  onChange={this._onOverageChange}
                  value={overageString}
                >
                  <MenuItem value="true">Enabled</MenuItem>
                  <MenuItem value="false">Disabled</MenuItem>
                </SelectField>
              </div>
            </div>

            {this.state.intervalMessage && (
              <div style={{marginTop: 8}}>
                NOTE: Interval has been automatically updated to Access Period
                to support the Global AUM Download plan.
              </div>
            )}

            {this.state.downloadMessage && (
              <div style={{marginTop: 8}}>
                NOTE: Global AUM requires an Access Period interval. Download
                plan has been automatically updated to the default: Starter
              </div>
            )}
          </div>
        )}
      </FormCard>
    );
  }
}
