import React, {useEffect, useState} from 'react';
import {array, bool, func, number, shape, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ComplianceRecord from '../compliance-record';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';

const List = props => {
  const [entity, setEntity] = useState(props.entity);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    props.reset();
    props.fetch();
    setEntity(props.entity);
  }, [props.entity]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const filters = ['flagged', 'confirmed', 'false positive'];

  return (
    <div>
      <Card style={{marginBottom: '1.5em'}}>
        <div className="pl-table-card-content">
          <TableControlsPanel
            activeFilters={props.params.filters}
            filters={filters}
            itemsPerPageLimit={props.params.limit}
            onFilter={props.fetch}
          />
          <Notification
            message={props.error}
            title="Oops, compliance request has failed"
            type="error"
          />
        </div>
      </Card>

      {!props.isLoading && props.records.length === 0 && (
        <Card>No Data Available</Card>
      )}

      {props.entity === entity &&
        props.records.length > 0 &&
        props.records.map(
          record =>
            record[props.entity] && (
              <ComplianceRecord
                entity={props.entity}
                fields={record[props.entity]}
                flagConfirmedBad={props.flagConfirmedBad}
                flagFalsePositive={props.flagFalsePositive}
                id={record.id}
                key={record.id}
                path={`/compliance/${props.entity}/${record.id}`}
                record={record.record}
                status={record.status}
              />
            )
        )}

      <Pagination
        itemsLimit={props.params.limit}
        itemsOffset={props.params.offset}
        itemsTotal={props.totalCount}
        onPageChange={props.fetch}
      />
    </div>
  );
};

List.propTypes = {
  entity: string.isRequired,
  error: string,
  fetch: func.isRequired,
  flagConfirmedBad: func.isRequired, // eslint-disable-line react/no-unused-prop-types
  flagFalsePositive: func.isRequired, // eslint-disable-line react/no-unused-prop-types
  isLoading: bool.isRequired,
  params: shape({
    filters: string.isRequired,
    limit: number.isRequired,
    offset: number.isRequired,
    sort: string.isRequired,
  }).isRequired,
  records: array.isRequired,
  reset: func,
  totalCount: number.isRequired,
};

export default List;
