import {connect} from 'react-redux';

import OrgMemberInvite from './org-member-invite';
import {
  inviteUser,
  resetInviteState,
  updateData,
  validateEmail,
} from './actions';

const mapStateToProps = (state, props) => {
  const _state = state.orgs.instance.users.invite;
  return {
    inviteError: _state.error,
    isEmailUnique: _state.isEmailUnique,
    isInviteRequested: _state.isInviteRequested,
    isInviteSent: _state.isInviteSent,
    isEmailCheckRequested: _state.isEmailCheckRequested,
    emailError: _state.emailError,
    roleError: _state.roleError,
    role: _state.role,
    email: _state.email,
    isOpen: props.isOpen,
  };
};

const mapDispatchToProps = (dispatch, {onClose, params: {orgId}}) => ({
  onClose: () => {
    if (onClose) {
      onClose();
    }
    dispatch(resetInviteState());
  },
  onDataChange: params => dispatch(updateData(params)),
  onEmailCheck: email => dispatch(validateEmail(email)),
  onSubmit: () => dispatch(inviteUser(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgMemberInvite);
