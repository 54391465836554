import * as constants from './constants';

const initial = {
  details: null,
  error: null,
  isProgramDetailsRequestPending: false,
};

export default function programInstanceReducer(state = initial, action = {}) {
  switch (action.type) {
    case constants.REQUEST_PROGRAM_DETAILS:
      return {
        ...state,
        details: null,
        error: null,
        isProgramDetailsRequestPending: true,
      };

    case constants.REQUEST_PROGRAM_DETAILS_SUCCESS:
      return {
        ...state,
        details: action.details,
        error: null,
        isProgramDetailsRequestPending: false,
      };

    case constants.REQUEST_PROGRAM_DETAILS_FAIL:
      return {
        ...state,
        details: null,
        error: action.error,
        isProgramDetailsRequestPending: false,
      };

    case constants.RESET_STATE:
      return {...initial};

    default:
      return state;
  }
}
