import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import DataState from 'admin-ng/components/common/data-state';
import IconButton from 'admin-ng/components/common/icon-button';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import SceneQuotaCell from 'admin-ng/components/common/subscription-details/quota-table-cell/scene';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import TileQuotaCell from 'admin-ng/components/common/subscription-details/quota-table-cell/tile';

export default class OrgSubscriptionsIndex extends Component {
  static propTypes = {
    history: object.isRequired,
    isDataRequestPending: bool.isRequired,
    isOwnOrgAdmin: bool.isRequired,
    isPlanetAdmin: bool.isRequired,
    onChange: func.isRequired,
    onDelete: func.isRequired,
    onMount: func.isRequired,
    onParentOrgDataRequest: func,
    params: object,
    parentOrg: object,
    plansTotalCount: number.isRequired,
    quotaUsages: object,
    subscriptions: arrayOf(object),
    subscriptionsError: string,
    subscriptionsTotalCount: number.isRequired,
    viewParams: object.isRequired,
  };

  state = {
    needSubscriptionDeleteConfirmation: false,
    subscriptionToBeDeleted: {
      plan: {
        name: null, //FIXME: this is too deeply nested
      },
    },
    subscriptionToBeOpened: null,
    isEditDialogOpen: false,
    isAddDialogOpen: false,
    isPlanLimitDialogOpen: false,
  };

  componentDidMount() {
    this.props.onMount();
    if (!this.props.parentOrg.details) {
      this.props.onParentOrgDataRequest();
    }
  }

  componentWillReceiveProps({params, onMount}) {
    if (params.orgId !== this.props.params.orgId) {
      onMount();
    }
  }

  _handleEditPress = ({id}) =>
    this.props.history.push(
      `/organizations/${this.props.params.orgId}/plans/${id}/edit`
    );

  _idColumnContentCreator = ({id}) => (
    <Link
      data-qa-id="org-subscriptions-view"
      to={`/organizations/${this.props.params.orgId}/plans/${id}`}
    >
      {id}
    </Link>
  );

  _planColumnContentCreator = ({plan: {id, name}}) => (
    <Link data-qa-id="org-subscriptions-view-plan" to={`/packages/${id}`}>
      {name}
    </Link>
  );

  _sceneQuotaContentCreator = sub => (
    <SceneQuotaCell sub={sub} usages={this.props.quotaUsages} />
  );

  _basemapTileQuotaContentCreator = sub => (
    <TileQuotaCell tileQuota={sub.basemap_tile_quota} />
  );

  _sceneTileQuotaContentCreator = sub => (
    <TileQuotaCell tileQuota={sub.scene_tile_quota} />
  );

  _referenceColumnContentCreator = item => {
    if (item.reference) {
      return item.reference;
    } else {
      return '-';
    }
  };

  _deleteSubscription = item => {
    if (item.hasOwnProperty('state') && item.state !== 'deleted') {
      this.setState({
        needSubscriptionDeleteConfirmation: true,
        subscriptionToBeDeleted: item,
      });
    }
  };

  _confirmDeleteSubscription = () => {
    if (
      this.state.subscriptionToBeDeleted &&
      this.state.subscriptionToBeDeleted.id
    ) {
      this.props.onDelete(this.state.subscriptionToBeDeleted.id);
    }
  };

  _closeDeleteSubscriptionConfirmation = () =>
    this.setState({
      needSubscriptionDeleteConfirmation: false,
      subscriptionToBeDeleted: {
        plan: {
          name: null,
        },
      },
    });

  _showCreatePage = () =>
    this.props.history.push(
      `/organizations/${this.props.params.orgId}/plans/create`
    );

  _showPlanLimitDialog = () => this.setState({isPlanLimitDialogOpen: true});

  _handlePlanLimitDialogClose = () =>
    this.setState({isPlanLimitDialogOpen: false});

  _toggleAddButton = () => (
    <Button
      className={
        this.props.subscriptionsTotalCount < this.props.plansTotalCount
          ? 'pl-table--controls-add-btn'
          : 'pl-table--controls-add-btn-limit'
      }
      color="primary"
      data-qa-id="add-subscription"
      key={1}
      onClick={
        this.props.subscriptionsTotalCount < this.props.plansTotalCount
          ? this._showCreatePage
          : this._showPlanLimitDialog
      }
      variant="contained"
    >
      Add
    </Button>
  );

  _actionsColumnCreator = item => (
    <div>
      <IconButton
        icon="edit"
        isDisabled={item.state === 'deleted'}
        onClick={this._handleEditPress.bind(this, item)}
        qaId="plan-subscription-edit"
      />
      <IconButton
        icon="delete"
        isDisabled={item.state === 'deleted'}
        onClick={this._deleteSubscription.bind(this, item)}
        qaId="org-subscription-delete"
      />
    </div>
  );

  _stateColumnContentCreator = item => {
    return item.state ? (
      <DataState
        label={item.state === 'inactive_plan' ? 'Deleted Package' : null}
        state={item.state}
      />
    ) : (
      '—'
    );
  };

  _activeFromCreator = item => {
    if (item.active_from) {
      return item.active_from.slice(0, item.active_from.indexOf('T'));
    }

    return <span>∞</span>;
  };

  _activeToCreator = item => {
    if (item.active_to) {
      return item.active_to.slice(0, item.active_to.indexOf('T'));
    }

    return <span>∞</span>;
  };

  _activeCreator = item => {
    return (
      <div>
        <div>{this._activeFromCreator(item)}</div>
        <div>{this._activeToCreator(item)}</div>
      </div>
    );
  };

  /**
   * This function returns data structure for organizaiton to be correctly
   * displayed in the a reusable component Table
   *
   * A single object represents a table column.
   *
   *     field - (required) property name in the data object (the same which is returned by api)
   *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
   *                          different from the field name.
   *     label - (required) column name.
   *     width - (required) column width to be set in the styles in the table header and table body
   *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
   *     contentCreator - [optional] - func - can be used for creating custom column content
   */
  get _schema() {
    const {isOwnOrgAdmin, isPlanetAdmin} = this.props;
    const schema = [
      {
        field: 'id',
        label: 'Plan',
        isSortable: false,
        width: '6%',
        contentCreator: this._idColumnContentCreator,
      },
      {
        field: 'name',
        sortKey: 'plan.name',
        label: 'Package',
        width: '12%',
        contentCreator: this._planColumnContentCreator,
      },
      {
        field: 'state',
        label: 'State',
        width: '5%',
        contentCreator: this._stateColumnContentCreator,
      },
      {
        field: 'active_from',
        label: 'Access Period',
        width: '7%',
        contentCreator: this._activeCreator,
      },
      {
        field: 'quota_sqkm',
        label: 'Scene Quota',
        width: '9%',
        contentCreator: this._sceneQuotaContentCreator,
      },
      {
        field: 'basemap_tile_quota',
        label: 'Basemap Tiles',
        width: '9%',
        contentCreator: this._basemapTileQuotaContentCreator,
      },
      {
        field: 'scene_tile_quota',
        label: 'Scene Tiles',
        width: '9%',
        contentCreator: this._sceneTileQuotaContentCreator,
      },
    ];

    if (isPlanetAdmin) {
      schema.push({
        field: 'reference',
        label: 'Opportunity ID',
        isSortable: false,
        width: '9%',
        contentCreator: this._referenceColumnContentCreator,
      });
    }

    if (isPlanetAdmin || !isOwnOrgAdmin) {
      schema.push({
        label: '',
        width: '6%',
        contentCreator: this._actionsColumnCreator,
      });
    }

    return schema;
  }

  render() {
    const {
      isDataRequestPending,
      isOwnOrgAdmin,
      onChange,
      subscriptions,
      subscriptionsError,
      subscriptionsTotalCount,
      viewParams: {filters, limit, offset, sort},
    } = this.props;
    const {
      isPlanLimitDialogOpen,
      needSubscriptionDeleteConfirmation,
      subscriptionToBeDeleted: {
        plan: {name: planName},
      }, //FIXME: this is too deeply nested
    } = this.state;
    const subscriptionsFilters = [
      'active',
      'expired',
      'inactive',
      'inactive_plan',
      'deleted',
    ];
    const subscriptionsLabels = [
      'Active',
      'Expired',
      'Inactive',
      'Deleted Package',
      'Deleted',
    ];
    const actions = [];
    if (!isOwnOrgAdmin) {
      actions.push(this._toggleAddButton());
    }
    return (
      <div className="pl-org-plan-subscriptions-page">
        <Card>
          <div className="pl-table-card-content">
            <Dialog fullWidth maxWidth="sm" open={isPlanLimitDialogOpen}>
              <DialogTitle>
                There are no Packages available for this Organisation.
              </DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Please either modify an existing Plan or create a new Package
                  with a corresponding Plan.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button
                  color="primary"
                  data-qa-id="plan-limit-confirmation"
                  key={1}
                  onClick={this._handlePlanLimitDialogClose}
                  variant="contained"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>

            <TableControlsPanel
              actions={actions}
              activeFilters={filters}
              filters={subscriptionsFilters}
              itemsPerPageLimit={limit}
              labels={subscriptionsLabels}
              onFilter={onChange}
            />

            <Notification
              message={subscriptionsError}
              title="Oops, plans request failed"
              type="error"
            />

            <ConfirmationMessage
              onClose={this._closeDeleteSubscriptionConfirmation}
              onSubmit={this._confirmDeleteSubscription}
              open={needSubscriptionDeleteConfirmation}
              title="Delete plan"
            >
              Delete plan for {planName}?
            </ConfirmationMessage>

            <Table
              className="pl-table-index"
              dataSchema={this._schema}
              isRequestPending={isDataRequestPending}
              onSort={onChange}
              qaId="organization-subscriptions-table"
              sortBy={sort}
              srcData={subscriptions}
              style={{minWidth: '1350px'}}
            />

            <Pagination
              itemsLimit={limit}
              itemsOffset={offset}
              itemsTotal={subscriptionsTotalCount}
              onPageChange={onChange}
            />
          </div>
        </Card>
      </div>
    );
  }
}
