import * as constants from './constants';

const initialState = {
  commentDeleteRequested: false,
  commentDeleted: false,
  error: null,
};

export default function orgCommentDeleteReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.REQUEST_DELETE_COMMENT:
      return {
        ...state,
        error: null,
        commentDeleteRequested: true,
      };

    case constants.COMMENT_DELETE_COMPLETED:
      return {
        ...state,
        commentDeleted: true,
        commentDeleteRequested: false,
      };

    case constants.COMMENT_DELETE_FAILED:
      return {
        ...state,
        error: action.error,
        commentDeleteRequested: false,
      };

    case constants.RESET_DELETED_STATE:
      return {
        ...state,
        commentDeleted: false,
        error: null,
      };

    default:
      return state;
  }
}
