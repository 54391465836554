import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import SelectField from 'admin-ng/components/common/select-field';
import TextField from 'admin-ng/components/common/text-field';
import countries from 'admin-ng/util/countries-list';
import isPlanetEmployee from 'admin-ng/util/isPlanetEmployee';
import roles from 'admin-ng/util/roles';
import {ROLES} from 'admin-ng/constants';

class MemberProfileForm extends Component {
  constructor(props) {
    super(props);

    this.planetUserCanView =
      this.props.userRoleLevel >= ROLES.planetAdminViewOnly;
    this.planetUserCanView = true;
    this.memberIsPlanetEmployee = isPlanetEmployee(props.details.user);

    this._handleTextDataChange = this._handleTextDataChange.bind(this);
    this._handleSelectDataChange = this._handleSelectDataChange.bind(this);
    this._handleEmailUpdate = this._handleEmailUpdate.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleCancel = this._handleCancel.bind(this);
  }

  _handleTextDataChange(field, value) {
    this.props.onDataChange({
      field,
      value,
    });
  }

  _handleSelectDataChange(field, event) {
    this.props.onDataChange({
      field,
      value: event.target.value,
    });
  }

  _getUserDetailsValue(field) {
    if (
      this.props.newDetails &&
      this.props.newDetails.hasOwnProperty('user') &&
      this.props.newDetails.user.hasOwnProperty(field)
    ) {
      return this.props.newDetails.user[field];
    }

    if (
      this.props.details &&
      this.props.details.user &&
      this.props.details.user.hasOwnProperty(field)
    ) {
      return this.props.details.user[field];
    }

    return null;
  }

  _getMembershipDataValue(field) {
    if (this.props.newDetails && this.props.newDetails.hasOwnProperty(field)) {
      return this.props.newDetails[field];
    }

    if (this.props.details && this.props.details.hasOwnProperty(field)) {
      return this.props.details[field];
    }

    return null;
  }

  _isUserDetailsDataUpdated(field) {
    return (
      this.props.newDetails &&
      this.props.newDetails.user &&
      this.props.newDetails.user.hasOwnProperty(field) &&
      this.props.newDetails.user[field] !== this.props.details.user[field]
    );
  }

  _isMemberDataUpdated(field) {
    return (
      this.props.newDetails &&
      this.props.newDetails.hasOwnProperty(field) &&
      this.props.newDetails[field] !== this.props.details[field]
    );
  }

  _renderUserRoles() {
    return roles
      .filter(role => {
        const maxRole = this.memberIsPlanetEmployee
          ? this.props.userRoleLevel
          : ROLES.orgAdmin;
        return role.level <= maxRole || this.props.isReadonly;
      })
      .map((role, i) => (
        <MenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={`role${i}`}
          value={role.level}
        >
          {role.label}
        </MenuItem>
      ));
  }

  _renderOrganizations() {
    const options = [];
    if (this.props.organizations) {
      options.push(
        ...this.props.organizations.map(({name, id}, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <MenuItem key={`organization${i}`} value={id}>
            {name}
          </MenuItem>
        ))
      );
    }
    return options;
  }

  _renderStates() {
    const states = ['Active', 'Disabled'];
    return states.map((state, i) => (
      <MenuItem
        // eslint-disable-next-line react/no-array-index-key
        key={`state${i}`}
        value={state.toLowerCase()}
      >
        {state}
      </MenuItem>
    ));
  }

  _renderCountries() {
    const options = [
      <MenuItem key="__none__" value="__none__">
        None
      </MenuItem>,
    ];
    // Setting empty string or null screws up floating label. __none__ is translated to
    // null in update action.
    countries().forEach(({code, name}) =>
      options.push(
        <MenuItem key={code} value={code}>
          {name}
        </MenuItem>
      )
    );
    return options;
  }

  _renderFormControls() {
    const isDataChanged =
      this._isUserDetailsDataUpdated('email') ||
      this._isUserDetailsDataUpdated('first_name') ||
      this._isUserDetailsDataUpdated('last_name') ||
      this._isUserDetailsDataUpdated('country') ||
      this._isUserDetailsDataUpdated('organization_id') ||
      this._isMemberDataUpdated('role_level') ||
      this._isMemberDataUpdated('state');

    if (isDataChanged) {
      return (
        <div className="pl-form--controls">
          <Button
            className="pl-form--control"
            color="primary"
            onClick={this._handleSubmit}
            style={{marginRight: 8}}
            variant="contained"
          >
            Save Changes
          </Button>

          <Button
            color="inherit"
            className="pl-form--control pl-form--control-default-btn"
            onClick={this._handleCancel}
          >
            Cancel
          </Button>
        </div>
      );
    }
  }

  _handleSubmit() {
    if (this.props.isEmailUnique !== false) {
      this.props.onSave();
    }
  }

  _handleCancel() {
    this.props.onCancel();
  }

  _handleEmailUpdate() {
    if (this._isUserDetailsDataUpdated('email')) {
      this.props.onEmailUpdate(this.props.newDetails.user.email);
    }
  }

  render() {
    const isDisabled = true;

    return (
      <div className="pl-form--section">
        <h4 className="pl-form--section-title">Member Profile</h4>

        {this.planetUserCanView && (
          <TextField
            disabled
            label="User Id"
            value={this._getUserDetailsValue('id')}
          />
        )}

        <TextField
          disabled={this.props.isReadonly}
          error={!!this.props.firstNameError}
          helperText={this.props.firstNameError}
          label="First Name"
          isChanged={this._isUserDetailsDataUpdated('first_name')}
          onChange={this._handleTextDataChange.bind(this, 'first_name')}
          value={this._getUserDetailsValue('first_name')}
        />

        <TextField
          disabled={this.props.isReadonly}
          error={!!this.props.lastNameError}
          helperText={this.props.lastNameError}
          label="Last Name"
          isChanged={this._isUserDetailsDataUpdated('last_name')}
          onChange={this._handleTextDataChange.bind(this, 'last_name')}
          value={this._getUserDetailsValue('last_name')}
        />

        <TextField
          disabled={this.props.isReadonly}
          error={!!this.props.emailError}
          helperText={this.props.emailError}
          label="Email"
          isChanged={this._isUserDetailsDataUpdated('email')}
          isInputValid={this.props.isEmailUnique}
          onChange={this._handleTextDataChange.bind(this, 'email')}
          onChangeCompleted={this._handleEmailUpdate}
          value={this._getUserDetailsValue('email')}
        />

        {this.planetUserCanView && (
          <TextField
            disabled
            label="Phone Number"
            // FIXME: Backend needs to serialize phone number on the member model
            value={this._getUserDetailsValue('phone_number') || '+1234567890'}
          />
        )}

        <SelectField
          disabled={this.props.isReadonly}
          error={!!this.props.countryError}
          helperText={this.props.countryError}
          label="Country"
          isChanged={this._isUserDetailsDataUpdated('country')}
          onChange={this._handleSelectDataChange.bind(this, 'country')}
          value={this._getUserDetailsValue('country') || '__none__'}
        >
          {this._renderCountries()}
        </SelectField>

        <SelectField
          disabled={this.props.isReadonly}
          label="Role"
          isChanged={this._isMemberDataUpdated('role_level')}
          onChange={this._handleSelectDataChange.bind(this, 'role_level')}
          value={this._getMembershipDataValue('role_level')}
        >
          {this._renderUserRoles()}
        </SelectField>

        {this._getMembershipDataValue('state') === 'deleted' ? (
          <SelectField
            disabled={isDisabled}
            label="State"
            isChanged={this._isMemberDataUpdated('state')}
            onChange={this._handleSelectDataChange.bind(this, 'state')}
            value={this._getMembershipDataValue('state')}
          >
            <MenuItem
              innerDivStyle={{textTransform: 'capitalize'}}
              value={this._getMembershipDataValue('state')}
            >
              {this._getMembershipDataValue('state')}
            </MenuItem>
          </SelectField>
        ) : (
          <SelectField
            disabled={this.props.isReadonly}
            label="State"
            isChanged={this._isMemberDataUpdated('state')}
            onChange={this._handleSelectDataChange.bind(this, 'state')}
            value={this._getMembershipDataValue('state')}
          >
            {this._renderStates()}
          </SelectField>
        )}

        {this._renderFormControls()}
      </div>
    );
  }
}

MemberProfileForm.propTypes = {
  countryError: string,
  details: object,
  emailError: string,
  firstNameError: string,

  isEmailUnique: bool,
  isReadonly: bool,

  lastNameError: string,
  newDetails: object,
  onCancel: func.isRequired,
  onDataChange: func.isRequired,
  onEmailUpdate: func.isRequired,
  onSave: func.isRequired,

  organizations: arrayOf(object),

  userRoleLevel: number.isRequired,
};

export default MemberProfileForm;
