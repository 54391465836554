import {bindActionCreators as bind} from 'redux';
import {connect} from 'react-redux';

import OrgCommentDelete from './org-comment-delete';
import {
  resetDeletedState,
  deleteOrgComment as unboundDeleteOrgComment,
} from './actions';

const mapStateToProps = (
  {
    orgs: {
      instance: {
        general: {
          comments: {del},
        },
      },
    },
  },
  {comment: srcComment, open}
) => ({
  ...del,
  srcComment,
  open,
});

const mapDispatchToProps = (dispatch, {orgId, onDeleteEnd}) => {
  const deleteOrgComment = unboundDeleteOrgComment.bind(null, orgId);
  return {
    onDelete: bind(deleteOrgComment, dispatch),
    resetDeletedState: () => bind(resetDeletedState, dispatch),
    onDeleteEnd: () => {
      if (onDeleteEnd) {
        onDeleteEnd();
      }
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgCommentDelete);
