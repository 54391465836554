export const PLAN_NAME_IS_NOT_UNIQUE_MSG = 'This package name is already taken';
export const PLAN_NAME_IS_NOT_SPECIFIED_MSG = 'Please enter a package name';
export const PLAN_ID_IS_MISSING_ERROR_MSG =
  'Package id is required to extract subscriptions';
export const DEFAULT_ERROR_MSG = 'Something went wrong';
export const PLAN_NOT_FOUND =
  'We are sorry, but the package you are looking for does not exist.';

export const ITEM_TYPE_ERROR_MESSAGE = 'Please select Item Type';
export const ITEM_TYPE_NOT_UNIQUE =
  'A permission can only have one of each item type';
export const POLICY_INVALID_AOI_ERROR_MESSAGE = 'Please correct AOI errors';
export const POLICY_AOI_NOT_SPECIFIED =
  'A permission must have at least one AOI record';
export const POLICY_INVALID_TOI_ERROR_MESSAGE = 'Please correct TOI errors';
export const POLICY_TOI_NOT_SPECIFIED =
  'A permission must have at least one TOI record';
export const MOSAICS_EMPTY = 'Please select at least one mosaic';
export const PERMISSIONS_EMPTY = 'Please select at least one permission';

export const START_DATE_NOT_SPECIFIED_ERROR_MESSAGE =
  'Start date is not specified';
export const END_DATE_NOT_SPECIFIED_ERROR_MESSAGE = 'End date is not specified';
