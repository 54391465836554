import {handleActions as create} from 'redux-actions';

import * as types from './types';
import {
  ERROR_MESSAGE,
  REFERENCE_PL_NUMBER_REGEX,
  REFERENCE_THEMES,
} from 'admin-ng/constants';

const initial = {
  basemapTileQuota: null,
  basemapQuadQuota: null,
  error: null,
  isFetchingPlans: false,
  isFetchingSubscription: false,
  isFetchingSubscriptions: false,
  newPlanName: '',
  organizationName: '',
  planId: null,
  planName: '',
  plans: [],
  plansError: null,
  reference: '',
  referenceValid: false,
  state: '',
  saveError: null,
  sceneTileQuota: null,
  subscriptionError: null,
  subscriptionId: null,
  subscriptions: [],
  subscriptionsError: null,
};

const validateReference = reference => {
  if (!reference) {
    return false;
  }

  let referenceValid = false;
  if (
    reference.match(REFERENCE_PL_NUMBER_REGEX) ||
    REFERENCE_THEMES.includes(reference)
  ) {
    referenceValid = true;
  }
  return referenceValid;
};

const saveHandler = {
  next: state => ({...state, saveError: initial.saveError}),
  throw: (state, {payload: payload}) => ({
    ...state,
    saveError: payload.body?.message || payload.message || ERROR_MESSAGE,
  }),
};

const handlers = {
  [types.CREATE]: saveHandler,

  [types.FETCH]: {
    next: (
      state,
      {
        payload: {isFetchingSubscription = false, ...payload} = {
          isFetchingSubscription: false,
        },
      }
    ) => ({
      ...state,
      ...payload,
      referenceValid: validateReference(payload.reference),
      subscriptionError: initial.subscriptionError,
      isFetchingSubscription,
    }),
    throw: (state, {payload: {message: subscriptionError}}) => ({
      ...state,
      isFetchingSubscription: false,
      subscriptionError: subscriptionError || ERROR_MESSAGE,
    }),
  },

  [types.FETCH_PLANS]: {
    next: (
      state,
      {
        payload: {isFetchingPlans = false, ...payload} = {
          isFetchingPlans: false,
        },
      }
    ) => ({
      ...state,
      ...payload,
      plansError: initial.plansError,
      isFetchingPlans,
    }),
    throw: (state, {payload: {message: plansError}}) => ({
      ...state,
      isFetchingPlans: false,
      plansError: plansError || ERROR_MESSAGE,
    }),
  },

  [types.FETCH_SUBSCRIPTIONS]: {
    next: (
      state,
      {
        payload: {isFetchingSubscriptions = false, ...payload} = {
          isFetchingSubscriptions: false,
        },
      }
    ) => ({
      ...state,
      ...payload,
      subscriptionsError: initial.subscriptionsError,
      isFetchingSubscriptions,
    }),
    throw: (state, {payload: {message: subscriptionsError}}) => ({
      ...state,
      isFetchingSubscriptions: false,
      subscriptionsError: subscriptionsError || ERROR_MESSAGE,
    }),
  },

  [types.RESET]: () => ({...initial}),

  [types.UPDATE]: saveHandler,

  [types.UPDATE_PLAN_ID]: {
    next: (state, {payload: planId}) => {
      const {subscriptionError, subscriptionId, planName} = initial;
      return {
        ...state,
        subscriptionError,
        subscriptionId,
        planId,
        planName,
      };
    },
    throw: (state, {payload: {message: subscriptionError}}) => {
      const {subscriptionId, planId, planName} = initial;
      return {
        ...state,
        subscriptionError,
        subscriptionId,
        planId,
        planName,
      };
    },
  },

  [types.UPDATE_NEW_PLAN_NAME]: (state, {payload: newPlanName}) => ({
    ...state,
    newPlanName,
  }),

  [types.UPDATE_REFERENCE]: (state, {payload: reference}) => {
    const referenceValid = validateReference(reference);
    return {...state, reference, referenceValid};
  },
};

export default create(handlers, initial);
