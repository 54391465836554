export const NEW_SUBORG_NAME_CHECK_REQUEST = 'NEW_SUBORG_NAME_CHECK_REQUEST';
export const NEW_SUBORG_NAME_CHECK_SUCCEESS = 'NEW_SUBORG_NAME_CHECK_SUCCEESS';
export const NEW_SUBORG_NAME_CHECK_FAIL = 'NEW_SUBORG_NAME_CHECK_FAIL';

export const NEW_SUBORG_UPDATE_NAME = 'NEW_SUBORG_UPDATE_NAME';

export const NEW_SUBORG_CREATE_REQUEST = 'NEW_SUBORG_CREATE_REQUEST';
export const NEW_SUBORG_CREATE_SUCCESS = 'NEW_SUBORG_CREATE_SUCCESS';
export const NEW_SUBORG_CREATE_FAIL = 'NEW_SUBORG_CREATE_FAIL';

export const NEW_SUBORG_RESET_STATE = 'NEW_SUBORG_RESET_STATE';
