import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import {bool, func, number, object, string} from 'prop-types';
import {withAuth} from '@apps-common/auth-react';

import Notification from 'admin-ng/components/common/notification';
import SelectField from 'admin-ng/components/common/select-field';
import TextField from 'admin-ng/components/common/text-field';
import countries from 'admin-ng/util/countries-list';
import roles from 'admin-ng/util/roles';
import {ROLES} from 'admin-ng/constants';

const emptyCountry = '__none__';

class MemberCreate extends Component {
  static propTypes = {
    additionalMessage: string,
    auth: object.isRequired,
    country: string,
    email: string.isRequired,
    emailError: string,
    error: string,
    firstName: string.isRequired,
    isCreated: bool.isRequired,
    isCreating: bool.isRequired,
    isEmailValid: bool.isRequired,
    isOpen: bool.isRequired,
    lastName: string.isRequired,
    onClose: func.isRequired,
    onEmailCheck: func.isRequired,
    onPasswordMatchingCheck: func.isRequired,
    onPasswordValidityCheck: func.isRequired,
    onSubmit: func.isRequired,
    password: string.isRequired,
    passwordError: string,
    passwordRepeat: string.isRequired,
    passwordRepeatError: string,
    reset: func.isRequired,
    role: number.isRequired,
    setUserProperty: func.isRequired,
  };

  constructor(props) {
    super(props);

    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._handleFirstNameChange = this._handleFirstNameChange.bind(this);
    this._handleLastNameChange = this._handleLastNameChange.bind(this);
    this._handlePasswordChange = this._handlePasswordChange.bind(this);
    this._handlePasswordRepeatChange =
      this._handlePasswordRepeatChange.bind(this);
    this._handleRoleChange = this._handleRoleChange.bind(this);
    this._handleCountryChange = this._handleCountryChange.bind(this);
    this._onPasswordCheck = this._onPasswordCheck.bind(this);
    this._onPasswordMatchingCheck = this._onPasswordMatchingCheck.bind(this);
    this._onClose = this._onClose.bind(this);
  }

  _renderUserRoles() {
    const userRoleLevel = this.props.auth.user.claims.role_level;
    return roles
      .filter(({level}) => level <= userRoleLevel)
      .map((role, i) => (
        <MenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={`role${i}`}
          value={role.level}
        >
          {role.label}
        </MenuItem>
      ));
  }

  _renderCountries() {
    const options = [];

    // Setting empty string or null screws up floating label. __none__ is translated to
    // null in update action.
    options.push(
      <MenuItem key="__none__" value={emptyCountry}>
        None
      </MenuItem>
    );
    countries().forEach(item =>
      options.push(
        <MenuItem key={item.code} value={item.code}>
          {item.name}
        </MenuItem>
      )
    );

    return options;
  }

  _handleEmailChange(value) {
    this.props.setUserProperty('email', value);
  }

  _handleFirstNameChange(value) {
    this.props.setUserProperty('firstName', value);
  }

  _handleLastNameChange(value) {
    this.props.setUserProperty('lastName', value);
  }

  _handlePasswordChange(value) {
    this.props.setUserProperty('password', value);
  }

  _handlePasswordRepeatChange(value) {
    this.props.setUserProperty('passwordRepeat', value);
  }

  _handleRoleChange(event) {
    const {value} = event.target;
    this.props.setUserProperty('role', value);
  }

  _handleCountryChange(event) {
    const {value} = event.target;
    this.props.setUserProperty('country', value);
  }

  _onPasswordCheck() {
    const {
      password,
      passwordRepeat,
      onPasswordValidityCheck,
      onPasswordMatchingCheck,
    } = this.props;
    onPasswordValidityCheck(password);
    if (passwordRepeat !== '') {
      onPasswordMatchingCheck(password, passwordRepeat);
    }
  }

  _onPasswordMatchingCheck() {
    const {password, passwordRepeat, onPasswordMatchingCheck} = this.props;
    if (password.length > 0) {
      onPasswordMatchingCheck(password, passwordRepeat);
    }
  }

  _onClose() {
    this.props.reset();
    this.props.onClose();
  }

  render() {
    const {
      additionalMessage,
      emailError,
      error,
      isCreated,
      isCreating,
      isEmailValid,
      isOpen,
      onEmailCheck,
      onSubmit,
      passwordError,
      passwordRepeatError,
      country,
      email,
      firstName,
      lastName,
      password,
      passwordRepeat,
      role,
    } = this.props;

    const userRoleLevel = this.props.auth.user.claims.role_level;
    const canUserSetPassword = userRoleLevel >= ROLES.planetAdmin;

    const emptyFields =
      email === '' ||
      !firstName ||
      firstName.length < 2 ||
      !lastName ||
      lastName.length < 2 ||
      country === null ||
      country === emptyCountry;
    const hasError =
      emailError || !isEmailValid || passwordError || passwordRepeatError;
    const emptyPassword =
      (canUserSetPassword && password === '') ||
      (canUserSetPassword && passwordRepeat === '');
    const passwordsMismatch = password !== passwordRepeat;
    const disabled = !!(
      hasError ||
      emptyFields ||
      emptyPassword ||
      passwordsMismatch ||
      isCreating
    );

    return isCreated ? (
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={this._onClose}
        open={isOpen}
        scroll="paper"
      >
        <DialogTitle>Add a user</DialogTitle>

        <DialogContent dividers>
          <DialogContentText>
            A user with the email {email} has been successully created
          </DialogContentText>

          {additionalMessage && (
            <Notification message={additionalMessage} type="warning" />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            data-qa-id="org-member-add-user-close-button"
            focusRipple
            onClick={this._onClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    ) : (
      <Dialog onClose={this._onClose} open={isOpen} scroll="paper">
        <DialogTitle>Add a user</DialogTitle>

        <DialogContent dividers>
          <Notification
            message={error}
            title="Something went wrong"
            type="error"
          />

          {!canUserSetPassword && (
            <Notification
              title="Password reset required after creation."
              type="info"
            >
              <p>
                Please direct the user to login to Planet at{' '}
                <a
                  href="https://planet.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  https://planet.com/
                </a>{' '}
                and to select the &quot;Forgot password?&quot; link after
                entering their their e-mail address to initiate a password reset
                and enable their account.
              </p>
            </Notification>
          )}

          <TextField
            error={!!emailError}
            helperText={emailError}
            label="Email *"
            isInputValid={isEmailValid}
            onChange={this._handleEmailChange}
            onChangeCompleted={onEmailCheck}
            type="email"
            value={email}
          />

          <TextField
            label="First name *"
            onChange={this._handleFirstNameChange}
            value={firstName}
          />

          <TextField
            label="Last name *"
            onChange={this._handleLastNameChange}
            value={lastName}
          />

          {canUserSetPassword && (
            <TextField
              error={!!passwordError}
              helperText={passwordError}
              label="Password *"
              onChange={this._handlePasswordChange}
              onChangeCompleted={this._onPasswordCheck}
              type="password"
              value={password}
            />
          )}

          {canUserSetPassword && (
            <TextField
              error={!!passwordRepeatError}
              helperText={passwordRepeatError}
              label="Repeat password *"
              onChange={this._handlePasswordRepeatChange}
              onChangeCompleted={this._onPasswordMatchingCheck}
              type="password"
              value={passwordRepeat}
            />
          )}

          <SelectField
            label="Country *"
            onChange={this._handleCountryChange}
            value={country || emptyCountry}
          >
            {this._renderCountries()}
          </SelectField>

          <SelectField
            label="Role *"
            onChange={this._handleRoleChange}
            style={{marginBottom: 10}}
            value={role}
          >
            {this._renderUserRoles()}
          </SelectField>
        </DialogContent>

        <DialogActions>
          <Button
            color="inherit"
            data-qa-id="org-member-add-user-cancel-button"
            onClick={this._onClose}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            data-qa-id="org-member-add-user-submit-button"
            disabled={disabled}
            focusRipple
            onClick={!disabled ? onSubmit : null}
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withAuth(MemberCreate);
