import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import * as actions from './actions';
import PlanPolicies from './plan-policies';
import {ROLES} from 'admin-ng/constants';
import {fetchPlan, resetState} from '../plan-common/actions';
import {fetchSubscriptions} from '../plan-subscriptions/plan-subscriptions-index/actions';

const mapStateToProps = (state, ownProps) => {
  const _state = state.plans.instance.policies;
  const roleLevel = ownProps.auth.user.claims.role_level;
  return {
    policies: _state.items,
    error: _state.error,
    isCopyPlanNameUnique: _state.isCopyPlanNameUnique,
    isPlanChanged: _state.isPlanChanged,
    isPlanetAdmin: roleLevel >= ROLES.planetAdmin,
    copyPlanError: _state.copyPlanError,
    selectedPolicy: _state.selectedPolicy,
    queryForNewName: _state.queryForNewName,
    availableItemTypes: _state.availableItemTypes,
    itemTypesRequestError: _state.itemTypesRequestError,
    isSaveRequestCompleted: _state.isSaveRequestCompleted,
    newSaveAsPlanId: _state.newSaveAsPlanId,

    subscriptions: state.plans.instance.subscriptions.subscriptions,
    subscriptionsTotalCount:
      state.plans.instance.subscriptions.subscriptionsTotalCount,

    commonError: state.plans.instance.common.error,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onAddPolicy: () => dispatch(actions.getNewPolicyName()),
  onAddAOI: () => dispatch(actions.addAOI()),
  onAddItemType: () => dispatch(actions.addItemType()),
  onAddTOI: () => dispatch(actions.addTOI()),
  onCancel: () => dispatch(resetState()),
  onCloseGlobalError: () => dispatch(actions.resetGlobalError()),
  onDeleteAOI: index => dispatch(actions.deleteAOI(index)),
  onDeleteItemType: index => dispatch(actions.deleteItemType(index)),
  onDeleteTOI: index => dispatch(actions.deleteTOI(index)),
  onGetAssetTypes: (policyIndex, itemTypeIndex, itemType) =>
    dispatch(actions.fetchAssetTypes(policyIndex, itemTypeIndex, itemType)),
  onGetAvailableItemTypes: () => dispatch(actions.fetchAvailableItemTypes()),
  onNewNameSubmit: (name, isExistingPolicy = false) =>
    dispatch(
      actions.setNewPolicyName(
        name,
        isExistingPolicy,
        props.match.params.planId,
        props.history
      )
    ),
  onOpen: () => dispatch(fetchPlan(props.match.params.planId, props.history)),
  onPlanNameCheck: name => dispatch(actions.checkPlanName(name)),
  onRemove: () =>
    dispatch(actions.removePolicy(props.match.params.planId, props.history)),
  onRename: () => dispatch(actions.renamePolicy()),
  onSave: () =>
    dispatch(actions.savePlan(props.match.params.planId, props.history)),
  onSaveAs: planName => dispatch(actions.savePlanAs(planName)),
  onSelectPolicy: index => dispatch(actions.setActivePolicy(index)),
  onUpdateDeliveryOptions: (type, value) =>
    dispatch(actions.updateDeliveryOptions(type, value)),
  onUpdateAOI: (index, aoi, isValid, error) =>
    dispatch(actions.updateAOI(index, aoi, isValid, error)),
  onUpdateAssetTypes: (index, assetTypes) =>
    dispatch(actions.updateAssetTypes(index, assetTypes)),
  onUpdateItemType: (index, itemType) =>
    dispatch(actions.updateItemType(index, itemType)),
  onUpdateTOI: (index, date, error) =>
    dispatch(actions.updateTOI(index, date, error)),
  onUploadAOIFile: (index, file) =>
    dispatch(actions.uploadAOIFile(index, file)),
  onOrgsRequest: () => dispatch(fetchSubscriptions(props.match.params.planId)),
  onUpdateSelectedMosaics: (index, items) =>
    dispatch(actions.updateSelectedMosaics(index, items)),
  onRequestBasemaps: (index, name) =>
    dispatch(actions.fetchBasemaps(index, name)),
  onRequestSeries: (index, name) => dispatch(actions.fetchSeries(index, name)),
  checkPlanUpdates: () => dispatch(actions.isPlanChanged()),
  resetSaved: () => dispatch(actions.resetSaved()),
  toggleItemType: index => dispatch(actions.toggleItemType(index)),
  toggleItemTypeAll: toggle => dispatch(actions.toggleItemTypeAll(toggle)),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps, undefined, {pure: false})(
    PlanPolicies
  )
);
