import React, {useEffect} from 'react';
import {Link, Route, Switch, useParams, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {func, number, object} from 'prop-types';

const SubscriptionLink = ({planId}) => {
  const {subscriptionId} = useParams();
  return (
    <Link to={`/plans/${planId}/subscriptions/${subscriptionId}`}>
      View Plan
    </Link>
  );
};

const PLANS_PAGE_TITLE = 'Packages';
const ASSIGN_PAGE_TITLE = 'Reassign packages';
const CREATE_PAGE_TITLE = 'Create new Package';
const POLICIES_PAGE_TITLE = 'Permissions';
const SUBSCRIPTIONS_PAGE_TITLE = 'Plans';
const BULK_CREATE = 'Plans bulk create';

SubscriptionLink.propTypes = {
  planId: number.isRequired,
};

const PlansHeader = ({details, updateTitle}) => {
  const rootPath = useRouteMatch('/packages');
  const assignPath = useRouteMatch('/packages/assign');
  const createPath = useRouteMatch('/packages/create');
  const generalPath = useRouteMatch('/packages/:planId/general');
  const policiesPath = useRouteMatch('/packages/:planId/policies');
  const subscriptionsPath = useRouteMatch('/packages/:planId/plans');
  const subscriptionPath = useRouteMatch(
    '/packages/:planId/plans/:subscriptionId'
  );
  const bulkCreatePath = useRouteMatch('/packages/:planId/plans/bulk-create');

  useEffect(() => {
    if (rootPath && rootPath.isExact) {
      updateTitle(PLANS_PAGE_TITLE);
    }
    if (generalPath) {
      updateTitle(details ? details.name : '');
    }
    if (subscriptionsPath && subscriptionsPath.isExact) {
      updateTitle(SUBSCRIPTIONS_PAGE_TITLE);
    }
    if (bulkCreatePath && bulkCreatePath.isExact) {
      updateTitle(BULK_CREATE);
    }
    if (subscriptionPath && subscriptionsPath.isExact) {
      updateTitle('View Plan');
    }
    if (policiesPath) {
      updateTitle(POLICIES_PAGE_TITLE);
    }
    if (assignPath) {
      updateTitle(ASSIGN_PAGE_TITLE);
    }
    if (createPath) {
      updateTitle(CREATE_PAGE_TITLE);
    }
  }, [
    details,
    updateTitle,
    rootPath,
    generalPath,
    policiesPath,
    subscriptionsPath,
    subscriptionPath,
    assignPath,
    bulkCreatePath,
    createPath,
  ]);

  return (
    <Route path="/packages">
      <Link to="/packages">Packages</Link>

      <Route path="/packages/create">
        {' / '}
        <Link to="/packages/create">Create new package</Link>
      </Route>

      <Route path="/packages/assign">
        {' / '}
        <Link to="/packages/assign">{ASSIGN_PAGE_TITLE}</Link>
      </Route>

      {details && (
        <Route path="/packages/:planId">
          {' / '}
          <Link to={`/packages/${details.id}`}>{details.name}</Link>

          <Route path="/packages/:planId/policies">
            {' / '}
            <Link to={`/packages/${details.id}/policies`}>Permissions</Link>
          </Route>

          <Switch>
            <Route exact path="/packages/:planId/plans/bulk-create">
              {' / '}
              <Link to={`/packages/${details.id}/plans/bulk-create`}>
                {BULK_CREATE}
              </Link>
            </Route>
            <Route path="/packages/:planId/plans">
              {' / '}
              <Link to={`/packages/${details.id}/plans`}>Plans</Link>
              <Route exact path="/packages/:planId/plans/:subscriptionId">
                {' / '}
                <SubscriptionLink planId={details.id} />
              </Route>
            </Route>
          </Switch>
        </Route>
      )}
    </Route>
  );
};

const mapStateToProps = ({
  plans: {
    instance: {
      common: {details},
    },
  },
}) => ({
  details,
});

PlansHeader.propTypes = {
  details: object,
  updateTitle: func.isRequired,
};

export default connect(mapStateToProps)(PlansHeader);
