import {combineReducers} from 'redux';

import common from './plan-common/reducer';
import general from './plan-general/reducer';
import policies from './plan-policies/reducer';
import subscriptions from './plan-subscriptions/reducer';
import workflow from './plan-create-workflow/reducer';

export default combineReducers({
  common,
  general,
  policies,
  subscriptions,
  workflow,
});
