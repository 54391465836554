import {connect} from 'react-redux';

import * as actions from './actions';
import DocumentCreate from './document-create';

const mapStateToProps = ({documents: {create}}) => create;
const mapDispatchToProps = (dispatch, props) => ({
  onCheckboxChange: event =>
    dispatch(actions.toggleCheckbox(event.currentTarget.name)),
  onClose: () => {
    props.onClose();
    return dispatch(actions.resetState());
  },
  onExternalNameChange: externalName =>
    dispatch(actions.updateExternalName(externalName)),
  onNameChange: name => dispatch(actions.updateName(name)),
  onNameCheck: name => dispatch(actions.checkName(name)),
  onSubmit: () => dispatch(actions.createNewDocument()),
  onTextChange: text => dispatch(actions.updateText(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCreate);
