import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import OrgEdit from './org-edit';
import {
  cancelOrgDetailsUpdates,
  checkOrgName,
  checkOrgSfdc,
  saveOrgDetailUpdates,
  updateDetailsData,
} from './actions';
import {fetchPrograms} from 'admin-ng/components/programs/programs-index/actions';

const mapStateToProps = (
  {
    orgs: {
      instance: {
        general: {
          edit: {
            initDetails,
            newDetails,
            error,
            isOrgNameUnique,
            isProgramsRequestPending,
            isCheckingOrgName,
            isOrgDetailsUpdateRequested,
            isOrgDetailsUpdateFinished,
            orgNameError,
            orgSfdcError,
          },
        },
      },
    },
    programs: {
      index: {programs},
    },
  },
  {
    auth: {
      user: {claims},
    },
  }
) => ({
  programs,
  initDetails,
  newDetails,
  error,
  isOrgNameUnique,
  isProgramsRequestPending,
  isCheckingOrgName,
  isOrgDetailsUpdateRequested,
  isOrgDetailsUpdateFinished,
  orgNameError,
  orgSfdcError,
  userRoleLevel: claims.role_level,
});

const mapDispatchToProps = (dispatch, props) => {
  const orgId = props.orgId ? parseInt(props.orgId) : null;
  return {
    isOrgSfdcValid: sfdc => dispatch(checkOrgSfdc(sfdc)),
    onOpen: () => dispatch(fetchPrograms({limit: 100})),
    onDataChange: params => dispatch(updateDetailsData(params)),
    onOrgNameCheck: orgName => dispatch(checkOrgName(orgName)),
    onCancel: () => {
      if (props.onCancel) {
        props.onCancel();
      }
      dispatch(cancelOrgDetailsUpdates());
    },
    onSave: () => dispatch(saveOrgDetailUpdates(orgId)),
  };
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(OrgEdit));
