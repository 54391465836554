export const ERROR_MESSAGE = 'Something went wrong';
export const PARAM_KEYS = ['filters', 'limit', 'offset', 'search', 'sort'];
export const ROLES = {
  planetAdmin: 2000,
  planetAdminViewOnly: 1999,
  orgAdmin: 1000,
  orgUser: 100,
};
export const WORLD_GEO_JSON =
  '{"geometry":{"coordinates":[[[-180,-90],[-180,90],[180,90],[180,-90],[-180,-90]]],"type":"Polygon"},"properties":{"area":511207893.3958111},"type":"Feature"}';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const AUTOCOMPLETE_MIN = 3;
export const PASSWORD_MIN = 10;
export const REFERENCE_THEMES = [
  'PL-Test',
  'PL-Partner',
  'PL-Marketing',
  'PL-Other',
];
export const REFERENCE_PL_NUMBER_REGEX = /^PL-\d{7}(;PL-\d{7})*?$/; // Semi-colon delimited PL#, e.g. "PL-1234567" or "PL-1234567;....;PL-8901234"
