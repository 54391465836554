import React from 'react';

import Documents from './documents-index/documents-index-container';
import Sidebar from 'admin-ng/components/common/sidebar';

const Programs = () => (
  <div className="pl-flex-wrapper">
    <Sidebar />
    <div className="pl-app--content">
      <Documents />
    </div>
  </div>
);

export default Programs;
