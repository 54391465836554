import {connect} from 'react-redux';

import ApiKeys from './api-keys';
import {fetch, reset, setParams} from './actions';

const mapStateToProps = ({apiKeys}) => apiKeys;
const mapDispatchToProps = dispatch => ({
  onMount: () => {
    dispatch(reset());
    dispatch(fetch());
  },
  onChange: params => {
    dispatch(setParams(params));
    dispatch(fetch());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiKeys);
