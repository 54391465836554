import {connect} from 'react-redux';

import Invitations from './invitations';
import {del, fetch, reset, setParams} from './actions';

const mapStateToProps = ({invitations}) => invitations;
const mapDispatchToProps = dispatch => ({
  onMount: () => {
    dispatch(reset());
    dispatch(fetch());
  },
  onChange: params => {
    dispatch(setParams(params));
    dispatch(fetch());
  },
  onDelete: id => dispatch(del(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Invitations);
