import {connect} from 'react-redux';

import OrgDetails from './org-details';
import {resetOrgApiKey} from './actions';

const mapStateToProps = state => {
  const {apiMasterKey, initDetails, error, isExporting} =
    state.orgs.instance.general.details;
  return {
    apiMasterKey,
    initDetails,
    error,
    isExporting,
  };
};
const mapDispatchToProps = dispatch => ({
  onResetApiKey: () => dispatch(resetOrgApiKey()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgDetails);
