import * as constants from './constants';

const initialState = {
  error: null,
  items: [],
  itemsTotalCount: 0,
  isOrgsRequestPending: false,
  viewParams: {
    filters: 'active,disabled',
    limit: 20,
    offset: 0,
    sort: '-id',
    search: '',
  },
};

export default function orgsIndexReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.REQUEST_ORGS:
      return Object.assign({}, state, {
        isOrgsRequestPending: true,
        viewParams: Object.assign({}, state.viewParams, action.params),
      });

    case constants.RECEIVE_ORGS:
      return Object.assign({}, state, {
        isOrgsRequestPending: false,
        items: action.items,
        itemsTotalCount: action.itemsTotalCount,
        error: null,
      });

    case constants.ORGS_REQUEST_FAILED:
      return Object.assign({}, state, {
        isOrgsRequestPending: false,
        error: action.error,
      });

    case constants.DELETE_ORG_REQUEST_FAILED:
      return Object.assign({}, state, {error: action.error});

    case constants.RESET_ORGS_VIEW_STATE: {
      return {
        ...state,
        viewParams: {...initialState.viewParams},
      };
    }

    default:
      return Object.assign({}, state);
  }
}
