import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import moment from 'moment';
import round from 'lodash/round';
import {Link, withRouter} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import DataState from 'admin-ng/components/common/data-state';
import GacReportDialog from 'admin-ng/components/common/gac-report-dialog';
import IconButton from 'admin-ng/components/common/icon-button';
import Notification from 'admin-ng/components/common/notification';
import OrganizationsCountLabel from './organizations-count-label';
import Pagination from 'admin-ng/components/common/pagination';
import Sidebar from 'admin-ng/components/common/sidebar';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import flippers from 'admin-ng/flippers';
import {DATE_FORMAT} from 'admin-ng/constants';

const menuItemStyle = {
  lineHeight: '48px',
  cursor: 'pointer',
};

class PlansIndex extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openToolsMenu: false,
      anchorEl: null,
      showDeleteConfirmation: false,
      itemToDelete: null,
      openGacDialog: false,
    };
    this._handleOpenToolsMenu = this._handleOpenToolsMenu.bind(this);
    this._stateColumnContentCreator =
      this._stateColumnContentCreator.bind(this);
    this._providersColumnContentCreator =
      this._providersColumnContentCreator.bind(this);
    this._handleCloseToolsMenu = this._handleCloseToolsMenu.bind(this);
    this._openAssignPage = this._openAssignPage.bind(this);
    this._openPlanCreate = this._openPlanCreate.bind(this);
    this._tableActionsColumnCreator =
      this._tableActionsColumnCreator.bind(this);
    this._ownerColumnContentCreator =
      this._ownerColumnContentCreator.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onDeleteConfirmed = this._onDeleteConfirmed.bind(this);
    this._onDeleteClose = this._onDeleteClose.bind(this);

    this._onGacDialogClose = this._onGacDialogClose.bind(this);
    this._openGacPage = this._openGacPage.bind(this);
  }

  componentDidMount() {
    // trigger data load with initial view params
    this.props.onResetView();
  }

  /**
   * This function returns data structure for organizaiton to be correctly
   * displayed in the a reusable component Table
   *
   * A single object represents a table column.
   *
   *     field - (required) property name in the data object (the same which is returned by api)
   *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
   *                          different from the field name.
   *     label - (required) column name.
   *     width - (required) column width to be set in the styles in the table header and table body
   *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
   *     contentCreator - [optional] - func - can be used for creating custom column content
   */
  _getOrgDataSchema() {
    return [
      {
        field: 'id',
        label: 'ID',
        width: '9%',
      },
      {
        field: 'name',
        label: 'Package Name',
        width: '22%',
        contentCreator: this._nameColumnContentCreator,
      },
      {
        field: 'author_organization.name',
        label: 'Owner Org',
        width: '10%',
        contentCreator: this._ownerColumnContentCreator,
      },
      {
        field: 'state',
        label: 'State',
        width: '8%',
        contentCreator: this._stateColumnContentCreator,
      },
      {
        field: 'item_types',
        isSortable: false,
        label: 'Providers',
        width: '15%',
        contentCreator: this._providersColumnContentCreator,
      },
      {
        field: 'union_aoi',
        sortKey: 'meta.union_aoi',
        isSortable: false,
        label: 'AOI (km²)',
        width: '8%',
        contentCreator: ({meta}) => (meta ? round(meta.union_aoi, 2) : '—'),
      },
      {
        field: 'organizations_count',
        label: (
          <OrganizationsCountLabel
            columnLabel="Orgs"
            excludeLabel="Exclude packages with 1 org"
            onToggleOneOrgPlans={this.props.onToggleOneOrgPlans}
            viewParams={this.props.viewParams}
          />
        ),
        width: '7%',
      },
      {
        field: 'policies_count',
        isSortable: false,
        label: 'Permissions',
        width: '7%',
      },
      {
        field: 'updated_at',
        label: 'Last Updated',
        width: '10%',
        contentCreator: item => moment(item.updated_at).format(DATE_FORMAT),
      },
      {
        field: null,
        label: '',
        width: '5%',
        contentCreator: this._tableActionsColumnCreator,
      },
    ];
  }

  _nameColumnContentCreator(item) {
    return (
      <Link title={item.name} to={`/packages/${item.id}`}>
        {item.name}
      </Link>
    );
  }

  _ownerColumnContentCreator(item) {
    if (item.author_organization) {
      return (
        <Link
          title={item.author_organization.name}
          to={`/organizations/${item.author_organization.id}`}
        >
          {item.author_organization.name}
        </Link>
      );
    } else {
      return null;
    }
  }

  _stateColumnContentCreator(item) {
    return item.state ? <DataState state={item.state} /> : '—';
  }

  _providersColumnContentCreator({meta}) {
    if (meta && meta.item_types && meta.item_types.length) {
      return (
        <span title={meta.item_types.join(', ')}>
          {meta.item_types.join(', ')}
        </span>
      );
    }
    return '—';
  }

  _tableActionsColumnCreator(item) {
    return (
      <IconButton
        icon="delete"
        isDisabled={item.state === 'deleted'}
        onClick={this._onDelete.bind(this, item)}
      />
    );
  }

  _onDelete(item) {
    this.setState({
      itemToDelete: item,
      showDeleteConfirmation: true,
    });
  }

  _onDeleteConfirmed() {
    this.props.onDeletePlan(this.state.itemToDelete.id);
    this._onDeleteClose();
  }

  _onDeleteClose() {
    this.setState({
      showDeleteConfirmation: false,
      itemToDelete: null,
    });
  }

  _onGacDialogClose() {
    this.setState({
      openGacDialog: false,
    });
  }

  _handleOpenToolsMenu(e) {
    e.preventDefault();

    this.setState({
      openToolsMenu: !this.state.openToolsMenu,
      anchorEl: e.currentTarget,
    });
  }

  _handleCloseToolsMenu() {
    this.setState({
      openToolsMenu: false,
    });
  }

  _openAssignPage() {
    this.props.history.push('/packages/assign');
  }

  _openGacPage() {
    this.setState({
      openGacDialog: true,
      openToolsMenu: false,
    });
  }

  _openPlanCreate() {
    this.props.history.push('/packages/create');
  }

  render() {
    const orgFilterNames = ['active', 'deleted'];

    const createButton = (
      <Button
        className="pl-table-controls--add-btn"
        color="primary"
        key={1}
        onClick={this._openPlanCreate}
        variant="contained"
      >
        Create
      </Button>
    );

    // The 'Tools' button with bulk package assign funciton. Only available to Planet Admins
    /*
    const toolsButton =
      flippers.PLANS_ASSIGN && isPlanetAdmin ? (
        <Button
          className="pl-table--tools-btn"
          endIcon={<SettingsIcon />}
          key={2}
          onClick={this._handleOpenToolsMenu}
          sx={{marginLeft: 1.5}}
          variant="outlined"
        >
          Tools
        </Button>
      ) : null;
      */

    return (
      <div className="pl-flex-wrapper">
        <Sidebar />
        <div className="pl-app--content">
          <Card>
            <div className="pl-table-card-content">
              <ConfirmationMessage
                onClose={this._onDeleteClose}
                onSubmit={this._onDeleteConfirmed}
                open={this.state.showDeleteConfirmation}
                qaId="plan-delete"
                title="Delete package"
              >
                Are you sure you want to delete package
                {this.state.itemToDelete
                  ? ` ${this.state.itemToDelete.name}`
                  : ''}
                ?
              </ConfirmationMessage>

              <TableControlsPanel
                actions={[createButton /*, toolsButton*/]} // Temporarily removing this to see if it's still in use
                activeFilters={this.props.viewParams.filters}
                filters={orgFilterNames}
                itemsPerPageLimit={this.props.viewParams.limit}
                onFilter={this.props.onRequestPlans}
              />

              <Notification
                message={this.props.plansRequestError}
                title="Oops, packages request has failed"
                type="error"
              />

              <Table
                className="pl-table-index"
                dataSchema={this._getOrgDataSchema()}
                isRequestPending={this.props.isPlansRequestPending}
                onSort={this.props.onRequestPlans}
                qaId="plans-table"
                sortBy={this.props.viewParams.sort}
                srcData={this.props.plans}
              />

              <Pagination
                itemsLimit={this.props.viewParams.limit}
                itemsOffset={this.props.viewParams.offset}
                itemsTotal={this.props.plansTotalCount}
                onPageChange={this.props.onRequestPlans}
              />

              {flippers.PLANS_ASSIGN ? (
                <Menu
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom',
                  }}
                  getContentAnchorEl={null}
                  onClose={this._handleCloseToolsMenu}
                  open={this.state.openToolsMenu}
                  transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                  }}
                >
                  <MenuItem
                    onClick={this._openAssignPage}
                    style={menuItemStyle}
                  >
                    Assign Packages
                  </MenuItem>

                  <MenuItem onClick={this._openGacPage} style={menuItemStyle}>
                    Generate GAC Report
                  </MenuItem>
                </Menu>
              ) : null}

              <GacReportDialog
                onClose={this._onGacDialogClose}
                open={this.state.openGacDialog}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

PlansIndex.propTypes = {
  history: object.isRequired,
  isPlansRequestPending: bool.isRequired,
  onDeletePlan: func.isRequired,
  onRequestPlans: func.isRequired,
  onResetView: func.isRequired,
  onToggleOneOrgPlans: func.isRequired,
  plans: arrayOf(object),
  plansRequestError: string,
  plansTotalCount: number.isRequired,
  viewParams: object.isRequired,
};

export default withRouter(PlansIndex);
