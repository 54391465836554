import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

const TRACK_NAME = '[admin-ng]';

export const trackEvent = (eventName, props = {}) => {
  window.analytics.track(`${TRACK_NAME} ${eventName}`, {
    appName: 'Admin NG',
    ...props,
  });
};

export function usePageView() {
  const location = useLocation();

  useEffect(() => {
    window.analytics.page({
      path: location.pathname,
      url: window.location.href,
    });
  }, [location]);
}

export const identify = user => {
  const identifyOptions = {
    Intercom: {
      hideDefaultLauncher: true,
    },
  };

  window.analytics.identify(
    user.email,
    {
      email: user.email,
      userId: user.user_id,
      organizationId: user.organization_id,
      programId: user.program_id,
    },
    identifyOptions
  );
};

export const reset = () => window.analytics.reset();
