import * as constants from './constants';

const initialState = {
  step: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.GOTO_NEXT_STEP:
      return Object.assign({}, state, {
        step: state.step + 1,
      });

    case constants.GOTO_PREVIOUS_STEP:
      return Object.assign({}, state, {
        step: state.step - 1,
      });

    case constants.RESET_ASSIGN_STATE:
      return Object.assign({}, state, initialState);

    default:
      return state;
  }
};
