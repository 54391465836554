import * as constants from './constants';
import {ERROR_MESSAGE} from 'admin-ng/constants';
import {all} from 'admin-ng/api/users';
import {inviteMember} from 'admin-ng/api/organizations';
import {isEmail} from 'admin-ng/util/validation';

const EMAIL_IS_NOT_UNIQUE_MSG = 'This email is already a member';
const EMAIL_IS_NOT_SPECIFIED = 'Please provide user email';
const ROLE_IS_NOT_SPECIFIED = 'Please select user role';
const INVALID_EMAIL_MSG = 'The email is not valid';

export const sendUserInviteRequest = (params = {}) => ({
  type: constants.SEND_USER_INVITE_REQUEST,
  params,
});

export const completeUserInviteRequest = () => ({
  type: constants.COMPLETE_USER_INVITE_REQUEST,
});

export const userInviteRequestFailed = (error = ERROR_MESSAGE) => ({
  type: constants.USER_INVITE_REQUEST_FAILED,
  error,
});

export const requestUserEmailCheck = (params = {}) => ({
  type: constants.REQUEST_USER_EMAIL_CHECK,
  params,
});

export const completeUserEmailCheck = (isEmailUnique, error = null) => ({
  type: constants.COMPLETE_USER_EMAIL_CHECK,
  isEmailUnique,
  error,
});

export const updateEmail = (email = '', error = null) => ({
  type: constants.UPDATE_EMAIL,
  email,
  error,
});

export const updateRole = (role = null, error = null) => ({
  type: constants.UPDATE_ROLE,
  role,
  error,
});

export const resetInviteState = () => ({
  type: constants.RESET_INVITE_STATE,
});

export const updateData =
  (params = {}) =>
  dispatch => {
    switch (params.field) {
      case 'email':
        dispatch(updateEmail(params.value));
        break;
      case 'role':
        dispatch(updateRole(params.value));
        break;
      default:
        break;
    }
  };

export const validateEmail = email => async dispatch => {
  if (!email) {
    dispatch(updateEmail(email, EMAIL_IS_NOT_SPECIFIED));
    return false;
  }
  if (!isEmail(email)) {
    dispatch(completeUserEmailCheck(false, INVALID_EMAIL_MSG));
    return false;
  }
  const params = {email};
  dispatch(requestUserEmailCheck(params));
  try {
    const {body} = await all(params);
    const items = (body && body.results) || [];
    if (!Array.isArray(items)) {
      dispatch(completeUserEmailCheck(false, 'Something went wrong'));
      return false;
    }
    if (items.length > 0) {
      dispatch(completeUserEmailCheck(false, EMAIL_IS_NOT_UNIQUE_MSG));
      return false;
    } else {
      dispatch(completeUserEmailCheck(true));
      return true;
    }
  } catch (err) {
    dispatch(
      completeUserEmailCheck(
        false,
        (err && err.message) || 'Something went wrong'
      )
    );
    return false;
  }
};

export const inviteUser = orgId => async (dispatch, getState) => {
  const {
    orgs: {
      instance: {
        users: {
          invite: {email, role},
        },
      },
    },
  } = getState();
  const result = await dispatch(validateEmail(email));
  if (!result) {
    return;
  }
  if (!role) {
    return dispatch(updateRole(null, ROLE_IS_NOT_SPECIFIED));
  }
  const params = {email, role_level: role};
  dispatch(sendUserInviteRequest(params));
  try {
    await inviteMember(orgId, params);
    return dispatch(completeUserInviteRequest());
  } catch (err) {
    dispatch(userInviteRequestFailed(err && err.message));
  }
};
