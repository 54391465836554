import download from 'downloadjs';

import request from 'admin-ng/util/request-promise';
import {SUBSCRIPTIONS} from '.';

export const all = params => request(SUBSCRIPTIONS, 'get', params);
export const one = id => request(`${SUBSCRIPTIONS}${id}`, 'get', {});
export const create = params => request(SUBSCRIPTIONS, 'post', params);
export const update = (id, params) =>
  request(`${SUBSCRIPTIONS}${id}`, 'put', params);
export const del = id => request(`${SUBSCRIPTIONS}${id}`, 'del');
export const reassign = params =>
  request(`${SUBSCRIPTIONS}reassign`, 'post', params);
export const bulkCreate = params =>
  request(`${SUBSCRIPTIONS}bulk_add`, 'post', params);
export const quotaUsage = (ids = []) =>
  request(`${SUBSCRIPTIONS}get-quota-usage/${ids.join(',')}?v=3`, 'get');
export const operations = () => request(`${SUBSCRIPTIONS}compute/ops`, 'get');
export const resetBasemapTileQuota = id =>
  request(`${SUBSCRIPTIONS}${id}/tile-quota-reset/basemap-tile`, 'post');

export const downloadCoverage = (id, from, to, filename, mime) => {
  request(`${SUBSCRIPTIONS}${id}/coverage_usage/${from},${to}`, 'get').then(
    ({body}) => {
      download(JSON.stringify(body), filename, mime);
    }
  );
};
