const roles = [];

roles.push(
  {
    label: 'Organization User',
    level: 100,
  },
  {
    label: 'Organization Admin',
    level: 1000,
  },
  {
    label: 'Planet Admin View Only',
    level: 1999,
  },
  {
    label: 'Planet Admin',
    level: 2000,
  }
);

export default roles;
