import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {bool, func, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';

export default class OrgCommentDelete extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.commentDeleted) {
      this._onDeleteClose();
    }
  }

  _onDeleteConfirmed = () => this.props.onDelete(this.props.srcComment.id);

  _onDeleteClose = () => {
    this.props.onDeleteEnd();
    this.props.resetDeletedState();
  };

  render() {
    const content = {__html: this.props.srcComment.text};
    /* eslint-disable react/no-danger */
    return (
      <div className="pl-org-comment-delete">
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={this._onDeleteClose}
          open={this.props.open}
          scroll="paper"
        >
          <DialogTitle>Delete Comment</DialogTitle>

          <DialogContent dividers>
            <Notification
              message={this.props.error}
              title="Oops, could not delete a comment"
              type="error"
            />

            <p className="pl-org-comment-delete--confirmation-msg">
              Are you sure you want to delete this comment?
            </p>

            <div className="pl-org-comment-delete--content">
              <div
                className="DraftEditor-editorContainer"
                dangerouslySetInnerHTML={content}
              />
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              color="inherit"
              label="No"
              onClick={this._onDeleteClose}
              sx={{marginRight: '10px'}}
            >
              No
            </Button>
            <Button
              color="primary"
              focusRipple
              onClick={this._onDeleteConfirmed}
              variant="contained"
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

OrgCommentDelete.propTypes = {
  commentDeleted: bool,
  error: string,
  onDelete: func.isRequired,
  onDeleteEnd: func.isRequired,
  open: bool,
  resetDeletedState: func.isRequired,
  srcComment: object.isRequired,
};
