import {combineReducers} from 'redux';

import bulk from './bulk-screening/reducer';
import index from './list/reducer';
import record from './record/reducer';

export default combineReducers({
  index,
  record,
  bulk,
});
