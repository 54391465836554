import React from 'react';
import classNames from 'classnames';
import {node, object, oneOfType, string} from 'prop-types';

const states = ['success', 'info', 'warning', 'error'];

const Notification = props => {
  if (!props.message && !props.children) {
    return null;
  }
  const classes = ['pl-notification'];
  if (!props.type) {
    classes.push('pl-notification--error');
  } else if (states.includes(props.type)) {
    classes.push(`pl-notification--${props.type}`);
  }

  return (
    <div className={classNames(classes)}>
      <div className="pl-notification--icon" />
      <div className="pl-notification--body">
        <p className="pl-notification--title">{props.title}</p>
        <p className="pl-notification--message">{props.message}</p>
        {props.children}
      </div>
    </div>
  );
};

Notification.propTypes = {
  children: node,
  message: oneOfType([string, object]),
  title: string,
  type: string,
};

export default Notification;
