import React, {useCallback, useEffect} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
  withRouter,
} from 'react-router-dom';
import {bool, func, object, string} from 'prop-types';

import Member from 'admin-ng/components/orgs/org-instance/org-members/org-member-instance/org-member-instance-container';
import MembersIndex from './org-members/org-members-index/org-members-index-container';
import Notification from 'admin-ng/components/common/notification';
import OrgGeneral from './org-general/org-general';
import PlanSubscriptions from './org-subscriptions/org-subscriptions-index';
import Sidebar from 'admin-ng/components/common/sidebar';
import SubOrgs from './suborgs/suborgs-index/suborgs-index-container';
import SubscriptionForm from 'admin-ng/components/common/subscription-details/form';
import SubscriptionInstance from 'admin-ng/components/common/subscription-details/instance';
import Tab from 'admin-ng/components/common/tab';
import Tabs from 'admin-ng/components/common/tabs';
import {fetchOrgDetails} from './org-general/org-details/actions';

const EditSubscriptionForm = props => (
  <SubscriptionForm type="edit" {...props} />
);
const CreateSubscriptionForm = props => (
  <SubscriptionForm type="create" {...props} />
);

const OrgInstance = ({
  dispatch,
  details,
  error,
  isOrgDetailsRequestPending,
}) => {
  const {orgId} = useParams();
  const history = useHistory();

  // Hide all tabs until org details have been fetched
  const isStoreOutdated = details && orgId !== String(details.id);
  const emptyContent = !details || isStoreOutdated;
  const tabsStyle = emptyContent ? {display: 'none'} : {};

  const fetchOrg = useCallback(() => {
    dispatch(fetchOrgDetails(orgId, history));
  }, [dispatch, orgId, history]);

  useEffect(() => {
    const oldId = details && details.id;
    if (!isOrgDetailsRequestPending && !error && orgId !== String(oldId)) {
      fetchOrg();
    }
  }, [isOrgDetailsRequestPending, error, details, orgId, fetchOrg]);

  useEffect(() => {
    fetchOrg();
  }, [fetchOrg]);

  return (
    <div className="pl-flex-wrapper">
      <Tabs className="pl-top-level-tabs" style={tabsStyle}>
        <Tab
          link={`/organizations/${orgId}/general`}
          qaId="pl-org-instance-tab-general"
        >
          General
        </Tab>
        {details && !details.parent_organization && (
          <Tab
            link={`/organizations/${orgId}/suborgs`}
            qaId="pl-org-instance-tab-suborgs"
          >
            Suborganizations
          </Tab>
        )}
        <Tab
          link={`/organizations/${orgId}/members`}
          qaId="pl-org-instance-tab-members"
        >
          Members
        </Tab>
        <Tab
          link={`/organizations/${orgId}/plans`}
          qaId="pl-org-instance-tab-subscriptions"
        >
          Plans
        </Tab>
      </Tabs>
      <Sidebar />
      <div className="pl-app--content">
        {error && (
          <Notification
            message={
              <span>
                {error} <a onClick={fetchOrg}>Try Again</a>
              </span>
            }
            title="Oops, Org Details request has failed"
            type="error"
          />
        )}

        <Switch>
          <Route component={OrgGeneral} path="/organizations/:orgId/general" />
          <Route component={SubOrgs} path="/organizations/:orgId/suborgs" />
          <Route
            component={Member}
            path="/organizations/:orgId/members/:memberId"
          />
          <Route
            component={MembersIndex}
            path="/organizations/:orgId/members"
          />
          <Route
            component={EditSubscriptionForm}
            path="/organizations/:orgId/plans/:subscriptionId/edit"
          />
          <Route
            component={CreateSubscriptionForm}
            exact
            path="/organizations/:orgId/plans/create"
          />
          <Route
            component={SubscriptionInstance}
            exact
            path="/organizations/:orgId/plans/:subscriptionId"
          />
          <Route
            component={PlanSubscriptions}
            path="/organizations/:orgId/plans"
          />
          <Redirect to={`/organizations/${orgId}/general`} />
        </Switch>
      </div>
    </div>
  );
};

OrgInstance.propTypes = {
  details: object,
  dispatch: func.isRequired,
  error: string,
  isOrgDetailsRequestPending: bool.isRequired,
};

export default withRouter(OrgInstance);
