import assign from 'deep-extend';

import * as constants from './constants';
import * as messages from '../plan-common/messages';
import {all, update} from 'admin-ng/api/plans';
import {updateRouteName} from 'admin-ng/components/app/actions';

const PLAN_NAME_MIN_LENGTH = 1;

export const updatePlanName = (name = '', error = null) => ({
  type: constants.UPDATE_NAME,
  name,
  error,
});

export const requestPlanNameCheck = () => ({
  type: constants.REQUEST_NAME_CHECK,
});

export const planNameCheckSuccess = () => ({
  type: constants.NAME_CHECK_SUCCESS,
});

export const planNameCheckFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.NAME_CHECK_FAIL,
  payload: error,
  error: true,
});

export const resetGeneralPlanDetails = () => ({
  type: constants.RESET_GENERAL_PLAN_DETAILS,
});

export const requestPlanNameSave = (params = null) => ({
  type: constants.REQUEST_PLAN_NAME_SAVE,
  params,
});

export const planNameSaveRequestCompleted = name => ({
  type: constants.PLAN_NAME_SAVE_REQUEST_COMPLETED,
  name,
});

export const planNameSaveRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.PLAN_NAME_SAVE_REQUEST_FAILED,
  error,
});

const dispatchActions = (dispatch, params) => {
  return all(params)
    .then((res = {}) => {
      const items = res.body && res.body.results ? res.body.results : [];

      if (items) {
        if (items.length) {
          dispatch(planNameCheckFail(messages.PLAN_NAME_IS_NOT_UNIQUE_MSG));
        } else {
          dispatch(planNameCheckSuccess());
        }
      } else {
        dispatch(planNameCheckFail(messages.DEFAULT_ERROR_MSG));
      }
    })
    .catch((err = {}) => {
      dispatch(planNameCheckFail(err.message || messages.DEFAULT_ERROR_MSG));
    });
};

let searchTimeout;

export const checkPlanName = () => {
  return function (dispatch, getState) {
    const state = getState(),
      generalState = state.plans.instance.general,
      params = {
        name__ieq: generalState.planName.trim(),
      };

    clearTimeout(searchTimeout);
    return new Promise((resolve, reject) => {
      searchTimeout = setTimeout(() => {
        dispatch(requestPlanNameCheck(params));
        dispatchActions(dispatch, params).then(resolve).catch(reject);
      }, 400);
    });
  };
};

export const savePlanName = planId => {
  return (dispatch, getState) => {
    const state = getState();
    const _common = state.plans.instance.common;
    const _general = state.plans.instance.general;
    const policies =
      _common.details &&
      _common.details.policies &&
      Array.isArray(_common.details.policies)
        ? _common.details.policies.slice(0)
        : [];
    const newPolicies = policies.map(policy => {
      const newPolicy = assign({}, policy);
      newPolicy.constraints.forEach(constraint => {
        // NOTE: The api doesn't like having constraint_version passed back to it
        delete constraint.constraint_version;
        if (constraint.key === 'aoi') {
          constraint.value = {
            definitions: constraint.value.definitions.features.slice(0),
            type: 'GeoJSON',
          };
        }
      });
      return newPolicy;
    });
    let params;

    if (_general.planName !== null) {
      const name = _general.planName.trim();
      if (name.length >= PLAN_NAME_MIN_LENGTH) {
        params = assign(
          {},
          {
            name,
            policies: newPolicies,
          }
        );
      } else {
        return dispatch(
          updatePlanName('', messages.PLAN_NAME_IS_NOT_SPECIFIED_MSG)
        );
      }
    }

    dispatch(requestPlanNameSave(params));

    if (_general.isPlanNameUnique) {
      return update(planId, params)
        .then(() => {
          dispatch(updateRouteName('PLAN_DETAILS', params.name));
          dispatch(planNameSaveRequestCompleted(params.name));
        })
        .catch((err = {}) => {
          dispatch(planNameSaveRequestFailed(err.message));
        });
    }
  };
};
