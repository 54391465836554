import LinearProgress from '@mui/material/LinearProgress';
import React from 'react';
import {number, object, string} from 'prop-types';

const IndicatorBar = ({
  barStyle,
  color,
  displayText,
  hoverText,
  textStyle,
  value,
}) => (
  <div style={{position: 'relative'}}>
    <LinearProgress
      sx={{
        height: '24px',
        // We have to set the color this way because LinearProgress's color prop
        // only takes named values, not hex strings
        '& .MuiLinearProgress-bar1Determinate': {backgroundColor: color},
        ...barStyle,
      }}
      value={value}
      variant="determinate"
    />

    <span
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        ...textStyle,
      }}
      title={hoverText}
    >
      {displayText}
    </span>
  </div>
);

IndicatorBar.propTypes = {
  barStyle: object,
  color: string,
  displayText: string,
  hoverText: string,
  textStyle: object,
  value: number,
};

export default IndicatorBar;
