import React from 'react';
import Table from '@mui/material/Table';
import classNames from 'classnames';
import {
  arrayOf,
  bool,
  func,
  number,
  object,
  oneOfType,
  string,
} from 'prop-types';

import TableBody from 'admin-ng/components/common/table-body';
import TableHeader from 'admin-ng/components/common/table-header';

const PlTable = props => {
  const classes = classNames({'pl-table': true}, props.className);

  return (
    <div className="pl-table-container" data-qa-id={props.qaId}>
      <div className="pl-table-viewport">
        <Table className={classes} height={props.height} style={props.style}>
          <TableHeader
            dataSchema={props.dataSchema}
            onSort={props.onSort}
            sortBy={props.sortBy}
          />

          <TableBody
            dataSchema={props.dataSchema}
            height={props.height}
            isRequestPending={props.isRequestPending}
            noDataLabel="No Data Available"
            qaId={props.qaId}
            srcData={props.srcData}
          />
        </Table>
      </div>
    </div>
  );
};

PlTable.propTypes = {
  className: string,
  dataSchema: arrayOf(object).isRequired,
  height: string,
  isRequestPending: bool,
  onSort: func,
  qaId: string,
  sortBy: string,
  srcData: arrayOf(oneOfType([string, number, object])),
  style: object,
};

PlTable.defaultProps = {
  height: 'inherit',
  selectable: false,
};

export default PlTable;
