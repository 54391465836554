import {connect} from 'react-redux';

import PlanCreateWorkflow from './plan-create-workflow';
import {
  createNewPlan,
  resetState,
  resetGlobalError as resetWorkflowError,
} from './actions';
import {resetGlobalError as resetPoliciesError} from '../plan-policies/actions';

const mapStateToProps = state => ({
  ...state.plans.instance.workflow,
  error: state.plans.instance.policies.error,
});

const mapDispatchToProps = dispatch => ({
  onCancel: () => dispatch(resetState()),
  onCreate: () => dispatch(createNewPlan()),
  onCloseGlobalError: () => {
    dispatch(resetWorkflowError());
    dispatch(resetPoliciesError());
  },
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
})(PlanCreateWorkflow);
