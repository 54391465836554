import GeoJSON from 'ol/format/GeoJSON.js';
import isEmailValid from 'validator/lib/isEmail';
import {isArray, isEmpty, isFinite, isNumber, isPlainObject} from 'lodash';

const geoJSONFormat = new GeoJSON();

export const isEmail = email => (email ? isEmailValid(String(email)) : false);
export const isPoint = point => isNumber(point) && isFinite(point);
export const isLatitude = latitude =>
  isPoint(latitude) && latitude >= -90 && latitude <= 90;
export const isPosition = position =>
  isArray(position) && position.length >= 2 && position.every(isPoint);
export const isExteriorRing = exteriorRing =>
  isArray(exteriorRing) &&
  !isEmpty(exteriorRing) &&
  exteriorRing.every(isPosition);
export const isGeoJSON = string => {
  try {
    geoJSONFormat.readFeatures(JSON.parse(string));
  } catch (err) {
    return false;
  }
  return true;
};

export const areCoordinates = coordinates => {
  if (!isArray(coordinates)) {
    return 'coordinates.invalid';
  }
  const exteriorRing = coordinates[0];
  if (!isExteriorRing(exteriorRing)) {
    return 'coordinates.exterior-ring';
  }
  if (!exteriorRing.every(([, lat]) => isLatitude(lat))) {
    return 'coordinates.world-boundaries';
  }
  return true;
};

export const isGeometry = geometry => {
  if (!isPlainObject(geometry)) {
    return 'geometry.invalid';
  }
  const coordinatesSet = [];
  if (geometry.type === 'MultiPolygon') {
    coordinatesSet.push(...geometry.coordinates);
  } else if (geometry.type === 'Polygon') {
    coordinatesSet.push(geometry.coordinates);
  } else {
    return 'geometry.type';
  }
  const results = coordinatesSet.map(areCoordinates);
  const invalid = results.find(result => result !== true);
  return invalid || true;
};

export const isAOI = aoi => {
  if (!aoi) {
    return 'aoi.invalid';
  }
  if (!isGeoJSON(aoi)) {
    return 'aoi.geo-json';
  }
  const parsed = JSON.parse(aoi);
  const geometries = [];
  if (parsed.type === 'FeatureCollection') {
    geometries.push(...parsed.features.map(({geometry}) => geometry));
  } else if (parsed.type === 'Feature') {
    geometries.push(parsed.geometry);
  } else if (parsed.type === 'GeometryCollection') {
    geometries.push(...parsed.geometries);
  } else {
    geometries.push(parsed);
  }
  const results = geometries.map(isGeometry);
  return results.find(result => result !== true) || true;
};

// https://regex101.com/r/9WqcBo/1
// https://support.okta.com/help/s/article/What-special-characters-are-accepted-by-the-Okta-password?language=en_US
export function isPasswordValid(password) {
  const passwordRegex =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!#@$%^&*()_+|~=`{}\[\]:’";<>?,\\.\/])/; // eslint-disable-line

  const minLength = 10;

  if (password.length < minLength) {
    return false;
  }

  if (!passwordRegex.test(password)) {
    return false;
  }

  return true;
}
