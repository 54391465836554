import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import DataState from 'admin-ng/components/common/data-state';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import Sidebar from 'admin-ng/components/common/sidebar';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';

export default class ApiKeys extends Component {
  static propTypes = {
    apiKeys: arrayOf(object),
    error: string,
    filters: string.isRequired,
    isFetching: bool.isRequired,
    limit: number.isRequired,
    offset: number.isRequired,
    onChange: func.isRequired,
    onMount: func.isRequired,
    sort: string.isRequired,
    total: number.isRequired,
  };

  static defaultProps = {
    error: null,
  };

  componentDidMount() {
    this.props.onMount();
  }

  get schema() {
    const schema = [
      {
        field: 'id',
        label: 'ID',
        width: '9%',
      },
      {
        field: 'label',
        label: 'Label',
        width: '15%',
        contentCreator: ({label}) =>
          label ? <span title={label}>{label}</span> : '—',
      },
      {
        field: 'name',
        label: 'Name',
        width: '25%',
        contentCreator: ({name}) =>
          name ? <span title={name}>{name}</span> : '—',
      },
      {
        field: 'state',
        label: 'State',
        width: '10%',
        contentCreator: ({state}) =>
          state ? <DataState state={state} /> : '-',
      },
      {
        field: 'organization.name',
        label: 'Organization',
        width: '21%',
        contentCreator: ({organization_id, organization}) =>
          organization_id && organization && organization.name ? (
            <Link
              title={organization.name}
              to={`/organizations/${organization_id}`}
            >
              {organization.name}
            </Link>
          ) : (
            '—'
          ),
      },
      {
        field: 'membership.user.email',
        label: 'User',
        width: '21%',
        contentCreator: ({membership}) =>
          membership && membership.user && membership.user.email ? (
            <Link
              title={membership.user.email}
              to={`/organizations/${membership.organization_id}/members/${membership.id}`}
            >
              {membership.user.email}
            </Link>
          ) : (
            '—'
          ),
      },
    ];
    return schema;
  }

  render() {
    const {
      apiKeys,
      error,
      filters: activeFilters,
      isFetching,
      limit,
      offset,
      onChange,
      sort,
      total,
    } = this.props;
    const filters = ['active', 'disabled'];
    return (
      <div className="pl-flex-wrapper">
        <Sidebar />
        <div className="pl-app--content">
          <Card>
            <div className="pl-table-card-content">
              <TableControlsPanel
                activeFilters={activeFilters}
                filters={filters}
                itemsPerPageLimit={limit}
                onFilter={onChange}
              />
              <Notification
                message={error}
                title="Oops, api keys request has failed"
                type="error"
              />
              <Table
                className="pl-table-index"
                dataSchema={this.schema}
                isRequestPending={isFetching}
                onSort={onChange}
                qaId="api-keys-table"
                sortBy={sort}
                srcData={apiKeys}
              />
              <Pagination
                itemsLimit={limit}
                itemsOffset={offset}
                itemsTotal={total}
                onPageChange={onChange}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
