import React, {useEffect} from 'react';
import {Link, Route, useRouteMatch} from 'react-router-dom';
import {func} from 'prop-types';

const UsersHeader = ({updateTitle}) => {
  const rootPath = useRouteMatch('/users');

  useEffect(() => {
    if (rootPath && rootPath.isExact) {
      updateTitle('Users');
    }
  }, [updateTitle, rootPath]);

  return (
    <Route exact path="/users">
      <Link to="/users">Users</Link>
    </Route>
  );
};

UsersHeader.propTypes = {
  updateTitle: func.isRequired,
};

export default UsersHeader;
