import {createActions} from 'redux-actions';

function actions(namespace, quotaType) {
  return createActions(
    {
      TOGGLE_ENABLED: () => {},
      TOGGLE_INFINITE: () => {},
      RECEIVE_SUBSCRIPTION_DETAILS: payload => {
        return payload[quotaType];
      },
      RESET: () => {},
    },
    'UPDATE_LIMIT',
    'UPDATE_OVERAGE',
    'UPDATE_REFRESH',
    {
      prefix: namespace,
    }
  );
}

export const sceneTileQuotaActions = actions(
  'SCENE_TILE_QUOTA',
  'scene_tile_quota'
);
export const basemapTileQuotaActions = actions(
  'BASEMAP_TILE_QUOTA',
  'basemap_tile_quota'
);
export const quadQuotaActions = actions(
  'BASEMAP_QUAD_QUOTA',
  'basemap_quad_quota'
);
