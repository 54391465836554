import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import Stack from '@mui/material/Stack';
import {bool, func, object, string} from 'prop-types';

import Checkbox from 'admin-ng/components/common/checkbox';
import Notification from 'admin-ng/components/common/notification';
import TextField from 'admin-ng/components/common/text-field';
import Textarea from 'admin-ng/components/common/textarea';

export default class DocumentEdit extends Component {
  constructor(props) {
    super(props);

    this._renderSuccessDialog = this._renderSuccessDialog.bind(this);
    this._isSubmitAllowed = this._isSubmitAllowed.bind(this);
    this._renderEditDialog = this._renderEditDialog.bind(this);
  }

  _renderSuccessDialog() {
    return (
      <Dialog fullWidth maxWidth="sm" open={this.props.isOpen}>
        <DialogTitle>Edit document details</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Your changes to this document have been saved.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            key={1}
            onClick={this.props.onClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  //Only allow submission if changes have been made, all fields are valid, and the request isn't already in progress.
  _isSubmitAllowed() {
    const {
      deprecated,
      details,
      externalName,
      isCheckingName,
      isSaveRequestPending,
      name,
      requiredForSignup,
      separate,
      text,
    } = this.props;
    // An unchanged document name is still considered unique.
    const isNameUnique =
      this.props.isNameUnique || this.props.isNameUnique === null;

    const isNameValid = !isCheckingName && isNameUnique && name.length;
    const isExternalNameValid = externalName.length;

    // Detect changes in the fields, compared to the original document details.
    // note the difference between camelCase and snake_case in the names!
    const hasChanges =
      deprecated !== details.deprecated ||
      externalName !== details.external_name ||
      name !== details.name ||
      requiredForSignup !== details.required_for_signup ||
      separate !== details.separate ||
      text !== details.text;

    return (
      hasChanges && isNameValid && isExternalNameValid && !isSaveRequestPending
    );
  }

  _renderEditDialog() {
    const {
      deprecated,
      details,
      documentSaveError,
      externalName,
      isNameUnique,
      isOpen,
      name,
      nameError,
      onCheckboxChange,
      onClose,
      onExternalNameChange,
      onNameChange,
      onNameCheck,
      onSubmit,
      onTextChange,
      requiredForSignup,
      separate,
      text,
    } = this.props;

    const allowed = this._isSubmitAllowed();

    const nameErrorText =
      isNameUnique === false
        ? 'A document with this name already exists.'
        : nameError;

    const hasTextChanged = text !== details.text;

    const textWrapperClassName = `pl-document-edit-text ${hasTextChanged ? 'changed' : ''}`;

    return (
      <Dialog fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
        <DialogTitle>Edit Document Details</DialogTitle>

        <DialogContent>
          <Notification
            message={documentSaveError}
            title="Oops, could not save this document"
            type="error"
          />

          <TextField
            error={!!nameErrorText}
            helperText={nameErrorText}
            label="Internal name *"
            isChanged={name !== details.name}
            isInputValid={isNameUnique}
            onChange={onNameChange}
            onChangeCompleted={onNameCheck}
            qaId="internal-name"
            value={name}
          />

          <TextField
            label="External name *"
            isChanged={externalName !== details.external_name}
            onChange={onExternalNameChange}
            qaId="external-name"
            value={externalName}
          />

          <div className={textWrapperClassName}>
            <Textarea
              label="Document text"
              maxRows={4}
              minRows={4}
              onChange={onTextChange}
              qaId="text"
              value={text}
            />
          </div>

          <Stack direction="row" gap={2} marginTop={4}>
            <Checkbox
              checked={requiredForSignup}
              fieldInstructions="This has to be signed in order to sign up"
              label="Required for signup"
              name="requiredForSignup"
              onChange={onCheckboxChange}
              qaId="checkbox-required"
            />

            <Checkbox
              checked={separate}
              fieldInstructions="This has to be shown in a separate step during sign up"
              label="Separate"
              name="separate"
              onChange={onCheckboxChange}
              qaId="checkbox-separate"
              wrapperStyle={{marginLeft: 20}}
            />

            <Checkbox
              checked={deprecated}
              fieldInstructions="This document is deprecated"
              label="Deprecated"
              name="deprecated"
              onChange={onCheckboxChange}
              qaId="checkbox-deprecated"
              wrapperStyle={{marginLeft: 20}}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={!allowed}
            onClick={allowed ? onSubmit : null}
            variant="contained"
          >
            Save Document
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return this.props.isDocumentSaved
      ? this._renderSuccessDialog()
      : this._renderEditDialog();
  }
}

DocumentEdit.propTypes = {
  deprecated: bool.isRequired,
  details: object.isRequired,
  documentSaveError: string,
  externalName: string.isRequired,
  isCheckingName: bool.isRequired,
  isDocumentSaved: bool,
  isNameUnique: bool,
  isOpen: bool.isRequired,
  isSaveRequestPending: bool.isRequired,
  name: string.isRequired,
  nameError: string,
  onCheckboxChange: func.isRequired,
  onClose: func.isRequired,
  onExternalNameChange: func.isRequired,
  onNameChange: func.isRequired,
  onNameCheck: func.isRequired,
  onSubmit: func.isRequired,
  onTextChange: func.isRequired,
  requiredForSignup: bool.isRequired,
  separate: bool.isRequired,
  text: string.isRequired,
};
