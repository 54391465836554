import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import LanguageIcon from '@mui/icons-material/Language';
import React, {Component} from 'react';
import {bool, func} from 'prop-types';

class AOITools extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {onFileUpload, onSelectWholeWorld, selectWholeWorldDisabled} =
      this.props;

    return (
      <div className="pl-aoi-item-tools">
        <p style={{marginTop: 0}}>
          Please use the <b>WSG84</b> standard. Supported file types:
          geojson,json,zip,kml,kmz.{' '}
        </p>

        <div className="pl-aoi-item-tool">
          <Button
            color="primary"
            component="label"
            startIcon={<FileUploadIcon />}
            variant="outlined"
          >
            Upload file
            <input
              accept="application/geo+json,application/json,application/zip,.kml,.kmz"
              onChange={onFileUpload}
              style={{display: 'none'}}
              type="file"
            />
          </Button>
        </div>

        {onSelectWholeWorld && (
          <div className="pl-aoi-item-tool">
            <Button
              color="primary"
              data-qa-id="select-whole-world-button"
              disabled={selectWholeWorldDisabled}
              startIcon={<LanguageIcon />}
              onClick={!selectWholeWorldDisabled ? onSelectWholeWorld : null}
              variant="outlined"
            >
              Select whole world
            </Button>
          </div>
        )}
      </div>
    );
  }
}

AOITools.propTypes = {
  onFileUpload: func.isRequired,
  onSelectWholeWorld: func,
  selectWholeWorldDisabled: bool,
};

AOITools.defaultProps = {
  onSelectWholeWorld: null,
  selectWholeWorldDisabled: false,
};

export default AOITools;
