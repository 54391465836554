import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, {Component} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import {func, number} from 'prop-types';

export default class Search extends Component {
  static propTypes = {
    keyPressTimeout: number,
    onFilter: func.isRequired,
  };

  static defaultProps = {
    keyPressTimeout: 400,
  };

  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
    };

    this._handleSearch = this._handleSearch.bind(this);
    this._onClear = this._onClear.bind(this);
  }

  _handleSearch(e) {
    const target = e.target,
      searchValue = target.value || '';

    this.setState({searchValue});
    this._triggerSearch(searchValue, this.props.keyPressTimeout);
  }

  _onClear() {
    if (this.state.searchValue !== '') {
      this.setState({
        searchValue: '',
      });
      this._triggerSearch('');
    }
  }

  _triggerSearch(value, delay = 0) {
    clearTimeout(this.searchTime);
    this.searchTime = setTimeout(
      () =>
        this.props.onFilter({
          search: value.trim(),
          offset: 0,
        }),
      delay
    );
  }

  render() {
    return (
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon color="action" />
            </InputAdornment>
          ),
          endAdornment: this.state.searchValue && (
            <InputAdornment position="end">
              <IconButton
                disabled={this.state.searchValue === ''}
                onClick={this._onClear}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        data-qa-id="search"
        fullWidth
        margin="normal"
        name="search"
        onChange={this._handleSearch}
        style={{
          marginTop: 8,
        }}
        value={this.state.searchValue}
        variant="standard"
      />
    );
  }
}
