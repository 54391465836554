import Icon from '@mui/material/Icon';
import React from 'react';
import classNames from 'classnames';
import grey from '@mui/material/colors/grey';
import {bool, func, string} from 'prop-types';

const grey300 = grey['300'];
const grey500 = grey['500'];

// Overriding default material-ui styles
const styles = {
  active: {
    float: 'right',
    color: grey500,
    cursor: 'pointer',
  },
  disabled: {
    float: 'right',
    color: grey300,
    cursor: 'not-allowed',
  },
};

const IconButton = props => (
  <Icon
    className={classNames('material-icons', props.className)}
    data-qa-id={props.qaId}
    onClick={!props.isDisabled ? props.onClick : null}
    sx={props.isDisabled ? styles.disabled : styles.active}
    title={props.title}
  >
    {props.icon}
  </Icon>
);

IconButton.propTypes = {
  className: string,
  icon: string.isRequired,
  isDisabled: bool,
  onClick: func.isRequired,
  qaId: string,
  title: string,
};

export default IconButton;
