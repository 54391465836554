import React, {Component} from 'react';
import grey from '@mui/material/colors/grey';
import moment from 'moment';
import {arrayOf, bool, number, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import Table from 'admin-ng/components/common/table';
import {DATE_FORMAT} from 'admin-ng/constants';
import {localizeNumber} from 'admin-ng/util/formatting';

const grey400 = grey['400'];

const tableHeight = '432px';

export default class PlanSubscriptionsBulkCreateSummaryView extends Component {
  constructor(props) {
    super(props);
  }

  _getOrgDataSchema() {
    return [
      {
        field: 'id',
        isSortable: false,
        label: 'ID',
        width: '10%',
      },
      {
        field: 'name',
        isSortable: false,
        label: 'Name',
        width: '90%',
      },
    ];
  }

  render() {
    const selectedOrgsCount =
      this.props.selectedOrgs && this.props.selectedOrgs.length
        ? this.props.selectedOrgs.length
        : '0';
    const quotaAmount =
      (this.props.quotaAmount && localizeNumber(this.props.quotaAmount)) || 0;
    const startDate = moment(this.props.startDate).format(DATE_FORMAT);
    const infiniteSymbol = '\u221e';
    const endDate = this.props.endDate
      ? moment(this.props.endDate).format(DATE_FORMAT)
      : infiniteSymbol;

    return (
      <div>
        <Notification
          message={this.props.error}
          title="Oops, organizations request has failed"
          type="error"
        />
        <p>
          Access Period:{' '}
          <b>
            {startDate} - {endDate}
          </b>
        </p>

        {this.props.quotaInterval && (
          <p style={!this.props.isQuotaEnabled ? {color: grey400} : {}}>
            Quota:{' '}
            <b>
              {quotaAmount}&nbsp;km²&nbsp;/&nbsp;{this.props.quotaInterval}
            </b>
          </p>
        )}
        <p>Selected organizations:</p>
        <div className="pl-data-table-selectable">
          <div className="pl-data-table-selectable--panel">
            {`${selectedOrgsCount} selected`}
          </div>
          <Table
            dataSchema={this._getOrgDataSchema()}
            qaId="plan-subscriptions-bulk-create-confirm-organizations-table"
            srcData={this.props.selectedOrgs}
            wrapperStyle={{maxHeight: tableHeight}}
          />
        </div>
      </div>
    );
  }
}

PlanSubscriptionsBulkCreateSummaryView.propTypes = {
  endDate: object,
  error: string,
  isQuotaEnabled: bool.isRequired,
  quotaAmount: number.isRequired,
  quotaInterval: string,
  selectedOrgs: arrayOf(object).isRequired,
  startDate: object.isRequired,
};
