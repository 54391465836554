import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, {Component} from 'react';
import moment from 'moment';
import {bool, func, string} from 'prop-types';

import DatePicker from 'admin-ng/components/common/date-picker';
import FormCard from 'admin-ng/components/common/form-card';
import {DATETIME_FORMAT} from 'admin-ng/constants';
import {checkMomentObject} from 'admin-ng/components/utils';

export default class OrgSubscriptionsAccessPeriod extends Component {
  static propTypes = {
    activeFrom: checkMomentObject,
    activeFromError: string,
    activeTo: checkMomentObject,
    activeToError: string,
    isActiveFromChanged: bool.isRequired,
    isActiveFromToday: bool.isRequired,
    isActiveToChanged: bool.isRequired,
    resetState: func.isRequired,
    type: string,
    updateActiveFrom: func.isRequired,
    updateActiveTo: func.isRequired,
  };

  componentDidMount() {
    if (this.props.type === 'create') {
      this.props.resetState();
    }
  }

  _isTOIValueValid(type, value) {
    let start, end, diff;

    if (type === 'activeFrom') {
      start = value;
      end = this.props.activeTo;
    } else {
      start = this.props.activeFrom;
      end = value;
    }

    if (start && end) {
      start = moment(start);
      end = moment(end);
      diff = end.diff(start, 'days');

      if (diff < 0) {
        return false;
      }
    }

    return true;
  }

  _handleActiveFromChange = (event, date = null) => {
    const type = 'activeFrom';
    let error;

    if (!this._isTOIValueValid(type, date)) {
      error = "End date can't be earlier than start date";
    }

    this.props.updateActiveFrom(
      date ? moment.utc(date).format() : date,
      false,
      error
    );
  };

  _handleActiveToChange = (event, date = null) => {
    const type = 'activeTo';
    let error;

    if (!this._isTOIValueValid(type, date)) {
      error = "End date can't be earlier than start date";
    }

    // Don't check this validation on edit so that other fields like reference/name
    // can be updated in the future
    if (
      this.props.type === 'create' &&
      date &&
      moment().isAfter(date, 'days')
    ) {
      error = "End date can't be in the past";
    }

    this.props.updateActiveTo(
      date ? moment.utc(date).format() : date,
      false,
      error
    );
  };

  _toggleStartDate = () => {
    if (!this.props.isActiveFromToday) {
      this._handleActiveFromChange(null);
    }
    const date = this.props.isActiveFromToday ? null : new Date();
    const isActiveFromToday = !this.props.isActiveFromToday;
    this.props.updateActiveFrom(date, isActiveFromToday);
  };

  _momentToDate(date) {
    // Convert moment object to Date object.
    if (date) {
      return moment(date).toDate();
    }
    return date;
  }

  render() {
    const {
      activeFrom,
      activeFromError,
      activeTo,
      activeToError,
      isActiveFromChanged,
      isActiveFromToday,
      isActiveToChanged,
      type,
    } = this.props;
    if (type === 'view') {
      const formattedTo = activeTo
        ? moment(activeTo).utc().format(DATETIME_FORMAT)
        : 'No end date';
      const formattedFrom = moment(activeFrom).utc().format(DATETIME_FORMAT);
      return (
        <div>
          {formattedFrom} - {formattedTo}
        </div>
      );
    }

    return (
      <FormCard title="Access Period">
        <div className="pl-subscription-grid">
          <div className="pl-subscription-grid-cell">
            <DatePicker
              error={activeFromError}
              label="Start"
              isChanged={isActiveFromChanged}
              onChange={this._handleActiveFromChange}
              onClear={this._handleActiveFromChange}
              value={this._momentToDate(activeFrom)}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={isActiveFromToday}
                  data-qa-id="access-period-is-active-from-today-checkbox"
                  onChange={this._toggleStartDate}
                />
              }
              label="Today"
            />
          </div>

          <div className="pl-subscription-grid-cell">
            <DatePicker
              error={activeToError}
              label="End"
              isChanged={isActiveToChanged}
              onChange={this._handleActiveToChange}
              onClear={this._handleActiveToChange}
              value={this._momentToDate(activeTo)}
            />
          </div>
        </div>
      </FormCard>
    );
  }
}
