import assign from 'deep-extend';
import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import PlanAssignGeneral from './plan-assign-general';
import {ROLES} from 'admin-ng/constants';
import {openNextStep, openPreviousStep, resetState} from './actions';

const mapStateToProps = (state, ownProps) => {
  const isPlanetAdmin =
    ownProps.auth.user.claims.role_level === ROLES.planetAdmin;
  return assign({}, state.plans.assign.general, {isPlanetAdmin});
};

const mapDispatchToProps = dispatch => ({
  resetView: () => dispatch(resetState()),
  onOpenNext: () => dispatch(openNextStep()),
  onOpenPrevious: () => dispatch(openPreviousStep()),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps, undefined, {pure: false})(
    PlanAssignGeneral
  )
);
