import * as constants from './constants';

const initial = {
  additionalMessage: null,
  country: null,
  email: '',
  emailError: null,
  error: null,
  firstName: '',
  isCheckingEmail: false,
  isCreated: false,
  isCreating: false,
  isEmailValid: true,
  lastName: '',
  password: '',
  passwordError: null,
  passwordRepeat: '',
  passwordRepeatError: null,
  role: 100,
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case constants.SET_USER_PROPERTY: {
      const {key, value} = action.payload;
      const newState = {...state, [key]: value};
      if (key === 'email') {
        newState.emailError = null;
      }
      if (key === 'password') {
        newState.passwordError = null;
      }
      if (key === 'passwordRepeat') {
        newState.passwordRepeatError = null;
      }
      return newState;
    }

    case constants.CHECK_EMAIL:
      return {...state, isCheckingEmail: true};

    case constants.CHECK_EMAIL_RESULT: {
      const isValid = action.error !== true && action.payload.valid === true;
      return {
        ...state,
        isCheckingEmail: false,
        isEmailValid: isValid,
        emailError: isValid ? null : action.payload.message,
      };
    }

    case constants.CHECK_PASSWORD_VALIDITY:
      return {
        ...state,
        passwordError: action.payload.message,
      };

    case constants.CHECK_PASSWORD_MATCHING:
      return {
        ...state,
        passwordRepeatError: action.payload.message,
      };

    case constants.CREATE_USER:
      return {
        ...state,
        error: null,
        isCreated: false,
        isCreating: true,
      };

    case constants.CREATE_USER_SUCCESS: {
      const additionalMessage =
        (action.payload && action.payload.additional_message) || null;
      return {
        ...state,
        additionalMessage,
        error: null,
        isCreated: true,
        isCreating: false,
      };
    }

    case constants.CREATE_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        isCreated: false,
        isCreating: false,
      };

    case constants.RESET:
      return {...initial};

    default:
      return state;
  }
};
