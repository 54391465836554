import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import ProgramsIndex from './programs-index';
import {fetchPrograms, resetView} from './actions';

const mapStateToProps = state => state.programs.index;

const mapDispatchToProps = dispatch => ({
  onOpen: (params = {}) => dispatch(fetchPrograms(params)),
  resetView: () => dispatch(resetView()),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(ProgramsIndex)
);
