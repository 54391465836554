import * as constants from './constants';

const initial = {
  error: null,
  documents: [],
  documentsTotalCount: 0,
  isRequestPending: false,
  viewParams: {
    limit: 20,
    offset: 0,
    sort: '-updated_at',
    search: '',
  },
};

export default function documentsReducer(state = initial, action = {}) {
  switch (action.type) {
    case constants.REQUEST_DOCUMENTS:
      return {
        ...state,
        isRequestPending: true,
        viewParams: action.params,
      };

    case constants.REQUEST_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        documents: action.documents,
        documentsTotalCount: action.documentsTotalCount,
        error: null,
      };

    case constants.REQUEST_DOCUMENTS_FAIL:
      return {
        ...state,
        isRequestPending: false,
        error: action.error,
      };

    case constants.RESET_VIEW_STATE:
      return {...state, viewParams: {...initial.viewParams}};

    case constants.RESET_STATE:
      return {...initial};

    default:
      return state;
  }
}
