import request from '@apps-common/js-client/api/request';

const interceptorTypes = ['before', 'success', 'fail'];
const interceptors = {
  before: [],
  success: [],
  fail: [],
};

export function addInterceptor(type, fn) {
  if (interceptorTypes.every(t => t !== type)) {
    throw new Error(
      `Interceptor type must be one of the following: ${interceptorTypes.join(', ')}`
    );
  }
  interceptors[type].push(fn);
  return fn;
}

// pending makes sure that in-flight GET requests are not duplicated
const pending = {};

export default (url, method = 'get', params) => {
  let key = null;
  // if an equivalent request is in-flight return the existing promise
  if (method === 'get') {
    const queryEncoded = params ? JSON.stringify(params) : '';
    key = url + queryEncoded;
    const p = pending[key];
    if (p) {
      return p;
    }
  }

  interceptors.before.forEach(fn => fn());

  // Translate the methods as used by AdminNG into the request functions as
  // used by js-client. Defaults to request[method], which works for everything
  // but delete.
  const fn =
    {
      del: request.delete,
    }[method] ?? request[method];

  // For `request` to put the params into the query string correctly, it
  // needs to be wrapped as `{params: params}`, but for PUT/POST/etc., we
  // want the bare param object
  const promise = fn(url, method === 'get' ? {params} : params);

  // Transform js-client response to look more like the original response
  // from the request function
  const modifiedPromise = promise.then(response => ({
    ...response,
    body: response?.data ?? {},
  }));

  if (key) {
    pending[key] = modifiedPromise;
  }

  modifiedPromise
    .then(res => {
      interceptors.success.forEach(fn => fn(res));
    })
    .catch(err => {
      interceptors.fail.forEach(fn => fn(err));
    })
    .finally(() => {
      if (key) {
        delete pending[key];
      }
    });

  return modifiedPromise;
};
