import Box from '@mui/material/Box';
import React from 'react';
import classNames from 'classnames';
import {array, bool, func, string} from 'prop-types';

import Checkbox from 'admin-ng/components/common/checkbox';

const MultiCheckbox = ({
  changed,
  disabled,
  error,
  items,
  onUpdate,
  selectedItems: selected,
}) => {
  const areAllSelected = selected && selected.length === items.length;

  // TODO: Tidy up. This code is super messy given that it's doing something that should be quite simple
  const onSelect = item => {
    const selectedItems = selected && selected.slice(0);
    if (item && item.id) {
      const index = selectedItems.indexOf(item.id);
      if (index === -1) {
        selectedItems.push(item.id);
      } else {
        selectedItems.splice(index, 1);
      }
      onUpdate(selectedItems);
    } else if (item === 'all') {
      onUpdate(items.map(item => item.id));
    } else if (item === 'none') {
      onUpdate([]);
    }
  };

  const renderItems = () => {
    const available = items.map((item, index) => {
      const hasDescription =
        item.display_description &&
        item.display_description !== item.display_name;
      return (
        // eslint-disable-next-line react/no-array-index-key
        <li className="pl-multi-checkbox-item" key={index}>
          <Box flex={1}>
            <Checkbox
              checked={selected.indexOf(item.id) !== -1}
              disabled={disabled}
              label={item.display_name}
              onChange={onSelect.bind(this, item)}
            />
          </Box>

          {hasDescription && (
            <i
              className="material-icons pl-multi-checkbox-item-descr"
              title={item.display_description}
            >
              info_outline
            </i>
          )}
        </li>
      );
    });

    return (
      <ul
        className={classNames({
          'pl-multi-checkbox--list': true,
          clearfix: true,
          changed,
        })}
      >
        {available}
      </ul>
    );
  };

  return (
    <div className="pl-multi-checkbox">
      <div className="clearfix">
        {!disabled && (
          <span
            className="pl-multi-checkbox-switch"
            data-qa-id="policy-item-type-none-all"
            onClick={onSelect.bind(this, areAllSelected ? 'none' : 'all')}
          >
            {areAllSelected ? 'Unselect All' : 'Select All'}
          </span>
        )}
        <span className={classNames('pl-multi-checkbox--count', {error})}>
          {selected.length} selected
        </span>
        {error && <span className="error-message">{error}</span>}
      </div>

      {renderItems()}
    </div>
  );
};

MultiCheckbox.propTypes = {
  changed: bool,
  disabled: bool,
  error: string,
  items: array,
  onUpdate: func.isRequired,
  selectedItems: array,
};

MultiCheckbox.defaultProps = {
  disabled: false,
  error: '',
  items: [],
  changed: false,
  selectedItems: [],
};

export default MultiCheckbox;
