import React, {useEffect} from 'react';
import {Link, Route, useRouteMatch} from 'react-router-dom';
import {func} from 'prop-types';

const SubscriptionsHeader = ({updateTitle}) => {
  const rootPath = useRouteMatch('/plans');

  useEffect(() => {
    if (rootPath && rootPath.isExact) {
      updateTitle('Plans');
    }
  }, [updateTitle, rootPath]);

  return (
    <Route exact path="/plans">
      <Link to="/plans">Plans</Link>
    </Route>
  );
};

SubscriptionsHeader.propTypes = {
  updateTitle: func.isRequired,
};

export default SubscriptionsHeader;
