import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import AuditLog from './audit-log';
import {ROLES} from 'admin-ng/constants';

const mapStateToProps = (
  state,
  {
    auth: {
      user: {
        claims: {role_level},
      },
    },
  }
) => ({
  isPlanetAdmin: role_level >= ROLES.planetAdmin,
});

export default withAuth(connect(mapStateToProps)(AuditLog));
