export const NAME = 'ORG_MEMBER_CREATE';
export const SET_USER_PROPERTY = `${NAME}/SET_USER_PROPERTY`;
export const RESET = `${NAME}/RESET`;
export const CHECK_EMAIL = `${NAME}/CHECK_EMAIL`;
export const CHECK_EMAIL_RESULT = `${NAME}/CHECK_EMAIL_RESULT`;
export const CHECK_PASSWORD_VALIDITY = `${NAME}/CHECK_PASSWORD_VALIDITY`;
export const CHECK_PASSWORD_MATCHING = `${NAME}/CHECK_PASSWORD_MATCHING`;
export const CREATE_USER = `${NAME}/CREATE_USER`;
export const CREATE_USER_SUCCESS = `${NAME}/CREATE_USER_SUCCESS`;
export const CREATE_USER_ERROR = `${NAME}/CREATE_USER_ERROR`;
