import Button from '@mui/material/Button';
import React, {Component} from 'react';
import RichTextEditor from 'react-rte';
import {bool, func} from 'prop-types';

export default class OrgCommentAdd extends Component {
  state = {
    newComment: RichTextEditor.createEmptyValue(),
    editorEnabled: false,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.newCommentAdded) {
      this.setState({
        newComment: RichTextEditor.createEmptyValue(),
        editorEnabled: false,
      });
    }
  }

  _enableEditor = () => this.setState({editorEnabled: true});

  _onChange = value => {
    const {onCommentUpdate} = this.props;
    this.setState({newComment: value});
    if (onCommentUpdate) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      onCommentUpdate(value.toString('html'));
    }
  };

  _onCancelComment = () => {
    this.setState({
      editorEnabled: false,
      newComment: RichTextEditor.createEmptyValue(),
    });
  };

  render() {
    const isCommentEmpty = !this.state.newComment
      .getEditorState()
      .getCurrentContent()
      .hasText();

    return (
      <div>
        {this.state.editorEnabled ? (
          <div>
            <RichTextEditor
              onChange={this._onChange}
              value={this.state.newComment}
            />

            <div className="pl-new-comment-controls">
              <Button
                disabled={isCommentEmpty}
                onClick={!isCommentEmpty ? this.props.addComment : null}
              >
                Submit
              </Button>

              <Button color="inherit" onClick={this._onCancelComment}>
                Cancel
              </Button>
            </div>
          </div>
        ) : (
          <Button
            color="primary"
            onClick={this._enableEditor}
            variant="outlined"
          >
            Add comment
          </Button>
        )}
      </div>
    );
  }
}

OrgCommentAdd.propTypes = {
  addComment: func.isRequired,
  newCommentAdded: bool,
  onCommentUpdate: func.isRequired,
};
