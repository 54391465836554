import GeoJSON from 'ol/format/GeoJSON.js';
import Map from 'ol/Map.js';
import OSMSource from 'ol/source/OSM.js';
import React, {Component} from 'react';
import TileLayer from 'ol/layer/Tile.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import View from 'ol/View.js';
import {array, bool, func, number, string} from 'prop-types';

import IconButton from 'admin-ng/components/common/icon-button';
import {localizeNumber} from 'admin-ng/util/formatting';

const geoJSON = new GeoJSON();

class MapView extends Component {
  static propTypes = {
    area: number,
    controls: array,
    interactions: array,
    isValid: bool,
    onDelete: func,
    onError: func,
    showArea: bool,
    showAreaWarning: bool,
    value: string,
  };

  static defaultProps = {
    area: undefined,
    controls: undefined,
    interactions: undefined,
    isValid: false,
    onDelete: null,
    onError: () => {},
    showArea: true,
    showAreaWarning: false,
    value: '',
  };

  componentDidMount() {
    this._createMap();
    this._fitMap();
  }

  componentWillReceiveProps(nextProps) {
    const {isValid, onError, value} = nextProps;

    if (value === this.props.value) {
      return;
    }

    this.source.clear();

    if (!value) {
      this.view.setCenter([0, 0]);
      this.view.setZoom(1.5);
    }

    if (value && isValid) {
      try {
        this._addFeatures(value);
        this._fitMap();
      } catch (error) {
        onError(error, value);
      }
    }
  }

  componentWillUnmount() {
    this.map.setTarget(undefined);
  }

  _createMap = () => {
    const {controls, interactions, onError, value} = this.props;
    const source = new VectorSource({projection: 'EPSG:4326'});
    const layer = new VectorLayer({source});
    const layers = [new TileLayer({source: new OSMSource()}), layer];
    const view = new View({
      projection: 'EPSG:3857',
      center: [0, 0],
      zoom: 1.5,
    });
    const target = this.refs.map;
    this.view = view;
    this.source = source;

    if (value) {
      try {
        this._addFeatures(value);
      } catch (error) {
        onError(error, value);
      }
    }
    this.map = new Map({layers, view, controls, interactions, target});
  };

  _addFeatures = aoi => {
    const features = geoJSON.readFeatures(aoi, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    this.source.addFeatures(features);
  };

  _fitMap = () => {
    const extent = this.source.getExtent();
    if (extent[0] !== Infinity) {
      this.view.fit(this.source.getExtent(), {size: this.map.getSize()});
    }
  };

  render() {
    const {area, onDelete, showArea, showAreaWarning, isValid} = this.props;
    return (
      <div className="pl-aoi-item--preview">
        <div className="pl-aoi-item--remove">
          {onDelete && <IconButton icon="delete" onClick={onDelete} />}
        </div>
        <div className="pl-aoi-item--preview-map" ref="map" />
        {showArea && isValid && Boolean(area) && !showAreaWarning && (
          <div className="pl-aoi-item--area">{localizeNumber(area)} km²</div>
        )}
        {showArea && showAreaWarning && (
          <div className="pl-aoi-item--area-warning">
            Please save changes to see area.
          </div>
        )}
      </div>
    );
  }
}

export default MapView;
