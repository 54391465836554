const NAME = 'PLAN_SUBSCRIPTIONS_BULK_CREATE';

export const UPDATE_SELECTED_ORGS = `${NAME}/UPDATE_SELECTED_ORGS`;
export const SELECTED_ORGS_EMPTY = `${NAME}/SELECTED_ORGS_EMPTY`;

export const REQUEST_ORGS = `${NAME}/REQUEST_ORGS`;
export const REQUEST_ORGS_SUCCESS = `${NAME}/REQUEST_ORGS_SUCCESS`;
export const REQUEST_ORGS_FAIL = `${NAME}/REQUEST_ORGS_FAIL`;

export const REQUEST_BULK_CREATE = `${NAME}/REQUEST_BULK_CREATE`;
export const REQUEST_BULK_CREATE_SUCCESS = `${NAME}/REQUEST_BULK_CREATE_SUCCESS`;
export const REQUEST_BULK_CREATE_FAILED = `${NAME}/REQUEST_BULK_CREATE_FAILED`;

export const REQUEST_SUBSCRIPTIONS = `${NAME}/REQUEST_SUBSCRIPTIONS`;
export const REQUEST_SUBSCRIPTIONS_SUCCESS = `${NAME}/REQUEST_SUBSCRIPTIONS_SUCCESS`;
export const REQUEST_SUBSCRIPTIONS_FAILED = `${NAME}/REQUEST_SUBSCRIPTIONS_FAILED`;
