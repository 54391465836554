import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {func, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import Notification from 'admin-ng/components/common/notification';
import ProgramEditContainer from '../program-edit/program-edit-container';

export default class ProgramInstance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditDialogShown: false,
    };

    this._showEditDialog = this._showEditDialog.bind(this);
    this._hideEditDialog = this._hideEditDialog.bind(this);
    this._renderDetailsList = this._renderDetailsList.bind(this);
    this._renderDocumentsList = this._renderDocumentsList.bind(this);
  }

  componentWillMount() {
    this.props.fetchProgramDetails(this.props.match.params.programId);
  }

  componentWillReceiveProps({
    fetchProgramDetails,
    match: {
      params: {programId},
    },
  }) {
    if (programId !== this.props.match.params.programId) {
      fetchProgramDetails(programId);
    }
  }

  _showEditDialog() {
    this.props.prefillEditDialog(this.props.details);
    this.setState({isEditDialogShown: true});
  }

  _hideEditDialog() {
    this.setState({isEditDialogShown: false});
  }

  _renderDetailsList() {
    const hasInviteText =
      this.props.details.invite_email_text &&
      this.props.details.invite_email_text.length >= 1;

    return (
      <div>
        <ul className="pl-program-details pl-details-list clearfix">
          <li className="item-fullwidth">
            <div className="pl-details-list--title" title="Program name">
              Program name
            </div>
            <div
              className="pl-details-list--value"
              title={this.props.details.name}
            >
              {this.props.details.name}
            </div>
          </li>

          <li className="item-fullwidth">
            <div className="pl-details-list--title" title="Description">
              Description
            </div>
            <div
              className="pl-details-list--value"
              title={this.props.details.description}
            >
              {this.props.details.description}
            </div>
          </li>

          <li className="item-fullwidth">
            <div
              className="pl-details-list--title"
              title="Invitation email text"
            >
              Invitation email text
            </div>
            <div className="pl-details-list--value pl-program-invite-email-text">
              {hasInviteText ? (
                <pre>{this.props.details.invite_email_text}</pre>
              ) : (
                '-'
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  }

  _renderDocumentsList() {
    const documents = this.props.details.documents.map((document, index) => (
      <Link
        className="document-chip"
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        title={document.name}
        to={`/documents/${document.id}`}
      >
        <Chip
          icon={<FolderIcon />}
          label={document.name}
          style={{cursor: 'pointer'}}
        />
      </Link>
    ));

    return (
      <Card>
        <div className="pl-form--section pl-program-instance">
          <div className="pl-form--section-header">
            <h3 className="pl-form--section-title">{`Documents (${documents.length})`}</h3>
            <div className="pl-program-instance-documents-wrapper pl-flex-wrapper">
              {documents}
            </div>
          </div>
        </div>
      </Card>
    );
  }

  render() {
    const {details, error} = this.props;
    const {isEditDialogShown} = this.state;
    const editDialog = (
      <ProgramEditContainer
        details={details}
        isOpen={isEditDialogShown}
        onClose={this._hideEditDialog}
      />
    );
    const hasDocuments =
      details && details.documents && details.documents.length >= 1;

    return (
      <div className="pl-program-instance-page">
        <Card style={{marginBottom: '25px'}}>
          <div className="pl-form--section pl-program-instance">
            <div className="pl-form--section-header">
              <h3 className="pl-form--section-title">Details</h3>

              {details && (
                <div className="pl-form--section-menu">
                  <Button
                    color="primary"
                    data-qa-id="edit-subscription"
                    startIcon={<EditIcon />}
                    onClick={this._showEditDialog}
                    variant="outlined"
                  >
                    Edit
                  </Button>
                </div>
              )}
            </div>

            <Notification
              message={error}
              title="Oops, something went wrong"
              type="error"
            />

            {details && this._renderDetailsList()}

            {details && editDialog}
          </div>
        </Card>

        {hasDocuments && this._renderDocumentsList()}
      </div>
    );
  }
}

ProgramInstance.propTypes = {
  details: object,
  error: string,
  fetchProgramDetails: func.isRequired,
  match: object.isRequired,
  params: object,
  prefillEditDialog: func.isRequired,
};

ProgramInstance.defaultProps = {
  details: null,
  error: null,
};
