import React, {Component} from 'react';
import Stack from '@mui/material/Stack';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import AuditLog from 'admin-ng/components/common/audit-log';
import Card from 'admin-ng/components/common/card';
import MemberApiKey from './components/member-api-key';
import MemberChangeOrg from './components/member-change-org';
import MemberChangePassword from './components/member-change-password';
import MemberProfileForm from './components/member-profile-form';
import Notification from 'admin-ng/components/common/notification';

export default class OrgMemberInstance extends Component {
  constructor(props) {
    super();
    props.resetState();
    props.onOpen();

    if (!props.parentOrgDetails.id) {
      props.onParentOrgDataRequest();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params.memberId !== nextProps.match.params.memberId ||
      this.props.match.params.orgId !== nextProps.match.params.orgId
    ) {
      nextProps.onOpen();
    }

    if (this.props.parentOrgDetails.id !== nextProps.parentOrgDetails.id) {
      this.props.onRequestOrgUsers(nextProps.parentOrgDetails.id);
    }
  }

  render() {
    let isReadonly, isLowerRole;

    if (this.props.details) {
      isLowerRole = this.props.userRoleLevel < this.props.details.role_level;
      isReadonly = isLowerRole === true;
    }
    return this.props.details ? (
      <div>
        <Card style={{marginBottom: '25px'}}>
          {this.props.details.state === 'deleted' ? (
            <Notification
              message="User is deleted"
              title="Oops, something went wrong"
              type="error"
            />
          ) : (
            <>
              <Notification
                message={this.props.error}
                title="Oops, something went wrong"
                type="error"
              />

              <MemberProfileForm
                countryError={this.props.countryError}
                details={this.props.details}
                emailError={this.props.emailError}
                firstNameError={this.props.firstNameError}
                isEmailCheckRequestPending={
                  this.props.isEmailCheckRequestPending
                }
                isEmailUnique={this.props.isEmailUnique}
                isReadonly={isReadonly}
                isUserDetailsRequestPending={
                  this.props.isUserDetailsRequestPending
                }
                isUserDetailsUpdateRequestPending={
                  this.props.isUserDetailsUpdateRequestPending
                }
                lastNameError={this.props.lastNameError}
                newDetails={this.props.newDetails}
                onCancel={this.props.onCancel}
                onDataChange={this.props.onDataChange}
                onEmailUpdate={this.props.onEmailUpdate}
                onSave={this.props.onSave}
                organizations={this.props.organizations}
                userRoleLevel={this.props.userRoleLevel}
              />

              {this.props.apiKey && (
                <MemberApiKey
                  apiKey={this.props.apiKey}
                  isCurrentUser={
                    this.props.user.user_id === this.props.details.user.id
                  }
                  isReadonly={isReadonly}
                  onApiKeyReset={this.props.onApiKeyReset}
                />
              )}

              <Stack direction="row" gap={2} marginTop={3.5}>
                <MemberChangeOrg
                  isOrgChanged={this.props.isOrgChanged}
                  isReadonly={isReadonly}
                  oldParentOrg={this.props.parentOrgDetails}
                  oldParentOrgMemberCount={this.props.parentOrgMemberCount}
                  onChangeOrg={this.props.onChangeOrg}
                  onDeleteOldOrg={this.props.onDeleteOldOrg}
                  organizations={this.props.organizations}
                  params={this.props.match.params}
                  requestOrgs={this.props.onRequestOrgs}
                  resetState={this.props.resetState}
                  userRoleLevel={this.props.userRoleLevel}
                />

                <MemberChangePassword
                  isPasswordChanged={this.props.isPasswordChanged}
                  isReadonly={isReadonly}
                  onDataChange={this.props.onDataChange}
                  onPasswordMatchingCheck={this.props.onPasswordMatchingCheck}
                  onPasswordValidityCheck={this.props.onPasswordValidityCheck}
                  password={
                    this.props.newDetails &&
                    this.props.newDetails.user &&
                    this.props.newDetails.user.password
                  }
                  passwordError={this.props.passwordError}
                  passwordRepeat={this.props.passwordRepeat}
                  passwordRepeatError={this.props.passwordRepeatError}
                  passwordRequestError={this.props.passwordRequestError}
                  reset={this.props.resetPassword}
                  saveNewPassword={this.props.saveNewPassword}
                />
              </Stack>
            </>
          )}
        </Card>
        <AuditLog modelId={this.props.details.user.id + ''} modelName="users" />
      </div>
    ) : null;
  }
}

OrgMemberInstance.propTypes = {
  apiKey: object,
  countryError: string,
  details: object,
  emailError: string,
  error: string,
  firstNameError: string,
  isEmailCheckRequestPending: bool,
  isEmailUnique: bool,
  isOrgChanged: bool,
  isPasswordChanged: bool,
  isUserDetailsRequestPending: bool,
  isUserDetailsUpdateRequestPending: bool,
  lastNameError: string,
  match: object,
  newDetails: object,
  onApiKeyReset: func.isRequired,
  onCancel: func.isRequired,
  onChangeOrg: func,
  onDataChange: func.isRequired,
  onDeleteOldOrg: func,
  onEmailUpdate: func.isRequired,
  onOpen: func.isRequired,
  onParentOrgDataRequest: func,
  onPasswordMatchingCheck: func,
  onPasswordValidityCheck: func,
  onRequestOrgUsers: func,
  onRequestOrgs: func,
  onSave: func.isRequired,
  organizations: arrayOf(object),
  parentOrgDetails: object,
  parentOrgMemberCount: number,
  passwordError: string,
  passwordRepeat: string,
  passwordRepeatError: string,
  passwordRequestError: string,
  resetPassword: func,
  resetState: func,
  saveNewPassword: func,
  user: object,
  userRoleLevel: number.isRequired,
};
