import * as constants from './constants';

const initialState = {
  isNameCheckRequested: false,
  isNameUnique: null,
  isSuborgCreated: null,
  isSuborgCreateRequested: false,
  name: '',
  nameError: null,
  suborgCreateError: null,
};

export default function suborgCreateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.NEW_SUBORG_UPDATE_NAME:
      return {
        ...state,
        name: action.name,
        nameError: action.error,
      };

    case constants.NEW_SUBORG_NAME_CHECK_REQUEST:
      return {
        ...state,
        isNameCheckRequested: true,
      };

    case constants.NEW_SUBORG_NAME_CHECK_SUCCEESS:
      return {
        ...state,
        isNameUnique: action.isNameUnique,
        isNameCheckRequested: false,
        nameError: action.error,
      };

    case constants.NEW_SUBORG_CREATE_REQUEST:
      return {
        ...state,
        isSuborgCreateRequested: true,
      };

    case constants.NEW_SUBORG_CREATE_SUCCESS:
      return {
        ...state,
        isSuborgCreateRequested: false,
        isSuborgCreated: true,
      };

    case constants.NEW_SUBORG_CREATE_FAIL:
      return {
        ...state,
        isSuborgCreateRequested: false,
        isSuborgCreated: false,
        suborgCreateError: action.error,
      };

    case constants.NEW_SUBORG_RESET_STATE:
      return {...initialState};

    default:
      return {...state};
  }
}
