import Button from '@mui/material/Button';
import React, {Component} from 'react';
import classNames from 'classnames';
import {bool, func, number, object} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmOrgsContainer from '../plan-assign-confirm-orgs/plan-assign-confirm-orgs-container';
import SelectOrgsContainer from '../plan-assign-select-orgs/plan-assign-select-orgs-container';
import SelectPlanContainer from '../plan-assign-select-plan/plan-assign-select-plan-container';
import Sidebar from 'admin-ng/components/common/sidebar';
import SummaryContainer from '../plan-assign-summary/plan-assign-summary-container';

export default class PlanAssign extends Component {
  constructor(props) {
    super(props);
    this._onFinish = this._onFinish.bind(this);
  }

  componentWillMount() {
    this.props.resetView();

    if (!this.props.isPlanetAdmin) {
      this.props.history.push('/page-not-found');
    }
  }

  _onFinish() {
    this.props.resetView();
    this.props.history.push('/packages');
  }

  _renderSteps() {
    return [
      'Select Packages',
      'Select Organizations',
      'Confirmation',
      'Done',
    ].map((step, index) => {
      const classes = classNames({
        active: this.props.step === index,
        completed: this.props.step > index,
      });

      return (
        // eslint-disable-next-line react/no-array-index-key
        <li className={classes} key={index}>
          <span className="pl-wizard-step-number">{index + 1}</span>
          <span className="pl-wizard-step-label">{step}</span>
        </li>
      );
    });
  }

  render() {
    return (
      <div className="pl-flex-wrapper">
        <Sidebar />
        <div className="pl-app--content">
          <Card>
            <div className="pl-plan-assign-wizard pl-plan-card-wizard">
              <ul className="pl-wizard-steps">{this._renderSteps()}</ul>

              <div className="pl-wizard-content">
                {this.props.step === 0 ? <SelectPlanContainer /> : null}

                {this.props.step === 1 ? <SelectOrgsContainer /> : null}

                {this.props.step === 2 ? <ConfirmOrgsContainer /> : null}

                {this.props.step === 3 ? <SummaryContainer /> : null}
              </div>

              <div className="pl-wizard-footer">
                {this.props.step === 3 ? (
                  <Button
                    color="primary"
                    onClick={this._onFinish}
                    variant="contained"
                  >
                    Finish{' '}
                  </Button>
                ) : (
                  <div>
                    {this.props.step === 0 ? (
                      <Button
                        className="pl-wizard-previous-btn"
                        color="primary"
                        onClick={this._onFinish}
                      >
                        Cancel
                      </Button>
                    ) : (
                      <Button
                        className="pl-wizard-previous-btn"
                        color="primary"
                        onClick={this.props.onOpenPrevious}
                      >
                        Previous
                      </Button>
                    )}

                    <Button
                      color="primary"
                      label="Next"
                      onClick={this.props.onOpenNext}
                      variant="contained"
                    >
                      Next
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

PlanAssign.propTypes = {
  history: object.isRequired,
  isPlanetAdmin: bool,
  onOpenNext: func.isRequired,
  onOpenPrevious: func.isRequired,
  resetView: func.isRequired,
  step: number,
};
