import * as constants from './constants';
import * as messages from '../messages';
import {all, update} from 'admin-ng/api/organizations';
import {fetchOrgDetails} from '../org-details/actions';
import {isValidSfdc} from 'admin-ng/components/utils';

export const updateOrgName = (name, error = null) => ({
  type: constants.ORG_NAME_UPDATED,
  name,
  error,
});

export const updateOrgSfdc = (sfdc, error = null) => ({
  type: constants.ORG_SFDC_UPDATED,
  sfdc,
  error,
});

export const updateOrgProgram = program => ({
  type: constants.ORG_PROGRAM_UPDATED,
  program,
});

export const updateOrgState = state => ({
  type: constants.ORG_STATE_UPDATED,
  state,
});

export const updateDetailsData =
  ({field, value} = {}) =>
  dispatch => {
    if (field === 'name') {
      return dispatch(updateOrgName(value));
    }
    if (field === 'sfdc') {
      return dispatch(updateOrgSfdc(value));
    }
    if (field === 'program') {
      return dispatch(updateOrgProgram(value));
    }
    if (field === 'state') {
      return dispatch(updateOrgState(value));
    }
  };

export const requestOrgNameCheck = () => ({
  type: constants.REQUEST_ORG_NAME_CHECK,
});

export const completeOrgNameCheck = (isOrgNameUnique, error = null) => ({
  type: constants.COMPLETE_ORG_NAME_CHECK,
  isOrgNameUnique,
  error,
});

export const checkOrgName = orgName => async dispatch => {
  dispatch(requestOrgNameCheck());
  const params = {name__ieq: orgName};
  try {
    const {body} = await all(params);
    const items = body.results || [];
    if (Array.isArray(items)) {
      if (items.length) {
        dispatch(
          completeOrgNameCheck(false, messages.ORG_NAME_IS_NOT_UNIQUE_MSG)
        );
      } else {
        dispatch(completeOrgNameCheck(true));
      }
    } else {
      dispatch(completeOrgNameCheck(false, messages.DEFAULT_ERROR_MSG));
    }
  } catch (err) {
    dispatch(
      completeOrgNameCheck(false, err.message || messages.DEFAULT_ERROR_MSG)
    );
  }
};

export const checkOrgSfdc = sfdc => async dispatch => {
  if (!isValidSfdc(sfdc)) {
    dispatch(updateOrgSfdc(sfdc, messages.SFDC_NOT_VALID));
  }
};

export const cancelOrgDetailsUpdates = () => ({
  type: constants.ORG_DETAILS_UPDATES_CANCEL,
});

export const requestSaveOrgDetailsUpdates = params => ({
  type: constants.ORG_DETAILS_UPDATE_REQUESTED,
  params,
});

export const completeSaveOrgDetailsUpdates = () => ({
  type: constants.ORG_DETAILS_UPDATE_COMPLETED,
});

export const saveOrgDetailsUpdatesRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.ORG_DETAILS_UPDATE_FAILED,
  error,
});

const handleTypedErrorMessagesOnSubmit = res => async dispatch => {
  if (!(res.body && res.body.errors)) {
    return;
  }
  if (res.body.errors.name) {
    dispatch(updateOrgName('', res.body.errors.name.join(' ')));
  }
  if (res.body.errors.sfdc) {
    dispatch(updateOrgSfdc('', res.body.errors.sfdc.join(' ')));
  }
};

export const saveOrgDetailUpdates = id => async (dispatch, getState) => {
  const {
    orgs: {
      instance: {
        general: {
          details: {initDetails},
          edit: {newDetails},
        },
      },
    },
  } = getState();
  if (newDetails.hasOwnProperty('name') && !String(newDetails.name).trim()) {
    dispatch(updateOrgName('', messages.ORG_NAME_IS_NOT_SPECIFIED_MSG));
    return;
  }
  const params = {...initDetails, ...newDetails};
  dispatch(requestSaveOrgDetailsUpdates(params));
  try {
    await update(id, params);
    dispatch(completeSaveOrgDetailsUpdates());
    dispatch(fetchOrgDetails(id));
  } catch (err) {
    dispatch(handleTypedErrorMessagesOnSubmit(err));
    dispatch(saveOrgDetailsUpdatesRequestFailed(err.message));
  }
};
