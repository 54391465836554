import React, {useEffect} from 'react';
import {func, number, object, shape, string} from 'prop-types';

import ComplianceRecord from '../compliance-record';
import Notification from 'admin-ng/components/common/notification';
import {fetch as fetchRecord, reset as resetRecord} from './actions';

const Record = props => {
  /* eslint-disable react/prop-types, react-hooks/exhaustive-deps */
  useEffect(() => {
    props.dispatch(resetRecord());
    props.dispatch(
      fetchRecord(props.entity, props.match.params.recordId, props.history)
    );
  }, []);
  /* eslint-enable react/prop-types, react-hooks/exhaustive-deps */

  let name = '';
  if (props.entity === 'organization' && props.organization) {
    name = props.organization.name;
  } else if (props.user) {
    name = `${props.user.first_name} ${props.user.last_name}`;
  }

  return (
    <div className={`pl-${props.entity}-compliance-record-page`}>
      <Notification
        message={props.error || props.updateError}
        title="Oops, compliance request has failed"
        type="error"
      />
      {props.id && (
        <ComplianceRecord
          entity={props.entity}
          fields={props[props.entity]}
          flagConfirmedBad={props.flagConfirmedBad}
          flagFalsePositive={props.flagFalsePositive}
          id={props.id}
          name={name}
          record={props.record}
          status={props.status}
        />
      )}
    </div>
  );
};

Record.propTypes = {
  entity: string.isRequired,
  error: string,
  flagConfirmedBad: func.isRequired,
  flagFalsePositive: func.isRequired,
  history: object.isRequired,
  id: number,
  organization: shape({
    name: string.isRequired,
  }),
  record: object,
  status: string,
  updateError: string,
  user: shape({
    country: string,
    email: string.isRequired,
    first_name: string.isRequired,
    last_name: string.isRequired,
  }),
};

export default Record;
