import * as constants from './constants';

export const receiveSubscriptionDetails = details => ({
  type: constants.RECEIVE_SUBSCRIPTION_DETAILS,
  payload: details,
});

export const updateActiveFrom = (
  date,
  isActiveFromToday = false,
  error = null
) => ({
  type: constants.ACTIVE_FROM_UPDATED,
  payload: {activeFrom: date, isActiveFromToday},
  error,
});

export const updateActiveTo = (date, hasNoEndDate = false, error = null) => ({
  type: constants.ACTIVE_TO_UPDATED,
  payload: {activeTo: date, hasNoEndDate},
  error,
});

export const resetState = () => ({type: constants.RESET_STATE});
