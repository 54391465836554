import * as constants from './constants';
import * as messages from '../plan-assign-general/messages';
import {all} from 'admin-ng/api/subscriptions';

export const orgsSelectNotCompleted = () => ({
  type: constants.ORGS_SELECT_NOT_COMPLETED,
  error: messages.ORGS_NOT_SELECTED_ERROR_MSG,
});

export const requestPlanSubscriptions = (params = null) => ({
  type: constants.REQUEST_PLAN_SUBSCRIPTIONS,
  params,
});

export const receivedPlanSubscriptions = (json = {}) => ({
  type: constants.RECEIVED_PLAN_SUBSCRIPTIONS,
  items: json.results || [],
});

export const planSubscriptionsRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.PLAN_SUBSCRIPTIONS_REQUEST_FAILED,
  error,
});

export const updateSelectedOrgs = items => ({
  type: constants.UPDATE_SELECTED_ORGS,
  items,
});

export const fetchSubscriptions = (params = {}) => {
  return (dispatch, getState) => {
    const state = getState(),
      selectPlanState = state.plans.assign.selectPlan,
      requestParams = Object.assign({}, params, {
        plan_id: selectPlanState.sourcePlanId,
      });

    dispatch(requestPlanSubscriptions(params));

    return all(requestParams)
      .then((res = {}) => {
        dispatch(receivedPlanSubscriptions(res.body));
      })
      .catch((err = {}) => {
        dispatch(planSubscriptionsRequestFailed(err.message));
      });
  };
};
