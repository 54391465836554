export const ORG_NAME_IS_NOT_UNIQUE_MSG =
  'This organization name is already taken';
export const ORG_NAME_IS_NOT_SPECIFIED_MSG =
  'Please provide an organization name';
export const ORG_PROGRAM_IS_NOT_SPECIFIED_MSG = 'Please select a program';
export const DEFAULT_ERROR_MSG = 'Something went wrong';
export const QUOTA_FORMAT_ERROR =
  'Quota should be a numeric value greater than or equal 0';
export const ORG_NOT_FOUND =
  'We are sorry, but the organization you are looking for does not exist.';
export const SFDC_NOT_VALID = 'SalesForce ID should have 15 characters.';
