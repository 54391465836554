import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import Record from './record';
import {FLAG_CONFIRMED_BAD, FLAG_FALSE_POSITIVE} from '../constants';
import {fetch, update} from './actions';

const mapStateToProps = ({compliance: {record}}) => ({...record});

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: {recordId},
    },
    entity,
  }
) => {
  const boundFetch = fetch.bind(this, entity, recordId);
  const boundUpdate = update.bind(this, entity, recordId);
  return {
    flagConfirmedBad: () =>
      dispatch(boundUpdate(FLAG_CONFIRMED_BAD)).then(() =>
        dispatch(boundFetch())
      ),
    flagFalsePositive: () =>
      dispatch(boundUpdate(FLAG_FALSE_POSITIVE)).then(() =>
        dispatch(boundFetch())
      ),
    dispatch,
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Record));
