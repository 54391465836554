import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import * as actions from './actions';
import PlanSubscriptionBulkCreateSelectAccessPeriodView from './plan-subscription-bulk-create-select-details-view';
import {ROLES} from 'admin-ng/constants';

const mapStateToProps = (
  {
    plans: {
      instance: {
        subscriptions: {
          bulkCreate: {details},
        },
      },
    },
  },
  {
    auth: {
      user: {
        claims: {role_level},
      },
    },
  }
) => ({
  ...details,
  isPlanetAdmin: role_level >= ROLES.planetAdmin,
});

const mapDispatchToProps = dispatch => ({
  onUpdateDate: params => dispatch(actions.updateDate(params)),
  onReferenceChange: reference => dispatch(actions.updateReference(reference)),
});

export default withAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PlanSubscriptionBulkCreateSelectAccessPeriodView)
);
