import React from 'react';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import {arrayOf, bool, number, object, oneOfType, string} from 'prop-types';

const rowStyle = {height: 35};
const rowColumnStyle = {
  fontSize: 16,
  height: 42,
  paddingLeft: 14,
  paddingRight: 14,
  wordBreak: 'break-word',
};

const PlTableBody = props => {
  // Remove props we don't want to pass on to the MUI component when we spread
  const {
    dataSchema: _dataSchema,
    isRequestPending: _isRequestPending,
    noDataLabel: _noDataLabel,
    qaId: _qaId,
    srcData: _srcData,
    ...strippedProps
  } = props;

  return (
    <TableBody {...strippedProps}>
      {props.srcData && props.srcData.length ? (
        props.srcData.map((item, rowKey) => {
          const isDeleted =
            item.hasOwnProperty('state') && item.state === 'deleted';

          const classes = classNames({
            'pl-table--deleted-entry': isDeleted,
          });

          const rowQaId = props.qaId && `${props.qaId}-row-${rowKey + 1}`;

          return (
            <TableRow
              className={classes}
              data-qa-id={rowQaId}
              // eslint-disable-next-line react/no-array-index-key
              key={rowKey}
              style={rowStyle}
            >
              {props.dataSchema.map((column, columnKey) => {
                const columnQaId =
                  rowQaId && `${rowQaId}-column-${columnKey + 1}`;
                const style = Object.assign({}, rowColumnStyle, {
                  width: column.width,
                });
                let content = null;

                if (column.contentCreator) {
                  content = column.contentCreator(item);
                } else if (column.field) {
                  content = item[column.field];
                }

                return (
                  <TableCell
                    data-qa-id={columnQaId}
                    // eslint-disable-next-line react/no-array-index-key
                    key={columnKey}
                    style={style}
                  >
                    {content}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })
      ) : (
        <TableRow style={rowStyle}>
          <TableCell
            colSpan={(props.dataSchema ?? []).length}
            data-qa-id={props.qaId && `${props.qaId}-no-data`}
            style={rowColumnStyle}
          >
            {props.noDataLabel}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

PlTableBody.muiName = TableBody.muiName;

PlTableBody.propTypes = {
  dataSchema: arrayOf(object).isRequired,
  isRequestPending: bool,
  noDataLabel: string,
  qaId: string,
  srcData: arrayOf(oneOfType([string, number, object])),
};

export default PlTableBody;
