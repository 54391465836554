// Process environment variables from import.meta.env into a usable list of flags

const defaultFlippers = {
  DISABLE_BASEMAPS: false,
  DISABLE_SERIES: false,
  ENABLE_COMPLIANCE: true,
  ENABLE_INVITATIONS: true,
  ENABLE_PLAN_AUDIT_LOG: false,
  MOCK_LOGIN: false,
  ORG_PROFILE_LINK: true,
  PLANS_ASSIGN: true,
};

const trueValues = ['TRUE', 'True', 'true', 't', '1'];

const flippers = {...defaultFlippers};

for (const key in defaultFlippers) {
  const value = defaultFlippers[key];

  const prefixedKey = 'VITE_ADMIN_NG_' + key;
  let val = value;

  // Check the environment variable and parse it
  if (import.meta.env[prefixedKey]) {
    val = trueValues.includes(import.meta.env[prefixedKey]);
    // eslint-disable-next-line no-console
    console.info('Feature Flipper:', prefixedKey, val ? 'Enabled' : 'Disabled');
  }

  if (val === true) {
    flippers[key] = val;
  } else {
    delete flippers[key];
  }
}

export default flippers;
