import assign from 'deep-extend';
import {connect} from 'react-redux';

import PlanAssignSelectOrgs from './plan-assign-select-orgs';
import {fetchSubscriptions, updateSelectedOrgs} from './actions';

const mapStateToProps = state => {
  const assignState = state.plans.assign;

  return assign({}, assignState.selectOrgs, {
    sourcePlanName: assignState.selectPlan.sourcePlanName,
    destinationPlanName: assignState.selectPlan.destinationPlanName,
  });
};

const mapDispatchToProps = dispatch => ({
  onOrgsRequest: params => dispatch(fetchSubscriptions(params)),
  onOrgsSelect: items => dispatch(updateSelectedOrgs(items)),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
})(PlanAssignSelectOrgs);
