import React, {useEffect} from 'react';
import {Link, Route, useParams, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {func, object, string} from 'prop-types';

const MemberLink = ({details, name}) => {
  const {memberId} = useParams();
  return (
    <Link to={`/organizations/${details.id}/members/${memberId}`}>{name}</Link>
  );
};

MemberLink.propTypes = {
  details: object,
  name: string,
};

const mapStateToMemberProps = state => ({
  name: state.app.routeNames.ORG_MEMBER_DETAILS,
});

const ConnectedMemberLink = connect(mapStateToMemberProps)(MemberLink);

const OrganizationsHeader = ({details, updateTitle}) => {
  const rootPath = useRouteMatch('/organizations');

  useEffect(() => {
    if (rootPath) {
      const title = details ? details.name : '';
      updateTitle(rootPath.isExact ? 'Organizations' : title);
    }
  }, [details, updateTitle, rootPath]);

  return (
    <Route path="/organizations">
      <Link to="/organizations">Organizations</Link>

      {details && (
        <Route path="/organizations/:orgId">
          {' / '}
          <Link to={`/organizations/${details.id}`}>{details.name}</Link>

          <Route path="/organizations/:orgId/suborgs">
            {' / '}
            <Link to={`/organizations/${details.id}/suborgs`}>
              Suborganizations
            </Link>
          </Route>

          <Route path="/organizations/:orgId/members">
            {' / '}
            <Link to={`/organizations/${details.id}/members`}>Members</Link>

            <Route path="/organizations/:orgId/members/:memberId">
              {' / '}
              <ConnectedMemberLink details={details} />
            </Route>
          </Route>
          <Route path="/organizations/:orgId/plans">
            {' / '}
            <Link to={`/organizations/${details.id}/plans`}>Plans</Link>
          </Route>
          <Route path="/organizations/:orgId/plans/:subscriptionId">
            {' / '}
            <Link to={`/organizations/${details.id}/plans`}>View Plan</Link>
          </Route>
        </Route>
      )}
    </Route>
  );
};

const mapStateToProps = ({
  orgs: {
    instance: {
      general: {
        details: {initDetails: details},
      },
    },
  },
}) => ({
  details,
});

OrganizationsHeader.propTypes = {
  details: object,
  updateTitle: func.isRequired,
};

export default connect(mapStateToProps)(OrganizationsHeader);
