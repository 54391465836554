import {combineReducers as combine} from 'redux';
import {handleActions as create} from 'redux-actions';

import * as types from './types';
import accessPeriod from 'admin-ng/components/common/subscription-details/access-period/reducer';
import instance from 'admin-ng/components/common/subscription-details/instance/reducer';
import quota from 'admin-ng/components/common/subscription-details/quota/reducer';
import {ERROR_MESSAGE} from 'admin-ng/constants';
import {
  basemapQuadQuota,
  basemapTileQuota,
  sceneTileQuota,
} from 'admin-ng/components/common/subscription-details/tile-quota/reducer';

const reducers = {
  accessPeriod,
  instance,
  quota,
  basemapTileQuota,
  basemapQuadQuota,
  sceneTileQuota,
};

const initial = {
  error: null,
  errorQuota: null,
  filters: 'active',
  isFetching: false,
  limit: 20,
  offset: 0,
  quotaUsages: {},
  search: '',
  sort: 'plan.name',
  subscriptions: [],
  total: 0,
};

export const handlers = {
  [types.DELETE]: {
    next: state => ({...state, error: initial.error}),
    throw: (state, {payload: {message: error}}) => ({
      ...state,
      error: error || ERROR_MESSAGE,
    }),
  },

  [types.FETCH]: {
    next: (state, {payload: {isFetching = false, ...payload} = {}}) => ({
      ...state,
      error: initial.error,
      errorQuota: initial.errorQuota,
      isFetching,
      ...payload,
    }),
    throw: (state, {payload: {message: error}}) => ({
      ...state,
      isFetching: false,
      error: error || ERROR_MESSAGE,
    }),
  },

  [types.QUOTA]: {
    next: (state, {payload: {isFetching = false, ...payload} = {}}) => ({
      ...state,
      errorQuota: initial.errorQuota,
      isFetching,
      quotaUsages: {...payload},
    }),
    throw: (state, {payload: {message: error}}) => {
      return {
        ...state,
        isFetching: false,
        errorQuota: error || ERROR_MESSAGE,
      };
    },
  },

  [types.SET_PARAMS]: (state, {payload: params}) => ({...state, ...params}),
  [types.RESET]: () => ({...initial}),
};

const index = create(handlers, initial);
reducers.index = index;

export {index};
export default combine(reducers);
