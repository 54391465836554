import * as constants from './constants';
import * as messages from './messages';
import {all, update} from 'admin-ng/api/documents';
import {fetchDocumentDetails} from '../actions';

export const initializeDetails = (details = {}) => ({
  type: constants.INITIALIZE_DETAILS,
  deprecated: details.deprecated,
  externalName: details.external_name,
  id: details.id,
  name: details.name,
  requiredForSignup: details.required_for_signup,
  separate: details.separate,
  text: details.text,
});

export const updateName = name => ({
  type: constants.UPDATE_NAME,
  name,
});

export const updateExternalName = externalName => ({
  type: constants.UPDATE_EXTERNAL_NAME,
  externalName,
});

export const updateText = text => ({
  type: constants.UPDATE_TEXT,
  text,
});

export const toggleCheckbox = boxname => ({
  type: constants.TOGGLE_CHECKBOX,
  boxname,
});

export const requestNameCheck = (params = {}) => ({
  type: constants.REQUEST_NAME_CHECK,
  params,
});

export const requestNameCheckSuccess = isNameUnique => ({
  type: constants.REQUEST_NAME_CHECK_SUCCESS,
  isNameUnique,
});

export const requestNameCheckFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.REQUEST_NAME_CHECK_FAIL,
  error,
});

export function checkName(name) {
  return function (dispatch, getState) {
    const state = getState().documents.edit;
    const params = {
      name__ieq: name,
      id__ne: state.id,
    };

    dispatch(requestNameCheck(params));

    return all(params)
      .then((res = {}) => {
        if (res.body && res.body.results) {
          if (res.body.results.length >= 1) {
            dispatch(requestNameCheckSuccess(false));
          } else {
            dispatch(requestNameCheckSuccess(true));
          }
        } else {
          dispatch(requestNameCheckFail());
        }
      })
      .catch((err = {}) => {
        dispatch(requestNameCheckFail(err.message));
      });
  };
}

export const requestSave = (params = {}) => ({
  type: constants.REQUEST_SAVE,
  params,
});

export const requestSaveSuccess = () => ({
  type: constants.REQUEST_SAVE_SUCCESS,
});

export const requestSaveFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.REQUEST_SAVE_FAIL,
  error,
});

export function saveDocument() {
  return function (dispatch, getState) {
    const state = getState().documents.edit;
    const params = {
      deprecated: state.deprecated,
      external_name: state.externalName,
      name: state.name,
      required_for_signup: state.requiredForSignup,
      separate: state.separate,
      text: state.text,
    };

    dispatch(requestSave(params));

    return update(state.id, params)
      .then(() => {
        dispatch(requestSaveSuccess());
        dispatch(fetchDocumentDetails(state.id));
      })
      .catch((err = {}) => {
        dispatch(requestSaveFail(err.message));
      });
  };
}

export const resetState = () => ({
  type: constants.RESET_STATE,
});
