import {bindActionCreators as bind} from 'redux';
import {connect} from 'react-redux';

import OrgCommentEdit from './org-comment-edit';
import {
  changeComment,
  resetEditState,
  saveComment as unboundSaveComment,
} from './actions';

const mapStateToProps = (
  {
    orgs: {
      instance: {
        general: {
          comments: {edit},
        },
      },
    },
  },
  {comment: srcComment, onEdit, open}
) => ({
  ...edit,
  srcComment,
  onEdit,
  open,
});

const mapDispatchToProps = (dispatch, {orgId}) => {
  const saveComment = unboundSaveComment.bind(null, orgId);
  return {
    onCommentChange: bind(changeComment, dispatch),
    onCommentSave: bind(saveComment, dispatch),
    resetEditState: bind(resetEditState, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrgCommentEdit);
