import request from 'admin-ng/util/request-promise';
import {COMPLIANCE_V0_API, ORGANIZATION_COMPLIANCE, USER_COMPLIANCE} from '.';

export const check = params =>
  request(`${COMPLIANCE_V0_API}check`, 'get', params);
export const getOrganization = id =>
  request(`${ORGANIZATION_COMPLIANCE}${id}`, 'get');
export const getOrganizations = params =>
  request(ORGANIZATION_COMPLIANCE, 'get', params);
export const updateOrganization = (id, flag) =>
  request(`${ORGANIZATION_COMPLIANCE}${id}`, 'put', {status: flag});
export const getUser = id => request(`${USER_COMPLIANCE}${id}`, 'get');
export const getUsers = params => request(USER_COMPLIANCE, 'get', params);
export const updateUser = (id, flag) =>
  request(`${USER_COMPLIANCE}${id}`, 'put', {status: flag});
