import * as constants from './constants';
import * as messages from '../messages';
import {apiKey, one} from 'admin-ng/api/organizations';
import {reset as resetApiKey} from 'admin-ng/api/api-keys';
import {updateRouteName} from 'admin-ng/components/app/actions';

// Org details
export const requestOrgDetails = id => ({
  type: constants.REQUEST_ORG_DETAILS,
  id,
});

export const receiveOrgDetails = (details = null) => ({
  type: constants.RECEIVE_ORG_DETAILS,
  details,
});

export const orgDetailsRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.ORG_DETAILS_REQUEST_FAILED,
  error,
});

// Api key
export const requestAPIKey = () => ({
  type: constants.REQUEST_API_KEY,
});

export const receiveAPIKey = (apiMasterKey = null) => ({
  type: constants.RECEIVE_API_KEY,
  apiMasterKey,
});

export const requestAPIKeyFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.API_KEY_REQUEST_FAILED,
  error,
});

export const requestResetApiKey = () => ({
  type: constants.REQUEST_RESET_API_KEY,
});

export const resetApiKeyRequestCompleted = (apiMasterKey = null) => ({
  type: constants.RESET_API_KEY_REQUEST_COMPLETED,
  apiMasterKey,
});

export const resetApiKeyRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.RESET_API_KEY_REQUEST_FAILED,
  error,
});

export const fetchOrgAPIKey = apiLink => {
  return function (dispatch) {
    dispatch(requestAPIKey());

    return apiKey(apiLink)
      .then((res = {}) => {
        dispatch(receiveAPIKey(res.body));
      })
      .catch((err = {}) => {
        dispatch(requestAPIKeyFailed(err.message));
      });
  };
};

export const fetchOrgDetails = (id = null, history) => {
  return function (dispatch) {
    dispatch(requestOrgDetails(id));

    return one(id)
      .then((res = {}) => {
        dispatch(receiveOrgDetails(res.body));
        dispatch(updateRouteName('ORG_DETAIL', res.body.name));

        if (res.body && res.body.master_key) {
          dispatch(fetchOrgAPIKey(res.body.master_key));
        }
      })
      .catch((err = {}) => {
        if (err.response && err.response.statusCode === 404) {
          history.push(`/page-not-found?message=${messages.ORG_NOT_FOUND}`);
        } else {
          dispatch(orgDetailsRequestFailed(err.message));
          dispatch(updateRouteName('ORG_DETAIL', null));
        }
      });
  };
};

export const resetOrgApiKey = () => (dispatch, getState) => {
  const instanceState = getState().orgs.instance.general.details;

  dispatch(requestResetApiKey());
  return resetApiKey(instanceState.apiMasterKey.id)
    .then((res = {}) => {
      dispatch(resetApiKeyRequestCompleted(res.body));
    })
    .catch((err = {}) => {
      dispatch(resetApiKeyRequestFailed(err.message));
    });
};
