export const NAME = 'QUOTA';

export const RECEIVE_SUBSCRIPTION_DETAILS = `${NAME}/RECEIVE_SUBSCRIPTION_DETAILS`;

export const TOGGLE_ENABLED = `${NAME}/TOGGLE_ENABLED`;
export const UPDATE_AMOUNT = `${NAME}/UPDATE_AMOUNT`;
export const UPDATE_INTERVAL = `${NAME}/UPDATE_INTERVAL`;
export const UPDATE_QUOTA_STYLE = `${NAME}/UPDATE_QUOTA_STYLE`;
export const UPDATE_QUOTA_USAGE = `${NAME}/UPDATE_QUOTA_USAGE`;
export const UPDATE_QUOTA_OVERAGE = `${NAME}/UPDATE_QUOTA_OVERAGE`;
export const TOGGLE_INFINITE = `${NAME}/TOGGLE_INFINITE`;

export const RESET = `${NAME}/RESET`;
