import * as constants from './constants';
import * as messages from './messages';
import {create} from 'admin-ng/api/comments';
import {fetchComments} from '../org-comments-index/actions';

export const updateNewComment = (comment = null) => ({
  type: constants.UPDATE_NEW_COMMENT,
  comment,
});

export const requestAddComment = (comment = null) => ({
  type: constants.REQUEST_ADD_COMMENT,
  comment,
});

export const addCommentCompleted = () => ({
  type: constants.ADD_COMMENT_COMPLETED,
});

export const addCommentFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.ADD_COMMENT_FAILED,
  error,
});

export const createComment = orgId => {
  return (dispatch, getState) => {
    const state = getState(),
      commentsState = state.orgs.instance.general.comments.add,
      newComment = commentsState.newComment;

    dispatch(requestAddComment(newComment));

    return create(orgId, newComment).then(
      () => {
        dispatch(addCommentCompleted());
        dispatch(fetchComments(orgId));
      },
      (err = {}) => {
        dispatch(addCommentFailed(err.message));
      }
    );
  };
};
