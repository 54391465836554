import React from 'react';
import {array, bool, func, number, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import Policy from 'admin-ng/components/common/policy';
import PolicyList from 'admin-ng/components/common/policy-list';

const PlanCreatePolicies = props => {
  const policy = props.policies[props.selectedPolicy];

  return (
    <div className="pl-plan-policies">
      <Card title="Permissions" divider>
        <div className="pl-plan-policies--container">
          <div className="pl-plan-policies--sidebar">
            <PolicyList
              onAddPolicy={props.onAddPolicy}
              onNewNameSubmit={props.onNewNameSubmit}
              onRemove={props.onRemove}
              onRename={props.onRename}
              onSelectPolicy={props.onSelectPolicy}
              openPolicyNameDialog={props.queryForNewName}
              policies={props.policies}
              selectedPolicy={props.selectedPolicy}
            />
          </div>

          <Policy
            availableItemTypes={props.availableItemTypes}
            itemTypesRequestError={props.itemTypesRequestError}
            onAddAOI={props.onAddAOI}
            onAddItemType={props.onAddItemType}
            onAddTOI={props.onAddTOI}
            onDeleteAOI={props.onDeleteAOI}
            onDeleteItemType={props.onDeleteItemType}
            onDeleteTOI={props.onDeleteTOI}
            onGetAssetTypes={props.onGetAssetTypes}
            onGetAvailableItemTypes={props.onGetAvailableItemTypes}
            onRequestBasemaps={props.onRequestBasemaps}
            onRequestSeries={props.onRequestSeries}
            onUpdateAOI={props.onUpdateAOI}
            onUpdateAssetTypes={props.onUpdateAssetTypes}
            onUpdateDeliveryOptions={props.onUpdateDeliveryOptions}
            onUpdateItemType={props.onUpdateItemType}
            onUpdateSelectedMosaics={props.onUpdateSelectedMosaics}
            onUpdateTOI={props.onUpdateTOI}
            onUploadAOIFile={props.onUploadAOIFile}
            policy={policy}
            toggleItemType={props.toggleItemType}
            toggleItemTypeAll={props.toggleItemTypeAll}
          />
        </div>
      </Card>
    </div>
  );
};

PlanCreatePolicies.propTypes = {
  availableItemTypes: array,
  itemTypesRequestError: string,

  onAddAOI: func,
  onAddItemType: func,
  onAddPolicy: func,
  onAddTOI: func,

  onDeleteAOI: func,
  onDeleteItemType: func,
  onDeleteTOI: func,

  onGetAssetTypes: func,
  onGetAvailableItemTypes: func,

  onNewNameSubmit: func,
  onRemove: func,
  onRename: func,
  onRequestBasemaps: func,
  onRequestSeries: func,

  onSelectPolicy: func,
  onUpdateAOI: func,
  onUpdateAssetTypes: func,
  onUpdateDeliveryOptions: func,
  onUpdateItemType: func,
  onUpdateSelectedMosaics: func,
  onUpdateTOI: func,
  onUploadAOIFile: func,

  policies: array,
  queryForNewName: bool,
  selectedPolicy: number,

  toggleItemType: func.isRequired,
  toggleItemTypeAll: func.isRequired,
};

export default PlanCreatePolicies;
