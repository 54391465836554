import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {array, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import ErrorDialog from 'admin-ng/components/common/error-dialog';
import Notification from 'admin-ng/components/common/notification';
import Policy from 'admin-ng/components/common/policy';
import PolicyList from 'admin-ng/components/common/policy-list';
import Table from 'admin-ng/components/common/table';
import TextField from 'admin-ng/components/common/text-field';
import {protectedPackages} from './constants';

const orgSummaryTable = {
  wrapperStyle: {
    marginTop: '15px',
    maxHeight: '317px',
  },
};

function isProtectedPackage(packageID) {
  return Object.keys(protectedPackages).includes(packageID);
}

export default class PlanPolicies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      needCancelConfirmation: false,
      needSaveAsDialogue: false,
      needSaveConfirmation: false,
      error: null,
      errorTitle: null,
      showErrorDialog: false,
      planCopyName: null,
    };

    this._onCancel = this._onCancel.bind(this);
    this._closeNewPlan = this._closeNewPlan.bind(this);
    this._closeCancelConfirmation = this._closeCancelConfirmation.bind(this);
    this._closeErrorDialog = this._closeErrorDialog.bind(this);
    this._closeSaveNotification = this._closeSaveNotification.bind(this);
    this._openSaveAsDialogue = this._openSaveAsDialogue.bind(this);
    this._closeSaveAsDialogue = this._closeSaveAsDialogue.bind(this);
    this._closeSaveDialogue = this._closeSaveDialogue.bind(this);
    this._validatePlanName = this._validatePlanName.bind(this);
    this._handleSaveAsPlanNameChange =
      this._handleSaveAsPlanNameChange.bind(this);
    this._handleSaveAs = this._handleSaveAs.bind(this);
    this._handleSave = this._handleSave.bind(this);
    this._savePlan = this._savePlan.bind(this);
  }

  componentDidMount() {
    this.props.onOrgsRequest();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error) {
      this.setState({
        error: nextProps.error,
        errorTitle: 'Oops, there are still errors on the plan',
        showErrorDialog: true,
      });
    }

    if (nextProps.match.params.planId !== this.props.match.params.planId) {
      nextProps.onCancel();
      nextProps.onOpen();
      nextProps.onOrgsRequest();
      nextProps.onGetAvailableItemTypes();
    }
  }

  _closeNewPlan() {
    this.props.onCancel();
    this.props.history.push('/packages');
  }

  _closeCancelConfirmation() {
    this.setState({
      needCancelConfirmation: false,
    });
  }

  _closeErrorDialog() {
    this.setState({
      showErrorDialog: false,
      error: null,
    });
    this.props.onCloseGlobalError();
  }

  _onCancel() {
    this.props.checkPlanUpdates();

    // Some magic: waiting this.props.isPlanChanged to update
    setTimeout(() => {
      if (this.props.isPlanChanged) {
        this.setState({
          needCancelConfirmation: true,
        });
      } else {
        this._closeNewPlan();
      }
    });
  }

  _closeSaveNotification() {
    this.props.resetSaved();
    if (this.props.newSaveAsPlanId) {
      this.props.history.push(
        '/packages/' + this.props.newSaveAsPlanId + '/policies'
      );
    }
  }

  _openSaveAsDialogue() {
    this.setState({
      needSaveAsDialogue: true,
    });
  }

  _validatePlanName(value) {
    this.props.onPlanNameCheck(value);
  }

  _savePlan() {
    this.props.onSave();

    this.setState({
      needSaveConfirmation: false,
    });
  }

  _handleSaveAs() {
    if (this.props.isCopyPlanNameUnique) {
      this.props.onSaveAs(this.state.planCopyName);
      this._closeSaveAsDialogue();
    }
  }

  _handleSave() {
    if (
      this.props.subscriptionsTotalCount ||
      isProtectedPackage(this.props.match.params.planId)
    ) {
      this.setState({
        needSaveConfirmation: true,
      });
    } else {
      this._savePlan();
    }
  }

  _handleSaveAsPlanNameChange(value) {
    this.setState({
      planCopyName: value,
    });
  }

  _closeSaveDialogue() {
    this.setState({
      needSaveConfirmation: false,
    });
  }

  _closeSaveAsDialogue() {
    this.setState({
      planCopyName: null,
      needSaveAsDialogue: false,
    });
  }

  _getOrgDataSchema() {
    return [
      {
        field: 'name',
        sortKey: 'organization.name',
        width: '80%',
        label: 'Organization Name',
        contentCreator: item => {
          return (
            <Link target="_blank" to={`/organizations/${item.organization.id}`}>
              {item.organization.name}
            </Link>
          );
        },
        isSortable: false,
      },
    ];
  }

  render() {
    const disabled =
      !this.props.isCopyPlanNameUnique ||
      this.state.planCopyName === null ||
      this.state.planCopyName === '';

    const policy = this.props.policies[this.props.selectedPolicy];
    const isPlanetAdmin = this.props.isPlanetAdmin;

    return (
      <Card>
        <div className="pl-plan-details">
          <div className="pl-plan-policies--container">
            <div className="pl-plan-policies--sidebar">
              <PolicyList
                onAddPolicy={this.props.onAddPolicy}
                onNewNameSubmit={this.props.onNewNameSubmit}
                onOrgsRequest={this.props.onOrgsRequest}
                onRemove={this.props.onRemove}
                onRename={this.props.onRename}
                onSelectPolicy={this.props.onSelectPolicy}
                openPolicyNameDialog={this.props.queryForNewName}
                policies={this.props.policies}
                selectedPolicy={this.props.selectedPolicy}
                subscriptions={this.props.subscriptions}
                subscriptionsTotalCount={this.props.subscriptionsTotalCount}
              />
            </div>

            <Policy
              availableItemTypes={this.props.availableItemTypes}
              itemTypesRequestError={this.props.itemTypesRequestError}
              onAddAOI={this.props.onAddAOI}
              onAddItemType={this.props.onAddItemType}
              onAddTOI={this.props.onAddTOI}
              onDeleteAOI={this.props.onDeleteAOI}
              onDeleteItemType={this.props.onDeleteItemType}
              onDeleteTOI={this.props.onDeleteTOI}
              onGetAssetTypes={this.props.onGetAssetTypes}
              onGetAvailableItemTypes={this.props.onGetAvailableItemTypes}
              onRequestBasemaps={this.props.onRequestBasemaps}
              onRequestSeries={this.props.onRequestSeries}
              onUpdateAOI={this.props.onUpdateAOI}
              onUpdateAssetTypes={this.props.onUpdateAssetTypes}
              onUpdateDeliveryOptions={this.props.onUpdateDeliveryOptions}
              onUpdateItemType={this.props.onUpdateItemType}
              onUpdateSelectedMosaics={this.props.onUpdateSelectedMosaics}
              onUpdateTOI={this.props.onUpdateTOI}
              onUploadAOIFile={this.props.onUploadAOIFile}
              policy={policy}
              toggleItemType={this.props.toggleItemType}
              toggleItemTypeAll={this.props.toggleItemTypeAll}
            />
          </div>

          <div className="pl-plan-policies--footer">
            <Button
              data-qa-id="policy-cancel"
              onClick={this._onCancel}
              sx={{marginRight: '15px'}}
            >
              Cancel
            </Button>

            {isPlanetAdmin && (
              <Button
                data-qa-id="policy-save-as-button"
                onClick={this._openSaveAsDialogue}
                sx={{marginRight: '15px'}}
                variant="contained"
              >
                Save as
              </Button>
            )}

            <Button
              color="primary"
              data-qa-id="policy-save-button"
              onClick={this._handleSave}
              variant="contained"
            >
              Save
            </Button>
          </div>

          <ConfirmationMessage
            onClose={this._closeCancelConfirmation}
            onSubmit={this._closeNewPlan}
            open={this.state.needCancelConfirmation}
            qaId="plan-changes-cancel"
            title="Cancel Plan Changes"
          >
            Are you sure you want to cancel package changes? All the entered
            data will be lost.
          </ConfirmationMessage>

          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={this._closeSaveNotification}
            open={this.props.isSaveRequestCompleted}
            data-qa-id="plan-change-success"
          >
            <DialogTitle>Success</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Package data has been successfully saved.
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                data-qa-id="policy-save-confirm"
                key={1}
                onClick={this._closeSaveNotification}
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={this._closeSaveAsDialogue}
            open={this.state.needSaveAsDialogue}
            data-qa-id="plan-change-save-as"
          >
            <DialogTitle>Save as</DialogTitle>

            <DialogContent>
              <TextField
                error={!!this.props.copyPlanError}
                helperText={this.props.copyPlanError}
                label="Plan Name *"
                isInputValid={this.props.isCopyPlanNameUnique}
                onChange={this._handleSaveAsPlanNameChange}
                onChangeCompleted={this._validatePlanName}
                value={this.state.planCopyName || ''}
              />
            </DialogContent>

            <DialogActions>
              <Button
                color="inherit"
                data-qa-id="policy-dialog-cancel"
                onClick={this._closeSaveAsDialogue}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                data-qa-id="policy-dialog-save"
                disabled={disabled}
                onClick={!disabled ? this._handleSaveAs : null}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={this._closeSaveDialogue}
            open={this.state.needSaveConfirmation}
            data-qa-id="save-package"
          >
            <DialogTitle>Save Package</DialogTitle>

            <DialogContent>
              {this.props.subscriptionsTotalCount ? (
                <div>
                  <span>
                    {`The changes, made to the current plan, will affect ${this.props.subscriptionsTotalCount} organizations.`}
                  </span>

                  <Table
                    className="pl-confirmation-table"
                    dataSchema={this._getOrgDataSchema()}
                    onSort={this.props.onOrgsRequest}
                    qaId="plan-policies-table"
                    sortBy="organization.name"
                    srcData={this.props.subscriptions}
                    wrapperStyle={orgSummaryTable.wrapperStyle}
                  />
                </div>
              ) : (
                <div>
                  <span>
                    Are you sure you want to save permissions changes?
                  </span>
                  <p>
                    This is a standard package. Making changes to this package
                    will affect many organizations.
                  </p>
                </div>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                color="inherit"
                data-qa-id="policy-dialog-cancel"
                onClick={this._closeSaveDialogue}
              >
                Cancel
              </Button>

              <Button
                color="primary"
                onClick={this._savePlan}
                variant="contained"
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <ErrorDialog
            onClose={this._closeErrorDialog}
            open={this.state.showErrorDialog}
            qaId="plan-policies-error-ok"
          >
            <Notification
              message={this.state.error}
              title={this.state.errorTitle}
              type="error"
            />
          </ErrorDialog>
        </div>
      </Card>
    );
  }
}

PlanPolicies.propTypes = {
  availableItemTypes: array,
  checkPlanUpdates: func,
  copyPlanError: string,

  error: string,
  history: object.isRequired,
  isCopyPlanNameUnique: bool,
  isPlanChanged: bool,
  isPlanetAdmin: bool.isRequired,
  isSaveRequestCompleted: bool,
  itemTypesRequestError: string,
  match: object.isRequired,
  newSaveAsPlanId: number,

  onAddAOI: func,
  onAddItemType: func,
  onAddPolicy: func,
  onAddTOI: func,

  onCancel: func,
  onCloseGlobalError: func,

  onDeleteAOI: func,
  onDeleteItemType: func,
  onDeleteTOI: func,

  onGetAssetTypes: func,
  onGetAvailableItemTypes: func,

  onNewNameSubmit: func,
  onOpen: func,
  onOrgsRequest: func,
  onPlanNameCheck: func,
  onRemove: func,
  onRename: func,
  onRequestBasemaps: func,
  onRequestSeries: func,
  onSave: func,
  onSaveAs: func,

  onSelectPolicy: func,
  onUpdateAOI: func,
  onUpdateAssetTypes: func,
  onUpdateDeliveryOptions: func,
  onUpdateItemType: func,
  onUpdateSelectedMosaics: func.isRequired,
  onUpdateTOI: func,

  onUploadAOIFile: func,

  policies: array,
  queryForNewName: bool,
  resetSaved: func,
  selectedPolicy: number,
  subscriptions: array,
  subscriptionsTotalCount: number,

  toggleItemType: func.isRequired,
  toggleItemTypeAll: func.isRequired,
};
