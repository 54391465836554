import {createAction as create} from 'redux-actions';
import {isEmpty} from 'lodash';

import * as constants from './constants';
import * as messages from './messages';
import {ROLES} from 'admin-ng/constants';
import {all as allPlans} from 'admin-ng/api/plans';
import {
  all as allSubscriptions,
  del,
  quotaUsage,
} from 'admin-ng/api/subscriptions';

export const requestPlanSubscriptions = (params = {}) => ({
  type: constants.REQUEST_PLAN_SUBSCRIPTIONS,
  params,
});

export const receivePlanSubscriptions = (json = {}) => ({
  type: constants.RECEIVE_PLAN_SUBSCRIPTIONS,
  items: json.results || [],
  itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
});

export const planSubscriptionsRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.PLAN_SUBSCRIPTIONS_REQUEST_FAILED,
  error,
});

export const requestSubscriptionDelete = id => ({
  type: constants.REQUEST_SUBSCRIPTION_DELETE,
  id,
});

export const subscriptionDeleteRequestCompleted = () => ({
  type: constants.DELETE_SUBSCRIPTION_REQUEST_COMPLETED,
});

export const subscriptionDeleteRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.DELETE_SUBSCRIPTION_REQUEST_FAILED,
  error,
});

const getQueryParams = (orgId, params, defaultParams) => {
  const requestParams = Object.assign({}, defaultParams, params);

  requestParams.organization_id = orgId;

  if (requestParams.hasOwnProperty('search') && !requestParams.search) {
    delete requestParams.search;
  }

  if (requestParams.filters) {
    requestParams.state__in = requestParams.filters;
  }
  delete requestParams.filters;

  return requestParams;
};

export const updateQuotaUsage = usages => ({
  type: constants.UPDATE_SUBSCRIPTIONS_QUOTA_USAGE,
  payload: usages,
});

export const quotaUsageRequestFailed = error => ({
  type: constants.QUOTA_USAGE_REQUEST_FAILED,
  error,
});

export const fetchQuotaUsage =
  (res = {}) =>
  dispatch => {
    if (!isEmpty(res.items)) {
      return quotaUsage(res.items.map(subscription => subscription.id))
        .then((usageResponse = {}) => {
          if (!isEmpty(usageResponse.body)) {
            dispatch(updateQuotaUsage(usageResponse.body));
          }
        })
        .catch((err = {}) => {
          dispatch(quotaUsageRequestFailed(err.message));
        });
    }
  };

export const fetchSubscriptions = (orgId, params = {}) => {
  return (dispatch, getState) => {
    dispatch(requestPlanSubscriptions(params));

    if (orgId) {
      const state = getState(),
        orgPlanSubscriptionsState = state.orgs.instance.subscriptions,
        stateViewParams = orgPlanSubscriptionsState.viewParams;

      const requestParams = getQueryParams(orgId, params, stateViewParams);

      return allSubscriptions(requestParams)
        .then((res = {}) => {
          return dispatch(receivePlanSubscriptions(res.body));
        })
        .then(res => {
          return dispatch(fetchQuotaUsage(res));
        })
        .catch((err = {}) => {
          dispatch(planSubscriptionsRequestFailed(err.message));
        });
    } else {
      dispatch(
        planSubscriptionsRequestFailed(messages.ORG_ID_IS_MISSING_ERROR_MSG)
      );
    }
  };
};

export const deleteSubscription = (orgId, subscriptionId, user) => {
  return function (dispatch) {
    const isOwnOrgAdmin =
      user.role_level === ROLES.orgAdmin && user.organization_id === orgId;

    if (isOwnOrgAdmin) {
      return dispatch(
        subscriptionDeleteRequestFailed(messages.PERMISSION_ERROR_MSG)
      );
    } else {
      if (orgId) {
        if (subscriptionId) {
          dispatch(requestSubscriptionDelete(subscriptionId));

          return del(subscriptionId)
            .then(() => {
              dispatch(subscriptionDeleteRequestCompleted());
              return dispatch(fetchSubscriptions(orgId));
            })
            .catch((err = {}) => {
              return dispatch(subscriptionDeleteRequestFailed(err.message));
            });
        } else {
          return dispatch(
            subscriptionDeleteRequestFailed(
              messages.ORG_ID_IS_MISSING_ERROR_MSG
            )
          );
        }
      } else {
        return dispatch(
          subscriptionDeleteRequestFailed(messages.ORG_ID_IS_MISSING_ERROR_MSG)
        );
      }
    }
  };
};

// fetch plans
export const fetchPlansStart = () => ({type: constants.FETCH_PLANS_START});

export const fetchPlansSuccess = json => ({
  type: constants.FETCH_PLANS_SUCCESS,
  plansTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
});

export const fetchPlansFail = (payload = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.FETCH_PLANS_FAIL,
  plansError: payload,
});

export const fetchPlans = () => {
  return function (dispatch) {
    const params = {
      limit: 0,
      state__in: 'active',
    };
    dispatch(fetchPlansStart());
    return allPlans(params)
      .then((res = {}) => {
        dispatch(fetchPlansSuccess(res.body));
      })
      .catch((err = {}) => {
        dispatch(fetchPlansFail(err.message));
      });
  };
};

export const reset = create(constants.RESET);
