import * as constants from './constants';

const initialState = {
  commentId: null,
  commentText: null,
  commentUpdateRequested: false,
  commentUpdated: false,
  error: null,
};

export default function orgCommentEditReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.CHANGE_COMMENT:
      return {
        ...state,
        commentUpdated: false,
        commentId: action.id,
        commentText: action.text,
      };

    case constants.REQUEST_UPDATE_COMMENT:
      return {
        ...state,
        error: null,
        commentUpdateRequested: true,
      };

    case constants.COMMENT_UPDATE_COMPLETED:
      return {
        ...state,
        error: null,
        commentId: null,
        commentText: null,
        commentUpdated: true,
        commentUpdateRequested: false,
      };

    case constants.COMMENT_UPDATE_FAILED:
      return {
        ...state,
        error: action.error,
        commentUpdateRequested: false,
      };

    case constants.COMMENT_UPDATE_RESET:
      return {
        ...state,
        error: null,
        commentUpdated: false,
      };

    default:
      return state;
  }
}
