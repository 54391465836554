export const NAME = 'BULK_SCREENING';

export const UPDATE_NAMES = `${NAME}/UPDATE_NAMES`;
export const TOGGLE_CANADA = `${NAME}/TOGGLE_CANADA`;
export const TOGGLE_USA = `${NAME}/TOGGLE_USA`;
export const TOGGLE_EU = `${NAME}/TOGGLE_EU`;

export const CHECK_NAMES_FAIL = `${NAME}/CHECK_NAMES_FAIL`;

export const SET_GROUPS = `${NAME}/SET_GROUPS`;

export const CHECK_START = `${NAME}/CHECK_START`;
export const CHECK_SUCCESS = `${NAME}/CHECK_SUCCESS`;
export const CHECK_FAIL = `${NAME}/CHECK_FAIL`;

export const RESET = `${NAME}/RESET`;
