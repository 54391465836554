import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import {createAction as create} from 'redux-actions';

import * as types from './types';
import {PARAM_KEYS} from 'admin-ng/constants';
import {all, del as apiDel, quotaUsage} from 'admin-ng/api/subscriptions';

export const quotaAction = create(types.QUOTA);
export const fetchQuotaUsage = subscriptions => async dispatch => {
  if (!isEmpty(subscriptions)) {
    try {
      const usageResponse = await quotaUsage(
        subscriptions.map(subscription => subscription.id)
      );
      if (!isEmpty(usageResponse.body)) {
        dispatch(quotaAction(usageResponse.body));
      }
    } catch (err) {
      dispatch(quotaAction(err));
    }
  }
};

export const fetchAction = create(types.FETCH);
export const fetch = () => async (dispatch, getState) => {
  dispatch(fetchAction({isFetching: true}));
  const params = pickBy(pick(getState().subscriptions.index, PARAM_KEYS));
  if (params.filters) {
    params.state__in = params.filters;
    delete params.filters;
  }
  try {
    const {
      body: {
        results: subscriptions,
        meta: {count: total},
      },
    } = await all(params);
    dispatch(fetchQuotaUsage(subscriptions));
    return dispatch(fetchAction({subscriptions, total}));
  } catch (err) {
    dispatch(fetchAction(err));
  }
};

export const setParams = create(types.SET_PARAMS);
export const reset = create(types.RESET);

export const deleteAction = create(types.DELETE);
export const del = id => async dispatch => {
  try {
    await apiDel(id);
    dispatch(deleteAction());
    dispatch(fetch());
  } catch (err) {
    dispatch(deleteAction(err));
  }
};
