import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import DocumentInstance from './document-instance';
import {fetchDocumentDetails} from './actions';
import {initializeDetails} from './document-edit/actions';

const mapStateToProps = ({documents: {instance}}) => instance;
const mapDispatchToProps = dispatch => ({
  fetchDocumentDetails: (docId, history) =>
    dispatch(fetchDocumentDetails(docId, history)),
  prefillEditDialog: details => dispatch(initializeDetails(details)),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(DocumentInstance)
);
