import Button from '@mui/material/Button';
import React, {Component} from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import {bool, func, object} from 'prop-types';

import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';

export default class MemberApiKey extends Component {
  static propTypes = {
    apiKey: object.isRequired,
    isCurrentUser: bool.isRequired,
    isReadonly: bool,
    onApiKeyReset: func.isRequired,
  };

  static defaultProps = {
    isReadonly: false,
  };

  state = {
    dialogOpen: false,
  };

  _handleReset = () => this.setState({dialogOpen: true});

  _confirmReset = () => {
    this.setState({dialogOpen: false});
    this.props.onApiKeyReset();
  };

  _closeDialog = () => this.setState({dialogOpen: false});

  render() {
    const {apiKey, isCurrentUser, isReadonly} = this.props;
    const {dialogOpen} = this.state;
    return (
      <div>
        <ConfirmationMessage
          onClose={this._closeDialog}
          onSubmit={this._confirmReset}
          open={dialogOpen}
          title="Reset API Key"
        >
          Are you sure you want to reset this API key?
          {isCurrentUser && ' You will need to log in again afterwards.'}
        </ConfirmationMessage>

        <div className="pl-form--field-group">
          <label className="pl-form--field-label api-key-label">API Key:</label>
          <p className="pl-form--field-simple-text">
            {apiKey ? apiKey.name : null}
          </p>
          <Button
            className="pl-btn-flat--default"
            data-qa-id="org-member-api-key-reset-button"
            disabled={isReadonly}
            startIcon={<ReplayIcon />}
            onClick={!isReadonly ? this._handleReset : null}
            variant="outlined"
          >
            Reset
          </Button>
        </div>
      </div>
    );
  }
}
