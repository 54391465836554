import {UPDATE_ROUTE_NAME} from './constants';

export const initialState = {
  routeNames: {},
  update: 0,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_ROUTE_NAME:
      return {
        routeNames: Object.assign({}, state.routeNames, {
          [action.key]: action.name,
        }),
        update: state.update + 1,
      };

    default:
      return state;
  }
};
