import amber from '@mui/material/colors/amber';
import cyan from '@mui/material/colors/cyan';
import grey from '@mui/material/colors/grey';

const cyan700 = cyan['700'];
const amber50 = amber['50'];
const grey600 = grey['600'];

export const FormFieldStyles = {
  general: {
    margin: '15px 0 25px',
    height: 48,
  },

  changed: {
    background: amber50,
  },

  underlineStyle: {
    borderColor: cyan700,
    bottom: 0,
  },

  underlineFocusStyle: {
    color: cyan700,
  },

  radioGroup: {
    margin: '0 0 30px',
  },

  maxDropDownHeight: 215,

  dropDownMenuStyle: {
    textTransform: 'capitalize',
    marginTop: 0,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },

  dropDownLabelStyle: {
    top: 0,
    lineHeight: '45px',
  },

  fieldLabel: {
    margin: '15px 0 10px',
    fontSize: '15px',
    display: 'block',
    color: grey600,
  },

  dialogBodyStyle: {
    padding: '24px',
  },
};
