import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {func, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import DocumentEditContainer from './document-edit/document-edit-container';
import Notification from 'admin-ng/components/common/notification';
import Sidebar from 'admin-ng/components/common/sidebar';
import {ROLES} from 'admin-ng/constants';

class DocumentInstance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditDialogShown: false,
    };

    this._showEditDialog = this._showEditDialog.bind(this);
    this._hideEditDialog = this._hideEditDialog.bind(this);
    this._renderDetailsList = this._renderDetailsList.bind(this);
  }

  componentWillMount() {
    this.props.fetchDocumentDetails(
      this.props.match.params.documentId,
      this.props.history
    );
  }

  _showEditDialog() {
    this.props.prefillEditDialog(this.props.details);
    this.setState({
      isEditDialogShown: true,
    });
  }

  _hideEditDialog() {
    this.setState({
      isEditDialogShown: false,
    });
  }

  _renderDetailsList() {
    const {
      details: {
        text,
        name,
        external_name,
        deprecated,
        required_for_signup,
        separate,
      },
    } = this.props;
    const hasText = text && text.length;

    return (
      <ul className="pl-org-details pl-details-list clearfix">
        <li className="item">
          <div className="pl-details-list--title" title="Internal name">
            Internal name
          </div>

          <div className="pl-details-list--value" title={name}>
            {name}
          </div>
        </li>

        <li className="item-right">
          <div className="pl-details-list--title" title="External name">
            External name
          </div>

          <div className="pl-details-list--value" title={external_name}>
            {external_name}
          </div>
        </li>

        <li className="item">
          <div className="pl-details-list--title" title="Internal name">
            Deprecated
          </div>

          <div className="pl-details-list--value" title={name}>
            {deprecated ? 'yes' : 'no'}
          </div>
        </li>

        <li className="item-right">
          <div className="pl-details-list--title" title="Internal name">
            Required for signup
          </div>

          <div className="pl-details-list--value" title={name}>
            {required_for_signup ? 'yes' : 'no'}
          </div>
        </li>

        <li className="item">
          <div className="pl-details-list--title" title="Internal name">
            Shown separate
          </div>

          <div className="pl-details-list--value" title={name}>
            {separate ? 'yes' : 'no'}
          </div>
        </li>

        <li className="item-fullwidth">
          <div className="pl-details-list--title" title="Text">
            Text
          </div>

          <div className="pl-details-list--value pl-document-text">
            {hasText ? <pre>{text}</pre> : '-'}
          </div>
        </li>
      </ul>
    );
  }

  render() {
    if (this.props.auth.user.claims.role_level < ROLES.planetAdmin) {
      return <Redirect to="page-not-found" />;
    }

    const {details, error} = this.props;
    return (
      <div className="pl-flex-wrapper">
        <Sidebar />

        <div className="pl-app--content">
          <div className="pl-document-instance-page">
            <Card>
              <div className="pl-form--section pl-document-instance">
                <div className="pl-form--section-header">
                  <h3 className="pl-form--section-title">Details</h3>

                  {details && (
                    <div className="pl-form--section-menu">
                      <Button
                        color="primary"
                        data-qa-id="edit-document"
                        startIcon={<EditIcon />}
                        onClick={this._showEditDialog}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </div>
                  )}
                </div>

                <Notification
                  message={error}
                  title="Oops, something went wrong"
                  type="error"
                />

                {details && this._renderDetailsList()}

                {details && (
                  <DocumentEditContainer
                    details={details}
                    isOpen={this.state.isEditDialogShown}
                    onClose={this._hideEditDialog}
                  />
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

DocumentInstance.propTypes = {
  auth: object.isRequired,
  details: object,
  error: string,
  fetchDocumentDetails: func.isRequired,
  history: object.isRequired,
  match: object.isRequired,
  params: object,
  prefillEditDialog: func.isRequired,
};

export default DocumentInstance;
