import React, {Component} from 'react';
import {arrayOf, bool, func, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import PlanOrgsTable from '../common/plan-orgs-table';
import PlanSummary from '../common/plan-summary';

export default class PlanAssignSelectOrgs extends Component {
  constructor(props) {
    super(props);

    this._handleOrgSelect = this._handleOrgSelect.bind(this);
  }

  componentDidMount() {
    this.props.onOrgsRequest();
  }

  _handleOrgSelect(items) {
    this.props.onOrgsSelect(items);
  }

  render() {
    const isTableSelectable = true;

    return (
      <div>
        <PlanSummary
          destinationPlanName={this.props.destinationPlanName}
          sourcePlanName={this.props.sourcePlanName}
        />
        <p className="pl-wizard-step-instructions">
          Select one or several organizations that need to be reassigned
        </p>

        <Notification
          message={this.props.error}
          title="Oops, packages request failed"
          type="error"
        />

        <PlanOrgsTable
          className="pl-table-index"
          isRequestPending={this.props.isOrgsRequestPending}
          onRowSelection={this._handleOrgSelect}
          onSort={this.props.onOrgsRequest}
          selectable={isTableSelectable}
          selectedOrgs={this.props.selectedOrgs}
          sortBy={this.props.orgsViewParams.sort}
          srcData={this.props.orgs || []}
        />
      </div>
    );
  }
}

PlanAssignSelectOrgs.propTypes = {
  destinationPlanName: string,
  error: string,
  isOrgsRequestPending: bool,
  onOrgsRequest: func,
  onOrgsSelect: func.isRequired,
  orgs: arrayOf(object),
  orgsViewParams: object,
  selectedOrgs: arrayOf(object),
  sourcePlanName: string,
};
