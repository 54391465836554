import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {arrayOf, func, object, shape, string} from 'prop-types';

import Autocomplete from 'admin-ng/components/common/autocomplete';
import Notification from 'admin-ng/components/common/notification';

export default class PlanAssignSelectPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      source: {
        name: '',
      },
      destination: {
        name: '',
      },
    };

    this._onPlanNameChange = this._onPlanNameChange.bind(this);
    this._requestPlansByName = this._requestPlansByName.bind(this);
  }

  _handlePlanSelect(type, e, index) {
    this.props.onPlanSelect(
      type,
      this.props.plans[type][index].id,
      this.props.plans[type][index].name
    );
  }

  _onPlanNameChange(type, planName) {
    this.setState({
      [type]: {
        name: planName,
      },
    });
  }

  _requestPlansByName(type) {
    if (this.state[type].name && this.state[type].name.length > 2) {
      this.props.onPlansRequest(type, this.state[type].name.trim());
    }
  }

  render() {
    const userHint = (
      <span>
        Start typing a name of a package (min 3 characters).&nbsp;
        <Link target="_blank" to="/packages">
          See all packages
        </Link>
      </span>
    );
    return (
      <div>
        <Notification
          message={this.props.error}
          title="Oops, packages request has failed"
          type="error"
        />
        <p className="pl-wizard-step-instructions">
          Select source and destination packages:
        </p>

        <div className="clearfix">
          <div className="pl-wizard-plans-left-col">
            <Autocomplete
              error={!!this.props.sourcePlanError}
              helperText={this.props.sourcePlanError}
              label="Source Package"
              onInputChange={this._onPlanNameChange.bind(this, 'source')}
              onInputCompleted={this._requestPlansByName.bind(this, 'source')}
              onChange={this._handlePlanSelect.bind(this, 'source')}
              options={this.props.plans.source || []}
              userHintBottomText={userHint}
            />
          </div>

          <div className="pl-wizard-plans-right-col">
            <Autocomplete
              error={!!this.props.destinationPlanError}
              helperText={this.props.destinationPlanError}
              label="Destination Package"
              onInputChange={this._onPlanNameChange.bind(this, 'destination')}
              onInputCompleted={this._requestPlansByName.bind(
                this,
                'destination'
              )}
              onChange={this._handlePlanSelect.bind(this, 'destination')}
              options={this.props.plans.destination || []}
              userHintBottomText={userHint}
            />
          </div>
        </div>
      </div>
    );
  }
}

PlanAssignSelectPlan.propTypes = {
  destinationPlanError: string,
  error: string,
  onPlanSelect: func,
  onPlansRequest: func,
  plans: shape({
    destination: arrayOf(object),
    source: arrayOf(object),
  }),
  sourcePlanError: string,
};
