export const SUBORG_MOVE_UPDATE_NAME = 'SUBORG_MOVE_UPDATE_NAME';

// Request handling when fetching all other (external) suborgs not belonging to the current organization.
export const SUBORG_MOVE_REQUEST_SUBORGS = 'SUBORG_MOVE_REQUEST_SUBORGS';
export const SUBORG_MOVE_REQUEST_SUBORGS_SUCCESS =
  'SUBORG_MOVE_REQUEST_SUBORGS_SUCCESS';
export const SUBORG_MOVE_REQUEST_SUBORGS_FAILED =
  'SUBORG_MOVE_REQUEST_SUBORGS_FAILED';

export const SUBORG_MOVE_SELECT_SUBORG = 'SUBORG_MOVE_SELECT_SUBORG';

// Request handling when moving the selected external suborg to the current organization.
export const SUBORG_MOVE_REQUEST_MOVE = 'SUBORG_MOVE_REQUEST_MOVE';
export const SUBORG_MOVE_REQUEST_MOVE_SUCCESS =
  'SUBORG_MOVE_REQUEST_MOVE_SUCCESS';
export const SUBORG_MOVE_REQUEST_MOVE_FAILED =
  'SUBORG_MOVE_REQUEST_MOVE_FAILED';

export const SUBORG_MOVE_RESET_STATE = 'SUBORG_MOVE_RESET_STATE';
