import countriesList from 'countries-list';

const countries = [];

export default () => {
  if (countries.length === 0) {
    for (const countryCode in countriesList.countries) {
      countries.push({
        code: countryCode,
        name: countriesList.countries[countryCode].name,
      });
    }
    countries.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  }

  return countries.slice();
};
