export const ACTIVE_FROM_DATE_NOT_SPECIFIED = 'Please specify the start date';
export const ACTIVE_TO_DATE_NOT_SPECIFIED = 'Please specify the end date';
export const DEFAULT_ERROR_MSG = 'Something went wrong';
export const INTERVAL_NOT_SPECIFIED = 'Please select an interval';
export const NO_SUBSCRIPTION_ID_MESSAGE = 'Plan id is missing';
export const OPPORTUNITY_ID_IS_MISSING = 'Please provide an opportunity ID';
export const OPPORTUNITY_ID_INVALID =
  'Invalid Opportunity ID. Please provide valid PL-Numbers or Theme.';
export const ORG_ID_IS_MISSING_ERROR_MSG =
  'Organization id is missing in the request';
export const AUTHSTATE_IS_MISSING_ERROR_MSG =
  'Auth state is missing in the request';
export const PERMISSION_ERROR_MSG =
  "You don't have permissions for this operation";
export const PLAN_IS_MISSING_ERROR_MESSAGE = 'Plan is not specified';
export const TOI_RANGE_VALIDATION_ERROR_MESSAGE =
  "End date can't be earlier than start date";
export const VALIDATION_ERROR = 'Access Period or Quota is not valid';
export const NO_QUOTA_ERROR =
  'At least one type of quota must be enabled with a non-zero amount';
