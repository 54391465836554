import assign from 'deep-extend';

import * as constants from './constants';
import {RESET_ASSIGN_STATE} from '../plan-assign-general/constants';

const initialState = {
  plans: {source: [], destination: []},
  error: null,
  sourcePlanId: null,
  sourcePlanName: null,
  sourcePlanError: null,
  destinationPlanId: null,
  destinationPlanName: null,
  destinationPlanError: null,
};

export default function planAssignSelectPlanReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.PLANS_ASSIGN_RECEIVE_DESTINATION_PLANS:
      return assign({}, state, {
        plans: {
          ...state.plans,
          destination: action.items,
        },
      });

    case constants.PLANS_ASSIGN_RECEIVE_SOURCE_PLANS:
      return assign({}, state, {
        plans: {
          ...state.plans,
          source: action.items,
        },
      });

    case constants.PLANS_ASSIGN_PLANS_REQUEST_FAILED:
      return assign({}, state, {
        error: action.error,
      });

    case constants.UPDATE_SOURCE_PLAN:
      return assign({}, state, {
        sourcePlanId: action.id,
        sourcePlanName: action.name,
        sourcePlanError: action.error,
      });

    case constants.UPDATE_DESTINATION_PLAN:
      return assign({}, state, {
        destinationPlanId: action.id,
        destinationPlanName: action.name,
        destinationPlanError: action.error,
      });

    case RESET_ASSIGN_STATE:
      return assign({}, state, initialState);

    default:
      return state;
  }
}
