import fetchContent from 'admin-ng/util/fetch-content';
import request from 'admin-ng/util/request-promise';
import {REPORTS, RE_REPORTS} from '.';
import {download as downloadFile} from 'admin-ng/util/file';

const filename = 'users.csv';

/**
 * usersCsv
 * @param  {string} token User authorization token
 * @return {Promise}
 */
export const usersCsv = async token => {
  const content = await fetchContent(`${REPORTS}${filename}`, token);
  downloadFile([content], filename, {type: 'text/csv'});
  return content;
};

export const report = dateRange => request(RE_REPORTS, 'post', dateRange);
