import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import moment from 'moment';
import {bool, func} from 'prop-types';

import DateRangePicker from 'admin-ng/components/common/date-range-picker';
import RequestSpinner from 'admin-ng/components/common/request-spinner';
import {DATE_FORMAT} from 'admin-ng/constants';
import {report} from 'admin-ng/api/reports';

class GacReportDialog extends Component {
  static propTypes = {
    onClose: func,
    open: bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      gacDateRange: null,
      recipientEmail: '',
      loading: false,
    };

    this._onGacDialogClose = this._onGacDialogClose.bind(this);
    this._onGacGenerate = this._onGacGenerate.bind(this);
    this._onGacDateRangeSelected = this._onGacDateRangeSelected.bind(this);
  }

  _formatGacDateRange(gacDateRange) {
    if (!gacDateRange) {
      return 'No range selected';
    }
    const fromString = moment(this.state.gacDateRange.from).format(DATE_FORMAT);
    const toString = moment(this.state.gacDateRange.to).format(DATE_FORMAT);
    return `From ${fromString} to ${toString}`;
  }

  _onGacDateRangeSelected(dateRange) {
    this.setState({
      gacDateRange: dateRange,
    });
  }

  _onGacDialogClose() {
    this.setState({
      gacDateRange: null,
      recipientEmail: '',
    });

    this.props.onClose();
  }

  _onGacGenerate() {
    this.setState({
      error: null,
      loading: true,
    });

    const params = {
      start_date: moment(this.state.gacDateRange.from).format(DATE_FORMAT),
      end_date: moment(this.state.gacDateRange.to).format(DATE_FORMAT),
    };

    report(params)
      .then(response => {
        this.setState({
          recipientEmail: response.body.recipientEmail,
          loading: false,
        });
      })
      .catch((error = '') => {
        this.setState({
          gacDateRange: null,
          error: error.message,
        });
      });
  }

  _renderForm() {
    return (
      <div>
        {this.state.loading ? <RequestSpinner /> : null}

        <DateRangePicker onRangeSelected={this._onGacDateRangeSelected} />
        <div className="gac-report-section">
          {this.state.error ? (
            <div className="error">{this.state.error}</div>
          ) : (
            this._formatGacDateRange(this.state.gacDateRange)
          )}
        </div>
        <Button
          color="primary"
          disabled={!this.state.gacDateRange}
          fullWidth
          onClick={this.state.gacDateRange ? this._onGacGenerate : null}
          sx={{width: '100%'}}
          variant="contained"
        >
          Generate
        </Button>
      </div>
    );
  }

  _renderConfirmation() {
    return (
      <div>
        <div className="gac-report-section">
          Thank you. After the report is generated, it will be sent to the email
          address associated with this account:
        </div>
        <div className="gac-report-section with-emphasis">
          {this.state.recipientEmail}
        </div>
        <Button
          onClick={this._onGacDialogClose}
          color="primary"
          sx={{width: '100%'}}
          variant="contained"
        >
          OK
        </Button>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        className="gac-report-dialog"
        maxWidth="md"
        onClose={this._onGacDialogClose}
        open={this.props.open}
      >
        <DialogTitle>GAC Report</DialogTitle>
        <DialogContent>
          {this.state.recipientEmail
            ? this._renderConfirmation()
            : this._renderForm()}
        </DialogContent>
      </Dialog>
    );
  }
}

export default GacReportDialog;
