import React from 'react';
import {HashRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {render} from 'react-dom';

import './main.scss';
import App from './components/app/app';
import AuthStore from './components/AuthStore';
import initializeErrorTracking from './util/error-tracking';
import store from './store';

initializeErrorTracking();

render(
  <HashRouter>
    <AuthStore>
      <Provider store={store}>
        <App />
      </Provider>
    </AuthStore>
  </HashRouter>,
  document.getElementById('web-admin-ng')
);
