import React from 'react';
import classNames from 'classnames';
import {string} from 'prop-types';

const states = ['active', 'disabled', 'deleted'];

const DataState = props => {
  const {label, state} = props;
  const classes = ['pl-entry-state'];
  if (states.includes(state)) {
    classes.push(`pl-entry-state--${state}`);
  }
  return (
    <span
      className={classNames(classes)}
      title={label || state.replace(/_/, ' ')}
    >
      {label || state.replace(/_/, ' ')}
    </span>
  );
};

DataState.propTypes = {
  label: string,
  state: string.isRequired,
};

export default DataState;
