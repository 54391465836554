import * as constants from './constants';
import * as messages from './messages';
import {fetchComments} from '../org-comments-index/actions';
import {update} from 'admin-ng/api/comments';

export const changeComment = (id, text) => ({
  type: constants.CHANGE_COMMENT,
  id,
  text,
});

export const requestCommentUpdate = () => ({
  type: constants.REQUEST_UPDATE_COMMENT,
});

export const updateCommentCompleted = () => ({
  type: constants.COMMENT_UPDATE_COMPLETED,
});

export const updateCommentFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.COMMENT_UPDATE_FAILED,
  error,
});

export const resetEditState = () => ({
  type: constants.COMMENT_UPDATE_RESET,
});

export const saveComment = orgId => (dispatch, getState) => {
  const state = getState(),
    commentEditState = state.orgs.instance.general.comments.edit;

  if (commentEditState.commentText) {
    dispatch(requestCommentUpdate());
    return update(
      commentEditState.commentId,
      commentEditState.commentText
    ).then(
      () => {
        dispatch(updateCommentCompleted());
        dispatch(fetchComments(orgId));
      },
      (err = {}) => {
        dispatch(updateCommentFailed(err.message));
      }
    );
  } else {
    dispatch(updateCommentFailed(messages.COMMENT_TEXT_NOT_SPECIFIED));
  }
};
