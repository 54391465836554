import assign from 'deep-extend';
import {connect} from 'react-redux';

import * as actions from './actions';
import SuborgCreate from './suborg-create';

const mapStateToProps = (state, props) =>
  assign({}, state.orgs.instance.suborgs.create, {
    parentId: props.parentId,
    isOpen: props.isOpen,
  });

const mapDispatchToProps = (dispatch, props) => ({
  onNameChange: name => dispatch(actions.updateSuborgName(name)),
  onNameCheck: name => dispatch(actions.checkSuborgName(props.parentId, name)),
  onSubmit: () =>
    dispatch(actions.createNewSuborg(props.parentId, props.parentOrgProgramId)),

  onClose: () => {
    props.onClose();
    return dispatch(actions.resetSuborgCreateState());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuborgCreate);
