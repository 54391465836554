import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {array, bool, func, number, object, string} from 'prop-types';

import Autocomplete from 'admin-ng/components/common/autocomplete';
import Notification from 'admin-ng/components/common/notification';
import {AUTOCOMPLETE_MIN} from 'admin-ng/constants';

export default class SuborgMove extends Component {
  constructor(props) {
    super(props);

    this._requestSuborgsByName = this._requestSuborgsByName.bind(this);
    this._renderSuccessDialog = this._renderSuccessDialog.bind(this);
    this._renderAutoComplete = this._renderAutoComplete.bind(this);
  }

  _requestSuborgsByName() {
    const name = this.props.name;
    if (name && name.length >= AUTOCOMPLETE_MIN) {
      this.props.onExternalSuborgsRequest(name);
    }
  }

  _renderSuccessDialog() {
    return (
      <Dialog fullWidth maxWidth="sm" open={this.props.isOpen}>
        <DialogTitle>Add an existing suborganization</DialogTitle>

        <DialogContent>
          <DialogContentText>
            The selected suborganization has been successfully added.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            focusRipple
            key={1}
            onClick={this.props.onClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderAutoComplete() {
    const disabled =
      !this.props.selectedSuborg || this.props.isSuborgMoveInProgress;

    // Handle case of too many results found.
    const numResults = this.props.searchResults.length;
    const label =
      this.props.searchResultsTotal > numResults
        ? 'More results available than currently displayed. Please narrow your search.'
        : 'Suborganization name';

    const userHint = (
      <span>
        Start typing a name of a suborganization (min 3 characters).
        <br />
        <a href={`${window.location.origin}/#/organizations`} target="_blank">
          See all organizations
        </a>
      </span>
    );

    return (
      <Dialog fullWidth maxWidth="sm" open={this.props.isOpen}>
        <DialogTitle>Add an existing suborganization</DialogTitle>

        <DialogContent>
          {this.props.error && (
            <Notification
              message={this.props.error}
              title="An error occurred while searching for or adding a suborganization"
              type="error"
            />
          )}

          <Autocomplete
            label={label}
            loadingText={this.props.name}
            onInputChange={name => this.props.onSuborgNameChange(name)}
            onInputCompleted={this._requestSuborgsByName}
            onChange={(_, organization) =>
              this.props.onSelectSuborg(organization)
            }
            options={this.props.searchResults ?? []}
            userHintBottomText={userHint}
          />
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={this.props.onClose}>
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={disabled}
            onClick={!disabled ? this.props.onSuborgMoveRequest : null}
            variant="contained"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return this.props.isSuborgMoved
      ? this._renderSuccessDialog()
      : this._renderAutoComplete();
  }
}

SuborgMove.propTypes = {
  error: string,
  isOpen: bool.isRequired,
  isSuborgMoveInProgress: bool.isRequired,
  isSuborgMoved: bool.isRequired,
  name: string.isRequired,
  onClose: func.isRequired,
  onExternalSuborgsRequest: func.isRequired,
  onSelectSuborg: func.isRequired,
  onSuborgMoveRequest: func.isRequired,
  onSuborgNameChange: func.isRequired,
  searchResults: array.isRequired,
  searchResultsTotal: number.isRequired,
  selectedSuborg: object,
};
