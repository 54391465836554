import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import React, {Component} from 'react';
import {func, object} from 'prop-types';

import FormCard from 'admin-ng/components/common/form-card';
import Item from './toi-item';
import TextField from 'admin-ng/components/common/text-field';

class ToiConstraint extends Component {
  static propTypes = {
    onAddTOI: func.isRequired,
    onDeleteTOI: func.isRequired,
    onUpdateTOI: func.isRequired,
    policy: object.isRequired,
  };

  constructor(props) {
    super(props);

    this._handleDelayChange = this._handleDelayChange.bind(this);
  }

  _handleDelayChange(value) {
    // Only allow digits or an empty string as input value
    const num = parseInt(value.replace(/[^0-9]/g, ''));
    if (value.length >= 1 && isNaN(num)) {
      return;
    }

    const params = {
      type: 'delay',
      value: num >= 0 ? num : '',
    };

    this.props.onUpdateTOI(null, params);
  }

  _isItemValueChanged(index, type) {
    const originalToi = this.props.policy.toi.data[index];
    const newToi = this.props.policy.toi.ui[index];

    if (
      !originalToi.start &&
      !originalToi.end &&
      !originalToi.hasNoStartDate &&
      !originalToi.hasNoEndDate
    ) {
      return false; // Toi is new, so never indicate the value has changed
    }

    const initialDate = newToi[type] ? newToi[type].toString() : null;
    const newItemDate = originalToi[type] ? originalToi[type].toString() : null;

    return initialDate !== newItemDate;
  }

  _renderItems() {
    const {
      onDeleteTOI,
      onUpdateTOI,
      policy: {
        toi: {ui: items},
      },
    } = this.props;
    const canDelete = items.length > 1;
    return items.map((item, index) => (
      <Item
        canDelete={canDelete}
        isEndChanged={this._isItemValueChanged(index, 'end')}
        isStartChanged={this._isItemValueChanged(index, 'start')}
        item={item}
        // eslint-disable-next-line react/no-array-index-key
        key={`toi-${index}`}
        onDeleteTOI={onDeleteTOI.bind(this, index)}
        onUpdateTOI={onUpdateTOI.bind(this, index)}
        qaId={`policy-toi-${index}`}
      />
    ));
  }

  _isDelayValueChanged() {
    const toi = this.props.policy.toi;
    return toi.delay !== toi.originalDelay;
  }

  _renderDelay() {
    const isSeries = this.props.policy.itemType.ui.some(itemType => {
      return itemType.category === 'series';
    });

    if (isSeries) {
      return;
    }

    return (
      <div className="pl-toi-delay clearfix">
        <div>
          Number of days to delay availability from most recent acquisition
        </div>
        <div className="pl-toi-delay--value" key={1}>
          <TextField
            isChanged={this._isDelayValueChanged()}
            onChange={this._handleDelayChange}
            style={{marginBottom: 10, width: 'inherit'}}
            value={this.props.policy.toi.delay}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="pl-data-constraint-section">
        <FormCard
          iconElementRight={
            <IconButton
              color="inherit"
              data-qa-id="add-toi-button"
              onClick={this.props.onAddTOI}
            >
              <AddIcon />
            </IconButton>
          }
          title="Time of Interest"
        >
          {this._renderItems()}
          {this._renderDelay()}
        </FormCard>
      </div>
    );
  }
}

export default ToiConstraint;
