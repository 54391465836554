import React from 'react';
import {object} from 'prop-types';

import QuotaUsage from 'admin-ng/components/common/subscription-details/quota-usage';
import {formatLargeNumber} from 'admin-ng/util/formatting';
import {getPrimaryText} from 'admin-ng/components/common/subscription-details/tile-quota/intervals';
import {infiniteQuotaCap} from '../quota/reducer';

const TileQuotaCell = props => {
  const tq = props.tileQuota;
  const unlimited = tq && tq.limit === infiniteQuotaCap;
  const disabled = tq && tq.limit === 0;

  if (tq === null || disabled) {
    return <span>-</span>;
  }
  return (
    <div>
      {!unlimited && (
        <span>{`${formatLargeNumber(tq.current)} / ${formatLargeNumber(tq.limit)}`}</span>
      )}
      {unlimited
        ? 'Unlimited '
        : tq.limit && (
            <QuotaUsage total={tq.limit} units="tiles" usage={tq.current} />
          )}
      <span>{getPrimaryText(tq.refresh)}</span>
    </div>
  );
};

TileQuotaCell.propTypes = {
  tileQuota: object,
};

export default TileQuotaCell;
