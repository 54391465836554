import * as constants from './constants';
import * as messages from './messages';
import {PLAN_ID_IS_MISSING_ERROR_MSG} from '../../../plan-common/messages';
import {all as allOrgs} from 'admin-ng/api/organizations';
import {all as allSubscriptions} from 'admin-ng/api/subscriptions';

export const requestOrgs = (params = {}) => ({
  type: constants.REQUEST_ORGS,
  payload: params,
});

export const receiveOrgs = (json = {}) => ({
  type: constants.REQUEST_ORGS_SUCCESS,
  payload: {
    items: json.results || [], // a set of orgs, limited by paging parameters
    itemsCount: json.meta && json.meta.count ? json.meta.count : 0, // the total number of all orgs in the db
  },
});

export const requestSubscriptionsSuccess = subscribedOrgIds => ({
  type: constants.REQUEST_SUBSCRIPTIONS_SUCCESS,
  payload: subscribedOrgIds,
});

export const requestSubscriptionsFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_SUBSCRIPTIONS_FAILED,
  payload: error,
  error: true,
});

export const updateSelectedOrgs = items => ({
  type: constants.UPDATE_SELECTED_ORGS,
  payload: items,
});

export const requestOrgsFailed = error => ({
  type: constants.REQUEST_ORGS_FAIL,
  payload: error,
  error: true,
});

export const requestSubscriptions = params => ({
  type: constants.REQUEST_SUBSCRIPTIONS,
  payload: params,
});

export const orgsSelectNotCompleted = () => ({
  type: constants.SELECTED_ORGS_EMPTY,
  payload: messages.NO_ORGS_SELECTED_MESSAGE,
});

const filterOrgIds = subscriptions => {
  const orgIds = subscriptions.map(
    subscription => subscription.organization_id
  );

  return orgIds.join(',');
};

export const loadSubscriptions = (dispatch, planId) => {
  if (!planId) {
    return dispatch(requestSubscriptionsFailed(PLAN_ID_IS_MISSING_ERROR_MSG));
  }

  const params = {
    plan_id: planId,
    state__in: 'active,inactive',
  };

  dispatch(requestSubscriptions(params));

  return allSubscriptions(params)
    .then((res = {}) => {
      const subscriptions = res.body && res.body.results;
      const subscribedOrgIds = filterOrgIds(subscriptions);

      return dispatch(requestSubscriptionsSuccess(subscribedOrgIds));
    })
    .catch((err = {}) => {
      return dispatch(requestSubscriptionsFailed(err.message));
    });
};

const fetchFilteredOrgs =
  (params = {}) =>
  dispatch =>
    allOrgs(params)
      .then((res = {}) => {
        return dispatch(receiveOrgs(res.body));
      })
      .catch((err = {}) => {
        return dispatch(
          requestOrgsFailed(err.message || messages.DEFAULT_ERROR_MSG)
        );
      });

export const fetchOrgs = (planId, params = {}) => {
  return (dispatch, getState) => {
    let state = getState();
    let selectOrgsState =
      state.plans.instance.subscriptions.bulkCreate.selectOrgs;
    const subscribedOrgIds = selectOrgsState.subscribedOrgIds;

    dispatch(requestOrgs(params));

    const requestParams = Object.assign(
      {},
      selectOrgsState.viewParams,
      params,
      {
        state__in: 'active',
      }
    );

    if (subscribedOrgIds) {
      requestParams.id__notin = subscribedOrgIds;

      dispatch(fetchFilteredOrgs(requestParams));
    } else {
      return loadSubscriptions(dispatch, planId)
        .then(() => {
          state = getState();
          selectOrgsState =
            state.plans.instance.subscriptions.bulkCreate.selectOrgs;
          requestParams.id__notin = selectOrgsState.subscribedOrgIds;

          dispatch(fetchFilteredOrgs(requestParams));
        })
        .catch((err = {}) => {
          dispatch(
            requestOrgsFailed(err.message || messages.DEFAULT_ERROR_MSG)
          );
        });
    }
  };
};
