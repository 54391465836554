export const REQUEST_ORG_DETAILS = 'REQUEST_ORG_DETAILS';
export const RECEIVE_ORG_DETAILS = 'RECEIVE_ORG_DETAILS';
export const ORG_DETAILS_REQUEST_FAILED = 'ORG_DETAILS_REQUEST_FAILED';

export const REQUEST_API_KEY = 'REQUEST_API_KEY';
export const RECEIVE_API_KEY = 'RECEIVE_API_KEY';
export const API_KEY_REQUEST_FAILED = 'API_KEY_REQUEST_FAILED';

export const API_KEY_RESET_REQUESTED = 'API_KEY_RESET_REQUESTED';
export const API_KEY_RESET_COMPLETED = 'API_KEY_RESET_COMPLETED';
export const API_KEY_RESET_FAILED = 'API_KEY_RESET_FAILED';

export const REQUEST_RESET_API_KEY = 'REQUEST_RESET_API_KEY';
export const RESET_API_KEY_REQUEST_FAILED = 'RESET_API_KEY_REQUEST_FAILED';
export const RESET_API_KEY_REQUEST_COMPLETED =
  'RESET_API_KEY_REQUEST_COMPLETED';

export const EXPORT_USAGE_START = 'EXPORT_USAGE_START';
export const EXPORT_USAGE_SUCCESS = 'EXPORT_USAGE_SUCCESS';
export const EXPORT_USAGE_FAIL = 'EXPORT_USAGE_FAIL';
