import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import PlanSubscriptionsIndex from './plan-subscriptions-index';
import {ROLES} from 'admin-ng/constants';
import {fetchSubscriptions} from './actions';

const mapStateToProps = (
  {
    plans: {
      instance: {
        subscriptions: {index},
      },
    },
  },
  {
    auth: {
      user: {
        claims: {role_level},
      },
    },
  }
) => {
  return {
    ...index,
    isPlanetAdmin: role_level === ROLES.planetAdmin,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onDataRequest: params =>
    dispatch(fetchSubscriptions(props.match.params.planId, params)),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(PlanSubscriptionsIndex)
);
