import * as constants from './constants';
import * as messages from './messages';
import {all, del} from 'admin-ng/api/plans';

export const requestPlans = params => {
  return {
    type: constants.REQUEST_PLANS,
    params,
  };
};

export const receivePlans = (json = {}) => {
  return {
    type: constants.RECEIVE_PLANS,
    items: json.results || [],
    itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
  };
};

export const requestPlansFailed = (error = messages.DEFAULT_ERROR_MSG) => {
  return {
    type: constants.PLANS_REQUEST_FAILED,
    error,
  };
};

export const fetchPlans =
  (params = {}) =>
  (dispatch, getState) => {
    const plansIndexState = getState().plans.index;

    dispatch(requestPlans(params));

    const requestParams = Object.assign({}, plansIndexState.viewParams, params);
    // remove empty search
    if (requestParams.hasOwnProperty('search') && !requestParams.search) {
      delete requestParams.search;
    }

    if (requestParams.filters) {
      requestParams.state__in = requestParams.filters;
    }
    delete requestParams.filters;

    if (requestParams.excludeOneOrgPlans) {
      requestParams.organizations_count__gt = 1;
    }
    delete requestParams.excludeOneOrgPlans;

    return all(requestParams)
      .then((res = {}) => {
        dispatch(receivePlans(res.body));
      })
      .catch((err = {}) => {
        dispatch(requestPlansFailed(err.message));
      });
  };

export const requestPlanDelete = id => {
  return {
    type: constants.DELETE_PLAN_REQUESTED,
    id,
  };
};

export const deletePlanCompleted = () => {
  return {
    type: constants.DELETE_PLAN_REQUEST_COMPLETED,
  };
};

export const deletePlanFailed = (error = messages.DEFAULT_ERROR_MSG) => {
  return {
    type: constants.DELETE_PLAN_REQUEST_FAILED,
    error,
  };
};

export const deletePlan = id => dispatch => {
  dispatch(requestPlanDelete(id));

  return del(id)
    .then(() => {
      dispatch(deletePlanCompleted());
      dispatch(fetchPlans());
    })
    .catch((err = {}) => {
      dispatch(deletePlanFailed(err.message));
    });
};

export const resetViewState = () => ({
  type: constants.RESET_PLANS_VIEW_STATE,
});

export const resetView = () => dispatch => {
  dispatch(resetViewState());
  return dispatch(fetchPlans());
};

export const toggleOneOrgPlansFilter = () => ({
  type: constants.PLANS_TOGGLE_ONE_ORG_PLANS,
});

export const toggleOneOrgPlans = () => dispatch => {
  dispatch(toggleOneOrgPlansFilter());
  return dispatch(fetchPlans());
};
