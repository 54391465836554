import React from 'react';
import {bool, func, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import TextField from 'admin-ng/components/common/text-field';

const PlanCreateGeneralContainer = props => (
  <div className="pl-plan-create-page">
    <Card title="General" divider>
      <TextField
        qaId="policy-name"
        error={!!props.planNameError}
        helperText={props.planNameError}
        label="Package Name *"
        isInputValid={props.isPlanNameUnique}
        onChange={props.onPlanNameUpdate}
        onChangeCompleted={props.onPlanNameCheck}
        value={props.planName}
      />
    </Card>
  </div>
);

PlanCreateGeneralContainer.propTypes = {
  isPlanNameUnique: bool,
  onPlanNameCheck: func.isRequired,
  onPlanNameUpdate: func.isRequired,
  planName: string.isRequired,
  planNameError: string,
};

export default PlanCreateGeneralContainer;
