import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import React, {Component} from 'react';
import Stack from '@mui/material/Stack';
import {func, node, oneOfType, string} from 'prop-types';

import TextField from 'admin-ng/components/common/text-field';

class PlAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isResultsListEnabled: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.value &&
      nextProps.value.length > 2 &&
      this.props.value !== nextProps.value
    ) {
      this.setState({
        isResultsListEnabled: true,
      });
    }
  }

  render() {
    // Remove props we don't want to pass on to the MUI component when we spread
    const {
      error: _error, // passed to TextField
      helperText: _helperText, // passed to TextField
      label: _label, // passed to TextField
      onInputChange: _onInputChange, // passed to TextField
      onInputCompleted: _onInputCompleted, // passed to TextField
      qaId: _qaId, // renamed
      userHintBottomText: _userHintBottomText, // used by FormHelperText
      ...strippedProps
    } = this.props;

    return (
      <Stack>
        <Autocomplete
          getOptionKey={option => `${option?.name}-${option?.id}`}
          getOptionLabel={option => option?.name}
          fullWidth
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          loadingText="Searching..."
          noOptionsText="No results"
          openOnFocus
          ref="autocomplete"
          renderInput={params => (
            <TextField
              {...params}
              error={this.props.error}
              helperText={this.props.helperText}
              label={this.props.label}
              onChange={this.props.onInputChange}
              onChangeCompleted={this.props.onInputCompleted}
              qaId={this.props.qaId}
            />
          )}
          sx={{
            '& .pl-text-field': {
              margin: '0 !important', // Have to override inline styles
            },
          }}
          {...strippedProps}
        />

        <FormHelperText className="pl-field-instructions">
          {this.props.userHintBottomText}
        </FormHelperText>
      </Stack>
    );
  }
}

PlAutocomplete.propTypes = {
  ...Autocomplete.propTypes,
  onInputCompleted: func,
  renderInput: func, // override and make it not required
  userHintBottomText: oneOfType([node, string]),
};

export default PlAutocomplete;
