export function download(content, filename, fileOptions) {
  const blob = new Blob(content, fileOptions);
  if (window.navigator.msSaveOrOpenBlob) {
    // IE 10+
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Other browsers
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = filename;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// we create a blob and then turn it into a file here because PhantomJS coughs
// on File API: https://github.com/ariya/phantomjs/issues/14247
export function create(content, filename, fileOptions) {
  const blob = new Blob(content, fileOptions);
  blob.lastModifiedDate = new Date();
  blob.name = filename;
  return blob;
}
