import * as constants from './constants';
import * as messages from './messages';
import {all, create} from 'admin-ng/api/organizations';
import {fetchOrgs} from '../orgs-index/actions';
import {isValidSfdc} from 'admin-ng/components/utils';

export const updateOrgName = (orgName, error = null) => ({
  type: constants.UPDATE_ORG_NAME,
  orgName,
  error,
});

export const requestOrgNameCheck = () => ({
  type: constants.REQUEST_ORG_NAME_CHECK,
});

export const completeOrgNameCheck = (isOrgNameUnique, error = null) => ({
  type: constants.COMPLETE_ORG_NAME_CHECK,
  isOrgNameUnique,
  error,
});

export const updateOrgSfdc = (sfdc, error = null) => ({
  type: constants.UPDATE_ORG_SFDC,
  sfdc: String(sfdc),
  error,
});

export const checkOrgSfdc = sfdc => dispatch => {
  if (!isValidSfdc(sfdc)) {
    dispatch(updateOrgSfdc(sfdc, messages.SFDC_NOT_VALID));
  } else {
    dispatch(updateOrgSfdc(sfdc));
  }
};

export const updateOrgProgram = (programId, error = null) => ({
  type: constants.UPDATE_ORG_PROGRAM,
  programId,
  error,
});

export const submitNewOrg = (params = null) => ({
  type: constants.SUBMIT_NEW_ORG,
  params,
});

export const completeNewOrgSubmit = (id = null, additionalMessage = null) => ({
  type: constants.COMPLETE_NEW_ORG_SUBMIT,
  id,
  additionalMessage,
});

export const newOrgSubmitFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.NEW_ORG_SUBMIT_FAILED,
  error,
});

export const resetOrgCreateState = () => ({
  type: constants.RESET_ORG_CREATE_STATE,
});

export function checkOrgName(orgName) {
  return function (dispatch) {
    const params = {
      parent_organization_id__isnull: 1,
      name__ieq: orgName,
      state__in: 'active,disabled',
    };

    dispatch(requestOrgNameCheck());

    return all(params)
      .then((res = {}) => {
        const items = res.body && res.body.results ? res.body.results : [];

        if (items) {
          if (items.length) {
            dispatch(
              completeOrgNameCheck(false, messages.ORG_NAME_IS_NOT_UNIQUE_MSG)
            );
          } else {
            dispatch(completeOrgNameCheck(true));
          }
        } else {
          dispatch(completeOrgNameCheck(false, messages.DEFAULT_ERROR_MSG));
        }
      })
      .catch((err = {}) => {
        dispatch(
          completeOrgNameCheck(false, err.message || messages.DEFAULT_ERROR_MSG)
        );
      });
  };
}

export const createNewOrg = () => async (dispatch, getState) => {
  const {orgName, orgProgramId, isOrgNameUnique, sfdc, orgSfdcError} =
    getState().orgs.create;
  if (!orgName) {
    dispatch(updateOrgName('', messages.ORG_NAME_IS_NOT_SPECIFIED_MSG));
  }
  if (!orgProgramId) {
    dispatch(updateOrgProgram(null, messages.ORG_PROGRAM_IS_NOT_SPECIFIED_MSG));
  }
  if (!orgName || !orgProgramId || !isOrgNameUnique || orgSfdcError) {
    return;
  }
  const params = {
    name: orgName,
    program: {id: orgProgramId},
    sfdc: sfdc === '' ? null : sfdc,
    state: 'active',
  };
  dispatch(submitNewOrg(params));
  try {
    const {
      body: {id, additional_message: additionalMessage},
    } = await create(params);
    dispatch(completeNewOrgSubmit(id, additionalMessage));
    dispatch(fetchOrgs());
  } catch (err) {
    dispatch(newOrgSubmitFailed(err.message));
  }
};
