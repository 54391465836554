import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import capitalize from 'lodash/capitalize';
import {object} from 'prop-types';

import QuotaUsage from 'admin-ng/components/common/subscription-details/quota-usage';
import {formatLargeNumber, localizeNumber} from 'admin-ng/util/formatting';
import {getPrimaryText} from 'admin-ng/components/common/subscription-details/quota/intervals';
import {infiniteQuotaCap} from '../quota/reducer';

const SceneQuotaCell = props => {
  const unlimited = props.sub.quota_sqkm === infiniteQuotaCap;

  const {sub, usages} = props;
  if (!sub.quota_enabled || !sub.quota_sqkm) {
    return <span>-</span>;
  }

  const record = usages.results
    ? usages.results.find(u => u.id === sub.id)
    : usages;
  const usage = (record && record.used) || 0;
  const error = record && record.status;

  if (error) {
    return (
      <Box alignContent="center" display="flex">
        <Tooltip title="Error fetching used quota">
          <ErrorOutlineIcon
            color="error"
            fontSize="small"
            style={{marginRight: 4}}
          />
        </Tooltip>
        <Typography color="error">Error</Typography>
      </Box>
    );
  }

  return (
    <div>
      {!unlimited && (
        <span title={`${localizeNumber(sub.quota_sqkm)} km²`}>
          {`${formatLargeNumber(usage)} / ${formatLargeNumber(sub.quota_sqkm)} km²`}
        </span>
      )}
      {unlimited ? (
        'Unlimited '
      ) : (
        <QuotaUsage total={sub.quota_sqkm} usage={usage} />
      )}
      <span>{getPrimaryText(sub.quota_interval)}</span>
      {sub.quota_style && capitalize(sub.quota_style)}
    </div>
  );
};

SceneQuotaCell.propTypes = {
  sub: object,
  usages: object,
};

export default SceneQuotaCell;
