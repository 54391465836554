import Raven from 'raven-js';

const ERROR_TRACKING_URL = import.meta.env.VITE_SENTRY_URL;
const release = '' + import.meta.env.VITE_VERSION;
const environment = '' + import.meta.env.ENVIRONMENT;
const git_commit = '' + import.meta.env.CI_COMMIT_HASH;

export default () => {
  if (ERROR_TRACKING_URL) {
    Raven.config(ERROR_TRACKING_URL, {
      release,
      environment,
      tags: {git_commit},
    }).install();
  }
};
