export const ORG_MEMBERS_REQUEST = 'ORG_MEMBERS_REQUEST';
export const ORG_MEMBERS_SUCCESS = 'ORG_MEMBERS_SUCCESS';
export const ORG_MEMBERS_FAIL = 'ORG_MEMBERS_FAIL';

export const ORG_MEMBERS_RESET_VIEW_STATE = 'ORG_MEMBERS_RESET_VIEW_STATE';

export const ORG_MEMBERS_INVITATION_DELETE_REQUEST =
  'ORG_MEMBERS_INVITATION_DELETE_REQUEST';
export const ORG_MEMBERS_INVITATION_SUCCESS = 'ORG_MEMBERS_INVITATION_SUCCESS';
export const ORG_MEMBERS_INVITATION_FAIL = 'ORG_MEMBERS_INVITATION_FAIL';
