import * as constants from './constants';

const initial = {
  error: null,
  organizations: [],
  isProgramOrganizationsRequestPending: false,
  viewParams: {
    filters: 'active,disabled',
    limit: 20,
    offset: 0,
    sort: '-id',
    search: '',
  },
};

export default function programOrganizationsReducer(
  state = initial,
  action = {}
) {
  switch (action.type) {
    case constants.REQUEST_PROGRAM_ORGANIZATIONS:
      return {
        ...state,
        error: null,
        isProgramOrganizationsRequestPending: true,
        viewParams: {...state.viewParams, ...action.payload},
      };

    case constants.REQUEST_PROGRAM_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        isProgramOrganizationsRequestPending: false,
        organizations: action.payload.items,
        organizationsTotalCount: action.payload.itemsTotalCount,
      };

    case constants.REQUEST_PROGRAM_ORGANIZATIONS_FAILED:
      return {
        ...state,
        error: action.payload,
        organizations: null,
        isProgramOrganizationsRequestPending: false,
      };

    case constants.RESET:
      return {...initial};

    default:
      return state;
  }
}
