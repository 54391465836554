// Note: it's more complicated than simply adding a value here
// Must also:
// - handle normalize round-tripping
// - add to allowed choices in schema
// - add logic to handle resetting (to first of month, for example)
export const intervals = [
  {label: 'Monthly (on 1st)', primaryText: 'Monthly', value: '1 month'},
  {label: 'Access Period', primaryText: 'Access Period', value: 'accessPeriod'},
];

const normalize = refresh => {
  if (!refresh) {
    return null;
  }
  const days = refresh.split(',')[0];
  if (days === '30 days') {
    return '1 month';
  } else if (days === '90 days') {
    return '3 months';
  } else if (days === '365 days') {
    return '1 year';
  }
  return days;
};

export const normalizeRefresh = normalize;

export const getPrimaryText = val => {
  const key = normalize(val);
  const quotaValue = key === null ? 'accessPeriod' : key;
  const interval = intervals.find(interval => interval.value === quotaValue);
  return interval ? interval.primaryText : '';
};
