import * as constants from './constants';

const initial = {
  error: null,
  names: '',
  groups: [],
  isWithCanada: true,
  isWithUSA: true,
  isWithEU: true,
};

export default (state = initial, {type, payload} = {}) => {
  switch (type) {
    case constants.UPDATE_NAMES:
      return {
        ...state,
        names: payload,
        error: initial.error,
      };

    case constants.TOGGLE_CANADA:
      return {...state, isWithCanada: !state.isWithCanada};

    case constants.TOGGLE_USA:
      return {...state, isWithUSA: !state.isWithUSA};

    case constants.TOGGLE_EU:
      return {...state, isWithEU: !state.isWithEU};

    case constants.CHECK_NAMES_FAIL:
      return {...state, error: payload};

    case constants.SET_GROUPS: {
      const groups = payload
        .split(/\n/)
        .filter(name => name)
        .filter((item, index, items) => items.indexOf(item) === index)
        .map(name => ({
          name,
          isLoading: false,
          results: [],
        }));
      return {...state, groups};
    }

    case constants.CHECK_START: {
      const {index} = payload;
      return {
        ...state,
        groups: state.groups.map((group, idx) =>
          idx === index ? {...group, isLoading: true} : group
        ),
      };
    }

    case constants.CHECK_SUCCESS: {
      const {index, results} = payload;
      return {
        ...state,
        groups: state.groups.map((group, idx) =>
          idx === index ? {...group, isLoading: false, results} : group
        ),
      };
    }

    case constants.CHECK_FAIL: {
      const {index, error} = payload;
      return {
        ...state,
        groups: state.groups.map((group, idx) =>
          idx === index ? {...group, isLoading: false, error} : group
        ),
      };
    }

    case constants.RESET:
      return {...initial};

    default:
      return state;
  }
};
