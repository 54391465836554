import * as constants from './constants';
import * as messages from './messages';
import {fetchProgramDetails} from '../program-general/actions';
import {all as getDocuments} from 'admin-ng/api/documents';
import {all as getPrograms, update} from 'admin-ng/api/programs';

export const initializeDetails = (details = {}) => ({
  type: constants.INITIALIZE_DETAILS,
  id: details.id,
  name: details.name,
  description: details.description || '',
  inviteEmailText: details.invite_email_text || '',
  documents: details.documents || [],
});

export const updateName = name => ({
  type: constants.UPDATE_NAME,
  name,
});

export const updateDescription = description => ({
  type: constants.UPDATE_DESCRIPTION,
  description,
});

export const updateInviteEmailText = inviteEmailText => ({
  type: constants.UPDATE_INVITE_EMAIL_TEXT,
  inviteEmailText,
});

export const updateDocuments = documents => ({
  type: constants.UPDATE_DOCUMENTS,
  documents,
});

export const requestNameCheck = (params = {}) => ({
  type: constants.REQUEST_NAME_CHECK,
  params,
});

export const requestNameCheckSuccess = isNameUnique => ({
  type: constants.REQUEST_NAME_CHECK_SUCCESS,
  isNameUnique,
});

export const requestNameCheckFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.REQUEST_NAME_CHECK_FAIL,
  error,
});

export function checkName(name) {
  return function (dispatch, getState) {
    const state = getState().programs.edit;
    const params = {
      name__ieq: name,
      id__ne: state.id,
    };

    dispatch(requestNameCheck(params));

    return getPrograms(params)
      .then((res = {}) => {
        if (res.body && res.body.results) {
          if (res.body.results.length >= 1) {
            dispatch(requestNameCheckSuccess(false));
          } else {
            dispatch(requestNameCheckSuccess(true));
          }
        } else {
          dispatch(requestNameCheckFail());
        }
      })
      .catch((err = {}) => {
        dispatch(requestNameCheckFail(err.message));
      });
  };
}

export const requestDocumentSearch = (params = {}) => ({
  type: constants.REQUEST_DOCUMENT_SEARCH,
  params,
});

export const requestDocumentSearchSuccess = documents => ({
  type: constants.REQUEST_DOCUMENT_SEARCH_SUCCESS,
  documents,
});

export const requestDocumentSearchFail = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_DOCUMENT_SEARCH_FAIL,
  error,
});

export const searchDocuments = name => dispatch => {
  const params = {
    fields: 'id,name',
    search: name,
  };

  dispatch(requestDocumentSearch(params));

  return getDocuments(params)
    .then(response =>
      dispatch(requestDocumentSearchSuccess(response.body.results))
    )
    .catch((error = {}) => dispatch(requestDocumentSearchFail(error.message)));
};

export const requestSave = (params = {}) => ({
  type: constants.REQUEST_SAVE,
  params,
});

export const requestSaveSuccess = () => ({
  type: constants.REQUEST_SAVE_SUCCESS,
});

export const requestSaveFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.REQUEST_SAVE_FAIL,
  error,
});

export function saveProgram(history) {
  return function (dispatch, getState) {
    const state = getState().programs.edit;
    const params = {
      name: state.name,
      description: state.description,
      invite_email_text: state.inviteEmailText,
      documents: state.documents.map(doc => doc.id),
    };

    dispatch(requestSave(params));

    return update(state.id, params)
      .then(() => {
        dispatch(requestSaveSuccess());
        dispatch(fetchProgramDetails(state.id, history));
      })
      .catch((err = {}) => {
        dispatch(requestSaveFail(err.message));
      });
  };
}

export const resetState = () => ({
  type: constants.RESET_STATE,
});
