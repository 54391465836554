export const DEFAULT_ERROR_MSG = 'Something went wrong';
export const MEMBER_ID_IS_NOT_SPECIFIED_ERROR_MSG =
  'Member id is not specified';
export const EMAIL_IS_NOT_UNIQUE_ERROR_MSG = 'This email is already a member';
export const EMAIL_IS_NOT_SPECIFIED_ERROR_MSG = 'Please provide user email';
export const FIRST_NAME_NOT_SPECIFIED_ERROR_MSG =
  'Please provide user first name';
export const LAST_NAME_NOT_SPECIFIED_ERROR_MSG =
  'Please provide user last name';
export const EMAIL_IS_INVALID_ERROR_MSG = 'Email is not valid';
export const ORG_ID_IS_NOT_SPECIFIED_ERROR_MSG =
  'Organization id is not specified';
export const USER_ID_IS_NOT_SPECIFIED_ERROR_MSG = 'User id is not specified';
export const NEW_ORG_ID_IS_NOT_SPECIFIED_ERROR_MSG =
  'New organization id is not specified';
export const COUNTRY_NOT_SPECIFIED_ERROR_MSG =
  'Country is not specified (Can not be unset)';
export const USER_NOT_FOUND =
  'We are sorry, but the member you are looking for does not exist.';
export const PASSWORDS_DONT_MATCH = "Passwords don't match";
export const PLEASE_SUPPLY_A_PHONE_NUMBER = 'Please supply a phone number';
export const PHONE_NUMBER_MUST_BE_BETWEEN_CHARACTERS =
  'Phone number must be a number between 5 and 20 digits long';
