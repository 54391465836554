import * as constants from './constants';
import {REQUEST_PROGRAMS_FAIL} from 'admin-ng/components/programs/programs-index/constants';

const initialState = {
  additionalMessage: null,
  isCheckingOrgName: false,
  isNewOrgCreated: null,
  isNewOrgRequestSending: false,
  isOrgNameUnique: null,

  orgName: '',
  orgNameError: null,
  orgProgramError: null,
  orgProgramId: null,
  orgCreateError: null,
};

export default function orgCreateReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.UPDATE_ORG_NAME:
      return {
        ...state,
        orgName: action.orgName,
        orgNameError: action.error,
      };

    case constants.REQUEST_ORG_NAME_CHECK:
      return {
        ...state,
        isCheckingOrgName: true,
      };

    case constants.COMPLETE_ORG_NAME_CHECK:
      return {
        ...state,
        isOrgNameUnique: action.isOrgNameUnique,
        isCheckingOrgName: false,
        orgNameError: action.error,
      };

    case constants.UPDATE_ORG_PROGRAM:
      return {
        ...state,
        orgProgramId: action.programId,
        orgProgramError: action.error,
      };

    case constants.SUBMIT_NEW_ORG:
      return {
        ...state,
        isNewOrgRequestSending: true,
      };

    case constants.COMPLETE_NEW_ORG_SUBMIT:
      return {
        ...state,
        isNewOrgRequestSending: false,
        isNewOrgCreated: true,
        additionalMessage: action.additionalMessage,
        newOrgId: action.id,
      };

    case constants.NEW_ORG_SUBMIT_FAILED:
      return {
        ...state,
        isNewOrgRequestSending: false,
        isNewOrgCreated: false,
        orgCreateError: action.error,
      };

    case constants.RESET_ORG_CREATE_STATE:
      return {...initialState};

    case REQUEST_PROGRAMS_FAIL:
      return {
        ...state,
        orgProgramError: action.error,
      };

    case constants.UPDATE_ORG_SFDC:
      return {
        ...state,
        sfdc: action.sfdc,
        orgSfdcError: action.error,
      };

    default:
      return {...state};
  }
}
