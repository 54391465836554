import * as constants from './constants';
import {RESET_VIEW} from '../plan-subscription-bulk-create-general/constants';

const initial = {
  error: null,
  isBulkCreateSuccess: false,
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_BULK_CREATE:
      return {...state, error: null};

    case constants.REQUEST_BULK_CREATE_SUCCESS:
      return {
        ...state,
        error: null,
        isBulkCreateSuccess: true,
      };

    case constants.REQUEST_BULK_CREATE_FAILED:
      return {...state, error: action.payload};

    case RESET_VIEW:
      return {...initial};

    default:
      return state;
  }
};
