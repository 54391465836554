import React from 'react';
import {oneOf, string} from 'prop-types';

export default function RecordStatus(props) {
  const baseClass = 'pl-compliance-record-status';
  return (
    <span className={`${baseClass} ${baseClass}--${props.status}`}>
      {props.label}
    </span>
  );
}

RecordStatus.propTypes = {
  label: string.isRequired,
  status: oneOf(['success', 'warning', 'danger']).isRequired,
};
