import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import OrgSubscriptionsIndex from './org-subscriptions';
import {ROLES} from 'admin-ng/constants';
import {
  deleteSubscription,
  fetchPlans,
  fetchSubscriptions,
  reset,
} from './actions';
import {fetchOrgDetails} from '../../org-general/org-details/actions';

const mapStateToProps = (
  {
    orgs: {
      instance: {general: parentOrg, subscriptions},
    },
  },
  props
) => {
  const user = props.auth.user.claims;

  return {
    subscriptionsError: subscriptions.error,
    isDataRequestPending: subscriptions.isDataRequestPending,
    isDeleteRequestPending: subscriptions.isDeleteRequestPending,
    quotaUsages: subscriptions.quotaUsages,
    subscriptions: subscriptions.items,
    subscriptionsTotalCount: subscriptions.itemsTotalCount,
    viewParams: subscriptions.viewParams,
    parentOrg,
    plansTotalCount: subscriptions.plansTotalCount,
    isFetchingPlans: subscriptions.isFetchingPlans,
    plansError: subscriptions.plansError,
    params: props.match.params,
    isOwnOrgAdmin:
      user.role_level === ROLES.orgAdmin &&
      user.organization_id === parseInt(props.match.params.orgId),
    isPlanetAdmin: user.role_level === ROLES.planetAdmin,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: {orgId: rawOrgId},
    },
    auth: {
      user: {claims},
    },
  }
) => {
  const orgId = parseInt(rawOrgId);
  return {
    onMount: params => {
      dispatch(reset());
      dispatch(fetchSubscriptions(orgId, params));
      dispatch(fetchPlans(params));
    },
    onChange: params => dispatch(fetchSubscriptions(orgId, params)),
    onDelete: subscriptionId =>
      dispatch(deleteSubscription(orgId, subscriptionId, claims)),
    onParentOrgDataRequest: () => dispatch(fetchOrgDetails(orgId)),
  };
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(OrgSubscriptionsIndex)
);
