import {connect} from 'react-redux';

import * as actions from './actions';
import PlanCreateGeneral from './plan-create-general';

const mapStateToProps = state => state.plans.instance.general;
const mapDispatchToProps = dispatch => ({
  onPlanNameUpdate: name => dispatch(actions.updatePlanName(name)),
  onPlanNameCheck: () => dispatch(actions.checkPlanName()),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
})(PlanCreateGeneral);
