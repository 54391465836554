import * as constants from './constants';
import {all, del} from 'admin-ng/api/organizations';
import {authClient} from 'admin-ng/components/AuthStore';

const DEFAULT_ERROR_MSG = 'Something went wrong';

export const requestOrgs = (params = {}) => ({
  type: constants.REQUEST_ORGS,
  params,
});

export const receiveOrgs = (json = {}) => ({
  type: constants.RECEIVE_ORGS,
  items: json.results || [], // a set of orgs, limited by paging parameters
  itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0, // the total number of all orgs in the db
});

export const requestOrgsFailed = (error = DEFAULT_ERROR_MSG) => ({
  type: constants.ORGS_REQUEST_FAILED,
  error,
});

export const fetchOrgs = params => {
  return function (dispatch, getState) {
    const state = getState(),
      orgsIndexState = state.orgs.index;

    dispatch(requestOrgs(params));

    const requestParams = Object.assign({}, orgsIndexState.viewParams, params);

    // if search is empty, no need to send the parameter, otherwise it will activate
    // a redundant process on the back-end
    if (requestParams.hasOwnProperty('search') && !requestParams.search) {
      delete requestParams.search;
    }

    if (requestParams.filters) {
      if (~requestParams.filters.indexOf('enterprise')) {
        requestParams.filters = requestParams.filters.replace('enterprise', '');
        requestParams.program_id__eq = 3;
      }
      if (requestParams.filters.length) {
        requestParams.state__in = requestParams.filters;
      }
    }
    delete requestParams.filters;

    requestParams.id__ne = authClient.getState().user.claims.organization_id;

    return all(requestParams)
      .then((res = {}) => {
        dispatch(receiveOrgs(res.body));
      })
      .catch((err = {}) => {
        dispatch(requestOrgsFailed(err.message));
      });
  };
};

export const requestOrgDelete = id => ({
  type: constants.DELETE_ORG_REQUESTED,
  id,
});

export const deleteOrgCompleted = () => ({
  type: constants.DELETE_ORG_REQUEST_COMPLETED,
});

export const deleteOrgFailed = (error = DEFAULT_ERROR_MSG) => ({
  type: constants.DELETE_ORG_REQUEST_FAILED,
  error,
});

export const deleteOrg = id => dispatch => {
  dispatch(requestOrgDelete(id));

  return del(id)
    .then(() => {
      dispatch(deleteOrgCompleted());
      dispatch(fetchOrgs());
    })
    .catch((err = {}) => {
      dispatch(deleteOrgFailed(err.message));
    });
};

export const resetViewState = () => ({type: constants.RESET_ORGS_VIEW_STATE});

export const resetView = () => dispatch => {
  dispatch(resetViewState());
  return dispatch(fetchOrgs());
};
