import React, {Component} from 'react';
import countriesList from 'countries-list';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import DataState from 'admin-ng/components/common/data-state';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import Sidebar from 'admin-ng/components/common/sidebar';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import {DATE_FORMAT} from 'admin-ng/constants';

const getFirstMembership = memberships =>
  memberships && memberships.length >= 1 ? memberships[0] : false;

export default class Users extends Component {
  componentDidMount() {
    this.props.onMount();
  }

  get schema() {
    return [
      {
        field: 'email',
        sortKey: 'email',
        label: 'Email',
        width: '22%',
        contentCreator: ({email, state, memberships}) => {
          if (!email) {
            return '-';
          }
          if (state === 'deleted') {
            return email;
          } else {
            const membership = getFirstMembership(memberships);
            const to = membership
              ? `/organizations/${membership.organization_id}/members/${membership.id}`
              : '';
            return (
              <Link title={email} to={to}>
                {email}
              </Link>
            );
          }
        },
      },
      {
        field: 'first_name',
        sortKey: 'first_name',
        label: 'First Name',
        width: '8%',
        contentCreator: ({first_name}) => first_name,
      },
      {
        field: 'last_name',
        sortKey: 'last_name',
        label: 'Last Name',
        width: '8%',
        contentCreator: ({last_name}) => last_name,
      },
      {
        field: 'country',
        sortKey: 'country',
        label: 'Country',
        width: '12%',
        contentCreator: ({source, country: name}) => {
          if (source === 'invite') {
            return '-';
          }
          const country = countriesList.countries[name];
          return country && country.name ? country.name : name;
        },
      },
      {
        field: 'organization',
        isSortable: false,
        label: 'Organization',
        width: '10%',
        contentCreator: ({memberships}) => {
          const membership = getFirstMembership(memberships);
          if (membership) {
            return (
              <Link to={`/organizations/${membership.organization_id}`}>
                {membership.organization.name}
              </Link>
            );
          } else {
            return '';
          }
        },
      },
      {
        field: 'role',
        isSortable: false,
        label: 'Role',
        width: '14%',
        contentCreator: ({memberships}) => {
          const membership = getFirstMembership(memberships);
          return membership ? membership.role : '';
        },
      },
      {
        field: 'created_at',
        label: 'Created',
        width: '8%',
        contentCreator: ({created_at}) => {
          if (created_at) {
            const date = moment(created_at);
            const title = date.toISOString();
            const formatted = date.format(DATE_FORMAT);
            return <span title={title}>{formatted}</span>;
          }
          return '';
        },
      },
      {
        field: 'state',
        isSortable: false,
        label: 'State',
        width: '10%',
        contentCreator: ({state}) =>
          state ? (
            <div className="pl-item-state">
              <DataState state={state} />
            </div>
          ) : (
            '-'
          ),
      },
    ];
  }

  render() {
    const {
      error,
      filters: activeFilters,
      hasDeletedFilter,
      hasDownload,
      isFetching,
      limit,
      offset,
      onChange,
      sort,
      total,
      users,
    } = this.props;

    const actions = [];
    const filters = ['active', 'disabled'];

    if (hasDownload) {
      // Commenting this out for now because the API behind this is broken
      // https://hello.planet.com/code/product/web-admin-ng/-/merge_requests/752#note_4315022
      /*
      actions.push(
        <Button
          color="primary"
          data-qa-id="download-users-csv"
          disabled={isDownloading}
          startIcon={<DownloadIcon />}
          key={1}
          onClick={!isDownloading ? onDownload : null}
          variant="contained"
        >
          Download All (CSV)
        </Button>
      );
      */
    }

    if (hasDeletedFilter) {
      filters.push('deleted');
    }

    return (
      <div className="pl-users-page pl-flex-wrapper">
        <Sidebar />
        <div className="pl-app--content">
          <Card>
            <div className="pl-table-card-content">
              <TableControlsPanel
                actions={actions}
                activeFilters={activeFilters}
                filters={filters}
                itemsPerPageLimit={limit}
                onFilter={onChange}
              />
              <Notification
                message={error}
                title="Oops, users request has failed"
                type="error"
              />
              <Table
                className="pl-table-index"
                dataSchema={this.schema}
                isRequestPending={isFetching}
                onSort={onChange}
                qaId="users-table"
                sortBy={sort}
                srcData={users}
              />
              <Pagination
                itemsLimit={limit}
                itemsOffset={offset}
                itemsTotal={total}
                onPageChange={onChange}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  error: string,
  filters: string.isRequired,
  hasDeletedFilter: bool.isRequired,
  hasDownload: bool.isRequired,
  isFetching: bool.isRequired,
  limit: number.isRequired,
  offset: number.isRequired,
  onChange: func.isRequired,
  onMount: func.isRequired,
  sort: string.isRequired,
  total: number.isRequired,
  users: arrayOf(object).isRequired,
};

Users.defaultProps = {
  error: null,
};
