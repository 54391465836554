import React from 'react';

import ProgramsIndexContainer from './programs-index/programs-index-container';

const Programs = () => (
  <div className="pl-programs-page">
    <ProgramsIndexContainer />
  </div>
);

export default Programs;
