import FormHelperText from '@mui/material/FormHelperText';
import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import assign from 'deep-extend';
import classNames from 'classnames';
import {bool, func} from 'prop-types';

import {FormFieldStyles} from 'admin-ng/components/common/form-field-styles';

const styles = assign({}, FormFieldStyles);
const isFullWidth = true;
const DEBOUNCE_DELAY = 400;

// This class is stateful in order to allow refs to be attached to it. See /common/date-picker.
class PlTextField extends Component {
  handleChange = event => {
    if (!event.target) {
      return;
    }
    let value = event.target.value;

    this.props.onChange(value);

    if (this.props.onChangeCompleted) {
      clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        value = value.trim();

        if (value) {
          this.props.onChangeCompleted(value);
        }
      }, DEBOUNCE_DELAY);
    }
  };

  render() {
    // Remove props we don't want to pass on to the MUI component when we spread
    const {
      helperText: _helperText,
      isChanged: _isChanged,
      isInputValid: _isInputValid,
      onChangeCompleted: _onChangeCompleted,
      qaId: _qaId,
      required: bool,
      ...strippedProps
    } = this.props;

    const {className} = this.props;
    let {general, changed} = styles;

    if (this.props.style) {
      general = {...styles.general, ...this.props.style};
      changed = {...general, ...styles.changed};
    }

    const style = this.props.isChanged ? {...general, ...changed} : general;

    let classes = classNames({
      'pl-text-field': true,
      'pl-text-field--icon': true,
      'pl-text-field--icon-check': this.props.value && this.props.isInputValid,
      'pl-text-field--icon-fail': this.props.isInputValid === false,
    });

    if (className) {
      classes += ` ${className}`;
    }

    return (
      <>
        <TextField
          {...strippedProps}
          className={classes}
          inputProps={{...this.props.inputProps, 'data-qa-id': this.props.qaId}}
          fullWidth={isFullWidth}
          onChange={this.handleChange}
          style={style}
          variant="standard"
        />

        {_helperText && <FormHelperText>{_helperText}</FormHelperText>}
      </>
    );
  }
}

PlTextField.propTypes = {
  ...TextField.propTypes,
  isChanged: bool,
  isInputValid: bool,
  onChangeCompleted: func,
  required: bool,
};

PlTextField.defaultProps = {
  isChanged: false,
  isInputValid: true,
  onChangeCompleted: null,
  required: false,
};

export default PlTextField;
