import Button from '@mui/material/Button';
import React, {Component} from 'react';
import classNames from 'classnames';
import {func, number, object} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import CreateConfirmOrgs from '../plan-subscription-bulk-create-confirm-orgs';
import CreateSummary from '../plan-subscription-bulk-create-summary';
import SelectDetails from '../plan-subscription-bulk-create-select-details';
import SelectOrgs from '../plan-subscription-bulk-create-select-orgs';

export default class PlanSubscriptionBulkCreateGeneral extends Component {
  constructor(props) {
    super(props);

    this._onFinish = this._onFinish.bind(this);
  }

  componentWillMount() {
    this.props.onResetView();
  }

  _onFinish() {
    this.props.onResetView();
    this.props.history.push(
      `/packages/${this.props.match.params.planId}/plans`
    );
  }

  _renderSteps() {
    const steps = ['Plan Details', 'Organizations', 'Confirmation', 'Done'];

    let options = [],
      stepClassName;

    options = steps.map((step, i) => {
      stepClassName = classNames({
        active: this.props.step === i,
        completed: this.props.step > i,
      });

      return (
        // eslint-disable-next-line react/no-array-index-key
        <li className={stepClassName} key={i}>
          <span className="pl-wizard-step-number">{i + 1}</span>
          <span className="pl-wizard-step-label">{step}</span>
        </li>
      );
    });

    return options;
  }

  render() {
    return (
      <Card>
        <div className="pl-plan-wizard pl-plan-card-wizard">
          <ul className="pl-wizard-steps">{this._renderSteps()}</ul>

          <div className="pl-wizard-content">
            {this.props.step === 0 && <SelectDetails />}

            {this.props.step === 1 && (
              <SelectOrgs params={this.props.match.params} />
            )}

            {this.props.step === 2 && (
              <CreateConfirmOrgs params={this.props.match.params} />
            )}

            {this.props.step === 3 && (
              <CreateSummary params={this.props.match.params} />
            )}
          </div>

          <div className="pl-wizard-footer">
            {this.props.step === 3 ? (
              <Button
                color="primary"
                onClick={this._onFinish}
                variant="contained"
              >
                Finish
              </Button>
            ) : (
              <div>
                {this.props.step === 0 ? (
                  <Button
                    className="pl-wizard-previous-btn"
                    color="inherit"
                    data-qa-id="subscription-bulk-create-cancel"
                    onClick={this._onFinish}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    className="pl-wizard-previous-btn"
                    color="inherit"
                    data-qa-id="subscription-bulk-create-previous"
                    onClick={this.props.onOpenPrevious}
                  >
                    Previous
                  </Button>
                )}

                <Button
                  data-qa-id="subscription-bulk-create-next"
                  onClick={this.props.onOpenNext}
                  color="primary"
                  variant="contained"
                >
                  Next
                </Button>
              </div>
            )}
          </div>
        </div>
      </Card>
    );
  }
}

PlanSubscriptionBulkCreateGeneral.propTypes = {
  history: object.isRequired,
  match: object.isRequired,
  onOpenNext: func,
  onOpenPrevious: func,
  onResetView: func.isRequired,
  step: number,
};
