import assign from 'deep-extend';
import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import SuborgsIndex from './suborgs-index';
import {fetchOrgDetails} from '../../org-general/org-details/actions';
import {fetchSuborgs, suborgUnlink} from './actions';

const mapStateToProps = (state, {auth}) =>
  assign({}, state.orgs.instance.suborgs.index, {
    parentOrg: state.orgs.instance.general,
    userRoleLevel: auth.user.claims.role_level,
  });

const mapDispatchToProps = (dispatch, props) => ({
  onSuborgsRequest: params => {
    return dispatch(fetchSuborgs(props.match.params.orgId, params));
  },
  onParentOrgDataRequest: () =>
    dispatch(fetchOrgDetails(props.match.params.orgId)),
  onSuborgUnlink: suborg => dispatch(suborgUnlink(suborg)),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(SuborgsIndex)
);
