import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FolderIcon from '@mui/icons-material/Folder';
import React, {Component} from 'react';
import TextField from '@mui/material/TextField';
import {array, bool, func, object, string} from 'prop-types';

import Autocomplete from 'admin-ng/components/common/autocomplete';
import Notification from 'admin-ng/components/common/notification';

const chipStyles = {
  margin: 4,
};

const autoCompleteMenuCloseDelay = 300; // ms. Default value of material-ui's <Autocomplete>

export default class ProgramEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentName: '', // Search query for document names in autocomplete field
    };

    this._removeDocument = this._removeDocument.bind(this);
    this._selectDocument = this._selectDocument.bind(this);
    this._requestDocumentsByName = this._requestDocumentsByName.bind(this);
    this._renderSuccessDialog = this._renderSuccessDialog.bind(this);
    this._renderSelectedDocuments = this._renderSelectedDocuments.bind(this);
    this._isSubmitAllowed = this._isSubmitAllowed.bind(this);
    this._hasNameChanged = this._hasNameChanged.bind(this);
    this._hasDescriptionChanged = this._hasDescriptionChanged.bind(this);
    this._hasTextChanged = this._hasTextChanged.bind(this);
    this._hasDocumentsChanged = this._hasDocumentsChanged.bind(this);
    this._renderEditDialog = this._renderEditDialog.bind(this);
    this._onUpdateDocumentSearch = this._onUpdateDocumentSearch.bind(this);
  }

  _onUpdateDocumentSearch(documentName) {
    this.setState({documentName});
  }

  _requestDocumentsByName() {
    if (this.state.documentName && this.state.documentName.length > 2) {
      this.props.requestDocuments(this.state.documentName.trim());
    }
  }

  _selectDocument(event, document) {
    if (!document) return;

    if (!this.props.documents.find(id => id === document.id)) {
      this.props.updateDocuments([...this.props.documents, document]);
    }

    // Clear search text. Needs to happen after <Autocomplete> has closed.
    setTimeout(
      () => this._onUpdateDocumentSearch(''),
      autoCompleteMenuCloseDelay + 1
    );
  }

  _removeDocument(document) {
    const documents = this.props.documents.slice(0);
    const documentIndex = documents
      .map(document => document.id)
      .indexOf(document.id);

    if (documentIndex !== -1) {
      documents.splice(documentIndex, 1);
      this.props.updateDocuments(documents);
    }
  }

  _renderSuccessDialog() {
    return (
      <Dialog fullWidth maxWidth="sm" open={this.props.isOpen}>
        <DialogTitle>Edit Program Details</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Your changes to this program have been saved.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            focusRipple
            key={1}
            onClick={this.props.onClose}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderSelectedDocuments() {
    const documents = this.props.documents.map((document, index) => (
      <Chip
        icon={<FolderIcon />}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        label={document.name}
        onDelete={this._removeDocument.bind(this, document)}
        style={chipStyles}
      />
    ));

    return (
      <div
        className={`pl-program-instance-documents-wrapper ${this._hasDocumentsChanged() ? 'changed' : ''}`}
      >
        {documents}
      </div>
    );
  }

  _hasNameChanged() {
    return this.props.name !== this.props.details.name;
  }

  _hasDescriptionChanged() {
    return this.props.description !== (this.props.details.description || '');
  }

  _hasTextChanged() {
    return (
      this.props.inviteEmailText !==
      (this.props.details.invite_email_text || '')
    );
  }

  _hasDocumentsChanged() {
    // Check if different documents have been selected
    const oldDocumentIds = new Set(
      this.props.details.documents.map(doc => doc.id)
    );
    const sameDocsSelected =
      oldDocumentIds.size === this.props.documents.length &&
      this.props.documents.every(doc => oldDocumentIds.has(doc.id));

    return !sameDocsSelected;
  }

  /*
   * Only allow submission if changes have been made, all fields are valid, and the request isn't already in progress.
   */
  _isSubmitAllowed() {
    // An unchanged program name is still considered unique.
    const isNameUnique =
      this.props.isNameUnique || this.props.isNameUnique === null;

    const isNameValid =
      !this.props.isCheckingName && isNameUnique && this.props.name.length >= 1;
    const isDescriptionValid = this.props.description.length >= 1;

    // Detect changes in the fields, compared to the original program details. Account for null values.
    const hasChanges =
      this._hasNameChanged() ||
      this._hasDescriptionChanged() ||
      this._hasTextChanged() ||
      this._hasDocumentsChanged();

    return (
      hasChanges &&
      isNameValid &&
      isDescriptionValid &&
      !this.props.isSaveRequestPending
    );
  }

  _renderEditDialog() {
    const disabled = !this._isSubmitAllowed();

    const nameErrorText =
      this.props.isNameUnique === false
        ? 'A program with this name already exists.'
        : this.props.nameError;

    const textWrapperClassName = `pl-program-edit-invite-email-text ${this._hasTextChanged() ? 'changed' : ''}`;

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={this.props.onClose}
        open={this.props.isOpen}
        scroll="paper"
      >
        <DialogTitle>Edit Program Details</DialogTitle>

        <DialogContent dividers>
          <Notification
            message={this.props.programSaveError}
            title="Oops, could not save this program"
            type="error"
          />

          <TextField
            error={Boolean(nameErrorText)}
            fullWidth
            helperText={nameErrorText && nameErrorText}
            label="Program name"
            margin="normal"
            onChange={event => {
              this.props.onNameChange(event.target.value);
              this.props.onNameCheck(event.target.value);
            }}
            required
            value={this.props.name}
            variant="standard"
          />

          <TextField
            fullWidth
            label="Description"
            margin="normal"
            onChange={event =>
              this.props.onDescriptionChange(event.target.value)
            }
            required
            value={this.props.description}
            variant="standard"
          />

          <div className={textWrapperClassName}>
            <TextField
              fullWidth
              label="Invite email text"
              margin="normal"
              maxRows={4}
              minRows={4}
              multiline
              onChange={event =>
                this.props.onInviteEmailTextChange(event.target.value)
              }
              value={this.props.inviteEmailText}
              variant="standard"
            />
          </div>

          <Autocomplete
            error={!!this.props.documentSearchError}
            freeSolo
            helperText={this.props.documentSearchError}
            inputValue={this.state.documentName}
            label="Documents"
            onInputChange={this._onUpdateDocumentSearch}
            onInputCompleted={this._requestDocumentsByName}
            onChange={this._selectDocument}
            options={this.props.documentSearchResults}
            sx={{marginTop: 1}}
            userHintBottomText="Start typing a name of a document (min 3 characters)."
          />

          {this._renderSelectedDocuments()}
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={this.props.onClose}>
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={disabled}
            onClick={!disabled ? this.props.onSubmit : null}
            variant="contained"
          >
            Save Program
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return this.props.isProgramSaved
      ? this._renderSuccessDialog()
      : this._renderEditDialog();
  }
}

ProgramEdit.propTypes = {
  description: string.isRequired,
  details: object.isRequired,
  documentSearchError: string,
  documentSearchResults: array.isRequired,
  documents: array.isRequired,
  inviteEmailText: string.isRequired,
  isCheckingName: bool.isRequired,
  isNameUnique: bool,
  isOpen: bool.isRequired,
  isProgramSaved: bool,
  isSaveRequestPending: bool.isRequired,
  name: string.isRequired,
  nameError: string,
  onClose: func.isRequired,
  onDescriptionChange: func.isRequired,
  onInviteEmailTextChange: func.isRequired,
  onNameChange: func.isRequired,
  onNameCheck: func.isRequired,
  onSubmit: func.isRequired,
  programSaveError: string,
  requestDocuments: func.isRequired,
  updateDocuments: func.isRequired,
};
