import React, {Component} from 'react';
import range from 'lodash/range';
import {func, instanceOf, number} from 'prop-types';

const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const currentYear = new Date().getFullYear();

class YearMonthForm extends Component {
  static propTypes = {
    date: instanceOf(Date),
    firstYear: number,
    onChange: func,
  };

  static defaultProps = {
    firstYear: 2009,
  };

  constructor(props) {
    super(props);
    this._onChange = this._onChange.bind(this);
  }

  _onChange(event) {
    const {year, month} = event.target.form;
    this.props.onChange(new Date(year.value, month.value));
  }

  render() {
    const year = this.props.date.getFullYear();
    const firstYear = this.props.firstYear ? this.props.firstYear : currentYear;

    // Year numbers with initial year to current year, plus 10 years into the future.
    const yearsIntoFuture = range(firstYear, currentYear + 11);

    // Handle values which are out of the default range of first year + 10 years.
    // Put earlier years at the beginning of the list, later years at the end.
    const selectableYears = yearsIntoFuture.slice();
    if (year < yearsIntoFuture[0]) {
      selectableYears.unshift(year);
    } else if (year > yearsIntoFuture[yearsIntoFuture.length - 1]) {
      selectableYears.push(year);
    }

    return (
      <form className="DayPicker-Caption">
        <select
          name="month"
          onChange={this._onChange}
          value={this.props.date.getMonth()}
        >
          {MONTH_NAMES.map((month, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select name="year" onChange={this._onChange} value={year}>
          {selectableYears.map((year, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    );
  }
}

export default YearMonthForm;
