const NAME = 'PROGRAM-EDIT';

// Prefill dialog fields with previously fetched data
export const INITIALIZE_DETAILS = `${NAME}/INITIALIZE_DETAILS`;

// Uniqueness check for program name
export const REQUEST_NAME_CHECK = `${NAME}/REQUEST_NAME_CHECK`;
export const REQUEST_NAME_CHECK_SUCCESS = `${NAME}/REQUEST_NAME_CHECK_SUCCESS`;
export const REQUEST_NAME_CHECK_FAIL = `${NAME}/REQUEST_NAME_CHECK_FAIL`;

// Update fields with user input
export const UPDATE_NAME = `${NAME}/UPDATE_NAME`;
export const UPDATE_DESCRIPTION = `${NAME}/UPDATE_DESCRIPTION`;
export const UPDATE_INVITE_EMAIL_TEXT = `${NAME}/UPDATE_INVITE_EMAIL_TEXT`;
export const UPDATE_DOCUMENTS = `${NAME}/UPDATE_DOCUMENTS`;

// Search for documents, triggered by autocomplete field
export const REQUEST_DOCUMENT_SEARCH = `${NAME}/REQUEST_DOCUMENT_SEARCH`;
export const REQUEST_DOCUMENT_SEARCH_SUCCESS = `${NAME}/REQUEST_DOCUMENT_SEARCH_SUCCESS`;
export const REQUEST_DOCUMENT_SEARCH_FAIL = `${NAME}/REQUEST_DOCUMENT_SEARCH_FAIL`;

// Submit PUT request to save changes to the program
export const REQUEST_SAVE = `${NAME}/REQUEST_SAVE`;
export const REQUEST_SAVE_SUCCESS = `${NAME}/REQUEST_SAVE_SUCCESS`;
export const REQUEST_SAVE_FAIL = `${NAME}/REQUEST_SAVE_FAIL`;

export const RESET_STATE = `${NAME}/RESET_STATE`;
