import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import Bulk from 'admin-ng/components/compliance/bulk-screening';
import ComplianceList from 'admin-ng/components/compliance/list';
import ComplianceRecord from 'admin-ng/components/compliance/record';
import Sidebar from 'admin-ng/components/common/sidebar';
import Tab from 'admin-ng/components/common/tab';
import Tabs from 'admin-ng/components/common/tabs';

const Compliance = () => (
  <div className="pl-flex-wrapper">
    <Tabs className="pl-top-level-tabs">
      <Tab link="/compliance/user">User Compliance</Tab>
      <Tab link="/compliance/organization">Organization Compliance</Tab>
      <Tab link="/compliance/screening">Bulk Screening</Tab>
    </Tabs>
    <Sidebar />
    <div className="pl-app--content">
      <Switch>
        <Route exact path="/compliance/user/:recordId">
          <ComplianceRecord entity="user" />
        </Route>

        <Route exact path="/compliance/organization/:recordId">
          <ComplianceRecord entity="organization" />
        </Route>

        <Route exact path="/compliance/user">
          <ComplianceList entity="user" />
        </Route>

        <Route exact path="/compliance/organization">
          <ComplianceList entity="organization" />
        </Route>

        <Route component={Bulk} exact path="/compliance/screening" />
        <Redirect to="/compliance/user" />
      </Switch>
    </div>
  </div>
);

export default Compliance;
