import * as constants from './constants';

const initial = {
  error: null,
  id: null,
  isLoading: false,
  isUpdating: false,
  organization: null,
  record: null,
  status: null,
  updateError: null,
  user: null,
};

export default (state = initial, {type, payload} = {}) => {
  switch (type) {
    case constants.FETCH_START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };

    case constants.FETCH_SUCCESS: {
      const {id, organization, record, status, user} = payload;
      return {
        ...state,
        error: null,
        isLoading: false,
        id,
        organization,
        record,
        status,
        user,
      };
    }

    case constants.FETCH_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
        record: null,
      };

    case constants.UPDATE_START:
      return {
        ...state,
        isUpdating: true,
        updateError: null,
      };

    case constants.UPDATE_SUCCESS:
      return {
        ...state,
        isUpdating: false,
        updateError: null,
      };

    case constants.UPDATE_FAIL:
      return {
        ...state,
        isUpdating: false,
        updateError: payload,
      };

    case constants.RESET:
      return {...initial};

    default:
      return state;
  }
};
