import get from 'lodash/get';
import moment from 'moment';

export const getStaticDate = date => {
  return moment.utc(date).toDate();
};

export const parseItemTypes = (policy, availableItemTypes) => {
  const constraints = policy.constraints;
  const itemTypes = [];

  constraints.forEach(constraint => {
    if (constraint.key === 'type_permissions') {
      Object.keys(constraint.value.map).forEach((itemType, index) => {
        const maybeMatchingItemType =
          availableItemTypes &&
          availableItemTypes.find(type => type.id === itemType);

        const category = maybeMatchingItemType
          ? maybeMatchingItemType.category
          : 'data_v1';

        itemTypes.push({
          availableAssetTypes: [],
          value: itemType,
          error: null,
          selectedAssetTypes: {
            items: constraint.value.map[itemType].slice(0),
            error: null,
          },
          isExpanded: index === 0, // only expand the first one by default
          category,
        });
      });
    }
  });

  return itemTypes;
};

export const parseAoi = policy => {
  const constraints = policy.constraints;
  const aoi = [];
  let features;

  constraints.forEach(constraint => {
    if (constraint.key === 'aoi') {
      if (
        constraint.value &&
        constraint.value.definitions &&
        constraint.value.definitions.features &&
        constraint.value.definitions.features.length
      ) {
        features = constraint.value.definitions.features.slice(0);
      }
    }
  });

  if (features && features.length) {
    features.forEach(feature => {
      aoi.push({
        value: JSON.stringify(feature),
        error: null,
        isValid: true,
      });
    });
  }

  return aoi;
};

export const parseAoiAsCollection = policy =>
  JSON.stringify(
    get(
      policy.constraints.find(item => item.key === 'aoi'),
      'value.definitions'
    )
  );

const parseToiRange = range => ({
  start: range.from ? getStaticDate(range.from) : null,
  end: range.to ? getStaticDate(range.to) : null,
  hasNoStartDate: range.from === null,
  hasNoEndDate: range.to === null,
  startError: null,
  endError: null,
  isValid: true,
});

export const parseToi = policy => {
  const constraints = policy.constraints;
  const toi = {
    data: [],
    ui: [],
    originalDelay: 0,
    delay: 0,
    originalUnits: 'days',
    delayUnits: 'days',
    originalDelayUnits: 'days',
  };
  let ranges;

  constraints.forEach(constraint => {
    if (constraint.key === 'toi') {
      if (constraint.value) {
        if (constraint.value.ranges && constraint.value.ranges.length) {
          ranges = constraint.value.ranges.slice(0);
        }
        toi.originalDelay = constraint.value.delay;
        toi.delay = constraint.value.delay;
      }
    }
  });

  if (ranges && ranges.length) {
    ranges.forEach(range => {
      toi.data.push(parseToiRange(range));
      toi.ui.push(parseToiRange(range));
    });
  }

  return toi;
};

export const parseDeliveryOptions = policy => {
  const constraints = policy.constraints;
  const deliveryOptions = {
    allowDeliveryGEOTIFF: false,
    allowDeliveryWebtile: false,
  };

  constraints.forEach(constraint => {
    if (constraint.key === 'flags') {
      if (constraint.value) {
        deliveryOptions.allowDeliveryGEOTIFF =
          constraint.value.geotiff_download;
        deliveryOptions.allowDeliveryWebtile = constraint.value.webtile_service;
      }
    }
  });

  return deliveryOptions;
};

export const parsePolicies = (sourcePolicies, availableItemTypes) =>
  sourcePolicies.map(policy => {
    const newPolicy = {
      name: policy.name,
      itemType: {
        data: parseItemTypes(policy, availableItemTypes),
        ui: parseItemTypes(policy, availableItemTypes),
      },
    };

    newPolicy.aoi = {
      data: parseAoi(policy),
      ui: parseAoi(policy),
    };
    newPolicy.toi = parseToi(policy);

    newPolicy.deliveryOptions = {
      data: parseDeliveryOptions(policy),
      ui: parseDeliveryOptions(policy),
    };

    return newPolicy;
  });
