import React, {Component} from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import DataState from 'admin-ng/components/common/data-state';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import SceneQuotaCell from 'admin-ng/components/common/subscription-details/quota-table-cell/scene';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import TileQuotaCell from 'admin-ng/components/common/subscription-details/quota-table-cell/tile';
import {DATE_FORMAT} from 'admin-ng/constants';

export default class PlanSubscriptions extends Component {
  static propTypes = {
    error: string,
    history: object.isRequired,
    isDataRequestPending: bool,
    isPlanetAdmin: bool.isRequired,
    match: object.isRequired,
    onDataRequest: func,
    quotaUsages: object,
    subscriptions: arrayOf(object),
    subscriptionsTotalCount: number,
    viewParams: object,
  };

  componentDidMount() {
    this.props.onDataRequest();
  }

  componentWillReceiveProps(nextProps) {
    nextProps.match.params.planId !== this.props.match.params.planId &&
      nextProps.onDataRequest();
  }

  _idColumnContentCreator = ({id}) => (
    <Link
      data-qa-id="plan-subscriptions-view"
      to={`/packages/${this.props.match.params.planId}/plans/${id}`}
    >
      {id}
    </Link>
  );

  _organizationColumnContentCreator = ({organization: {id, name}}) => (
    <Link
      data-qa-id="plan-subscriptions-view-organization"
      to={`/organizations/${id}`}
    >
      {name}
    </Link>
  );

  _sceneQuotaContentCreator = sub => (
    <SceneQuotaCell sub={sub} usages={this.props.quotaUsages} />
  );

  _basemapTileQuotaContentCreator = sub => (
    <TileQuotaCell tileQuota={sub.basemap_tile_quota} units="tiles" />
  );

  _sceneTileQuotaContentCreator = sub => (
    <TileQuotaCell tileQuota={sub.scene_tile_quota} units="tiles" />
  );

  _quadQuotaContentCreator = sub => (
    <TileQuotaCell tileQuota={sub.basemap_quad_quota} units="quads" />
  );

  _referenceColumnContentCreator = ({reference}) => {
    return reference || '-';
  };

  _stateColumnContentCreator = item => {
    return item.state ? <DataState state={item.state} /> : '—';
  };

  _openBulkAssign = () => {
    this.props.history.push(
      `/packages/${this.props.match.params.planId}/plans/bulk-create`
    );
  };

  /**
   * This function returns data structure for organizaiton to be correctly
   * displayed in the a reusable component PlTable
   *
   * A single object represents a table column.
   *
   *     field - (required) property name in the data object (the same which is returned by api)
   *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
   *                          different from the field name.
   *     label - (required) column name.
   *     width - (required) column width to be set in the styles in the table header and table body
   *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
   *     contentCreator - [optional] - func - can be used for creating custom column content
   */
  get _schema() {
    const {isPlanetAdmin} = this.props;
    const schema = [
      {
        field: 'id',
        label: 'Plan',
        isSortable: false,
        width: '5%',
        contentCreator: this._idColumnContentCreator,
      },
      {
        field: 'name',
        sortKey: 'organization.name',
        label: 'Organization',
        width: '15%',
        contentCreator: this._organizationColumnContentCreator,
      },
      {
        field: 'state',
        label: 'State',
        width: '3%',
        contentCreator: this._stateColumnContentCreator,
      },
      {
        field: 'active',
        label: 'Access Period',
        width: '5%',
        contentCreator: item => {
          const from = item.active_from
            ? moment.utc(item.active_from).format(DATE_FORMAT)
            : '—';
          const to = item.active_to
            ? moment.utc(item.active_to).format(DATE_FORMAT)
            : '—';
          return (
            <>
              <div>{from}</div>
              <div>{to}</div>
            </>
          );
        },
      },
      {
        field: 'scene_quota',
        label: 'Scene Quota',
        width: '5%',
        contentCreator: this._sceneQuotaContentCreator,
      },
      {
        field: 'basemap_tile_quota',
        label: 'Basemap Tile Quota',
        width: '5%',
        contentCreator: this._basemapTileQuotaContentCreator,
      },
      {
        field: 'scene_tile_quota',
        label: 'Scene Tile Quota',
        width: '5%',
        contentCreator: this._sceneTileQuotaContentCreator,
      },
    ];

    if (isPlanetAdmin) {
      schema.push({
        field: 'reference',
        label: 'Opportunity ID',
        isSortable: false,
        width: '10%',
        contentCreator: this._referenceColumnContentCreator,
      });
    }

    return schema;
  }

  render() {
    const {
      error,
      isDataRequestPending,
      isPlanetAdmin,
      onDataRequest,
      subscriptions,
      subscriptionsTotalCount,
      viewParams: {filters: activeFilters, limit, offset, sort},
    } = this.props;

    const filters = ['active', 'expired', 'inactive', 'deleted'];

    const actions = [
      /*
      <Button
        color="primary"
        onClick={this._openBulkAssign}
        variant="contained"
      >
        Bulk Subscribe
      </Button>,
      */
    ];

    return (
      <Card>
        <div className="pl-table-card-content">
          <TableControlsPanel
            actions={isPlanetAdmin ? actions : null}
            activeFilters={activeFilters}
            filters={filters}
            itemsPerPageLimit={limit}
            onFilter={onDataRequest}
          />
          <Notification
            message={error}
            title="Oops, plans request failed"
            type="error"
          />
          <Table
            className="pl-table-index"
            dataSchema={this._schema}
            isRequestPending={isDataRequestPending}
            onSort={onDataRequest}
            qaId="plan-subscriptions-table"
            sortBy={sort}
            srcData={subscriptions}
            style={{minWidth: '1350px'}}
          />
          <Pagination
            itemsLimit={limit}
            itemsOffset={offset}
            itemsTotal={subscriptionsTotalCount}
            onPageChange={onDataRequest}
          />
        </div>
      </Card>
    );
  }
}
