import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import DataState from 'admin-ng/components/common/data-state';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';

export default class ProgramOrganizations extends Component {
  constructor(props) {
    super(props);
    this._stateColumnContentCreator =
      this._stateColumnContentCreator.bind(this);
  }

  componentDidMount() {
    this.props.onDataRequest();
  }

  componentWillReceiveProps(nextProps) {
    nextProps.match.params.programId !== this.props.match.params.programId &&
      nextProps.onDataRequest();
  }

  _getOrgDataSchema() {
    return [
      {
        field: 'id',
        label: 'ID',
        width: '20%',
      },
      {
        field: 'name',
        label: 'Name',
        width: '60%',
        contentCreator: this._orgNameColumnContentCreator,
      },
      {
        field: 'state',
        label: 'State',
        width: '20%',
        contentCreator: this._stateColumnContentCreator,
      },
    ];
  }

  _orgNameColumnContentCreator(item) {
    const displayName = item.name.replace(/\s\s+/g, ' ');

    return (
      <Link title={displayName} to={`/organizations/${item.id}`}>
        {displayName}
      </Link>
    );
  }

  _stateColumnContentCreator(item) {
    return item.state ? <DataState state={item.state} /> : '—';
  }

  render() {
    const orgFilterNames = ['active', 'disabled', 'deleted'];
    return (
      <div className="pl-program-organizations-page">
        <Card>
          <div className="pl-table-card-content">
            <TableControlsPanel
              activeFilters={this.props.viewParams.filters}
              filters={orgFilterNames}
              itemsPerPageLimit={this.props.viewParams.limit}
              onFilter={this.props.onDataRequest}
            />

            <Notification
              message={this.props.error}
              title="Oops, program organizations request has failed"
              type="error"
            />

            <Table
              activeFilters={this.props.viewParams.filters}
              className="pl-table-index"
              dataSchema={this._getOrgDataSchema()}
              filters={orgFilterNames}
              isRequestPending={this.props.isProgramOrganizationsRequestPending}
              onSort={this.props.onDataRequest}
              qaId="program-organizations-table"
              sortBy={this.props.viewParams.sort}
              srcData={this.props.organizations}
            />

            <Pagination
              itemsLimit={this.props.viewParams.limit}
              itemsOffset={this.props.viewParams.offset}
              itemsTotal={this.props.organizationsTotalCount}
              onPageChange={this.props.onDataRequest}
            />
          </div>
        </Card>
      </div>
    );
  }
}

ProgramOrganizations.propTypes = {
  error: string,
  isProgramOrganizationsRequestPending: bool,
  match: object.isRequired,
  onDataRequest: func.isRequired,
  organizations: arrayOf(object),
  organizationsTotalCount: number,
  params: object,
  viewParams: object.isRequired,
};

ProgramOrganizations.defaultProps = {
  error: null,
  isProgramOrganizationsRequestPending: false,
  organizations: null,
  organizationsTotalCount: null,
};
