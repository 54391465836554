const MILLION = 'million';
const BILLION = 'billion';
const TRILLION = 'trillion';

/**
 * Call to localeString with browser language locale
 * @see: https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Number/toLocaleString
 * @param value
 * @param args
 */
export const localizeDate = (value, ...args) =>
  value.toLocaleDateString(navigator.language, ...args);
export const localizeNumber = (value, ...args) =>
  value.toLocaleString(navigator.language, ...args);

/**
 * For large numbers, we want to make them more human-readable. This
 * is achieved by finding the largest factor out of million, billion or
 * trillion, dividing the number by this and suffixing the text equivalent
 * of the factor.
 *
 * Caveat: Without a tokenizable translation string, this may not work
 * perfectly in some languages.
 */
export const formatLargeNumber = val => {
  let displayFactor = 1;
  let unitPrefix = '';
  const million = 1000000;
  const billion = 1000000000;
  const trillion = 1000000000000;

  if (val / (10 * million) > 1) {
    displayFactor = million;
    unitPrefix = ` ${MILLION}`;
  }

  if (val / (10 * billion) > 1) {
    displayFactor = billion;
    unitPrefix = ` ${BILLION}`;
  }

  if (val / (10 * trillion) > 1) {
    displayFactor = trillion;
    unitPrefix = ` ${TRILLION}`;
  }

  return `${localizeNumber(Math.round(val / displayFactor), {maximumFractionDigits: 0})}${unitPrefix}`;
};
