import {connect} from 'react-redux';

import Subscriptions from './subscriptions';
import {del, fetch, reset, setParams} from './actions';

const mapStateToProps = ({subscriptions: {index}}) => index;
const mapDispatchToProps = dispatch => ({
  onMount: () => {
    dispatch(reset());
    dispatch(fetch());
  },
  onChange: params => {
    dispatch(setParams(params));
    dispatch(fetch());
  },
  onDelete: id => {
    dispatch(del(id));
    dispatch(fetch());
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
