import Button from '@mui/material/Button';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';
import {withAuth} from '@apps-common/auth-react';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import DataState from 'admin-ng/components/common/data-state';
import IconButton from 'admin-ng/components/common/icon-button';
import Notification from 'admin-ng/components/common/notification';
import OrgCreateContainer from '../org-create/org-create-container';
import Pagination from 'admin-ng/components/common/pagination';
import Sidebar from 'admin-ng/components/common/sidebar';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import {ROLES} from 'admin-ng/constants';

class OrgsIndex extends Component {
  static propTypes = {
    auth: object.isRequired,
    isOrgsRequestPending: bool.isRequired,
    onDeleteOrg: func.isRequired,
    onRequestOrgs: func.isRequired,
    onResetView: func.isRequired,

    orgs: arrayOf(object),
    orgsRequestError: string,
    orgsTotalCount: number.isRequired,

    viewParams: object.isRequired,
  };

  state = {
    isCreateOpen: false,
    showDeleteConfirmation: false,
    itemToDelete: null,
  };

  componentDidMount = () => {
    // Requesting organizations data with inital view state
    this.props.onResetView();
  };

  /**
   * This function returns data structure for organizaiton to be correctly
   * displayed in the a reusable component Table
   *
   * A single object represents a table column.
   *
   *     field - (required) property name in the data object (the same which is returned by api)
   *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
   *                          different from the field name.
   *     label - (required) column name.
   *     width - (required) column width to be set in the styles in the table header and table body
   *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
   *     contentCreator - [optional] - func - can be used for creating custom column content
   */
  _getOrgDataSchema = () => [
    {
      field: 'id',
      label: 'ID',
      width: '9%',
    },
    {
      field: 'name',
      label: 'Name',
      width: '23%',
      contentCreator: this._orgNameColumnContentCreator,
    },
    {
      field: 'state',
      label: 'State',
      width: '10%',
      contentCreator: this._stateColumnContentCreator,
    },
    {
      field: 'program',
      sortKey: 'program_name',
      label: 'Program',
      width: '16%',
      contentCreator: this._programColumnContentCreator,
    },
    {
      field: 'subscriptions_active',
      label: 'Active Plans #',
      width: '14%',
      isSortable: false,
    },
    {
      field: 'subscriptions_total',
      label: 'Total Plans #',
      width: '14%',
      isSortable: false,
    },
    {
      field: 'suborgs_count',
      label: 'Suborgs #',
      width: '10%',
      isSortable: false,
    },
    {
      field: null,
      label: '',
      width: '5%',
      contentCreator: this._tableActionsColumnCreator,
    },
  ];

  _isPlanetAdmin = () => {
    const user = this.props.auth.user.claims;
    return user.role_level >= ROLES.planetAdmin;
  };

  _openCreateOrg = () => {
    if (this._isPlanetAdmin()) {
      this.setState({
        isCreateOpen: true,
      });
    }
  };

  _handleOrgCreateCancel = () => {
    this.setState({
      isCreateOpen: false,
    });
  };

  _orgNameColumnContentCreator = item => {
    const orgParent = item.parent_organization;
    const displayName = item.name.replace(/\s\s+/g, ' ');

    if (orgParent && orgParent.name) {
      return (
        <span className="pl-org-name pl-flex-wrapper">
          <span className="pl-org-name--container">
            <span className="pl-org-name--label">
              <Link title={displayName} to={`/organizations/${item.id}`}>
                {displayName}
              </Link>
            </span>
          </span>

          <span className="pl-suborg" title={orgParent.name}>
            <span className="pl-suborg--name">
              <Link
                title={`Suborg of ${orgParent.name}`}
                to={`/organizations/${orgParent.id}`}
              >
                Suborg of {orgParent.name}
              </Link>
            </span>
          </span>
        </span>
      );
    } else {
      return (
        <Link title={displayName} to={`/organizations/${item.id}`}>
          {displayName}
        </Link>
      );
    }
  };

  _stateColumnContentCreator = item =>
    item.state ? <DataState state={item.state} /> : '—';

  _programColumnContentCreator = item =>
    item.program && item.program.name ? (
      <span title={item.program.name}>{item.program.name}</span>
    ) : (
      '—'
    );

  _tableActionsColumnCreator = item => (
    <IconButton
      icon="delete"
      isDisabled={item.state === 'deleted'}
      onClick={this._onDelete.bind(this, item)}
    />
  );

  _onDelete = item => {
    this.setState({
      itemToDelete: item,
      showDeleteConfirmation: true,
    });
  };

  _onDeleteConfirmed = () => {
    this.props.onDeleteOrg(this.state.itemToDelete.id);
    this._onDeleteClose();
  };

  _onDeleteClose = () => {
    this.setState({
      showDeleteConfirmation: false,
      itemToDelete: null,
    });
  };

  render = () => {
    const orgFilterLabels = ['Active', 'Disabled', 'Deleted'];
    const orgFilterValues = ['active', 'disabled', 'deleted'];
    const tablePanelActions = [];
    const {
      isOrgsRequestPending,
      onRequestOrgs,
      orgs,
      orgsRequestError,
      orgsTotalCount,
      viewParams: {filters, limit, sort, offset},
    } = this.props;
    const {isCreateOpen, showDeleteConfirmation, itemToDelete} = this.state;

    if (this._isPlanetAdmin()) {
      orgFilterLabels.push('Enterprise Only');
      orgFilterValues.push('enterprise');

      tablePanelActions.push(
        <Button
          className="pl-table-controls--add-btn"
          color="primary"
          key={1}
          onClick={this._openCreateOrg}
          variant="contained"
        >
          Create
        </Button>
      );
    }

    return (
      <div className="pl-flex-wrapper">
        <Sidebar />

        <div className="pl-app--content">
          <Card>
            <div className="pl-table-card-content">
              <TableControlsPanel
                actions={tablePanelActions}
                activeFilters={filters}
                filters={orgFilterValues}
                itemsPerPageLimit={limit}
                labels={orgFilterLabels}
                onFilter={onRequestOrgs}
              />

              <OrgCreateContainer
                isOpen={isCreateOpen}
                onClose={this._handleOrgCreateCancel}
              />

              <Notification
                message={orgsRequestError}
                title="Oops, organizations request has failed"
                type="error"
              />

              <ConfirmationMessage
                onClose={this._onDeleteClose}
                onSubmit={this._onDeleteConfirmed}
                open={showDeleteConfirmation}
                qaId="org-delete"
                title="Delete organization"
              >
                Are you sure you want to delete organization
                {itemToDelete ? ` ${itemToDelete.name}?` : '?'}
              </ConfirmationMessage>

              <Table
                className="pl-table-index"
                dataSchema={this._getOrgDataSchema()}
                isRequestPending={isOrgsRequestPending}
                onSort={onRequestOrgs}
                qaId="organizations-table"
                sortBy={sort}
                srcData={orgs}
              />

              <Pagination
                itemsLimit={limit}
                itemsOffset={offset}
                itemsTotal={orgsTotalCount}
                onPageChange={onRequestOrgs}
              />
            </div>
          </Card>
        </div>
      </div>
    );
  };
}

export default withAuth(OrgsIndex);
