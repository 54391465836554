import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import * as actions from './actions';
import PlanCreatePolicies from './plan-create-policies';

const mapStateToProps = state => {
  // This component uses state.plans.instance in order to share code between
  // create and instance views.
  // TODO: Consolidate this with plan-policies-container
  const _state = state.plans.instance.policies;

  return {
    policies: _state.items,
    selectedPolicy: _state.selectedPolicy,
    queryForNewName: _state.queryForNewName,
    availableItemTypes: _state.availableItemTypes,
    itemTypesRequestError: _state.itemTypesRequestError,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  onAddAOI: () => dispatch(actions.addAOI()),
  onAddPolicy: () => dispatch(actions.getNewPolicyName()),
  onAddItemType: () => dispatch(actions.addItemType()),
  onAddTOI: () => dispatch(actions.addTOI()),
  onDeleteAOI: index => dispatch(actions.deleteAOI(index)),
  onDeleteItemType: index => dispatch(actions.deleteItemType(index)),
  onDeleteTOI: index => dispatch(actions.deleteTOI(index)),
  onGetAssetTypes: (policyIndex, itemTypeIndex, itemType) =>
    dispatch(actions.fetchAssetTypes(policyIndex, itemTypeIndex, itemType)),
  onGetAvailableItemTypes: () => dispatch(actions.fetchAvailableItemTypes()),
  onNewNameSubmit: name => dispatch(actions.setNewPolicyNameRequested(name)),
  onRemove: () => dispatch(actions.removePolicy(null, props.history)),
  onRename: () => dispatch(actions.renamePolicy()),
  onRequestBasemaps: (index, name) =>
    dispatch(actions.fetchBasemaps(index, name)),
  onRequestSeries: (index, name) => dispatch(actions.fetchSeries(index, name)),
  onSelectPolicy: index => dispatch(actions.setActivePolicy(index)),
  onUpdateDeliveryOptions: (type, value) =>
    dispatch(actions.updateDeliveryOptions(type, value)),
  onUpdateAOI: (index, aoi, isValid, error) =>
    dispatch(actions.updateAOI(index, aoi, isValid, error)),
  onUpdateAssetTypes: (index, assetTypes) =>
    dispatch(actions.updateAssetTypes(index, assetTypes)),
  onUpdateItemType: (index, itemType) =>
    dispatch(actions.updateItemType(index, itemType)),
  onUpdateTOI: (index, date, error) =>
    dispatch(actions.updateTOI(index, date, error)),
  onUpdateSelectedMosaics: (index, items) =>
    dispatch(actions.updateSelectedMosaics(index, items)),
  onUploadAOIFile: (index, file) =>
    dispatch(actions.uploadAOIFile(index, file)),
  toggleItemType: index => dispatch(actions.toggleItemType(index)),
  toggleItemTypeAll: toggle => dispatch(actions.toggleItemTypeAll(toggle)),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps, undefined, {pure: false})(
    PlanCreatePolicies
  )
);
