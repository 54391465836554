import {connect} from 'react-redux';

import Component from './org-instance';

const mapStateToProps = ({
  orgs: {
    instance: {
      general: {
        details: {error, initDetails: details, isOrgDetailsRequestPending},
      },
    },
  },
}) => ({
  error,
  details,
  isOrgDetailsRequestPending,
});

export default connect(mapStateToProps)(Component);
