import {connect} from 'react-redux';

import * as actions from './actions';
import PlanAssignSelectPlan from './plan-assign-select-plan';

const mapStateToProps = state => state.plans.assign.selectPlan;

const mapDispatchToProps = dispatch => ({
  onPlanSelect: (type, id, name) =>
    dispatch(actions.selectPlan(type, id, name)),
  onPlansRequest: (type, planQuery) =>
    dispatch(actions.fetchPlans(type, planQuery)),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
})(PlanAssignSelectPlan);
