import * as constants from './constants';

const initial = {
  error: null,
  programs: [],
  programsTotalCount: 0,
  isRequestPending: false,
  viewParams: {
    limit: 20,
    offset: 0,
    sort: 'name',
    search: '',
  },
};

export default function programsReducer(state = initial, action = {}) {
  switch (action.type) {
    case constants.REQUEST_PROGRAMS:
      return {
        ...state,
        isRequestPending: true,
        viewParams: {...state.viewParams, ...action.params},
      };

    case constants.REQUEST_PROGRAMS_SUCCESS:
      return {
        ...state,
        isRequestPending: false,
        programs: action.programs,
        programsTotalCount: action.programsTotalCount,
        error: null,
      };

    case constants.REQUEST_PROGRAMS_FAIL:
      return {
        ...state,
        isRequestPending: false,
        error: action.error,
      };

    case constants.RESET_VIEW_STATE:
      return {...state, viewParams: {...initial.viewParams}};

    case constants.RESET_STATE:
      return initial;

    default:
      return state;
  }
}
