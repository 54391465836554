import * as constants from './constants';
import * as messages from './messages';
import {del} from 'admin-ng/api/comments';
import {fetchComments} from '../org-comments-index/actions';

export const requestCommentDelete = () => ({
  type: constants.REQUEST_DELETE_COMMENT,
});

export const deleteCommentCompleted = () => ({
  type: constants.COMMENT_DELETE_COMPLETED,
});

export const deleteCommentFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.COMMENT_DELETE_FAILED,
  error,
});

export const resetDeletedState = () => ({
  type: constants.RESET_DELETED_STATE,
});

export const deleteOrgComment = (orgId, commentId) => dispatch => {
  dispatch(requestCommentDelete());
  return del(commentId).then(
    () => {
      dispatch(deleteCommentCompleted());
      dispatch(fetchComments(orgId));
    },
    (err = {}) => {
      dispatch(deleteCommentFailed(err.message));
    }
  );
};
