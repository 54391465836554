import React from 'react';
import {useParams} from 'react-router-dom';

import AuditLog from 'admin-ng/components/common/audit-log';
import OrgComments from './org-comments';
import OrgDetails from './org-details';

const OrgGeneral = () => {
  const {orgId} = useParams();

  return (
    <>
      <OrgDetails orgId={orgId} />

      <OrgComments orgId={orgId} />

      <AuditLog modelId={orgId} modelName="organizations" />
    </>
  );
};

export default OrgGeneral;
