import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import {createAction as create} from 'redux-actions';

import * as loading from 'admin-ng/components/common/request-spinner/actions';
import * as types from './types';
import {PARAM_KEYS} from 'admin-ng/constants';
import {all} from 'admin-ng/api/users';
import {usersCsv} from 'admin-ng/api/reports';

export const downloadAction = create(types.DOWNLOAD);
export const download = auth => async dispatch => {
  const token = auth.user.accessToken;
  // NOTE: dispatching loading's action creators because requests are being tracked globally
  dispatch(loading.start());
  dispatch(downloadAction({isDownloading: true}));
  try {
    // NOTE: this uses a different endpoint and is only available for planet admins.
    await usersCsv(token);
    dispatch(loading.success());
    dispatch(downloadAction());
  } catch (err) {
    dispatch(loading.fail());
    dispatch(downloadAction(err));
  }
};

export const fetchAction = create(types.FETCH);
export const fetch = () => async (dispatch, getState) => {
  dispatch(fetchAction({isFetching: true}));
  const params = pickBy(pick(getState().users, PARAM_KEYS));
  if (params.filters) {
    params.state__in = params.filters;
    delete params.filters;
  }
  try {
    const {
      body: {
        results: users,
        meta: {count: total},
      },
    } = await all(params);
    dispatch(fetchAction({users, total}));
  } catch (err) {
    dispatch(fetchAction(err));
  }
};

export const setParams = create(types.SET_PARAMS);
export const reset = create(types.RESET);
