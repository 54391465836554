import React from 'react';
import TextField from '@mui/material/TextField';
import {bool, string} from 'prop-types';

import {FormFieldStyles} from 'admin-ng/components/common/form-field-styles';

const styles = {
  ...FormFieldStyles.general,
  height: 'auto',
  background: 'red',
};

const Textarea = props => {
  // Remove props we don't want to pass on to the MUI component when we spread
  const {isChanged: _isChanged, qaId: _qaId, ...strippedProps} = props;

  const style = props.isChanged
    ? {...styles.general, ...styles.changed}
    : styles.general;

  const handleChange = ({target}) => {
    if (target) {
      const {value} = target;
      props.onChange(value);
    }
  };

  let classes = 'pl-textarea';
  if (props.className) {
    classes += ` ${props.className}`;
  }

  return (
    <TextField
      {...strippedProps}
      className={classes}
      inputProps={{'data-qa-id': props.qaId}}
      fullWidth
      multiline
      onChange={handleChange}
      style={style}
      variant="standard"
    />
  );
};

Textarea.propTypes = {
  ...TextField.propTypes,
  isChanged: bool,
  qaId: string,
};

export default Textarea;
