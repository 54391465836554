import * as constants from './constants';
import {all} from 'admin-ng/api/documents';

export const DEFAULT_ERROR_MSG = 'Something went wrong';

export const requestDocuments = (params = {}) => ({
  type: constants.REQUEST_DOCUMENTS,
  params,
});

export const receiveDocuments = (json = {}) => ({
  type: constants.REQUEST_DOCUMENTS_SUCCESS,
  documents: json.results || [],
  documentsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
});

export const requestDocumentsFail = (error = DEFAULT_ERROR_MSG) => ({
  type: constants.REQUEST_DOCUMENTS_FAIL,
  error,
});

export const fetchDocuments = (params = {}) => {
  return (dispatch, getState) => {
    const {viewParams} = getState().documents.index;
    const combinedParams = {
      ...viewParams,
      ...params,
    };

    dispatch(requestDocuments(combinedParams));

    // if search is empty, no need to send the parameter, otherwise it will activate
    // a redundant process on the back-end
    if (combinedParams?.hasOwnProperty('search') && !combinedParams.search) {
      delete combinedParams.search;
    }

    return all(combinedParams)
      .then((res = {}) => {
        dispatch(receiveDocuments(res.body));
      })
      .catch(err => {
        dispatch(requestDocumentsFail(err.message));
      });
  };
};

export const resetViewState = () => ({
  type: constants.RESET_VIEW_STATE,
});

export const resetView = () => {
  return function (dispatch) {
    dispatch(resetViewState());
    return dispatch(fetchDocuments());
  };
};

export const resetState = () => ({
  type: constants.RESET_STATE,
});
