import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, {Component} from 'react';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {bool, func, string} from 'prop-types';

import FormCard from 'admin-ng/components/common/form-card';
import TextField from 'admin-ng/components/common/text-field';
import {REFERENCE_THEMES} from 'admin-ng/constants';

const _PL_NUMBER_INVALID_MESSAGE =
  'Must be semicolon-separated list of PL Numbers that follow 7-digit PL-####### format.';

const _REFERENCE_OPTIONS = {
  PL_NUMBER: 0,
  PL_THEME: 1,
};

export default class ReferenceInput extends Component {
  static propTypes = {
    onReferenceChange: func.isRequired,
    reference: string.isRequired,
    referenceValid: bool.isRequired,
  };

  state = {
    enableTextboxError: false,
    referenceOption: null,
    referenceNumber: '',
    referenceTheme: REFERENCE_THEMES[0],
  };

  onChange = (event, referenceOption) => {
    this.setState({referenceOption: Number(referenceOption)});

    if (referenceOption === _REFERENCE_OPTIONS.PL_NUMBER) {
      this.props.onReferenceChange(this.state.referenceNumber);
    } else {
      this.props.onReferenceChange(this.state.referenceTheme);
    }
  };

  updatePLNumber = value => {
    this.setState({referenceNumber: value});
    this.props.onReferenceChange(value);
  };

  selectTheme = event => {
    this.setState({referenceTheme: event.target.value});
    this.props.onReferenceChange(event.target.value);
  };

  render() {
    const {referenceTheme, referenceNumber, enableTextboxError} = this.state;
    const {reference, referenceValid} = this.props;
    const referenceLooksLikeTheme = REFERENCE_THEMES.includes(reference);

    // If referenceOption wasn't defined yet because user didn't click a radio button,
    // then pick a setting based on how the current reference looks, or default to PL-Number.
    let referenceOption = this.state.referenceOption;
    if (referenceOption === null) {
      referenceOption = referenceLooksLikeTheme
        ? _REFERENCE_OPTIONS.PL_THEME
        : _REFERENCE_OPTIONS.PL_NUMBER;
    }

    const enableText = referenceOption === _REFERENCE_OPTIONS.PL_NUMBER;
    const enableDropdown = referenceOption === _REFERENCE_OPTIONS.PL_THEME;

    const dropdownValue =
      enableDropdown && referenceLooksLikeTheme ? reference : referenceTheme;

    const textboxValue = enableText ? reference : referenceNumber;
    const textboxError =
      enableText && enableTextboxError && !referenceValid
        ? _PL_NUMBER_INVALID_MESSAGE
        : '';

    return (
      <FormCard title="PL-Number / Opportunity ID">
        <RadioGroup
          name="reference-input-group"
          value={referenceOption}
          onChange={this.onChange}
        >
          <Table style={{maxWidth: '600px'}}>
            <TableBody>
              <TableRow>
                <TableCell style={{width: '200px'}}>
                  <FormControlLabel
                    control={<Radio />}
                    label="Opportunity ID"
                    value={_REFERENCE_OPTIONS.PL_NUMBER}
                  />
                </TableCell>

                <TableCell style={{overflow: 'normal'}}>
                  <TextField
                    disabled={!enableText}
                    error={!!textboxError}
                    helperText={textboxError}
                    label="PL Number(s)"
                    placeholder="e.g. PL-8675309 or PL-1234567;PL-8901234"
                    maxLength="65"
                    onBlur={() => this.setState({enableTextboxError: true})}
                    onChange={this.updatePLNumber}
                    onFocus={() => this.setState({enableTextboxError: false})}
                    value={textboxValue}
                    qaId="plnumbers-input"
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>
                  <FormControlLabel
                    control={<Radio />}
                    label="Theme"
                    value={_REFERENCE_OPTIONS.PL_THEME}
                  />
                </TableCell>

                <TableCell>
                  <Select
                    disabled={!enableDropdown}
                    fullWidth
                    onChange={this.selectTheme}
                    value={dropdownValue}
                    variant="standard"
                  >
                    {REFERENCE_THEMES.map((value, key) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={key} value={value}>
                        {value}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </RadioGroup>
      </FormCard>
    );
  }
}
