import React, {Component} from 'react';
import moment from 'moment';
import {bool, func, object, string} from 'prop-types';

import Checkbox from 'admin-ng/components/common/checkbox';
import DatePicker from 'admin-ng/components/common/date-picker';
import FormCard from 'admin-ng/components/common/form-card';
import Notification from 'admin-ng/components/common/notification';
import Quota from 'admin-ng/components/common/subscription-details/quota/quota-container.js';
import ReferenceInput from 'admin-ng/components/common/reference-input';
import {
  BasemapTileQuota,
  SceneTileQuota,
} from 'admin-ng/components/common/subscription-details/tile-quota/quota-container';

export default class PlanSubscriptionsBulkCreateSelectAccessPeriod extends Component {
  constructor(props) {
    super(props);

    this._toggleStartDate = this._toggleStartDate.bind(this);
    this._toggleEndDate = this._toggleEndDate.bind(this);
  }

  _isTOIValueValid(type, value) {
    let start, end, diff;

    if (type === 'startDate') {
      start = value;
      end = this.props.endDate;
    } else {
      start = this.props.startDate;
      end = value;
    }

    if (start && end) {
      start = moment(start);
      end = moment(end);
      diff = end.diff(start, 'days');

      if (diff <= 0) {
        return false;
      }
    }

    return true;
  }

  _handleDatePickerClear(type) {
    this.props.onUpdateDate({
      field: type,
      payload: {
        value: null,
      },
    });
  }

  _handleDatePickerChange(type, e, date) {
    let error;
    if (!this._isTOIValueValid(type, date)) {
      error = "End date can't be earlier than start date";
    }
    if (type === 'endDate' && date && date < new Date()) {
      error = "End date can't be in the past";
    }
    const params = {
      field: type,
      payload: {
        value: date,
        error,
      },
    };

    this.props.onUpdateDate(params);
  }

  _toggleStartDate() {
    this.props.onUpdateDate({
      field: 'startDate',
      payload: {
        value: this.props.isActiveFromToday ? null : new Date(),
        isActiveFromToday: !this.props.isActiveFromToday,
      },
    });
  }

  _toggleEndDate() {
    this.props.onUpdateDate({
      field: 'endDate',
      payload: {
        value: null,
        hasNoEndDate: !this.props.hasNoEndDate,
      },
    });
  }

  render() {
    const {onReferenceChange, reference, referenceError, referenceValid} =
      this.props;

    return (
      <div>
        <Notification message={referenceError} title="Error" type="error" />

        {this.props.isPlanetAdmin && (
          <ReferenceInput
            onReferenceChange={onReferenceChange}
            reference={reference}
            referenceValid={referenceValid}
          />
        )}

        <FormCard title="Access Management">
          <FormCard title="Access Period">
            <div className="pl-subscription-grid">
              <div className="pl-subscription-grid-cell">
                <DatePicker
                  error={this.props.startDateError}
                  label="Start"
                  onChange={this._handleDatePickerChange.bind(
                    this,
                    'startDate'
                  )}
                  onClear={this._handleDatePickerClear.bind(this, 'startDate')}
                  value={this.props.startDate}
                />

                <Checkbox
                  checked={this.props.isActiveFromToday}
                  data-qa-id="plan-subscription-from-today-checkbox"
                  label="Today"
                  onChange={this._toggleStartDate}
                />
              </div>

              <div className="pl-subscription-grid-cell">
                <DatePicker
                  disabled={this.props.hasNoEndDate}
                  error={this.props.endDateError}
                  label="End"
                  onChange={this._handleDatePickerChange.bind(this, 'endDate')}
                  onClear={this._handleDatePickerClear.bind(this, 'endDate')}
                  value={this.props.endDate}
                />

                <Checkbox
                  checked={this.props.hasNoEndDate}
                  data-qa-id="plan-subscription-no-end-day-checkbox"
                  label="No end date"
                  onChange={this._toggleEndDate}
                />
              </div>
            </div>
          </FormCard>

          {this.props.isPlanetAdmin && <Quota />}

          {this.props.isPlanetAdmin && (
            <BasemapTileQuota title="Basemap Tile Quota" />
          )}

          {this.props.isPlanetAdmin && (
            <SceneTileQuota title="Scene Tile Quota" />
          )}
        </FormCard>
      </div>
    );
  }
}

PlanSubscriptionsBulkCreateSelectAccessPeriod.propTypes = {
  endDate: object,
  endDateError: string,
  hasNoEndDate: bool,
  isActiveFromToday: bool,
  isPlanetAdmin: bool,
  onReferenceChange: func,
  onUpdateDate: func,
  reference: string,
  referenceError: string,
  referenceValid: bool,
  startDate: object,
  startDateError: string,
};
