import assign from 'deep-extend';

import * as constants from './constants';

const initialState = {
  name: '',
  searchResults: [],
  searchResultsTotal: 0,
  error: null,
  selectedSuborg: null,
  isSuborgMoved: false,
  isSuborgMoveInProgress: false,
};

export default function suborgMoveReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.SUBORG_MOVE_UPDATE_NAME:
      return {
        ...state,
        name: action.name,
      };

    case constants.SUBORG_MOVE_REQUEST_SUBORGS:
      return {
        ...state,
        searchResults: [],
        searchResultsTotal: 0,
      };

    case constants.SUBORG_MOVE_REQUEST_SUBORGS_SUCCESS:
      return assign({}, state, {
        searchResults: action.searchResults,
        searchResultsTotal: action.searchResultsTotal,
      });

    case constants.SUBORG_MOVE_REQUEST_SUBORGS_FAILED:
      return {
        ...state,
        error: action.error,
      };

    case constants.SUBORG_MOVE_SELECT_SUBORG:
      return {
        ...state,
        selectedSuborg: action.selectedSuborg,
      };

    case constants.SUBORG_MOVE_REQUEST_MOVE:
      return {
        ...state,
        isSuborgMoveInProgress: true,
      };

    case constants.SUBORG_MOVE_REQUEST_MOVE_SUCCESS:
      return {
        ...state,
        isSuborgMoveInProgress: false,
        selectedSuborg: null,
        isSuborgMoved: true,
      };

    case constants.SUBORG_MOVE_REQUEST_MOVE_FAILED:
      return {
        ...state,
        isSuborgMoveInProgress: false,
        error: action.error,
      };

    case constants.SUBORG_MOVE_RESET_STATE:
      return {...initialState};

    default:
      return {...state};
  }
}
