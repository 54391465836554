import {connect} from 'react-redux';

import OrgsIndex from './orgs-index';
import {deleteOrg, fetchOrgs, resetView} from './actions';

const mapStateToProps = state => {
  const _state = state.orgs.index;

  return {
    orgsRequestError: _state.error,
    isOrgsRequestPending: _state.isOrgsRequestPending,
    orgs: _state.items,
    orgsTotalCount: _state.itemsTotalCount,
    viewParams: _state.viewParams,
  };
};
const mapDispatchToProps = dispatch => ({
  onRequestOrgs: params => dispatch(fetchOrgs(params)),
  onDeleteOrg: id => dispatch(deleteOrg(id)),
  onResetView: () => dispatch(resetView()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgsIndex);
