import {connect} from 'react-redux';

import OrgMembersIndex from './org-members-index';
import {deleteInvitation, fetchUsers, resetView} from './actions';
import {fetchOrgDetails} from '../../org-general/org-details/actions';

const mapStateToProps = state => {
  const state_ = state.orgs.instance.users.index;
  return {
    membersError: state_.error,
    isRequestPending: state_.isRequestPending,
    members: state_.items,
    membersTotalCount: state_.itemsTotalCount,
    viewParams: state_.viewParams,
    parentOrg: state.orgs.instance.general,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    match: {
      params: {orgId},
    },
  }
) => ({
  onOpen: (params = {}) => dispatch(fetchUsers(orgId, params)),
  onDelete: memberId => dispatch(deleteInvitation(orgId, memberId)),
  resetView: () => dispatch(resetView(orgId)),
  onParentOrgDataRequest: () => dispatch(fetchOrgDetails(orgId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrgMembersIndex);
