import {connect} from 'react-redux';

import * as actions from './actions';
import PlanSubscriptionsBulkCreateSelectOrgsView from './plan-subscription-bulk-create-select-orgs-view';

const mapStateToProps = state =>
  state.plans.instance.subscriptions.bulkCreate.selectOrgs;
const mapDispatchToProps = (dispatch, {params: {planId: rawId}}) => ({
  onRequestOrgs: params => dispatch(actions.fetchOrgs(parseInt(rawId), params)),
  onOrgsSelect: items => dispatch(actions.updateSelectedOrgs(items)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanSubscriptionsBulkCreateSelectOrgsView);
