import Button from '@mui/material/Button';
import React, {Component} from 'react';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {bool, func, number, object, shape, string} from 'prop-types';

import AuditLog from 'admin-ng/components/common/audit-log';
import Card from 'admin-ng/components/common/card';
import Notification from 'admin-ng/components/common/notification';
import TextField from 'admin-ng/components/common/text-field';
import flippers from 'admin-ng/flippers';
import {DATETIME_FORMAT} from 'admin-ng/constants';

export default class PlanCreateGeneral extends Component {
  constructor(props) {
    super(props);

    this._isPlanNameChanged = this._isPlanNameChanged.bind(this);
    this._handlePlanNameCheck = this._handlePlanNameCheck.bind(this);
  }

  componentWillMount() {
    this.props.onReset();
    this.props.onOpen();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.planId !== this.props.match.params.planId) {
      nextProps.onReset();
      nextProps.onOpen();
    }
  }

  _isPlanNameChanged() {
    if (!this.props.planName) {
      return false;
    } else {
      return (
        this.props.planName &&
        this.props.planName.trim() !== this.props.originalPlanName
      );
    }
  }

  _handlePlanNameCheck() {
    if (this.props.planName !== this.props.originalPlanName) {
      this.props.onPlanNameCheck();
    }
  }

  _renderFormControls() {
    if (this._isPlanNameChanged()) {
      return (
        <Stack className="pl-form--controls" direction="row" gap={1}>
          <Button
            className="pl-form--control"
            color="primary"
            disabled={this.props.isPlanNameChecking}
            onClick={!this.props.isPlanNameChecking ? this.props.onSave : null}
            variant="contained"
          >
            Save Changes
          </Button>

          <Button
            className="pl-form--control pl-form--control-default-btn"
            color="inherit"
            onClick={this.props.onCancel}
          >
            Cancel
          </Button>
        </Stack>
      );
    }
  }

  _renderMeta() {
    const author = this.props.authorOrganization;
    return (
      <dl className="meta clearfix">
        <dt>Owned by organization</dt>
        <dd>
          {author && (
            <Link
              key={0}
              title={author.name}
              to={`/organizations/${author.id}`}
            >
              {author.name}
            </Link>
          )}
        </dd>
        <dt>Create date</dt>
        <dd>{moment(this.props.createdAt).utc().format(DATETIME_FORMAT)}</dd>
        <dt>Last updated</dt>
        <dd>{moment(this.props.updatedAt).utc().format(DATETIME_FORMAT)}</dd>
      </dl>
    );
  }

  render() {
    return (
      <div>
        <Card style={{marginBottom: '25px'}}>
          <div className="pl-form--section pl-org-general">
            <h3 className="pl-form--section-title">Details</h3>

            <Notification
              message={this.props.error}
              title="Oops, cound not update the package name"
              type="error"
            />

            <TextField
              error={!!this.props.planNameError}
              helperText={this.props.planNameError}
              label="Package Name *"
              isChanged={this._isPlanNameChanged()}
              isInputValid={this.props.isPlanNameUnique}
              onChange={this.props.onPlanNameUpdate}
              onChangeCompleted={this._handlePlanNameCheck}
              value={this.props.planName}
            />

            {this._renderFormControls()}

            {this._renderMeta()}
          </div>
        </Card>

        {flippers.ENABLE_PLAN_AUDIT_LOG && (
          <AuditLog
            modelId={this.props.match.params.planId}
            modelName="plans"
          />
        )}
      </div>
    );
  }
}

PlanCreateGeneral.propTypes = {
  authorOrganization: shape({
    id: number.isRequired,
    name: string.isRequired,
  }),
  createdAt: string,
  error: string,
  isPlanNameChecking: bool,
  isPlanNameUnique: bool,
  match: object.isRequired,
  onCancel: func,
  onOpen: func,
  onPlanNameCheck: func,
  onPlanNameUpdate: func,
  onReset: func,
  onSave: func,
  originalPlanName: string,
  planName: string,
  planNameError: string,
  updatedAt: string,
};
