import {connect} from 'react-redux';

import PlanSubscriptionBulkCreateConfirmOrgsView from './plan-subscription-bulk-create-confirm-orgs-view';

export default connect(state => {
  const bulkCreateState = state.plans.instance.subscriptions.bulkCreate;
  const {
    ui: {
      interval: quotaInterval,
      amount: quotaAmount,
      isEnabled: isQuotaEnabled,
    },
  } = state.subscriptions.quota;

  return {
    startDate: bulkCreateState.details.startDate,
    endDate: bulkCreateState.details.endDate,
    selectedOrgs: bulkCreateState.selectOrgs.selectedOrgs,
    isQuotaEnabled,
    quotaInterval,
    quotaAmount,
  };
})(PlanSubscriptionBulkCreateConfirmOrgsView);
