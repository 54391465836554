const NAME = 'PLAN_GENERAL';

export const UPDATE_NAME = `${NAME}/UPDATE_NAME`;
export const REQUEST_NAME_CHECK = `${NAME}/REQUEST_NAME_CHECK`;
export const NAME_CHECK_SUCCESS = `${NAME}/NAME_CHECK_SUCCESS`;
export const NAME_CHECK_FAIL = `${NAME}/NAME_CHECK_FAIL`;

export const RESET_GENERAL_PLAN_DETAILS = `${NAME}/RESET_GENERAL_PLAN_DETAILS`;

export const REQUEST_PLAN_NAME_SAVE = `${NAME}/REQUEST_PLAN_NAME_SAVE`;
export const PLAN_NAME_SAVE_REQUEST_COMPLETED = `${NAME}/PLAN_NAME_SAVE_REQUEST_COMPLETED`;
export const PLAN_NAME_SAVE_REQUEST_FAILED = `${NAME}/PLAN_NAME_SAVE_REQUEST_FAILED`;
