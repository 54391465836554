import assign from 'deep-extend';

import * as constants from './constants';
import * as messages from './messages';
import {all as allApiKeys, reset as resetApiKey} from 'admin-ng/api/api-keys';
import {
  all as allOrgs,
  getMember,
  updateMember,
} from 'admin-ng/api/organizations';
import {all as allUsers, update as updateUser} from 'admin-ng/api/users';
import {isEmail, isPasswordValid} from 'admin-ng/util/validation';
import {updateRouteName} from 'admin-ng/components/app/actions';

export const requestUserDetails = (id = null) => ({
  type: constants.REQUEST_USER_DETAILS,
  id,
});

export const receivedUserDetails = details => ({
  type: constants.RECEIVE_USER_DETAILS,
  details,
});

export const userDetailsRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.USER_DETAILS_REQUEST_FAILED,
  error,
});

export const requestOrgs = () => ({
  type: constants.USER_DETAILS_REQUEST_ORGANIZATIONS,
});

export const receiveOrgs = (json = {}) => ({
  type: constants.USER_DETAILS_RECEIVE_ORGANIZATIONS,
  items: json.results || [], // a set of orgs, limited by paging parameters
});

export const requestOrgsFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.USER_DETAILS_ORGANIZATIONS_REQUEST_FAIL,
  error,
});

export const requestUserEmailCheck = (email = null) => ({
  type: constants.REQUEST_USER_EMAIL_CHECK,
  email,
});

export const completeUserEmailCheckRequest = (isEmailUnique, error = null) => ({
  type: constants.USER_EMAIL_CHECK_REQUEST_COMPLETED,
  isEmailUnique,
  error,
});

export const updateEmail = (email = '', error = null) => ({
  type: constants.UPDATE_USER_EMAIL,
  email,
  error,
});

export const updateFirstName = (firstName = '', error = null) => ({
  type: constants.UPDATE_USER_FIRST_NAME,
  firstName,
  error,
});

export const updateLastName = (lastName = '', error = null) => ({
  type: constants.UPDATE_USER_LAST_NAME,
  lastName,
  error,
});

export const updateCountry = (country = '', error = null) => ({
  type: constants.UPDATE_USER_COUNTRY,
  country,
  error,
});

export const updateRole = (role = null, error = null) => ({
  type: constants.UPDATE_MEMBER_ROLE,
  role,
  error,
});

export const updateState = (state = null, error = null) => ({
  type: constants.UPDATE_MEMBER_STATE,
  state,
  error,
});

// Password
export const updatePassword = (password = null, error = null) => ({
  type: constants.UPDATE_MEMBER_PASSWORD,
  password,
  error,
});

export const updatePasswordRepeat = (passwordRepeat = null, error = null) => ({
  type: constants.UPDATE_MEMBER_PASSWORD_REPEAT,
  passwordRepeat,
  error,
});

export const checkPasswordValidity = password => {
  const isValid = isPasswordValid(password);

  return {
    type: constants.CHECK_PASSWORD_VALIDITY,
    payload: {
      valid: isValid,
      message: isValid
        ? null
        : 'Use 10 or more characters with a mix of upper and lowercase letters, numbers, & symbols',
    },
  };
};

export const checkPasswordMatching = (password, repeat) => ({
  type: constants.CHECK_PASSWORD_MATCHING,
  payload: {
    valid: password === repeat,
    message: password === repeat ? null : messages.PASSWORDS_DONT_MATCH,
  },
});

export const resetPassword = () => ({
  type: constants.RESET_PASSWORD,
});

export const requestSaveNewPassword = () => ({
  type: constants.PASSWORD_SAVE_REQUEST,
});

export const passwordSaveFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.PASSWORD_SAVE_REQUEST_FAILED,
  error,
});

export const passwordSaveSuccess = () => ({
  type: constants.PASSWORD_SAVE_REQUEST_SUCCESS,
});

export const saveNewPassword = () => {
  return (dispatch, getState) => {
    const state = getState(),
      initlUserDetails = state.orgs.instance.users.instance.details.user || {},
      newUserDetails = state.orgs.instance.users.instance.newDetails.user || {},
      user = assign({}, initlUserDetails, newUserDetails);

    dispatch(requestSaveNewPassword());

    return updateUser(user.id, user)
      .then(() => {
        dispatch(passwordSaveSuccess());
      })
      .catch((err = {}) => {
        dispatch(passwordSaveFailed(err.message));
      });
  };
};

export const updateData = (params = {}) => {
  return dispatch => {
    switch (params.field) {
      case 'email':
        dispatch(updateEmail(params.value));
        break;
      case 'first_name':
        dispatch(updateFirstName(params.value));
        break;
      case 'last_name':
        dispatch(updateLastName(params.value));
        break;
      case 'country':
        dispatch(updateCountry(params.value));
        break;
      case 'role_level':
        dispatch(updateRole(params.value));
        break;
      case 'state':
        dispatch(updateState(params.value));
        break;
      case 'password':
        dispatch(updatePassword(params.value));
        break;
      case 'passwordRepeat':
        dispatch(updatePasswordRepeat(params.value));
        break;
      default:
        break;
    }
  };
};

export const requestUserDetailsUpdate = (params = null) => ({
  type: constants.REQUEST_USER_DETAILS_UPDATE,
  params,
});

export const completeUserDetailsRequest = () => ({
  type: constants.USER_DETAILS_UPDATE_REQUEST_COMPLETED,
});

export const userDetailsUpdateRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.USER_DETAILS_UPDATE_REQUEST_FAILED,
  error,
});

export const cancelUserDetailsUpdates = () => ({
  type: constants.CANCEL_USER_DETAILS_UPDATES,
});

export const resetState = () => ({
  type: constants.RESET_STATE,
});

export const requestAPIKey = params => ({
  type: constants.REQUEST_API_KEY,
  params,
});

export const receiveAPIKey = (json = {}) => ({
  type: constants.RECEIVE_API_KEY,
  apiKey: json.results && json.results[0] ? json.results[0] : null,
});

export const requestApiKeyReset = () => ({
  type: constants.REQUEST_API_KEY_RESET,
});

export const apiKeyResetRequestCompleted = apiKey => ({
  type: constants.API_KEY_RESET_COMPLETED,
  apiKey,
});

export const apiKeyResetRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.API_KEY_RESET_FAILED,
  error,
});

export const fetchUserDetails = (orgId, memberId, history) => {
  return dispatch => {
    if (orgId && memberId) {
      dispatch(resetState());
      dispatch(requestUserDetails(memberId));

      return getMember(orgId, memberId)
        .then((res = {}) => {
          dispatch(receivedUserDetails(res.body));

          if (
            res.body &&
            res.body.user &&
            res.body.user.first_name &&
            res.body.user.last_name
          ) {
            const memberDetailsPageName =
              res.body.user.first_name + ' ' + res.body.user.last_name;
            dispatch(
              updateRouteName('ORG_MEMBER_DETAILS', memberDetailsPageName)
            );
          }

          const userId =
            res.body && res.body.user && res.body.user.id
              ? res.body.user.id
              : null;

          if (userId) {
            const apiRequestParams = {
              user_id: userId,
              organization_id: orgId,
              state: 'active',
            };

            dispatch(requestAPIKey(apiRequestParams));

            return allApiKeys(apiRequestParams)
              .then((res = {}) => {
                return dispatch(receiveAPIKey(res.body));
              })
              .catch((err = {}) => {
                return dispatch(userDetailsRequestFailed(err.message));
              });
          } else {
            return dispatch(
              userDetailsRequestFailed(
                messages.USER_ID_IS_NOT_SPECIFIED_ERROR_MSG
              )
            );
          }
        })
        .catch((err = {}) => {
          if (err.response && err.response.statusCode === 404) {
            history.push(`/page-not-found?message=${messages.USER_NOT_FOUND}`);
          } else {
            return dispatch(userDetailsRequestFailed(err.message));
          }
        });
    }

    if (!orgId) {
      return dispatch(
        userDetailsRequestFailed(messages.ORG_ID_IS_NOT_SPECIFIED_ERROR_MSG)
      );
    }

    if (!memberId) {
      return dispatch(
        userDetailsRequestFailed(messages.MEMBER_ID_IS_NOT_SPECIFIED_ERROR_MSG)
      );
    }
  };
};

export const checkEmail = email => {
  return dispatch => {
    if (email) {
      const isEmailValid = isEmail(email);

      if (isEmailValid) {
        dispatch(requestUserEmailCheck(email));

        const params = {
          email,
        };

        return allUsers(params)
          .then((res = {}) => {
            const items =
              res.body && res.body.results ? res.body.results : null;

            if (items !== null) {
              if (items.length) {
                dispatch(
                  completeUserEmailCheckRequest(
                    false,
                    messages.EMAIL_IS_NOT_UNIQUE_ERROR_MSG
                  )
                );
              } else {
                dispatch(completeUserEmailCheckRequest(true));
              }
            } else {
              dispatch(
                completeUserEmailCheckRequest(false, messages.DEFAULT_ERROR_MSG)
              );
            }
          })
          .catch((err = {}) => {
            dispatch(
              completeUserEmailCheckRequest(
                false,
                err.message || messages.DEFAULT_ERROR_MSG
              )
            );
          });
      } else {
        return dispatch(
          completeUserEmailCheckRequest(
            false,
            messages.EMAIL_IS_INVALID_ERROR_MSG
          )
        );
      }
    } else {
      return dispatch(
        completeUserEmailCheckRequest(
          false,
          messages.EMAIL_IS_NOT_SPECIFIED_ERROR_MSG
        )
      );
    }
  };
};

export const updateUserDetails = (orgId, memberId) => {
  return (dispatch, getState) => {
    if (orgId && memberId) {
      const state = getState(),
        initMembershipDetails = state.orgs.instance.users.instance.details,
        newMembershipDetails = state.orgs.instance.users.instance.newDetails,
        initlUserDetails = initMembershipDetails.user || {},
        newUserDetails = newMembershipDetails.user || {};

      const params = Object.assign(
        {},
        initMembershipDetails,
        newMembershipDetails,
        {
          user: Object.assign({}, initlUserDetails, newUserDetails),
        }
      );

      // Require country only when it was already set on the user
      let validCountry = true;
      if (
        initlUserDetails.country &&
        (params.user.country === '__none__' || !params.user.country)
      ) {
        validCountry = false;
      }

      if (
        params.user.email &&
        params.user.first_name &&
        params.user.last_name &&
        validCountry
      ) {
        // can't use null as it screws up floating label. Therefore special value __none__
        params.user.country =
          params.user.country === '__none__' ? null : params.user.country;

        dispatch(requestUserDetailsUpdate(params));

        return updateUser(params.user.id, params.user)
          .then(() => {
            return updateMember(orgId, memberId, params)
              .then(() => {
                dispatch(completeUserDetailsRequest());
              })
              .catch((err = {}) => {
                dispatch(userDetailsUpdateRequestFailed(err.message));
              });
          })
          .catch((err = {}) => {
            dispatch(userDetailsUpdateRequestFailed(err.message));
          });
      }

      if (!params.user.email) {
        dispatch(updateEmail('', messages.EMAIL_IS_NOT_SPECIFIED_ERROR_MSG));
      }

      if (!params.user.first_name) {
        dispatch(
          updateFirstName('', messages.FIRST_NAME_NOT_SPECIFIED_ERROR_MSG)
        );
      }

      if (!params.user.last_name) {
        dispatch(
          updateLastName('', messages.LAST_NAME_NOT_SPECIFIED_ERROR_MSG)
        );
      }

      if (!validCountry) {
        dispatch(updateCountry('', messages.COUNTRY_NOT_SPECIFIED_ERROR_MSG));
      }
    }

    if (!orgId) {
      return dispatch(
        userDetailsUpdateRequestFailed(
          messages.ORG_ID_IS_NOT_SPECIFIED_ERROR_MSG
        )
      );
    }

    if (!memberId) {
      return dispatch(
        userDetailsUpdateRequestFailed(
          messages.MEMBER_ID_IS_NOT_SPECIFIED_ERROR_MSG
        )
      );
    }
  };
};

export const resetUserApiKey =
  ({auth}) =>
  async (dispatch, getState) => {
    const {apiKey} = getState().orgs.instance.users.instance;

    if (!apiKey) {
      return;
    }

    dispatch(requestApiKeyReset());
    try {
      const {body: newApiKey} = await resetApiKey(apiKey.id);
      const userId = auth.user.claims.user_id;
      if (userId === apiKey.user_id) {
        return auth.signOut();
      }
      dispatch(apiKeyResetRequestCompleted(newApiKey));
    } catch (err) {
      dispatch(apiKeyResetRequestFailed(err && err.message));
    }
  };

export const requestOrgChange = id => ({
  type: constants.USER_DETAILS_ORG_CHANGE_REQUESTED,
  id,
});

export const requestOrgChangeCompleted = () => ({
  type: constants.USER_DETAILS_ORG_CHANGE_SUCCESS,
});

export const requestOrgChangeFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.USER_DETAILS_ORG_CHANGE_FAIL,
  error,
});

export const changeOrg = (orgId, memberId, newParentOrgId) => {
  return (dispatch, getState) => {
    if (orgId && memberId && newParentOrgId) {
      const state = getState(),
        initMembershipDetails = state.orgs.instance.users.instance.details;

      dispatch(requestOrgChange(newParentOrgId));

      const params = Object.assign({}, initMembershipDetails, {
        organization_id: newParentOrgId,
      });

      return updateMember(orgId, memberId, params)
        .then(() => {
          dispatch(requestOrgChangeCompleted());
        })
        .catch((err = {}) => {
          dispatch(requestOrgChangeFailed(err.message));
        });
    }

    if (!orgId) {
      return dispatch(
        userDetailsUpdateRequestFailed(
          messages.ORG_ID_IS_NOT_SPECIFIED_ERROR_MSG
        )
      );
    }

    if (!memberId) {
      return dispatch(
        userDetailsUpdateRequestFailed(
          messages.MEMBER_ID_IS_NOT_SPECIFIED_ERROR_MSG
        )
      );
    }

    if (!newParentOrgId) {
      return dispatch(
        userDetailsUpdateRequestFailed(
          messages.NEW_ORG_ID_IS_NOT_SPECIFIED_ERROR_MSG
        )
      );
    }
  };
};

export const fetchOrgs = (currentOrgId, name) => dispatch => {
  dispatch(requestOrgs());

  const requestParams = {
    search: name,
    limit: 1000,
    id__notin: currentOrgId,
  };

  return allOrgs(requestParams)
    .then((res = {}) => {
      dispatch(receiveOrgs(res.body));
    })
    .catch((err = {}) => {
      dispatch(requestOrgsFailed(err.message));
    });
};
