import assign from 'deep-extend';

import * as constants from './constants';

const initialState = {
  error: null,
  isDataRequestPending: false,
  isDeleteRequestPending: false,
  items: [],
  itemsTotalCount: 0,
  plansTotalCount: 0,
  isFetchingPlans: false,
  plansError: null,
  quotaUsages: {},
  viewParams: {
    filters: 'active,expired,inactive,inactive_plan',
    limit: 20,
    offset: 0,
    sort: 'plan.name',
    search: '',
  },
};

export default function orgSubscriptionsIndexReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.REQUEST_PLAN_SUBSCRIPTIONS:
      return assign({}, state, {
        error: null,
        isDataRequestPending: true,
        viewParams: action.params,
      });

    case constants.RECEIVE_PLAN_SUBSCRIPTIONS:
      return assign({}, state, {
        isDataRequestPending: false,
        items: action.items,
        itemsTotalCount: action.itemsTotalCount,
      });

    case constants.PLAN_SUBSCRIPTIONS_REQUEST_FAILED:
      return assign({}, state, {
        isDataRequestPending: false,
        error: action.error,
      });

    case constants.REQUEST_SUBSCRIPTION_DELETE:
      return assign({}, state, {
        isDeleteRequestPending: true,
        error: null,
      });

    case constants.DELETE_SUBSCRIPTION_REQUEST_COMPLETED:
      return assign({}, state, {
        isDeleteRequestPending: false,
      });

    case constants.DELETE_SUBSCRIPTION_REQUEST_FAILED:
      return assign({}, state, {
        isDeleteRequestPending: false,
        error: action.error,
      });

    case constants.UPDATE_SUBSCRIPTIONS_QUOTA_USAGE:
      return assign({}, state, {
        quotaUsages: action.payload,
      });
    // fetch plans
    case constants.FETCH_PLANS_START:
      return assign({}, state, {
        isFetchingPlans: true,
        plansError: null,
      });
    case constants.FETCH_PLANS_SUCCESS:
      return assign({}, state, {
        isFetchingPlans: false,
        plansError: null,
        plansTotalCount: action.plansTotalCount,
      });
    case constants.FETCH_PLANS_FAIL:
      return assign({}, state, {
        isFetchingPlans: false,
        plansError: action.error,
        plansTotalCount: 0,
      });

    case constants.RESET:
      return assign({}, initialState);

    default:
      return assign({}, state);
  }
}
