import * as constants from './constants';

const initial = {
  deprecated: false,
  documentSaveError: null,
  externalName: '',
  id: null,
  isCheckingName: false,
  isDocumentSaved: null,
  isNameUnique: null,
  isSaveRequestPending: false,
  name: '',
  nameError: null,
  requiredForSignup: false,
  separate: false,
  text: '',
};

export default function documentEditReducer(state = initial, action = {}) {
  const {
    deprecated,
    externalName,
    id,
    isNameUnique,
    name,
    requiredForSignup,
    separate,
    text,
    type,
  } = action;
  switch (type) {
    case constants.INITIALIZE_DETAILS:
      return {
        ...state,
        deprecated,
        externalName,
        id,
        name,
        requiredForSignup,
        separate,
        text,
      };

    case constants.UPDATE_NAME:
      return {
        ...state,
        name,
      };

    case constants.UPDATE_EXTERNAL_NAME:
      return {
        ...state,
        externalName,
      };

    case constants.UPDATE_TEXT:
      return {
        ...state,
        text,
      };

    case constants.TOGGLE_CHECKBOX: {
      const newState = {...state};
      newState[action.boxname] = !state[action.boxname];
      return newState;
    }

    case constants.REQUEST_NAME_CHECK:
      return {
        ...state,
        isCheckingName: true,
        nameError: null,
      };

    case constants.REQUEST_NAME_CHECK_SUCCESS:
      return {
        ...state,
        isCheckingName: false,
        isNameUnique,
        nameError: null,
      };

    case constants.REQUEST_NAME_CHECK_FAIL:
      return {
        ...state,
        isCheckingName: false,
        nameError: action.error,
      };

    case constants.REQUEST_SAVE:
      return {
        ...state,
        isSaveRequestPending: true,
      };

    case constants.REQUEST_SAVE_SUCCESS:
      return {
        ...state,
        isDocumentSaved: true,
        isSaveRequestPending: false,
      };

    case constants.REQUEST_SAVE_FAIL:
      return {
        ...state,
        documentSaveError: action.error,
        isDocumentSaved: false,
        isSaveRequestPending: false,
      };

    case constants.RESET_STATE:
      return {...initial};

    default:
      return state;
  }
}
