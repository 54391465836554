import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, {Component} from 'react';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';
import {bool, func, object, string} from 'prop-types';

import AOITools from 'admin-ng/components/common/aoi-tools';
import MapView from 'admin-ng/components/common/map-view';
import Textarea from 'admin-ng/components/common/textarea';
import {WORLD_GEO_JSON} from 'admin-ng/constants';

class AOIItem extends Component {
  state = {
    isDirty: null,
  };

  _onFileChange = ({target: {files}}) => {
    const file = files[0];
    if (file) {
      this.props.onFileUpload(file);
    }
  };

  _selectWholeWorld = () =>
    this.props.onUpdate(WORLD_GEO_JSON, true, null, null);

  _onChange = value => this.props.onUpdate(value, true);

  _onError = (error, value) => this.props.onUpdate(value, false, error);

  _getArea = (value, isValid) => {
    if (!isValid) {
      return [null, false];
    }
    try {
      let areaProperty;
      const geojson = JSON.parse(value);
      if ('properties' in geojson && 'area' in geojson.properties) {
        areaProperty = geojson.properties.area;
        return [areaProperty, false];
      } else {
        return [null, true];
      }
    } catch (error) {
      return [null, true];
    }
  };

  render() {
    const {
      canDelete,
      disabled,
      isChanged,
      item,
      onDelete,
      showTools,
      textareaQaId,
    } = this.props;
    const value = item.value || '';
    const {error, isValid} = item;
    const selectWholeWorldDisabled = value === WORLD_GEO_JSON;
    const textFieldClassname = classNames({
      'pl-aoi-item--field': true,
      changed: isChanged,
      invalid: error,
    });
    const [area, showAreaWarning] = this._getArea(value, isValid);

    return (
      <div className="pl-aoi-item">
        {showTools && !disabled && (
          <AOITools
            onFileUpload={this._onFileChange}
            onSelectWholeWorld={this._selectWholeWorld}
            selectWholeWorldDisabled={selectWholeWorldDisabled}
          />
        )}

        <div className="pl-aoi-item-content clearfix">
          <div className={textFieldClassname}>
            {error ? (
              <Tooltip placement="bottom-start" title={error.message || error}>
                <ErrorOutlineIcon
                  className="pl-aoi-item-field-error"
                  color="error"
                />
              </Tooltip>
            ) : null}

            <Textarea
              disabled={disabled}
              label="Area GeoJSON"
              maxRows={11}
              minRows={11}
              onChange={this._onChange}
              qaId={textareaQaId}
              sx={{marginTop: 2}}
              value={value}
            />
          </div>

          <MapView
            area={area}
            isValid={isValid}
            onDelete={!disabled && canDelete ? onDelete : null}
            onError={this._onError}
            showAreaWarning={showAreaWarning}
            value={value}
          />
        </div>
      </div>
    );
  }
}

AOIItem.propTypes = {
  canDelete: bool,
  disabled: bool,
  isChanged: bool,
  item: object.isRequired,
  onDelete: func,
  onFileUpload: func,
  onUpdate: func.isRequired,
  showTools: bool,
  textareaQaId: string.isRequired,
};

AOIItem.defaultProps = {
  disabled: false,
  canDelete: false,
  isChanged: false,
  onDelete: null,
  onFileUpload: null,
  showTools: true,
};

export default AOIItem;
