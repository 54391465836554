import {bindActionCreators as bind} from 'redux';
import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import OrgCommentsIndex from './org-comments-index';
import {ROLES} from 'admin-ng/constants';
import {fetchComments as unboundFetchComments} from './actions';

const mapStateToProps = (
  {
    orgs: {
      instance: {
        general: {
          comments: {index},
        },
      },
    },
  },
  {
    orgId,
    auth: {
      user: {
        claims: {user_id, role_level},
      },
    },
  }
) => {
  return {
    ...index,
    orgId,
    userId: user_id,
    isPlanetAdmin: role_level === ROLES.planetAdmin,
  };
};

const mapDispatchToProps = (dispatch, {orgId}) => {
  const fetchComments = unboundFetchComments.bind(null, orgId);
  return {
    onOpen: bind(fetchComments, dispatch),
  };
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(OrgCommentsIndex)
);
