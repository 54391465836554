import * as constants from './constants';
import * as messages from './messages';
import {one} from 'admin-ng/api/documents';
import {updateRouteName} from 'admin-ng/components/app/actions';

export const requestDocumentDetails = id => ({
  type: constants.REQUEST_DOCUMENT_DETAILS,
  id,
});

export const requestDocumentDetailsSuccess = (details = null) => ({
  type: constants.REQUEST_DOCUMENT_DETAILS_SUCCESS,
  details,
});

export const requestDocumentDetailsFail = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_DOCUMENT_DETAILS_FAIL,
  error,
});

export const fetchDocumentDetails = (id, history) => {
  return function (dispatch) {
    dispatch(requestDocumentDetails(id));

    return one(id)
      .then((res = {}) => {
        dispatch(requestDocumentDetailsSuccess(res.body));
        dispatch(updateRouteName('DOC_DETAIL', res.body.name));
      })
      .catch((err = {}) => {
        if (err.response && err.response.statusCode === 404) {
          history.push(`/page-not-found?message=${messages.DOC_NOT_FOUND}`);
        } else {
          dispatch(requestDocumentDetailsFail(err.message));
        }
      });
  };
};

export const resetState = () => ({
  type: constants.RESET_STATE,
});
