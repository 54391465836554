import assign from 'deep-extend';

import * as constants from './constants';
import * as messages from './messages';
import {all, update} from 'admin-ng/api/organizations';

export const requestSuborgs = (params = {}) => ({
  type: constants.SUBORGS_LIST_REQUEST,
  params,
});

export const receiveSuborgs = (json = {}) => ({
  type: constants.SUBORGS_LIST_SUCCESS,
  items: json.results || [], // a set of orgs, limited by paging parameters
  itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0, // the total number of all orgs in the db
});

export const requestSuborgsFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.SUBORGS_LIST_FAIL,
  error,
});

export const requestSuborgUnlink = suborg => ({
  type: constants.SUBORGS_LIST_UNLINK_SUBORG,
  suborg,
});

export const requestSuborgUnlinkFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.SUBORGS_LIST_UNLINK_SUBORG_FAIL,
  error,
});

export const resetState = () => ({
  type: constants.SUBORGS_LIST_RESET_STATE,
});

export const fetchSuborgs = (parentId, params) => {
  return function (dispatch, getState) {
    const state = getState(),
      _state = state.orgs.instance.suborgs.index;

    dispatch(requestSuborgs(params));

    let requestParams = assign({}, _state.viewParams, {
      parent_organization_id: parentId,
    });

    if (params) {
      requestParams = assign({}, requestParams, params);
    }

    // if search is epmty, no need to send the parameter, otherwise it will activate
    // a redundant process on the back-end
    if (requestParams.hasOwnProperty('search') && !requestParams.search) {
      delete requestParams.search;
    }

    if (requestParams.filters) {
      requestParams.state__in = requestParams.filters;
    }
    delete requestParams.filters;

    return all(requestParams)
      .then((res = {}) => {
        dispatch(receiveSuborgs(res.body));
      })
      .catch((err = {}) => {
        dispatch(requestSuborgsFailed(err.message));
      });
  };
};

export const suborgUnlink = suborg => dispatch => {
  const parentId = suborg.parent_organization_id;
  const params = assign({}, suborg, {
    parent_organization: null,
    parent_organization_id: null,
  });

  dispatch(requestSuborgUnlink(suborg));

  return update(suborg.id, params)
    .then(() => {
      dispatch(fetchSuborgs(parentId));
    })
    .catch((err = {}) => {
      dispatch(requestSuborgUnlinkFailed(err.message));
    });
};
