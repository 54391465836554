import Button from '@mui/material/Button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, {Component} from 'react';
import Tooltip from '@mui/material/Tooltip';
import countriesList from 'countries-list';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import DataState from 'admin-ng/components/common/data-state';
import IconButton from 'admin-ng/components/common/icon-button';
import Notification from 'admin-ng/components/common/notification';
import OrgMemberCreateContainer from '../org-member-create';
import OrgMemberInviteContainer from '../org-member-invite/org-member-invite-container';
import Pagination from 'admin-ng/components/common/pagination';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import flippers from 'admin-ng/flippers';
import {DATE_FORMAT} from 'admin-ng/constants';

export default class OrgMembersIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreateFormOpen: false,
      isInviteFormOpen: false,
      showDeleteConfirmation: false,
      itemToDelete: null,
    };

    this._emailColumnContentCreator =
      this._emailColumnContentCreator.bind(this);
    this._stateColumnContentCreator =
      this._stateColumnContentCreator.bind(this);
    this._actionsContentCreator = this._actionsContentCreator.bind(this);

    this._openCreateForm = this._openCreateForm.bind(this);
    this._closeCreateForm = this._closeCreateForm.bind(this);

    this._openInviteForm = this._openInviteForm.bind(this);
    this._closeInviteForm = this._closeInviteForm.bind(this);

    this._onDelete = this._onDelete.bind(this);
    this._onDeleteConfirmed = this._onDeleteConfirmed.bind(this);
    this._onDeleteClose = this._onDeleteClose.bind(this);
  }

  componentDidMount() {
    this.props.resetView();

    if (!this.props.parentOrg.details) {
      this.props.onParentOrgDataRequest();
    }
  }

  componentWillReceiveProps(nextProps) {
    nextProps.match.params.orgId !== this.props.match.params.orgId &&
      nextProps.resetView();
  }

  _emailColumnContentCreator(item) {
    if (item.email) {
      switch (item.state) {
        case 'deleted':
          return item.email;
        case 'pending': {
          const sender = `${item.user.first_name} ${item.user.last_name}`;
          return <span title={`Invited by ${sender}`}>{item.email}</span>;
        }
        default:
          return (
            <Link
              to={`/organizations/${this.props.match.params.orgId}/members/${item.id}`}
            >
              {item.user.email}
            </Link>
          );
      }
    }

    return '—';
  }

  _stateColumnContentCreator(item) {
    let sender;
    if (item.state === 'pending') {
      const senderName = `${item.user.first_name} ${item.user.last_name}`;
      sender = (
        <Tooltip title={`Invited by ${senderName}`}>
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      );
    }
    return item.state ? (
      <div className="pl-item-state">
        <DataState state={item.state} />
        {sender}
      </div>
    ) : (
      '—'
    );
  }

  _actionsContentCreator(item) {
    return item.source === 'invite' && item.state !== 'deleted' ? (
      <IconButton icon="delete" onClick={this._onDelete.bind(this, item)} />
    ) : null;
  }

  _openCreateForm() {
    this.setState({
      isCreateFormOpen: true,
    });
  }

  _closeCreateForm() {
    this.setState({
      isCreateFormOpen: false,
    });
  }

  _openInviteForm() {
    this.setState({
      isInviteFormOpen: true,
    });
  }

  _closeInviteForm() {
    this.setState({
      isInviteFormOpen: false,
    });
  }

  _onDelete(item) {
    this.setState({
      itemToDelete: item,
      showDeleteConfirmation: true,
    });
  }

  _onDeleteConfirmed() {
    this.props.onDelete(this.state.itemToDelete.id);
    this._onDeleteClose();
  }

  _onDeleteClose() {
    this.setState({
      showDeleteConfirmation: false,
      itemToDelete: null,
    });
  }

  /**
   * This function returns data structure for organizaiton to be correctly
   * displayed in the a reusable component Table
   *
   * A single object represents a table column.
   *
   *     field - (required) property name in the data object (the same which is returned by api)
   *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
   *                          different from the field name.
   *     label - (required) column name.
   *     width - (required) column width to be set in the styles in the table header and table body
   *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
   *     contentCreator - [optional] - func - can be used for creating custom column content
   */
  _getOrgDataSchema() {
    return [
      {
        field: 'email',
        sortKey: 'email',
        label: 'Email',
        width: '15%',
        contentCreator: this._emailColumnContentCreator,
      },
      {
        field: 'first_name',
        sortKey: 'first_name',
        label: 'First name',
        width: '15%',
        contentCreator: item =>
          item.source === 'membership' ? item.user.first_name : '—',
      },
      {
        field: 'last_name',
        sortKey: 'last_name',
        label: 'Last name',
        width: '15%',
        contentCreator: item =>
          item.source === 'membership' ? item.user.last_name : '—',
      },
      {
        field: 'country',
        sortKey: 'country',
        label: 'Country',
        width: '12%',
        contentCreator: item => {
          if (item.source === 'invite') {
            return '—';
          }
          const country = countriesList.countries[item.user.country];
          return country && country.name ? country.name : item.user.country;
        },
      },
      {
        field: 'role',
        label: 'Role',
        width: '14%',
      },
      {
        field: 'created_at',
        label: 'Created',
        width: '9%',
        contentCreator: item => {
          const source =
            item.source === 'invite' ? item.created_at : item.user.created_at;
          if (source) {
            const time = moment(source);
            return (
              <span title={time.toISOString()}>{time.format(DATE_FORMAT)}</span>
            );
          }
        },
      },
      {
        field: 'state',
        label: 'State',
        width: '11%',
        contentCreator: this._stateColumnContentCreator,
      },
      {
        field: '',
        label: null,
        widht: '10%',
        contentCreator: this._actionsContentCreator,
      },
    ];
  }

  render() {
    const userFilterNames = ['active', 'pending', 'deleted', 'disabled'];
    const tablePanelActions = [];

    if (flippers.ENABLE_INVITATIONS) {
      tablePanelActions.push(
        <Button
          color="primary"
          data-qa-id="org-members-invite-button"
          key={1}
          onClick={this._openInviteForm}
          variant="contained"
        >
          Invite
        </Button>
      );
    }

    tablePanelActions.push(
      <Button
        color="primary"
        data-qa-id="org-members-add-button"
        key={2}
        onClick={this._openCreateForm}
        variant="contained"
      >
        Add
      </Button>
    );

    return (
      <div className="pl-org-members-page">
        <Card>
          <div className="pl-table-card-content">
            <TableControlsPanel
              actions={tablePanelActions}
              activeFilters={this.props.viewParams.filters}
              filters={userFilterNames}
              itemsPerPageLimit={this.props.viewParams.limit}
              onFilter={this.props.onOpen}
            />

            <Notification
              message={this.props.membersError}
              title="Oops, members request has failed"
              type="error"
            />

            <ConfirmationMessage
              onClose={this._onDeleteClose}
              onSubmit={this._onDeleteConfirmed}
              open={this.state.showDeleteConfirmation}
              title="Delete invitation"
            >
              {`Are you sure you want to delete invitation for ${this.state.itemToDelete ? this.state.itemToDelete.email : ''}?`}
            </ConfirmationMessage>

            <OrgMemberInviteContainer
              isOpen={this.state.isInviteFormOpen}
              onClose={this._closeInviteForm}
              params={this.props.match.params}
            />

            <OrgMemberCreateContainer
              isOpen={this.state.isCreateFormOpen}
              onClose={this._closeCreateForm}
              onSubmit={this.props.resetView}
              params={this.props.match.params}
            />

            <Table
              className="pl-table-index"
              dataSchema={this._getOrgDataSchema()}
              isRequestPending={this.props.isRequestPending}
              onSort={this.props.onOpen}
              qaId="organization-members-table"
              sortBy={this.props.viewParams.sort}
              srcData={this.props.members}
            />

            <Pagination
              itemsLimit={this.props.viewParams.limit}
              itemsOffset={this.props.viewParams.offset}
              itemsTotal={this.props.membersTotalCount}
              onPageChange={this.props.onOpen}
            />
          </div>
        </Card>
      </div>
    );
  }
}

OrgMembersIndex.propTypes = {
  isRequestPending: bool,
  match: object.isRequired,
  members: arrayOf(object),
  membersError: string,
  membersTotalCount: number.isRequired,
  onDelete: func.isRequired,
  onOpen: func.isRequired,
  onParentOrgDataRequest: func,
  parentOrg: object,
  resetView: func.isRequired,
  viewParams: object.isRequired,
};
