import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import React from 'react';
import {bool, func, object, string} from 'prop-types';

const PlCheckbox = ({
  checked,
  disabled,
  fieldInstructions,
  label,
  name,
  onChange,
  qaId,
  style,
}) => {
  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            className="pl-checkbox"
            disabled={disabled}
            name={name}
            onChange={onChange}
            style={style}
          />
        }
        label={label ?? ''}
        data-qa-id={qaId}
      />

      {fieldInstructions && (
        <FormHelperText className="pl-field-instructions" sx={{marginTop: 0}}>
          {fieldInstructions}
        </FormHelperText>
      )}
    </Box>
  );
};

PlCheckbox.propTypes = {
  checked: bool,
  disabled: bool,
  fieldInstructions: string,
  label: string,
  name: string,
  onChange: func,
  qaId: string,
  style: object,
};

export default PlCheckbox;
