import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import TileQuotaForm from './quota';
import {ROLES} from 'admin-ng/constants';
import {
  basemapTileQuotaActions,
  quadQuotaActions,
  sceneTileQuotaActions,
} from './actions';

const mapStateToProps = key => {
  return (state, ownProps) => {
    const roleLevel = ownProps.auth.user.claims.role_level;
    const _state = state.subscriptions[key];
    const {
      ui: {enabled, refresh: uiRefresh, overage: uiOverage, limit: uiLimit},
      data: {refresh: dataInterval, overage: dataOverage, limit: dataLimit},
    } = _state;
    const isRefreshChanged = Boolean(enabled && dataInterval !== uiRefresh);
    const isOverageChanged = Boolean(enabled && dataOverage !== uiOverage);
    const isLimitChanged = Boolean(enabled && dataLimit !== uiLimit);
    return {
      ..._state,
      ..._state.ui,
      isPlanetAdmin: roleLevel >= ROLES.planetAdmin,
      isRefreshChanged,
      isOverageChanged,
      isLimitChanged,
    };
  };
};

const mapDispatchToProps = actions => {
  return {
    toggleInfinite: actions.toggleInfinite,
    toggleEnable: actions.toggleEnabled,
    onLimitChange: actions.updateLimit,
    onRefreshChange: actions.updateRefresh,
    onOverageChange: actions.updateOverage,
  };
};

export const BasemapTileQuota = withAuth(
  connect(
    mapStateToProps('basemapTileQuota'),
    mapDispatchToProps(basemapTileQuotaActions)
  )(TileQuotaForm)
);

export const QuadQuota = withAuth(
  connect(
    mapStateToProps('basemapQuadQuota'),
    mapDispatchToProps(quadQuotaActions)
  )(TileQuotaForm)
);

export const SceneTileQuota = withAuth(
  connect(
    mapStateToProps('sceneTileQuota'),
    mapDispatchToProps(sceneTileQuotaActions)
  )(TileQuotaForm)
);
