import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classNames from 'classnames';
import grey from '@mui/material/colors/grey';
import {arrayOf, func, object, string} from 'prop-types';

const grey600 = grey['600'];

// Overriding default material-ui table styles
const styles = {
  tableHeader: {
    borderBottom: 'none',
  },
  tableHeaderRow: {
    height: '35px',
    borderBottom: 'none',
  },
};

const PlTableHeader = props => {
  const isSortReversed = sortType => sortType.charAt(0) === '-';

  const sortByColumn = sortType => {
    if (!props.sortBy) {
      return;
    }
    let sort;
    if (sortType !== props.sortBy) {
      sort = sortType;
    } else {
      sort = isSortReversed(sortType) ? sortType.splice(0, 1) : `-${sortType}`;
    }
    props.onSort({sort});
  };

  const renderColumn = (sortType, name) => {
    const sortable = props.sortBy && sortType;

    if (!name) {
      return;
    }
    let isReversed;
    // checking both ascending and descending options
    if (props.sortBy === sortType || props.sortBy === `-${sortType}`) {
      isReversed = isSortReversed(props.sortBy);
    }
    const classes = classNames({
      'pl-table--header-column-name': true,
      'sort-desc': sortable && isReversed,
      'sort-asc': sortable && isReversed === false,
    });
    return (
      <div
        className={classes}
        data-qa-id={`sort-${sortType.toLowerCase()}`}
        onClick={sortByColumn.bind(this, sortType)}
      >
        {name}
      </div>
    );
  };

  const renderColumns = () =>
    props.dataSchema.map((column, key) => {
      const sortBy = column.sortKey || column.field;
      const isSortable = !(!sortBy || column.isSortable === false);
      return (
        <TableCell
          // eslint-disable-next-line react/no-array-index-key
          key={key}
          style={{
            fontSize: '15px',
            height: '35px',
            fontWeight: 'bold',
            color: grey600,
            width: column.width,
            paddingLeft: 14,
            paddingRight: 14,
          }}
        >
          {isSortable ? renderColumn(sortBy, column.label) : column.label}
        </TableCell>
      );
    });

  // Remove props we don't want to pass on to the MUI component when we spread
  const {
    dataSchema: _dataSchema,
    onSort: _onSort,
    sortBy: _sortBy,
    ...strippedProps
  } = props;

  return (
    <TableHead
      {...strippedProps}
      className="pl-table--header"
      style={styles.tableHeader}
    >
      <TableRow style={styles.tableHeaderRow}>
        {props.dataSchema && renderColumns()}
      </TableRow>
    </TableHead>
  );
};

PlTableHeader.muiName = TableHead.muiName;
PlTableHeader.propTypes = {
  dataSchema: arrayOf(object).isRequired,
  onSort: func,
  sortBy: string,
};

export default PlTableHeader;
