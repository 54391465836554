import assign from 'deep-extend';
import moment from 'moment';

import * as constants from './constants';

const initialState = {
  activeToError: null,
  activeFromError: null,
  data: {
    activeFrom: null,
    activeTo: null,
    isActiveFromToday: false,
  },
  ui: {
    activeFrom: null,
    activeTo: null,
    isActiveFromToday: false,
  },
};

export default function orgSubscriptionDetailsReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.RECEIVE_SUBSCRIPTION_DETAILS: {
      const {active_from: activeFrom, active_to: activeTo} = action.payload;
      const data = {
        activeFrom,
        activeTo,
        isActiveFromToday: moment.utc().isSame(moment.utc(activeFrom), 'day'),
      };
      const returnState = assign({}, state, {
        data,
        ui: data,
      });
      return returnState;
    }

    case constants.ACTIVE_FROM_UPDATED: {
      const {
        error,
        payload: {activeFrom},
        payload: {isActiveFromToday},
      } = action;
      return assign({}, state, {
        ui: {
          activeFrom,
          isActiveFromToday,
        },
        activeFromError: error ? error : null,
      });
    }

    case constants.ACTIVE_TO_UPDATED: {
      const {
        error,
        payload: {activeTo},
      } = action;
      return assign({}, state, {
        ui: {
          activeTo,
        },
        activeToError: error ? error : null,
      });
    }

    case constants.RESET_STATE: {
      return {...initialState};
    }

    default:
      return state;
  }
}
