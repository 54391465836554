import {connect} from 'react-redux';

import BulkScreening from './bulk-screening';
import {
  check,
  reset,
  toggleCanada,
  toggleEU,
  toggleUSA,
  updateNames,
} from './actions';

const mapStateToProps = ({compliance: {bulk}}) => bulk;
const mapDispatchToProps = {
  updateNames,
  toggleCanada,
  toggleUSA,
  toggleEU,
  check,
  reset,
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkScreening);
