import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import {array, bool, func, number, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import SelectField from 'admin-ng/components/common/select-field';

const ItemType = props => {
  const _onChange = event => {
    const {value} = event.target;
    props.onUpdateItemType(props.index, value);
    if (value) {
      props.onItemTypeChanged(null, props.index, value);
    }
  };

  const _isEnabled = itemType => {
    const values = props.selectedItemTypes.map(itemType => itemType.value);
    const valueCount = values.filter(id => id).length;

    if (
      // If all entries are empty, always select anything
      valueCount === 0 ||
      // One or more entries, only one entry has a value
      (valueCount === 1 && props.itemType.value) ||
      // One or more entries, multiple entries have a value
      (valueCount > 1 && itemType.id === props.itemType.value)
    ) {
      return true;
    }

    const selectedItemCategory = props.selectedItemTypes.filter(
      itemType => itemType.value
    )[0].category;
    const isSameCategory = itemType.category === selectedItemCategory;
    const isNotUsedElseWhere = !values.includes(itemType.id);

    // Filter out invalid options for the empty itemTypes
    return isSameCategory && isNotUsedElseWhere;
  };

  const _createMenuItem = (itemType, key) => (
    <MenuItem
      data-qa-id={itemType.id}
      disabled={!_isEnabled(itemType)}
      key={key}
      value={itemType.id}
    >
      {itemType.display_name}
    </MenuItem>
  );

  const _renderAvailableItemTypes = () => {
    const items = [];
    let index = 0;

    const dataV1 = props.availableItemTypes.filter(
      itemType => itemType.category === 'data_v1'
    );
    const basemap = props.availableItemTypes.filter(
      itemType => itemType.category === 'basemap'
    );
    const series = props.availableItemTypes.filter(
      itemType => itemType.category === 'series'
    );

    items.push(
      dataV1.map(itemType =>
        _createMenuItem(itemType, `item-type-element-${index++}`)
      )
    );
    items.push(<Divider key={`item-type-element-${index++}`} />);
    items.push(
      basemap.map(itemType =>
        _createMenuItem(itemType, `item-type-element-${index++}`)
      )
    );
    items.push(<Divider key={`item-type-element-${index++}`} />);
    items.push(
      series.map(itemType =>
        _createMenuItem(itemType, `item-type-element-${index++}`)
      )
    );

    return items;
  };

  const itemType = props.itemType;

  const options = _renderAvailableItemTypes();

  const itemTypeIds = props.availableItemTypes.map(itemType => itemType.id);

  return (
    <>
      <Notification
        message={props.error}
        title="Oops, could not request item types"
        type="error"
      />

      <SelectField
        className={props.isItemTypeChanged ? 'changed' : ''}
        error={!!itemType.error}
        helperText={itemType.error}
        label="Item Type *"
        onChange={_onChange}
        qaId={`policy-item-type-${props.index}-select`}
        value={itemTypeIds.includes(itemType.value) ? itemType.value : ''}
      >
        {options}
      </SelectField>
    </>
  );
};

ItemType.propTypes = {
  availableItemTypes: array.isRequired,
  error: string,
  index: number,
  isItemTypeChanged: bool,
  itemType: object.isRequired,
  onItemTypeChanged: func.isRequired,
  onUpdateItemType: func.isRequired,
  selectedItemTypes: array.isRequired,
};

export default ItemType;
