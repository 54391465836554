import * as constants from './constants';

const initial = {
  error: null,
  isLoading: false,
  records: [],
  totalCount: 0,
  isPristine: true,
  params: {
    filters: 'flagged',
    limit: 20,
    offset: 0,
    sort: '-id',
    search: '',
  },
};

export default (state = initial, {type, payload} = {}) => {
  switch (type) {
    case constants.FETCH_START:
      return {
        ...state,
        error: null,
        isLoading: true,
        params: {
          ...state.params,
          ...payload,
        },
      };

    case constants.FETCH_SUCCESS: {
      const {records, totalCount} = payload;
      return {
        ...state,
        error: null,
        isLoading: false,
        records,
        totalCount,
      };
    }

    case constants.FETCH_FAIL:
      return {
        ...state,
        error: payload,
        isLoading: false,
        records: [],
        totalCount: 0,
      };

    case constants.RESET:
      return {...initial};

    default:
      return state;
  }
};
