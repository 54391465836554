export const NAME = 'COMPLIANCE_RECORD';

export const FETCH_START = `${NAME}/FETCH_START`;
export const FETCH_SUCCESS = `${NAME}/FETCH_SUCCESS`;
export const FETCH_FAIL = `${NAME}/FETCH_FAIL`;

export const UPDATE_START = `${NAME}/UPDATE_START`;
export const UPDATE_SUCCESS = `${NAME}/UPDATE_SUCCESS`;
export const UPDATE_FAIL = `${NAME}/UPDATE_FAIL`;

export const RESET = `${NAME}/RESET`;
