import React, {Children} from 'react';
import classNames from 'classnames';
import {node, object, string} from 'prop-types';

const Tabs = ({children, className, style}) => {
  const childrenArray = Children.toArray(children);
  const width = childrenArray.length ? 100 / childrenArray.length : 100;
  const childProps = {style: {width: `${width}%`}};

  return (
    <div className={classNames('pl-tabs', className)} style={style}>
      {childrenArray.map(child => React.cloneElement(child, childProps))}
    </div>
  );
};

Tabs.propTypes = {
  children: node,
  className: string,
  style: object,
};

export default Tabs;
