import assign from 'deep-extend';

import * as constants from './constants';

const initialState = {
  error: null,
  isCreateRequestRunning: false,
  globalError: null,
  isPlanCreated: false,
};

export default function planCreateWorflowReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.REQUEST_PLAN_CREATE:
      return assign({}, state, {
        error: null,
        isCreateRequestRunning: true,
      });

    case constants.PLAN_CREATE_REQUEST_COMPLETED:
      return assign({}, state, {
        isPlanCreated: true,
        isCreateRequestRunning: false,
        newPlanId: action.payload.id,
      });

    case constants.PLAN_CREATE_REQUEST_FAILED:
      return assign({}, state, {
        error: action.error,
        isCreateRequestRunning: false,
      });

    case constants.PLAN_CREATE_SET_GLOBAL_ERROR:
      return assign({}, state, {
        globalError: action.error,
      });

    case constants.PLAN_CREATE_RESET_GLOBAL_ERROR:
      return assign({}, state, {
        globalError: null,
      });

    case constants.PLAN_RESET_STATE:
      return assign({}, initialState);

    default:
      return assign({}, state);
  }
}
