import * as constants from './constants';

export const initialState = {
  activeRequests: 0,
  lastFail: null,
};

export default (state = initialState, {type} = {}) => {
  switch (type) {
    case constants.START:
      return {
        ...state,
        activeRequests: state.activeRequests + 1,
      };

    case constants.SUCCESS:
      return {
        ...state,
        activeRequests: state.activeRequests - 1,
      };

    case constants.FAIL:
      return {
        ...state,
        activeRequests: state.activeRequests - 1,
        lastFail: new Date(),
      };

    default:
      return state;
  }
};
