import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import React, {Component} from 'react';
import {array, func, object, string} from 'prop-types';
import {isEqual} from 'lodash';

import FormCard from 'admin-ng/components/common/form-card';
import ItemType from './item-type';
import Mosaics from './mosaics';
import Permissions from './permissions';
import {doesRequireAssetTypes} from 'admin-ng/util/item-types.js';

class DataConstraints extends Component {
  static propTypes = {
    availableItemTypes: array.isRequired,
    itemTypesRequestError: string,
    onAddItemType: func,
    onDeleteItemType: func,
    onGetAssetTypes: func,
    onGetAvailableItemTypes: func,
    onRequestBasemaps: func.isRequired,
    onRequestSeries: func.isRequired,
    onUpdateAssetTypes: func,
    onUpdateItemType: func.isRequired,
    onUpdateSelectedMosaics: func.isRequired,
    policy: object.isRequired,
    toggleItemType: func.isRequired,
    toggleItemTypeAll: func.isRequired,
  };

  constructor(props) {
    super(props);
    this._onDelete = this._onDelete.bind(this);
    this._onToggleItemTypeAll = this._onToggleItemTypeAll.bind(this);
    this.state = {toggleAllState: false};
  }

  componentDidMount() {
    this.props.onGetAvailableItemTypes();
  }

  _areDeletable() {
    return this.props.policy.itemType.ui.length > 1;
  }

  _onDelete(index) {
    if (this._areDeletable()) {
      this.props.onDeleteItemType(index);
    }
  }

  _onToggleItemTypeAll() {
    this.props.toggleItemTypeAll(this.state.toggleAllState);
    this.setState({toggleAllState: !this.state.toggleAllState});
  }

  _isItemTypeChanged(oldItemType, newItemType) {
    if (!oldItemType.value) {
      return false; // Item type is new, so never indicate the value has changed
    }
    return oldItemType.value !== newItemType.value;
  }

  _isAssetTypesChanged(oldAssetTypes, newAssetTypes) {
    if (oldAssetTypes.length < 1) {
      return false;
    }
    return !isEqual(oldAssetTypes, newAssetTypes);
  }

  _renderTitle(itemType) {
    const type = this.props.availableItemTypes.find(
      available => available.id === itemType.value
    );
    let name = itemType.value;
    if (type) {
      name = type.display_name;
    }
    return <span style={{cursor: 'pointer'}}>{name}</span>;
  }

  _renderToggle(itemType, index) {
    return (
      <IconButton
        color="inherit"
        onClick={this.props.toggleItemType.bind(this, index)}
      >
        {itemType.isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton>
    );
  }

  _renderDelete(index) {
    const disabled = !this._areDeletable();
    return (
      <IconButton
        color="inherit"
        disabled={disabled}
        onClick={!disabled ? this._onDelete.bind(this, index) : null}
      >
        <DeleteIcon />
      </IconButton>
    );
  }

  _renderItems() {
    const selectedItemTypes = this.props.policy.itemType.ui;

    return this.props.policy.itemType.ui.map((itemType, index) => {
      const originalItemType = this.props.policy.itemType.data[index];

      const isItemTypeChanged = this._isItemTypeChanged(
        originalItemType,
        itemType
      );
      const isAssetTypesChanged = this._isAssetTypesChanged(
        originalItemType.selectedAssetTypes.items,
        itemType.selectedAssetTypes.items
      );

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div className="pl-data-constraint-itemtype" key={index}>
          <FormCard
            iconElementLeft={this._renderToggle(itemType, index)}
            iconElementRight={this._renderDelete(index)}
            title={this._renderTitle(itemType)}
          >
            {itemType.isExpanded && (
              <div>
                <ItemType
                  assetTypesError={itemType.selectedAssetTypes.error}
                  availableAssetTypes={itemType.availableAssetTypes}
                  availableItemTypes={this.props.availableItemTypes}
                  error={this.props.itemTypesRequestError}
                  index={index}
                  isItemTypeChanged={isItemTypeChanged}
                  itemType={itemType}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${index}itemType`}
                  onItemTypeChanged={this.props.onGetAssetTypes}
                  onUpdateItemType={this.props.onUpdateItemType}
                  selectedItemTypes={selectedItemTypes}
                />

                {itemType.value === 'basemap' && (
                  <Mosaics
                    availableMosaics={itemType.availableAssetTypes}
                    error={itemType.selectedAssetTypes.error}
                    index={index}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}basemap`}
                    onRequestMosaics={this.props.onRequestBasemaps}
                    onUpdateSelectedMosaics={this.props.onUpdateSelectedMosaics}
                    selectedMosaics={itemType.selectedAssetTypes.items}
                  />
                )}

                {itemType.value === 'series' && (
                  <Mosaics
                    availableMosaics={itemType.availableAssetTypes}
                    error={itemType.selectedAssetTypes.error}
                    index={index}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${index}series`}
                    onRequestMosaics={this.props.onRequestSeries}
                    onUpdateSelectedMosaics={this.props.onUpdateSelectedMosaics}
                    selectedMosaics={itemType.selectedAssetTypes.items}
                  />
                )}

                {itemType.category &&
                  doesRequireAssetTypes(itemType.category) &&
                  itemType.availableAssetTypes.length > 0 && (
                    <Permissions
                      availableAssetTypes={itemType.availableAssetTypes}
                      error={itemType.selectedAssetTypes.error}
                      isAssetTypesChanged={isAssetTypesChanged}
                      itemType={itemType}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${index}assets`}
                      onUpdateAssetTypes={this.props.onUpdateAssetTypes.bind(
                        null,
                        index
                      )}
                      selectedAssetTypes={itemType.selectedAssetTypes}
                    />
                  )}
              </div>
            )}
          </FormCard>
        </div>
      );
    });
  }

  _renderToggleAll() {
    return (
      <IconButton color="inherit" onClick={this._onToggleItemTypeAll}>
        {!this.state.toggleAllState ? (
          <KeyboardDoubleArrowDownIcon />
        ) : (
          <KeyboardDoubleArrowUpIcon />
        )}
      </IconButton>
    );
  }

  render() {
    return (
      <div className="pl-data-constraint-section">
        <FormCard
          iconElementLeft={this._renderToggleAll()}
          iconElementRight={
            <IconButton color="inherit" onClick={this.props.onAddItemType}>
              <AddIcon />
            </IconButton>
          }
          title="Item Types"
        >
          {this._renderItems()}
        </FormCard>
      </div>
    );
  }
}

export default DataConstraints;
