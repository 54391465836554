import Button from '@mui/material/Button';
import React, {Component} from 'react';
import RichTextEditor from 'react-rte';
import {bool, func, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';

export default class OrgCommentEdit extends Component {
  state = {
    comment: RichTextEditor.createEmptyValue(),
    isCommentChanged: false,
  };

  componentWillReceiveProps({commentUpdated, open}) {
    if (commentUpdated) {
      this.setState({
        comment: RichTextEditor.createEmptyValue(),
        isCommentChanged: false,
      });
      this.props.onEndEdit();
      this.props.resetEditState();
    } else if (this._isCommentEmpty() && open) {
      this.props.resetEditState();
      this.setState({
        comment: RichTextEditor.createValueFromString(
          this.props.srcComment.text,
          'html'
        ),
      });
    }
  }

  _onChange = value => {
    this.setState({comment: value});
    if (this.props.onCommentChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onCommentChange(
        this.props.srcComment.id,
        value.toString('html')
      );

      if (!this.state.isCommentChanged) {
        this.setState({
          isCommentChanged: true,
        });
      }
    }
  };

  _onCancelComment = () =>
    this.setState(
      {isCommentChanged: false, comment: RichTextEditor.createEmptyValue()},
      () => {
        this.props.onEndEdit();
      }
    );

  _onCommentSave = () => this.props.onCommentSave();

  _isCommentEmpty = () =>
    this.state.comment
      ? !this.state.comment.getEditorState().getCurrentContent().hasText()
      : true;

  render() {
    const {open, error} = this.props;
    const disabled = !this.state.isCommentChanged || this._isCommentEmpty();
    return (
      open && (
        <div>
          <Notification
            message={error}
            title="Oops, could not edit this comment"
            type="error"
          />

          <div>
            <RichTextEditor
              onChange={this._onChange}
              value={this.state.comment}
            />
          </div>

          <div className="pl-new-comment-controls">
            <Button
              disabled={disabled}
              onClick={!disabled ? this._onCommentSave : null}
            >
              Submit
            </Button>

            <Button color="inherit" onClick={this._onCancelComment}>
              Cancel
            </Button>
          </div>
        </div>
      )
    );
  }
}

OrgCommentEdit.propTypes = {
  commentUpdated: bool,
  error: string,
  onCommentChange: func.isRequired,
  onCommentSave: func.isRequired,
  onEndEdit: func,
  open: bool,
  resetEditState: func,
  srcComment: object.isRequired,
};
