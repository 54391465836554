import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import TileQuota from './quota';
import {ROLES} from 'admin-ng/constants';
import {
  toggleEnable,
  toggleInfinite,
  updateAmount,
  updateInterval,
  updateOverage,
  updateStyle,
} from './actions';

const mapStateToProps = (state, ownProps) => {
  const _state = state.subscriptions.quota;
  const roleLevel = ownProps.auth.user.claims.role_level;

  const {
    ui: {
      interval: uiInterval,
      overage: uiOverage,
      style: uiStyle,
      amount: uiAmount,
    },
    data: {
      interval: dataInterval,
      style: dataStyle,
      overage: dataOverage,
      amount: dataAmount,
    },
  } = _state;
  const isIntervalChanged = Boolean(dataInterval !== uiInterval);
  const isStyleChanged = Boolean(dataStyle !== uiStyle);
  const isOverageChanged = Boolean(dataOverage !== uiOverage);
  const isAmountChanged = Boolean(dataAmount !== uiAmount);
  return {
    ..._state,
    ..._state.ui,
    isPlanetAdmin: roleLevel >= ROLES.planetAdmin,
    isIntervalChanged,
    isStyleChanged,
    isAmountChanged,
    isOverageChanged,
  };
};

const mapDispatchToProps = {
  toggleEnable,
  toggleInfinite,
  onAmountChange: updateAmount,
  onIntervalChange: updateInterval,
  onStyleChange: updateStyle,
  onOverageChange: updateOverage,
};

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(TileQuota)
);
