import assign from 'deep-extend';

import * as constants from './constants';

const initialState = {
  error: null,
  isDetailsRequestRunning: false,
  details: null,
};

export default function planWorkflowReducer(state = initialState, action = {}) {
  switch (action.type) {
    case constants.REQUEST_PLAN_DETAILS:
      return assign({}, state, {
        error: null,
        isDetailsRequestRunning: true,
      });

    case constants.RECEIVE_PLAN_DETAILS:
      return assign({}, state, {
        isDetailsRequestRunning: false,
        details: action.details,
      });

    case constants.PLAN_DETAILS_REQUEST_FAILED:
      return assign({}, state, {
        isDetailsRequestRunning: false,
        error: action.error,
      });

    case constants.PLAN_DETAILS_RESET_STATE:
      return assign({}, initialState);

    default:
      return assign({}, state);
  }
}
