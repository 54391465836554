import * as constants from './constants';
import * as messages from './messages';
import {
  fetchSubscriptions,
  orgsSelectNotCompleted,
} from '../plan-assign-select-orgs/actions';
import {reassignOrgs} from '../plan-assign-summary/actions';
import {
  updateDestinationPlan,
  updateSourcePlan,
} from '../plan-assign-select-plan/actions';

export const gotoNextStep = () => {
  return {
    type: constants.GOTO_NEXT_STEP,
  };
};

export const gotoPreviousStep = () => {
  return {
    type: constants.GOTO_PREVIOUS_STEP,
  };
};

export const openNextStep = () => {
  return (dispatch, getState) => {
    const state = getState(),
      assignGeneralState = state.plans.assign.general,
      selectPlanState = state.plans.assign.selectPlan,
      selectOrgsState = state.plans.assign.selectOrgs;

    switch (assignGeneralState.step) {
      case 0:
        if (!selectPlanState.sourcePlanId) {
          return dispatch(
            updateSourcePlan(
              null,
              null,
              messages.SRC_PLAN_NOT_SPECIFIED_ERROR_MSG
            )
          );
        }

        if (!selectPlanState.destinationPlanId) {
          return dispatch(
            updateDestinationPlan(
              null,
              null,
              messages.DESTINATION_PLAN_NOT_SPECIFIED_ERROR_MSG
            )
          );
        }

        if (
          selectPlanState.sourcePlanId === selectPlanState.destinationPlanId
        ) {
          return dispatch(
            updateDestinationPlan(
              selectPlanState.destinationPlanId,
              selectPlanState.destinationPlanName,
              messages.SAME_PLAN_ERROR_MSG
            )
          );
        }

        dispatch(fetchSubscriptions());
        return dispatch(gotoNextStep());

      case 1:
        if (!selectOrgsState.selectedOrgs.length) {
          return dispatch(orgsSelectNotCompleted());
        }
        return dispatch(gotoNextStep());

      case 2:
        if (!selectOrgsState.selectedOrgs.length) {
          return dispatch(
            orgsSelectNotCompleted(messages.ORGS_NOT_SELECTED_ERROR_MSG)
          );
        }

        dispatch(reassignOrgs());
        return dispatch(gotoNextStep());

      default:
        return;
    }
  };
};

export const openPreviousStep = () => {
  return dispatch => {
    return dispatch(gotoPreviousStep());
  };
};

export const resetState = () => {
  return {
    type: constants.RESET_ASSIGN_STATE,
  };
};
