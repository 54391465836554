import React from 'react';
import {number} from 'prop-types';

const RequestSpinner = props =>
  props.activeRequests ? <div className="pl-request-spinner active" /> : null;

RequestSpinner.propTypes = {
  activeRequests: number.isRequired,
};

export default RequestSpinner;
