export const SEND_USER_INVITE_REQUEST = 'SEND_USER_INVITE_REQUEST';
export const COMPLETE_USER_INVITE_REQUEST = 'COMPLETE_USER_INVITE_REQUEST';
export const USER_INVITE_REQUEST_FAILED = 'USER_INVITE_REQUEST_FAILED';
export const REQUEST_USER_EMAIL_CHECK = 'REQUEST_USER_EMAIL_CHECK';
export const COMPLETE_USER_EMAIL_CHECK = 'COMPLETE_USER_EMAIL_CHECK';
export const USER_EMAIL_CHECK_REQUEST_FAILED =
  'USER_EMAIL_CHECK_REQUEST_FAILED';
export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const RESET_INVITE_STATE = 'RESET_INVITE_STATE';
