import {connect} from 'react-redux';

import * as actions from './actions';
import PlanSubscriptionBulkCreateGeneralView from './plan-subscription-bulk-create-general-view';

const mapStateToProps = state =>
  state.plans.instance.subscriptions.bulkCreate.general;
const mapDispatchToProps = dispatch => ({
  onOpenNext: () => dispatch(actions.openNextStep()),
  onOpenPrevious: () => dispatch(actions.openPreviousStep()),
  onResetView: () => dispatch(actions.resetView()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlanSubscriptionBulkCreateGeneralView);
