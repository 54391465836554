import moment from 'moment';

export const testActionDescription = description =>
  `should create an action to ${description}`;
export const testReducerDescription = actionType =>
  `should handle ${actionType}`;

export const checkMomentObject = (props, propName, componentName) => {
  const obj = props[propName];
  if (!obj || moment.isMoment(obj)) {
    return;
  }

  return new Error(
    `Failed prop type: Invalid prop '${propName}' of type '${typeof obj}' supplied to '${componentName}', expected instance of 'moment'.`
  );
};

// note: is nullable
export const isValidSfdc = sfdc => String(sfdc).length === 15 || !sfdc;
