import React, {Component} from 'react';
import {array, func, number, object, string} from 'prop-types';

import Autocomplete from 'admin-ng/components/common/autocomplete';
import IconButton from 'admin-ng/components/common/icon-button';
import Table from 'admin-ng/components/common/table';

const tableHeight = '432px';

class Mosaics extends Component {
  static propTypes = {
    availableMosaics: array,
    error: string,
    index: number.isRequired,
    mosaicListLink: object,
    onRequestMosaics: func.isRequired,
    onUpdateSelectedMosaics: func.isRequired,
    selectedMosaics: array,
  };

  constructor(props) {
    super(props);

    this.state = {
      mosaicName: '',
    };

    this._onMosaicNameChange = this._onMosaicNameChange.bind(this);
    this._requestMosaicsByName = this._requestMosaicsByName.bind(this);
    this._selectMosaic = this._selectMosaic.bind(this);
    this._deleteMosaic = this._deleteMosaic.bind(this);
  }

  _getOrderedMosaics() {
    let assetTypes = this.props.availableMosaics || [];

    assetTypes = assetTypes.slice().sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    return assetTypes;
  }

  _getDataSource() {
    const results = this._getOrderedMosaics();

    return [
      // Add freeform search as dropdown option to allow for wildcard matches
      ...(this.state.mosaicName ? [{name: this.state.mosaicName}] : []),
      // Filter out things already selected
      ...results.filter(
        m => !(this.props.selectedMosaics ?? []).includes(m.name)
      ),
    ];
  }

  _getTableMosaicsSchema() {
    return [
      {
        field: '',
        label: 'Name',
        width: '85%',
        isSortable: false,
        contentCreator: name => name,
      },
      {
        field: '',
        label: '',
        width: '15%',
        isSortable: false,
        contentCreator: name => {
          return (
            <IconButton
              icon="delete"
              onClick={() => this._deleteMosaic(name)}
            />
          );
        },
      },
    ];
  }

  _onMosaicNameChange(value) {
    this.setState({
      mosaicName: value,
    });
  }

  _requestMosaicsByName() {
    if (this.state.mosaicName && this.state.mosaicName.length > 2) {
      this.props.onRequestMosaics(
        this.props.index,
        this.state.mosaicName.trim()
      );
    }
  }

  _selectMosaic(event, mosaic) {
    if (mosaic && this.props.selectedMosaics.indexOf(mosaic.name) === -1) {
      this.props.onUpdateSelectedMosaics(this.props.index, [
        ...this.props.selectedMosaics,
        mosaic.name,
      ]);
    }

    setTimeout(() => this.setState({mosaicName: ''}), 301);
  }

  _deleteMosaic(mosaicName) {
    const itemIndex = this.props.selectedMosaics.indexOf(mosaicName);

    const items = this.props.selectedMosaics.slice(0);

    if (itemIndex !== -1) {
      items.splice(itemIndex, 1);

      this.props.onUpdateSelectedMosaics(this.props.index, items);
    }
  }

  _renderSelectedMosaics() {
    const selectedMosaics = this.props.selectedMosaics.map((item, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={i}>{item}</li>
    ));

    return <ul>{selectedMosaics}</ul>;
  }

  render() {
    const userHint = (
      <span>
        Start typing a name of a mosaic (min 3 characters).&nbsp;
        {this.props.mosaicListLink}
      </span>
    );

    return (
      <div className="pl-constraint--item-type-mosaic">
        <Autocomplete
          error={!!this.props.error}
          freeSolo
          helperText={this.props.error}
          inputValue={this.state.mosaicName}
          isOptionEqualToValue={(option, value) => option?.name === value?.name}
          label="Mosaic Name"
          onInputChange={this._onMosaicNameChange}
          onInputCompleted={this._requestMosaicsByName}
          onChange={this._selectMosaic}
          options={this._getDataSource()}
          userHintBottomText={userHint}
        />

        {this.props.selectedMosaics?.length > 0 && (
          <Table
            dataSchema={this._getTableMosaicsSchema()}
            qaId="data-constraints-mosaics-table"
            srcData={this.props.selectedMosaics}
            wrapperStyle={{maxHeight: tableHeight}}
          />
        )}
      </div>
    );
  }
}

export default Mosaics;
