import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {ThemeProvider} from '@mui/material/styles';

import APIKeys from 'admin-ng/components/api-keys';
import AssignGeneral from 'admin-ng/components/plans/plan-assign/plan-assign-general/plan-assign-general-container';
import Compliance from 'admin-ng/components/compliance';
import Create from 'admin-ng/components/plans/plan-instance/plan-create-workflow/plan-create-workflow-container';
import Document from 'admin-ng/components/documents/document-instance/document-instance-container';
import Documents from 'admin-ng/components/documents';
import Header from '../header/header';
import Invitations from 'admin-ng/components/invitations';
import Organization from '../orgs/org-instance';
import Organizations from '../orgs';
import PageNotFound from 'admin-ng/components/page-not-found';
import Plan from 'admin-ng/components/plans/plan-instance/plan-common/plan-common-container';
import Plans from '../plans';
import Program from 'admin-ng/components/programs/program-instance/program-common';
import Programs from 'admin-ng/components/programs';
import RequestSpinner from 'admin-ng/components/common/request-spinner';
import Subscriptions from 'admin-ng/components/subscriptions';
import Users from 'admin-ng/components/users';
import theme from './app-ui-theme.config.js';
import {usePageView} from '../../analytics';

/**
 *
 * Plans: Packages
 * Subscriptions: Plans
 * Policies: Permissions
 */

const App = () => {
  usePageView();

  return (
    <ThemeProvider theme={theme}>
      <section className="pl-app">
        <div className="pl-app--body">
          <Header />

          <Switch>
            <Route component={Organization} path="/organizations/:orgId" />
            <Route component={Organizations} path="/organizations" />

            <Route component={Create} path="/packages/create" />
            <Route component={AssignGeneral} path="/packages/assign" />
            <Route component={Plan} path="/packages/:planId" />
            <Route component={Plans} path="/packages" />

            <Route component={Subscriptions} path="/plans" />
            <Route component={Invitations} path="/invitations" />
            <Route component={Users} path="/users" />
            <Route component={APIKeys} path="/api-keys" />
            <Route component={Compliance} path="/compliance" />

            <Route component={Program} path="/programs/:programId" />
            <Route component={Programs} path="/programs" />

            <Route component={Document} path="/documents/:documentId" />
            <Route component={Documents} path="/documents" />

            <Route component={PageNotFound} path="/page-not-found" />

            <Route path="/subscriptions">
              <Redirect to="/plans" />
            </Route>

            <Redirect to="/organizations" />
          </Switch>

          <RequestSpinner />
        </div>
      </section>
    </ThemeProvider>
  );
};

export default App;
