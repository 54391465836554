import fetchContent from 'admin-ng/util/fetch-content';
import request from 'admin-ng/util/request-promise';
import {DATE_FORMAT} from 'admin-ng/constants';
import {ORGANIZATIONS, V0_API} from '.';
import {download as downloadFile} from 'admin-ng/util/file';

export const all = params => request(ORGANIZATIONS, 'get', params);
export const apiKey = apiLink => request(apiLink, 'get');
export const create = params => request(ORGANIZATIONS, 'post', params);
export const del = id => request(`${ORGANIZATIONS}${id}`, 'del');
export const one = id => request(`${ORGANIZATIONS}${id}`, 'get');
export const update = (id, params) =>
  request(`${ORGANIZATIONS}${id}`, 'put', params);
// export const delMember = (id, memberId) => request(`${ORGANIZATIONS}${id}/memberships/${memberId}`, 'del');
export const getMember = (id, memberId) =>
  request(`${ORGANIZATIONS}${id}/memberships/${memberId}`, 'get');
export const getMembers = (id, params) =>
  request(`${ORGANIZATIONS}${id}/memberships`, 'get', params);
export const inviteMember = (id, params) =>
  request(`${ORGANIZATIONS}${id}/memberships/invite`, 'post', params);
export const updateMember = (id, memberId, params) =>
  request(`${ORGANIZATIONS}${id}/memberships/${memberId}`, 'put', params);

/**
 * fetchUsageExport
 * @param  {string} token      User authorization token
 * @param  {Number} id         ID of the organization
 * @param  {Object} start      Moment.js instance of start date
 * @param  {Object} end        Moment.js instance of end date
 * @return {Promise}
 */
export const usage = async (token, id, start, end) => {
  const filename = `Usage-${id}-${start.format(DATE_FORMAT)}-${end.format(DATE_FORMAT)}.json`;
  const content = await fetchContent(
    `${V0_API}organizations/${id}/usage?start=${start.format()}&end=${end.format()}`,
    token
  );
  downloadFile([content], filename, {type: 'text/csv'});
  return content;
};
