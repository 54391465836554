import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {bool, func, string} from 'prop-types';

import TextField from 'admin-ng/components/common/text-field';

class PolicyNameDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      isValid: null,
      error: null,
    };

    this._onCancel = this._onCancel.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._handleNameChange = this._handleNameChange.bind(this);
    this._validatePolicyName = this._validatePolicyName.bind(this);
  }

  componentWillReceiveProps(next) {
    this.setState({
      name: next.originalName,
    });
  }

  _onCancel() {
    this._resetState();
    this.props.onNewNameSubmit(null);
  }

  _resetState() {
    this.setState({
      name: '',
      isValid: null,
      error: null,
    });
  }

  _onSubmit() {
    if (this.state.name) {
      if (this.state.isValid) {
        this.props.onNewNameSubmit(this.state.name, !!this.props.originalName);
        this._resetState();
      }
    } else {
      this.setState({error: 'Please enter a permission name'});
    }
  }

  _handleNameChange(value) {
    this.setState({
      name: value,
      error: null,
    });
  }

  _validatePolicyName(value) {
    const state = {};
    if (this.props.onValidate) {
      state.isValid = this.props.onValidate(value);
      if (!state.isValid) {
        state.error = 'This permission name is already taken';
      }
    }
    this.setState(state);
  }

  render() {
    return (
      <Dialog fullWidth maxWidth="sm" open={this.props.open}>
        <DialogTitle>Permission Name</DialogTitle>

        <DialogContent>
          <TextField
            error={!!this.state.error}
            helperText={this.state.error}
            label="Permission Name *"
            isInputValid={this.state.isValid}
            onChange={this._handleNameChange}
            onChangeCompleted={this._validatePolicyName}
            value={this.state.name}
          />
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={this._onCancel}>
            Cancel
          </Button>

          <Button
            color="primary"
            focusRipple
            onClick={this._onSubmit}
            variant="contained"
          >
            {this.props.originalName ? 'Set' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

PolicyNameDialog.propTypes = {
  onNewNameSubmit: func,
  onValidate: func,
  open: bool,
  originalName: string,
};

export default PolicyNameDialog;
