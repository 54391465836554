import React from 'react';
import {arrayOf, func, node, number, string} from 'prop-types';

import ItemsPerPageSelect from 'admin-ng/components/common/items-per-page-select';
import QuickFilter from 'admin-ng/components/common/quick-filter';
import Search from 'admin-ng/components/common/search';

const TableControlsPanel = props => (
  <div className="pl-table-controls clearfix">
    <div className="pl-table-controls--left">
      <Search onFilter={props.onFilter} />
    </div>

    <div className="pl-table-controls--middle">
      <ItemsPerPageSelect
        itemsPerPageLimit={props.itemsPerPageLimit}
        onFilter={props.onFilter}
      />

      {props.filters.length > 0 && (
        <QuickFilter
          activeFilters={props.activeFilters}
          filters={props.filters}
          labels={props.labels}
          onFilter={props.onFilter}
        />
      )}
    </div>

    <div className="pl-table-controls--right">{props.actions}</div>
  </div>
);

TableControlsPanel.propTypes = {
  actions: arrayOf(node),
  activeFilters: string,
  filters: arrayOf(string),
  itemsPerPageLimit: number,
  labels: arrayOf(string),
  onFilter: func.isRequired,
};

TableControlsPanel.defaultProps = {
  activeFilters: '',
  filters: [],
  labels: [],
  itemsPerPageLimit: 20,
};

export default TableControlsPanel;
