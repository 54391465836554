import {connect} from 'react-redux';

import Sidebar from './sidebar';
import {toggle} from './actions';

const mapStateToProps = ({sidebar: {visible}}) => ({visible});

const mapDispatchToProps = {
  toggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
