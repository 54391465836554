import React, {Component} from 'react';
import moment from 'moment';
import {bool, func, object, string} from 'prop-types';

import Checkbox from 'admin-ng/components/common/checkbox';
import DatePicker from 'admin-ng/components/common/date-picker';
import IconButton from 'admin-ng/components/common/icon-button';

class ToiItem extends Component {
  static propTypes = {
    canDelete: bool.isRequired,
    disabled: bool,
    isEndChanged: bool,
    isStartChanged: bool,
    item: object.isRequired,
    onDeleteTOI: func,
    onUpdateTOI: func.isRequired,
    qaId: string.isRequired,
  };

  static defaultProps = {
    disabled: false,
    isEndChanged: false,
    isStartChanged: false,
    onDeleteTOI: null,
  };

  _isTOIValueValid = (type, value) => {
    let start, end, diff;

    if (type === 'start') {
      start = value;
      end = this.props.item.end;
    } else {
      start = this.props.item.start;
      end = value;
    }

    if (start && end) {
      start = moment(start);
      end = moment(end);
      diff = end.diff(start, 'days');

      if (diff <= 0) {
        return false;
      }
    }

    return true;
  };

  _handleDatePickerChange = (type, event, value) => {
    const params = {
      type,
      payload: {
        value,
      },
    };
    let error;
    if (!this._isTOIValueValid(type, value)) {
      error = "End date can't be earlier than start date";
    }

    this.props.onUpdateTOI(params, error);
  };

  _handleDatePickerClear = type =>
    this.props.onUpdateTOI({type, payload: {value: null}});

  _toggleStartDate = () =>
    this.props.onUpdateTOI({
      type: 'start',
      payload: {value: null, hasNoStartDate: !this.props.item.hasNoStartDate},
    });

  _toggleEndDate = () =>
    this.props.onUpdateTOI({
      type: 'end',
      payload: {value: null, hasNoEndDate: !this.props.item.hasNoEndDate},
    });

  render() {
    const {
      canDelete,
      disabled,
      isEndChanged,
      isStartChanged,
      item,
      onDeleteTOI,
      qaId,
    } = this.props;

    return (
      <div className="pl-plan-toi">
        <div>
          <div className="clearfix">
            <div className="pl-plan-toi--left-col">
              <DatePicker
                disabled={disabled || item.hasNoStartDate}
                error={item.startError}
                label="Start"
                isChanged={isStartChanged}
                onChange={this._handleDatePickerChange.bind(this, 'start')}
                onClear={this._handleDatePickerClear.bind(this, 'start')}
                qaId={`${qaId}-date-start`}
                value={item.start}
              />
            </div>

            <div className="pl-plan-toi--right-col">
              <DatePicker
                disabled={disabled || item.hasNoEndDate}
                error={item.endError}
                label="End"
                isChanged={isEndChanged}
                onChange={this._handleDatePickerChange.bind(this, 'end')}
                onClear={this._handleDatePickerClear.bind(this, 'end')}
                qaId={`${qaId}-date-end`}
                value={item.end}
              />
            </div>
          </div>

          <div className="clearfix">
            <div className="pl-plan-toi--left-col">
              <Checkbox
                checked={item.hasNoStartDate}
                disabled={disabled}
                label="Earliest available acquisition"
                onChange={this._toggleStartDate}
              />
            </div>

            <div className="pl-plan-toi--right-col">
              <Checkbox
                checked={item.hasNoEndDate}
                disabled={disabled}
                label="Most recent acquisition"
                onChange={this._toggleEndDate}
              />
            </div>
          </div>
        </div>

        {canDelete && (
          <div className="pl-plan-toi--delete">
            {onDeleteTOI && <IconButton icon="delete" onClick={onDeleteTOI} />}
          </div>
        )}
      </div>
    );
  }
}

export default ToiItem;
