import React, {useEffect} from 'react';
import {Link, Route, Switch, useRouteMatch} from 'react-router-dom';
import {func} from 'prop-types';

const userTitle = 'User Compliance';
const orgTitle = 'Organization Compliance';
const screeningTitle = 'Bulk Screening';

const ComplianceHeader = ({updateTitle}) => {
  const userPath = useRouteMatch('/compliance/user');
  const orgPath = useRouteMatch('/compliance/organization');
  const screeningPath = useRouteMatch('/compliance/screening');

  useEffect(() => {
    if (userPath && userPath.isExact) {
      updateTitle(userTitle);
    }
    if (orgPath && orgPath.isExact) {
      updateTitle(orgTitle);
    }
    if (screeningPath && screeningPath.isExact) {
      updateTitle(screeningTitle);
    }
  }, [updateTitle, userPath, orgPath, screeningPath]);

  return (
    <Route path="/compliance">
      <Link to="/compliance">Compliance</Link>
      {' / '}
      <Switch>
        <Route path="/compliance/user">
          <Link to="/compliance/user">{userTitle}</Link>
        </Route>
        <Route path="/compliance/organization">
          <Link to="/compliance/organization">{orgTitle}</Link>
        </Route>
        <Route path="/compliance/screening">
          <Link to="/compliance/screening">{screeningTitle}</Link>
        </Route>
      </Switch>
    </Route>
  );
};

ComplianceHeader.propTypes = {
  updateTitle: func.isRequired,
};

export default ComplianceHeader;
