import assign from 'deep-extend';
import {connect} from 'react-redux';

import PlanAssignSummary from './plan-assign-summary';
import {reassignOrgs} from './actions';

const mapStateToProps = state => {
  const assignState = state.plans.assign;

  return assign({}, assignState.summary, {
    selectedOrgs: assignState.selectOrgs.selectedOrgs,
  });
};

const mapDispatchToProps = dispatch => ({
  onResubmit: () => dispatch(reassignOrgs()),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
})(PlanAssignSummary);
