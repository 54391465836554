const NAME = 'DOCUMENT-CREATE';

// Uniqueness check for document name
export const REQUEST_NAME_CHECK = `${NAME}/REQUEST_NAME_CHECK`;
export const REQUEST_NAME_CHECK_SUCCESS = `${NAME}/REQUEST_NAME_CHECK_SUCCESS`;
export const REQUEST_NAME_CHECK_FAIL = `${NAME}/REQUEST_NAME_CHECK_FAIL`;

// Update fields with user input
export const UPDATE_NAME = `${NAME}/UPDATE_NAME`;
export const UPDATE_EXTERNAL_NAME = `${NAME}/UPDATE_EXTERNAL_NAME`;
export const UPDATE_TEXT = `${NAME}/UPDATE_TEXT`;
export const TOGGLE_CHECKBOX = `${NAME}/TOGGLE_CHECKBOX`;

// Submit POST request to create document
export const REQUEST_CREATE = `${NAME}/REQUEST_CREATE`;
export const REQUEST_CREATE_SUCCESS = `${NAME}/REQUEST_CREATE_SUCCESS`;
export const REQUEST_CREATE_FAIL = `${NAME}/REQUEST_CREATE_FAIL`;

export const RESET_STATE = `${NAME}/RESET_STATE`;
