import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import Sidebar from 'admin-ng/components/common/sidebar';

const PageNotFound = () => {
  const location = useLocation();
  const history = useHistory();
  const [message, setMessage] = useState(
    'We are sorry, but the page you are looking for does not exist.'
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has('message')) {
      setMessage(queryParams.get('message'));

      queryParams.delete('message');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, [history, location.search]);

  return (
    <div className="pl-flex-wrapper">
      <Sidebar />
      <div className="pl-app--content">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default PageNotFound;
