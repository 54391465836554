import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {object} from 'prop-types';
import {useAuth} from '@apps-common/auth-react';

import General from 'admin-ng/components/programs/program-instance/program-general/program-general-container';
import Organizations from 'admin-ng/components/programs/program-instance/program-organizations/program-organizations-container';
import Sidebar from 'admin-ng/components/common/sidebar';
import Tab from 'admin-ng/components/common/tab';
import Tabs from 'admin-ng/components/common/tabs';
import {ROLES} from 'admin-ng/constants';

const ProgramCommon = ({
  match: {
    params: {programId},
  },
}) => {
  const auth = useAuth();

  if (auth.user.claims.role_level < ROLES.planetAdmin) {
    return <Redirect to="/page-not-found" />;
  }

  return (
    <div className="pl-flex-wrapper">
      <Tabs className="pl-top-level-tabs">
        <Tab link={`/programs/${programId}/general`}>General</Tab>
        <Tab link={`/programs/${programId}/organizations`}>Organizations</Tab>
      </Tabs>
      <Sidebar />
      <div className="pl-app--content">
        <Switch>
          <Route component={General} path="/programs/:programId/general" />
          <Route
            component={Organizations}
            path="/programs/:programId/organizations"
          />
          <Redirect to={`/programs/${programId}/general`} />
        </Switch>
      </div>
    </div>
  );
};

ProgramCommon.propTypes = {
  match: object.isRequired,
};

export default ProgramCommon;
