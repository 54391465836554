import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import Form from './form';
import {ROLES} from 'admin-ng/constants';
import {
  create,
  fetch,
  fetchPlans,
  reset,
  update,
  updateNewPlanName,
  updatePlanId,
  updateReference,
} from '../instance/actions';
import {reset as quotaReset} from 'admin-ng/components/common/subscription-details/quota/actions';

const mapStateToProps = (
  {
    subscriptions: {
      instance: {
        isFetchingPlans,
        newPlanName,
        plans,
        plansError,
        reference,
        referenceValid,
        saveError,
        subscriptionError,
      },
    },
  },
  {
    match: {
      params: {orgId, subscriptionId},
    },
    auth: {
      user: {
        claims: {role_level},
      },
    },
    type,
  }
) => {
  const id = type === 'edit' ? parseInt(subscriptionId) : null;
  const entity = 'organization';
  const entityId = parseInt(orgId);

  return {
    id,
    isFetchingPlans,
    isPlanetAdmin: role_level >= ROLES.planetAdmin,
    newPlanName,
    entity,
    entityId,
    plans,
    plansError,
    reference,
    referenceValid,
    saveError,
    subscriptionError,
    type,
  };
};

const mapDispatchToProps = (
  dispatch,
  {
    history,
    auth,
    type,
    match: {
      params: {orgId, subscriptionId},
    },
  }
) => ({
  onCancel: () => {
    history.goBack();
  },
  onMount: () => {
    dispatch(reset());
    dispatch(quotaReset());
    if (type === 'edit') {
      dispatch(fetch(subscriptionId, auth.user.claims));
    }
  },
  onPlanAutocomplete: query => dispatch(fetchPlans(orgId, query)),
  onPlanIdUpdate: id => dispatch(updatePlanId(id)),
  onPlanNameUpdate: name => dispatch(updateNewPlanName(name)),
  onReferenceChange: reference => dispatch(updateReference(reference)),
  onSave: async () => {
    const {payload, error} = await dispatch(
      type === 'edit'
        ? update(orgId, subscriptionId, auth.user.claims)
        : create(orgId, null, auth.user.claims)
    );

    if (!error) {
      history.push(`/organizations/${orgId}/plans/${payload.id}`);
    }
  },
});

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Form));
