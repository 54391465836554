import React, {useEffect} from 'react';
import {Link, Route, useRouteMatch} from 'react-router-dom';
import {func} from 'prop-types';

const InvitationsHeader = ({updateTitle}) => {
  const rootPath = useRouteMatch('/invitations');

  useEffect(() => {
    if (rootPath && rootPath.isExact) {
      updateTitle('Invitations');
    }
  }, [updateTitle, rootPath]);

  return (
    <Route exact path="/invitations">
      <Link to="/invitations">Invitations</Link>
    </Route>
  );
};

InvitationsHeader.propTypes = {
  updateTitle: func.isRequired,
};

export default InvitationsHeader;
