import assign from 'deep-extend';

import * as constants from './constants';
import {
  REQUEST_PROGRAMS,
  REQUEST_PROGRAMS_FAIL,
  REQUEST_PROGRAMS_SUCCESS,
} from 'admin-ng/components/programs/programs-index/constants';

const initial = {
  error: null,
  isCheckingOrgName: false,
  isOrgDetailsRequestPending: false,
  isOrgDetailsUpdateFinished: false,
  isOrgDetailsUpdateRequested: false,
  isOrgNameUnique: false,
  isProgramsRequestPending: false,
  newDetails: null,
  orgDetailsError: null,
  orgNameError: null,
};

export default function orgEditReducer(state = initial, action = {}) {
  switch (action.type) {
    case constants.ORG_NAME_UPDATED:
      return assign({}, state, {
        newDetails: {name: action.name},
        orgNameError: action.error,
      });

    case constants.ORG_SFDC_UPDATED:
      return assign({}, state, {
        newDetails: {sfdc: action.sfdc},
        orgSfdcError: action.error,
      });

    case constants.ORG_PROGRAM_UPDATED:
      return assign({}, state, {
        newDetails: {program: action.program},
      });

    case constants.ORG_STATE_UPDATED:
      return assign({}, state, {
        newDetails: {state: action.state},
      });

    case constants.REQUEST_ORG_NAME_CHECK:
      return {
        ...state,
        isCheckingOrgName: true,
      };

    case constants.COMPLETE_ORG_NAME_CHECK:
      return {
        ...state,
        isOrgNameUnique: action.isOrgNameUnique,
        isCheckingOrgName: false,
        orgNameError: action.error,
      };

    case constants.ORG_DETAILS_UPDATE_REQUESTED:
      return {
        ...state,
        error: null,
        isOrgDetailsUpdateRequested: true,
      };

    case constants.ORG_DETAILS_UPDATE_FAILED:
      return {
        ...state,
        error: action.error,
        isOrgDetailsUpdateRequested: false,
      };

    case constants.ORG_DETAILS_UPDATE_COMPLETED:
      return assign({}, state, {
        isOrgDetailsUpdateFinished: true,
        isOrgDetailsUpdateRequested: initial.isOrgDetailsUpdateRequested,
        isOrgNameUnique: initial.isOrgNameUnique,
        newDetails: initial.newDetails,
      });

    case constants.ORG_DETAILS_UPDATES_CANCEL: {
      const {isCheckingOrgName, isOrgDetailsRequestPending, orgDetailsError} =
        state;
      return {
        ...initial,
        isCheckingOrgName,
        isOrgDetailsRequestPending,
        orgDetailsError,
      };
    }

    case REQUEST_PROGRAMS:
      return {
        ...state,
        error: initial.error,
        isProgramsRequestPending: true,
      };

    case REQUEST_PROGRAMS_SUCCESS:
      return {
        ...state,
        isProgramsRequestPending: false,
      };

    case REQUEST_PROGRAMS_FAIL:
      return {
        ...state,
        error: action.error,
        isProgramsRequestPending: false,
      };

    default:
      return assign({}, state);
  }
}
