import get from 'lodash/get';
import moment from 'moment';

import {requiredDeliveryOptions} from 'admin-ng/util/item-types';

export const prepareItemTypes = policy => {
  const itemTypeMap = {};

  policy.itemType.ui.forEach(itemType => {
    itemTypeMap[itemType.value] = get(itemType, 'selectedAssetTypes.items', []);
  });

  return {
    key: 'type_permissions',
    value: {
      map: itemTypeMap,
    },
  };
};

export const prepareAoi = policy => {
  const aoi = {
    key: 'aoi',
    value: {
      definitions: [],
      type: 'GeoJSON',
    },
  };

  const items = policy.aoi.ui;

  for (const key in items) {
    aoi.value.definitions.push(JSON.parse(items[key].value));
  }

  return aoi;
};

export const prepareToi = policy => {
  const toi = {
    key: 'toi',
    value: {
      delay: policy.toi.delay,
      ranges: [],
    },
  };

  toi.value.ranges = policy.toi.ui.map(item => ({
    from: item.start ? moment.utc(item.start).format() : null,
    to: item.end ? moment.utc(item.end).format() : null,
  }));

  return toi;
};

export const prepareDeliveryOptions = policy => {
  return {
    key: 'flags',
    value: {
      geotiff_download: policy.deliveryOptions.ui.allowDeliveryGEOTIFF,
      webtile_service: policy.deliveryOptions.ui.allowDeliveryWebtile,
    },
  };
};

export const preparePolicies = policies => {
  const _policies = [];

  policies.forEach(policy => {
    const hasDeliveryOptions = policy.itemType.ui.some(itemType =>
      requiredDeliveryOptions(itemType.category)
    );

    const newPolicy = {
      name: policy.newName || policy.name,
      constraints: [],
    };

    // FIXME This is a dummy item type name, it is ignored and must be removed
    //       when validation on the backend ignores it
    newPolicy.item_type = 'REScene';
    newPolicy.constraints.push(prepareItemTypes(policy));

    if (policy.aoi.ui.length > 0) {
      newPolicy.constraints.push(prepareAoi(policy));
    }
    if (policy.toi.ui.length > 0) {
      newPolicy.constraints.push(prepareToi(policy));
    }
    if (hasDeliveryOptions) {
      newPolicy.constraints.push(prepareDeliveryOptions(policy));
    }

    _policies.push(newPolicy);
  });

  return _policies;
};
