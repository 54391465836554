import * as constants from './constants';

const initialState = {
  commentsRequested: false,

  viewParams: {
    limit: 10,
    offset: 0,
    sort: '-id',
  },

  comments: [],
  commentsTotalCount: 0,
  error: null,
};

export default function orgCommentsIndexReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.REQUEST_COMMENTS:
      return {
        ...state,
        error: null,
        commentsRequested: true,
      };

    case constants.RECEIVE_COMMENTS:
      return {
        ...state,
        commentsRequested: false,
        comments: action.items,
        commentsTotalCount: action.itemsCount,
      };

    case constants.COMMENTS_REQUEST_FAILED:
      return {
        ...state,
        commentsRequested: false,
        error: action.error,
      };

    default:
      return state;
  }
}
