import React, {Component} from 'react';
import moment from 'moment';
import {bool, object, string} from 'prop-types';

import IconButton from 'admin-ng/components/common/icon-button';
import OrgCommentDelete from '../org-comment-delete';
import OrgCommentEdit from '../org-comment-edit';

export default class OrgCommentInstance extends Component {
  static propTypes = {
    comment: object.isRequired,
    isReadOnly: bool,
    orgId: string,
  };

  state = {
    isEditMode: false,
    isDeleteMode: false,
  };

  _onStartEdit = () => {
    this.setState({isEditMode: true});
  };

  _onEndEdit = () => {
    this.setState({isEditMode: false});
  };

  _onStartDelete = () => {
    this.setState({isDeleteMode: true});
  };

  _onEndDelete = () => {
    this.setState({isEditMode: false, isDeleteMode: false});
  };

  render() {
    const content = {__html: this.props.comment.text};
    const {first_name: firstName, last_name: lastName} =
      this.props.comment.author;
    const date = moment(this.props.comment.created_at).format('D MMM Y HH:mm');
    /* eslint-disable react/no-danger */
    return (
      <li className="pl-comment">
        <p className="pl-comment--author">
          {firstName} {lastName} commented - {date}
        </p>
        {!this.state.isEditMode && (
          <div
            className="pl-comment--content DraftEditor-editorContainer"
            dangerouslySetInnerHTML={content}
          />
        )}
        {!this.props.isReadOnly && (
          <div className="pl-comment--controls">
            <IconButton
              disabled={this.state.isDeleteMode}
              icon="delete"
              onClick={this._onStartDelete}
              title="Delete"
            />
            <IconButton
              disabled={this.state.isEditMode}
              icon="create"
              onClick={this._onStartEdit}
              title="Edit"
            />
          </div>
        )}
        <OrgCommentDelete
          comment={this.props.comment}
          onDeleteEnd={this._onEndDelete}
          open={this.state.isDeleteMode}
          orgId={this.props.orgId}
        />
        <OrgCommentEdit
          comment={this.props.comment}
          onEndEdit={this._onEndEdit}
          open={this.state.isEditMode}
          orgId={this.props.orgId}
        />
      </li>
    );
  }
}
