import * as constants from './constants';

const initial = {step: 0};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case constants.GOTO_NEXT_STEP:
      return {...state, step: state.step + 1};

    case constants.GOTO_PREVIOUS_STEP:
      return {...state, step: state.step - 1};

    case constants.RESET_VIEW:
      return {...initial};

    default:
      return state;
  }
};
