import React, {useEffect} from 'react';
import {Link, Route, useParams, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';
import {func, object} from 'prop-types';

const ProgramLink = ({details}) => {
  const {programId} = useParams();
  return (
    <Link to={`/programs/${programId}`}>
      {details ? details.name : 'Program Details'}
    </Link>
  );
};

ProgramLink.propTypes = {
  details: object,
};

const OrgsLink = () => {
  const {programId} = useParams();
  return (
    <Link to={`/programs/${programId}/organizations`}>
      Program Organizations
    </Link>
  );
};

const ProgramsHeader = ({details, updateTitle}) => {
  const rootPath = useRouteMatch('/programs');
  const generalPath = useRouteMatch('/programs/:programId/general');
  const orgsPath = useRouteMatch('/programs/:programId/organizations');

  useEffect(() => {
    if (rootPath && rootPath.isExact) {
      updateTitle('Programs');
    }
    if (generalPath && generalPath.isExact) {
      updateTitle(details ? details.name : '');
    }
    if (orgsPath && orgsPath.isExact) {
      updateTitle('Program Organizations');
    }
  }, [details, updateTitle, rootPath, generalPath, orgsPath]);

  return (
    <Route path="/programs">
      <Link to="/programs">Programs</Link>
      <Route path="/programs/:programId">
        {' / '}
        <ProgramLink details={details} />
        <Route path="/programs/:programId/organizations">
          {' / '}
          <OrgsLink />
        </Route>
      </Route>
    </Route>
  );
};

const mapStateToProps = ({
  programs: {
    instance: {details},
  },
}) => ({
  details,
});

ProgramsHeader.propTypes = {
  details: object,
  updateTitle: func.isRequired,
};

export default connect(mapStateToProps)(ProgramsHeader);
