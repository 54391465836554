import * as constants from './constants';
import * as messages from './messages';
import {ERROR_MESSAGE} from 'admin-ng/constants';
import {check as apiCheck} from 'admin-ng/api/compliance';
import {partial as partialAction} from 'admin-ng/components/app/actions';

export const updateNames = partialAction(constants.UPDATE_NAMES);

export const toggleCanada = () => ({type: constants.TOGGLE_CANADA});
export const toggleUSA = () => ({type: constants.TOGGLE_USA});
export const toggleEU = () => ({type: constants.TOGGLE_EU});

export const checkNamesFail = () => ({
  type: constants.CHECK_NAMES_FAIL,
  payload: messages.EMPTY_NAMES_ERROR,
  error: true,
});

export const setGroups = partialAction(constants.SET_GROUPS);

export const checkStart = index => ({
  type: constants.CHECK_START,
  payload: {index},
});

export const checkSuccess = (index, results) => ({
  type: constants.CHECK_SUCCESS,
  payload: {index, results},
});

export const checkFail = (index, error = ERROR_MESSAGE) => ({
  type: constants.CHECK_FAIL,
  payload: {index, error},
  error: true,
});

export const checkGroup = async (index, options, dispatch) => {
  const nameParts = options.name.split(',', 3).map(x => x.trim());
  const name = nameParts[0];
  const entity = nameParts[1];
  const country = nameParts[2];

  const params = {
    name,
    entity: entity ? entity : null,
    country: country ? country : null,
    compact: true,
    strict_country: false,
    org: null,
    check_usa: options.isWithUSA,
    check_canada: options.isWithCanada,
    check_eu: options.isWithEU,
  };
  dispatch(checkStart(index));
  try {
    const {
      body: {results},
    } = await apiCheck(params);
    dispatch(checkSuccess(index, results));
  } catch (err) {
    dispatch(checkFail(index, err && err.message));
  }
};

export const check = () => (dispatch, getState) => {
  const names = getState().compliance.bulk.names;
  if (names.trim() === '') {
    return dispatch(checkNamesFail());
  }
  dispatch(setGroups(names));
  const {groups, isWithUSA, isWithCanada, isWithEU} =
    getState().compliance.bulk;
  const promises = groups.map((group, index) => {
    const params = {isWithUSA, isWithCanada, isWithEU, name: group.name};
    return checkGroup(index, params, dispatch);
  });
  return Promise.all(promises);
};

export const reset = () => ({type: constants.RESET});
