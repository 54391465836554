export const NAME = 'PLAN_DETAILS_';

// PLAN
export const PLAN_DETAILS_SET_GLOBAL_ERROR = `${NAME}SET_GLOBAL_ERROR`;
export const PLAN_DETAILS_RESET_GLOBAL_ERROR = `${NAME}RESET_GLOBAL_ERROR`;

export const PLAN_DETAILS_REQUEST_SAVE = `${NAME}REQUEST_SAVE`;
export const PLAN_DETAILS_SAVE_REQUEST_COMPLETED = `${NAME}SAVE_REQUEST_COMPLETED`;
export const PLAN_DETAILS_SAVE_REQUEST_FAILED = `${NAME}SAVE_REQUEST_FAILED`;

export const PLAN_DETAILS_RESET_SAVED_STATE = `${NAME}RESET_SAVED_STATE`;
export const PLAN_DETAILS_PLAN_NAME_CHECK = `${NAME}PLAN_NAME_CHECK`;
export const PLAN_DETAILS_COMPLETE_PLAN_NAME_CHECK = `${NAME}COMPLETE_PLAN_NAME_CHECK`;
export const PLAN_DETAILS_CHANGED = `${NAME}CHANGED`;

// POLICY
export const PLAN_DETAILS_POLICY_GET_NEW_NAME = `${NAME}POLICY_GET_NEW_NAME`;
export const PLAN_DETAILS_POLICY_SET_NEW_NAME = `${NAME}POLICY_SET_NEW_NAME`;
export const PLAN_DETAILS_POLICY_RENAME = `${NAME}POLICY_RENAME`;
export const PLAN_DETAILS_POLICY_SELECT = `${NAME}POLICY_SELECT`;
export const PLAN_DETAILS_POLICY_REMOVE = `${NAME}POLICY_REMOVE`;

// POLICY ITEM TYPES
export const PLAN_DETAILS_FETCH_AVAILABLE_ITEM_TYPES = `${NAME}FETCH_AVAILABLE_ITEM_TYPES`;

export const PLAN_DETAILS_POLICY_ADD_ITEM_TYPE = `${NAME}POLICY_ADD_ITEM_TYPE`;
export const PLAN_DETAILS_POLICY_UPDATE_ITEM_TYPE = `${NAME}POLICY_UPDATE_ITEM_TYPE`;
export const PLAN_DETAILS_POLICY_DELETE_ITEM_TYPE = `${NAME}POLICY_DELETE_ITEM_TYPE`;
export const PLAN_DETAILS_POLICY_TOGGLE_ITEM_TYPE = `${NAME}POLICY_TOGGLE_ITEM_TYPE`;
export const PLAN_DETAILS_POLICY_TOGGLE_ITEM_TYPE_ALL = `${NAME}POLICY_TOGGLE_ITEM_TYPE_ALL`;

// POLICY ASSET TYPES
export const PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES = `${NAME}REQUEST_AVAILABLE_ASSETS_TYPES`;
export const PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES_SUCCESS = `${NAME}REQUEST_AVAILABLE_ASSETS_TYPES_SUCCESS`;
export const PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES_FAIL = `${NAME}REQUEST_AVAILABLE_ASSETS_TYPES_FAIL`;

export const PLAN_DETAILS_UPDATE_SELECTED_ASSET_TYPES = `${NAME}UPDATE_SELECTED_ASSET_TYPES`;
export const PLAN_DETAILS_SET_ASSET_TYPE_ERROR = `${NAME}SET_ASSET_TYPE_ERROR`;

export const PLAN_DETAILS_REQUEST_MOSAICS = `${NAME}REQUEST_MOSAICS`;
export const PLAN_DETAILS_REQUEST_BASEMAPS = `${NAME}REQUEST_BASEMAPS`;
export const PLAN_DETAILS_REQUEST_SERIES = `${NAME}REQUEST_SERIES`;
export const PLAN_DETAILS_UPDATE_SELECTED_MOSAICS = `${NAME}UPDATE_SELECTED_MOSAICS`;

// POLICY AOI
export const PLAN_DETAILS_POLICY_ADD_AOI = `${NAME}POLICY_ADD_AOI`;
export const PLAN_DETAILS_POLICY_UPDATE_AOI = `${NAME}POLICY_UPDATE_AOI`;
export const PLAN_DETAILS_POLICY_DELETE_AOI = `${NAME}POLICY_DELETE_AOI`;
export const PLAN_DETAILS_POLICY_SET_AOI_ERROR = `${NAME}POLICY_SET_AOI_ERROR`;

export const PLAN_DETAILS_POLICY_UPLOAD_AOI_START = `${NAME}POLICY_UPLOAD_AOI_START`;
export const PLAN_DETAILS_POLICY_UPLOAD_AOI_SUCCESS = `${NAME}POLICY_UPLOAD_AOI_SUCCESS`;
export const PLAN_DETAILS_POLICY_UPLOAD_AOI_FAIL = `${NAME}POLICY_UPLOAD_AOI_FAIL`;

// POLICY TOI
export const PLAN_DETAILS_POLICY_ADD_TOI = `${NAME}POLICY_ADD_TOI`;
export const PLAN_DETAILS_POLICY_UPDATE_TOI_START = `${NAME}POLICY_UPDATE_TOI_START`;
export const PLAN_DETAILS_POLICY_UPDATE_TOI_END = `${NAME}POLICY_UPDATE_TOI_END`;
export const PLAN_DETAILS_POLICY_UPDATE_TOI_DELAY = `${NAME}POLICY_UPDATE_TOI_DELAY`;
export const PLAN_DETAILS_POLICY_UPDATE_TOI_DELAY_UNITS = `${NAME}POLICY_UPDATE_TOI_DELAY_UNITS`;
export const PLAN_DETAILS_POLICY_DELETE_TOI = `${NAME}POLICY_DELETE_TOI`;
export const PLAN_DETAILS_POLICY_SET_TOI_ERROR = `${NAME}POLICY_SET_TOI_ERROR`;

export const BASEMAPS_LOCALSTORAGE_KEY = '@planetlabs/admin-ng/mosaic-basemaps';
export const SERIES_LOCALSTORAGE_KEY = '@planetlabs/admin-ng/mosaic-series';

// DELIVERY OPTIONS
export const PLAN_DETAILS_UPDATE_DELIVERY_GEOTIFF = `${NAME}UPDATE_DELIVERY_GEOTIFF`;
export const PLAN_DETAILS_UPDATE_DELIVERY_WEBTILE = `${NAME}UPDATE_DELIVERY_WEBTILE`;

// STANDARD PACKAGES
// We need to warn users who update policies that are part of the following packages (plans)
export const protectedPackages = {
  5405: 'Global Basemaps',
  662: 'Global AOI Trial',
  8998: 'Global Basemap Webtile',
  9004: 'Global Area Under Management',
  9502: 'Tiered Area Under Management - Tier 3',
  9501: 'Tiered Area Under Management - Tier 2',
  9500: 'Tiered Area Under Management - Tier 1',
  1243: 'Education and Research Standard (PlanetScope)',
  8411: 'NICFI_Level_1',
  562: 'Media Program',
  1244: 'Education and Research Standard (Landsat/Sentinel)',
};
