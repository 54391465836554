import * as constants from './constants';
import {ERROR_MESSAGE} from 'admin-ng/constants';
import {NOT_FOUND} from './messages';
import {
  getOrganization,
  getUser,
  updateOrganization,
  updateUser,
} from 'admin-ng/api/compliance';
import {updateRouteName} from 'admin-ng/components/app/actions';

export const fetchStart = id => ({
  type: constants.FETCH_START,
  payload: id,
});

export const fetchSuccess = record => ({
  type: constants.FETCH_SUCCESS,
  payload: record,
});

export const fetchFail = (error = ERROR_MESSAGE) => ({
  type: constants.FETCH_FAIL,
  payload: error,
  error: true,
});

export const fetch =
  (entity = 'user', id, history) =>
  dispatch => {
    const promise = entity === 'user' ? getUser : getOrganization;
    dispatch(fetchStart(id));
    return promise(id)
      .then(({body}) => {
        if (body) {
          if (
            entity === 'user' &&
            body.user &&
            body.user.first_name &&
            body.user.last_name
          ) {
            const name = `${body.user.first_name} ${body.user.last_name}`;
            dispatch(updateRouteName('USER_COMPLIANCE_RECORD', name));
          } else if (body.organization && body.organization.name) {
            dispatch(
              updateRouteName(
                'ORGANIZATION_COMPLIANCE_RECORD',
                body.organization.name
              )
            );
          }
        }
        dispatch(fetchSuccess(body));
      })
      .catch((error = {}) => {
        if (error.response && error.response.statusCode === 404) {
          history.push(`/page-not-found?message=${NOT_FOUND}`);
        } else {
          dispatch(fetchFail(error.message));
        }
      });
  };

export const updateStart = (id, flag) => ({
  type: constants.UPDATE_START,
  payload: {id, flag},
});

export const updateSuccess = () => ({
  type: constants.UPDATE_SUCCESS,
});

export const updateFail = (error = ERROR_MESSAGE) => ({
  type: constants.UPDATE_FAIL,
  payload: error,
  error: true,
});

export const update =
  (entity = 'user', id, flag) =>
  dispatch => {
    const promise = entity === 'user' ? updateUser : updateOrganization;

    dispatch(updateStart(id, flag));
    return promise(id, flag)
      .then(() => dispatch(updateSuccess()))
      .catch((error = {}) => dispatch(updateFail(error.message)));
  };

export const reset = () => ({type: constants.RESET});
