import * as api from 'admin-ng/api/users';
import * as constants from './constants';
import * as messages from './messages';
import {isEmail, isPasswordValid} from 'admin-ng/util/validation';

export const reset = () => ({
  type: constants.RESET,
});

export const setUserProperty = (key, value) => ({
  type: constants.SET_USER_PROPERTY,
  payload: {key, value},
});

export const startEmailCheck = email => ({
  type: constants.CHECK_EMAIL,
  payload: {
    email,
  },
});

export const emailCheckResult = (valid, message = null, error = false) => ({
  type: constants.CHECK_EMAIL_RESULT,
  error,
  payload: {
    valid,
    message,
  },
});

export const checkEmail = email => dispatch => {
  dispatch(startEmailCheck(email));
  if (isEmail(email)) {
    return api
      .all({email})
      .then((res = {}) => {
        const items = res.body && res.body.results ? res.body.results : [];

        if (Array.isArray(items)) {
          if (items.length) {
            dispatch(emailCheckResult(false, messages.EMAIL_IS_NOT_UNIQUE_MSG));
          } else {
            dispatch(emailCheckResult(true));
          }
        } else {
          dispatch(emailCheckResult(false, messages.DEFAULT_ERROR_MSG, true));
        }
      })
      .catch((err = {}) => {
        dispatch(
          emailCheckResult(
            false,
            err.message || messages.DEFAULT_ERROR_MSG,
            true
          )
        );
      });
  } else {
    return new Promise(resolve => {
      dispatch(emailCheckResult(false, messages.INVALID_EMAIL_MSG));
      resolve();
    });
  }
};

export const checkPasswordValidity = password => {
  const isValid = isPasswordValid(password);

  return {
    type: constants.CHECK_PASSWORD_VALIDITY,
    payload: {
      valid: isValid,
      message: isValid
        ? null
        : 'Use 10 or more characters with a mix of upper and lowercase letters, numbers, & symbols',
    },
  };
};

export const checkPasswordMatching = (password, repeat) => ({
  type: constants.CHECK_PASSWORD_MATCHING,
  payload: {
    valid: password === repeat,
    message: password === repeat ? null : messages.PASSWORDS_DONT_MATCH,
  },
});

export const startCreateUser = params => ({
  type: constants.CREATE_USER,
  payload: params,
});

export const createUserSuccess = user => ({
  type: constants.CREATE_USER_SUCCESS,
  payload: user,
});

export const createUserError = (message = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.CREATE_USER_ERROR,
  error: true,
  payload: message,
});

export const createUser = orgId => async (dispatch, getState) => {
  const {email, firstName, lastName, password, passwordRepeat, role, country} =
    getState().orgs.instance.users.create;
  if (
    !dispatch(checkPasswordMatching(password, passwordRepeat)).payload.valid
  ) {
    return;
  }
  const params = {
    email,
    first_name: firstName,
    last_name: lastName,
    organization_id: orgId,
    password,
    role_level: role,
    country,
  };
  dispatch(startCreateUser(params));
  try {
    const {body: user} = await api.create(params);
    dispatch(createUserSuccess(user));
  } catch (err) {
    dispatch(createUserError(err && err.message));
  }
};
