import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import {bool, func, number, object, string} from 'prop-types';

import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import ErrorDialog from 'admin-ng/components/common/error-dialog';
import Notification from 'admin-ng/components/common/notification';
import PlanCreateGeneral from 'admin-ng/components/plans/plan-instance/plan-general/plan-create-general-container';
import PlanCreatePolicies from 'admin-ng/components/plans/plan-instance/plan-policies/plan-create-policies-container';
import Sidebar from 'admin-ng/components/common/sidebar';

export default class PlanCreateWorkflow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorTitle: null,
      needCancelConfirmation: false,
      showErrorDialog: false,
    };

    this._onCancel = this._onCancel.bind(this);
    this._closeNewPlan = this._closeNewPlan.bind(this);
    this._closeCancelConfirmation = this._closeCancelConfirmation.bind(this);
    this._closeErrorDialog = this._closeErrorDialog.bind(this);
  }

  componentDidMount() {
    // cleaning previously set data
    this.props.onCancel();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalError || nextProps.error) {
      this.setState({
        error: nextProps.globalError || nextProps.error,
        errorTitle: nextProps.globalError
          ? 'Oops, could not create a package'
          : 'Package data is invalid',
        showErrorDialog: true,
      });
    }
  }

  _closeNewPlan() {
    this.props.onCancel();
    // There will be no 'newPlanId' when cancelling
    if (this.props.newPlanId) {
      this.props.history.push(`/packages/${this.props.newPlanId}/general`);
      return;
    }
    this.props.history.push('/packages');
  }

  _closeCancelConfirmation() {
    this.setState({
      needCancelConfirmation: false,
    });
  }

  _closeErrorDialog() {
    this.setState({
      showErrorDialog: false,
    });

    this.props.onCloseGlobalError();
  }

  _onCancel() {
    this.setState({
      needCancelConfirmation: true,
    });
  }

  render() {
    return (
      <div className="pl-flex-wrapper">
        <Sidebar />

        <div className="pl-app--content pl-plan-create">
          <Notification
            message={this.props.error}
            title="Oops, could not create a plan"
            type="error"
          />

          <ErrorDialog
            onClose={this._closeErrorDialog}
            open={this.state.showErrorDialog}
            qaId="plan-create-error-ok"
          >
            <Notification
              message={this.state.error}
              title={this.state.errorTitle}
              type="error"
            />
          </ErrorDialog>

          <PlanCreateGeneral />

          <PlanCreatePolicies />

          <ConfirmationMessage
            onClose={this._closeCancelConfirmation}
            onSubmit={this._closeNewPlan}
            open={this.state.needCancelConfirmation}
            qaId="plan-create-cancel"
            title="Cancel New Plan"
          >
            Are you sure you want to cancel the new package? All entered data
            will be lost.
          </ConfirmationMessage>

          <Dialog
            fullWidth
            maxWidth="sm"
            open={this.props.isPlanCreated}
            data-qa-id="plan-create-success"
          >
            <DialogTitle>Success</DialogTitle>

            <DialogContent>
              <DialogContentText>
                A new package has been successfully created!
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                color="primary"
                data-qa-id="plan-subscriptions-create-close-button"
                onClick={this._closeNewPlan}
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <div className="pl-plan-create--footer">
            <Button
              data-qa-id="policy-cancel"
              onClick={this._onCancel}
              sx={{marginRight: '15px'}}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              data-qa-id="policy-create"
              onClick={this.props.onCreate}
              variant="contained"
            >
              Create Package
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

PlanCreateWorkflow.propTypes = {
  error: string,
  globalError: string,
  history: object.isRequired,
  isPlanCreated: bool,
  newPlanId: number,
  onCancel: func,
  onCloseGlobalError: func,
  onCreate: func,
};
