import * as constants from './constants';
import {RESET_VIEW} from '../plan-subscription-bulk-create-general/constants';

const initial = {
  error: null,
  orgs: [],
  orgsTotalCount: 0,
  subscribedOrgIds: '',
  selectedOrgs: [],
  selectedOrgIds: [],
  totalCount: 0,
  subscriptions: [],
  viewParams: {
    limit: 20,
    offset: 0,
    sort: '-id',
  },
};

export default (state = initial, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_ORGS:
      return {
        ...state,
        orgs: [],
        error: null,
        isLoading: true,
        viewParams: action.payload,
      };

    case constants.REQUEST_ORGS_SUCCESS: {
      const orgs = action.payload.items;
      const selectedOrgIds = state.selectedOrgIds.slice(0);
      orgs.map(org => ({...org, selected: selectedOrgIds.includes(org.id)}));
      return {
        ...state,
        orgs,
        orgsTotalCount: action.payload.itemsCount,
        error: null,
      };
    }

    case constants.REQUEST_ORGS_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    case constants.REQUEST_SUBSCRIPTIONS:
      return {
        ...state,
        error: null,
        subscribedOrgIds: '',
      };

    case constants.REQUEST_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        error: null,
        subscribedOrgIds: action.payload,
      };

    case constants.REQUEST_SUBSCRIPTIONS_FAILED:
      return {...state, error: action.payload};

    case constants.SELECTED_ORGS_EMPTY:
      return {...state, error: action.payload};

    case constants.UPDATE_SELECTED_ORGS: {
      const orgs = state.orgs.slice(0);
      const selectedOrgs = state.selectedOrgs.slice(0);
      const selectedOrgIds = state.selectedOrgIds.slice(0);
      const selectedItems = action.payload;

      if (Array.isArray(selectedItems)) {
        orgs.forEach((org, i) => {
          org.selected = selectedItems.indexOf(i) !== -1;
          const index = selectedOrgIds.indexOf(org.id);

          if (org.selected) {
            if (index === -1) {
              selectedOrgs.push(org);
              selectedOrgIds.push(org.id);
            }
          } else if (index !== -1) {
            selectedOrgs.splice(index, 1);
            selectedOrgIds.splice(index, 1);
          }
        });
      } else if (selectedItems === 'all') {
        orgs.forEach(org => {
          org.selected = true;

          const index = selectedOrgIds.indexOf(org.id);

          if (index === -1) {
            selectedOrgs.push(org);
            selectedOrgIds.push(org.id);
          }
        });
      } else {
        orgs.forEach(org => {
          org.selected = false;

          const index = selectedOrgIds.indexOf(org.id);

          if (index !== -1) {
            selectedOrgs.splice(index, 1);
            selectedOrgIds.splice(index, 1);
          }
        });
      }

      return {...state, orgs, selectedOrgs, selectedOrgIds};
    }

    case RESET_VIEW:
      return {...initial};

    default:
      return state;
  }
};
