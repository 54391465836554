export const errorMessage =
  'An error occurred while trying to fetch the content.';

class ApiError extends Error {
  constructor(message, props = {}) {
    super(message || errorMessage);
    Object.assign(this, props);
  }
}

export default async (uri, token) => {
  try {
    const response = await fetch(uri, {
      headers: new Headers({Authorization: `Bearer ${token}`}),
    });

    if (response.ok) {
      const blob = await response.blob();
      return blob;
    } else {
      const {statusCode: status, statusMessage: message} = response;
      throw new ApiError(message, {response, status});
    }
  } catch (error) {
    throw new ApiError();
  }
};
