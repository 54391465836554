import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import {bool, func, number, object, string} from 'prop-types';
import {withAuth} from '@apps-common/auth-react';

import Notification from 'admin-ng/components/common/notification';
import SelectField from 'admin-ng/components/common/select-field';
import TextField from 'admin-ng/components/common/text-field';
import roles from 'admin-ng/util/roles';

class OrgMemberInvite extends Component {
  constructor(props) {
    super(props);

    this._handleEmailChange = this._handleEmailChange.bind(this);
    this._handleRoleChange = this._handleRoleChange.bind(this);
  }

  _handleEmailChange(value) {
    this.props.onDataChange({
      field: 'email',
      value,
    });
  }

  _handleRoleChange(event) {
    this.props.onDataChange({
      field: 'role',
      value: event.target.value,
    });
  }

  _renderUserRoles() {
    const user = this.props.auth.user.claims;
    return roles
      .filter(role => role.level <= user.role_level)
      .map((role, i) => (
        <MenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={`role${i}`}
          value={role.level}
        >
          {role.label}
        </MenuItem>
      ));
  }

  _renderSuccess = () => (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={this.props.onClose}
      open={this.props.isOpen}
    >
      <DialogTitle>Invite a user</DialogTitle>

      <DialogContent>
        <DialogContentText>
          An invitation for a user {this.props.email} has been successully sent
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          data-qa-id="org-member-invite-close-button"
          focusRipple
          key={1}
          onClick={this.props.onClose}
          variant="contained"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  _renderDialog = () => {
    const disabled = this.props.isInviteRequested;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={this.props.onClose}
        open={this.props.isOpen}
      >
        <DialogTitle>Invite a user</DialogTitle>

        <DialogContent>
          <Notification
            message={this.props.inviteError}
            title="Oops, failed to invite a user"
            type="error"
          />

          <TextField
            error={!!this.props.emailError}
            helperText={this.props.emailError}
            label="Email *"
            isInputValid={this.props.isEmailUnique}
            onChange={this._handleEmailChange}
            onChangeCompleted={this.props.onEmailCheck}
            value={this.props.email}
          />

          <SelectField
            error={!!this.props.roleError}
            helperText={this.props.roleError}
            label="Role *"
            onChange={this._handleRoleChange}
            style={{marginBottom: 10}}
            value={this.props.role}
          >
            {this._renderUserRoles()}
          </SelectField>
        </DialogContent>

        <DialogActions>
          <Button
            color="inherit"
            data-qa-id="org-member-invite-cancel-button"
            onClick={this.props.onClose}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            data-qa-id="org-member-invite-submit-button"
            disabled={disabled}
            focusRipple
            onClick={!disabled ? this.props.onSubmit : null}
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    return this.props.isInviteSent
      ? this._renderSuccess()
      : this._renderDialog();
  }
}

OrgMemberInvite.propTypes = {
  auth: object.isRequired,
  email: string,
  emailError: string,
  inviteError: string,
  isEmailUnique: bool,
  isInviteRequested: bool,
  isInviteSent: bool,
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  onDataChange: func.isRequired,
  onEmailCheck: func.isRequired,
  onSubmit: func.isRequired,
  role: number,
  roleError: string,
};

export default withAuth(OrgMemberInvite);
