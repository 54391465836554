import {UPDATE_ROUTE_NAME} from './constants';

export const updateRouteName = (key, name) => ({
  type: UPDATE_ROUTE_NAME,
  key,
  name,
});

export const fail =
  (type, defaultMessage) =>
  ({status, message = defaultMessage} = {}) => {
    let error = message;
    if (status) {
      error += `. Error code: ${status}`;
    }
    return {type, error};
  };

export const partial = type => payload => ({type, payload});
