import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import DocumentsIndex from './documents-index';
import {fetchDocuments, resetView} from './actions';

const mapStateToProps = ({documents: {index}}) => index;
const mapDispatchToProps = dispatch => ({
  onOpen: (params = {}) => dispatch(fetchDocuments(params)),
  resetView: () => dispatch(resetView()),
});

export default withAuth(
  connect(mapStateToProps, mapDispatchToProps)(DocumentsIndex)
);
