import * as constants from './constants';
import * as messages from './messages';
import {fetchAssetTypes} from '../plan-policies/actions';
import {one} from 'admin-ng/api/plans';
import {updateRouteName} from 'admin-ng/components/app/actions';

export const requestPlanDetails = (id = null) => ({
  type: constants.REQUEST_PLAN_DETAILS,
  id,
});

export const receivePlanDetails = (details = null) => ({
  type: constants.RECEIVE_PLAN_DETAILS,
  details,
});

export const planDetailsRequestFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.PLAN_DETAILS_REQUEST_FAILED,
  error,
});

export const resetState = () => ({
  type: constants.PLAN_DETAILS_RESET_STATE,
});

export const fetchPlan = (planId, history) => dispatch => {
  dispatch(requestPlanDetails(planId));
  return one(planId)
    .then((res = {}) => {
      dispatch(receivePlanDetails(res.body));

      if (res.body && res.body.name) {
        dispatch(updateRouteName('PLAN_DETAILS', res.body.name));
      }

      return res;
    })
    .then(res => {
      res.body.policies.forEach((policy, policyIndex) => {
        policy.constraints.forEach(constraint => {
          if (constraint.key === 'type_permissions') {
            const itemTypes = Object.keys(constraint.value.map);
            for (const itemType of itemTypes) {
              const itemTypeIndex = itemTypes.indexOf(itemType);
              dispatch(fetchAssetTypes(policyIndex, itemTypeIndex, itemType));
            }
          }
        });
      });
    })
    .catch((err = {}) => {
      if (err.response && err.response.statusCode === 404) {
        history.push(`/page-not-found?message=${messages.PLAN_NOT_FOUND}`);
      } else {
        dispatch(planDetailsRequestFailed(err.message));
      }
    });
};
