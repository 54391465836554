import React from 'react';
import {string} from 'prop-types';

const PlanSummary = props => (
  <ul className="pl-wizard-plan-summary">
    <li>
      Source Package:{' '}
      <span className="pl-wizard-plan-summary--value">
        {props.sourcePlanName}
      </span>
    </li>
    <li>
      Destination Package:
      <span className="pl-wizard-plan-summary--value">
        {props.destinationPlanName}
      </span>
    </li>
  </ul>
);

PlanSummary.propTypes = {
  destinationPlanName: string,
  sourcePlanName: string,
};

export default PlanSummary;
