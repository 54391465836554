import * as constants from './constants';

// Remove already selected documents from the autocomplete search results (they can't be added again).
const filterResults = (selectedDocs, newDocs) => {
  const ids = new Set(selectedDocs.map(doc => doc.id));
  return newDocs.filter(doc => !ids.has(doc.id));
};

const initial = {
  id: null,
  programSaveError: null,
  description: '',
  documents: [],
  documentSearchResults: [],
  documentSearchError: null,
  isCheckingName: false,
  isNameUnique: null,
  isSaveRequestPending: false,
  name: '',
  nameError: null,
  inviteEmailText: '',
  isProgramSaved: null,
};

export default function programEditReducer(state = initial, action = {}) {
  switch (action.type) {
    case constants.INITIALIZE_DETAILS:
      return {
        ...state,
        id: action.id,
        name: action.name,
        description: action.description,
        inviteEmailText: action.inviteEmailText,
        documents: action.documents,
      };

    case constants.UPDATE_NAME:
      return {
        ...state,
        name: action.name,
      };

    case constants.UPDATE_DESCRIPTION:
      return {
        ...state,
        description: action.description,
      };

    case constants.UPDATE_INVITE_EMAIL_TEXT:
      return {
        ...state,
        inviteEmailText: action.inviteEmailText,
      };

    case constants.UPDATE_DOCUMENTS:
      return {
        ...state,
        documents: action.documents,
        documentSearchResults: filterResults(
          action.documents,
          state.documentSearchResults
        ),
      };

    case constants.REQUEST_NAME_CHECK:
      return {
        ...state,
        isCheckingName: true,
        nameError: null,
      };

    case constants.REQUEST_NAME_CHECK_SUCCESS:
      return {
        ...state,
        isCheckingName: false,
        nameError: null,
        isNameUnique: action.isNameUnique,
      };

    case constants.REQUEST_NAME_CHECK_FAIL:
      return {
        ...state,
        isCheckingName: false,
        nameError: action.error,
      };

    case constants.REQUEST_DOCUMENT_SEARCH:
      return {
        ...state,
        documentSearchResults: [],
        documentSearchError: null,
      };

    case constants.REQUEST_DOCUMENT_SEARCH_SUCCESS:
      return {
        ...state,
        documentSearchResults: filterResults(state.documents, action.documents),
        documentSearchError: null,
      };

    case constants.REQUEST_DOCUMENT_SEARCH_FAIL:
      return {
        ...state,
        documentSearchResults: [],
        documentSearchError: action.error,
      };

    case constants.REQUEST_SAVE:
      return {
        ...state,
        isSaveRequestPending: true,
      };

    case constants.REQUEST_SAVE_SUCCESS:
      return {
        ...state,
        isSaveRequestPending: false,
        isProgramSaved: true,
      };

    case constants.REQUEST_SAVE_FAIL:
      return {
        ...state,
        isSaveRequestPending: false,
        isProgramSaved: false,
        programSaveError: action.error,
      };

    case constants.RESET_STATE:
      return {...initial};

    default:
      return state;
  }
}
