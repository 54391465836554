import thunk from 'redux-thunk';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import {routerReducer as routing} from 'react-router-redux';

import addSpinnerInterceptors from './components/common/request-spinner/interceptors';
import apiKeys from './components/api-keys/reducer';
import app from './components/app/reducer';
import compliance from './components/compliance/reducer';
import documents from './components/documents/reducer';
import invitations from './components/invitations/reducer';
import orgs from './components/orgs/reducer';
import plans from './components/plans/reducer';
import programs from './components/programs/reducer';
import requestSpinner from './components/common/request-spinner/reducer';
import sidebar from './components/common/sidebar/reducer';
import subscriptions from './components/subscriptions/reducer';
import users from './components/users/reducer';

const middlewares = [thunk];

// Integrate redux-logger and redux-devtools when we're not in production.
const composeEnhancers =
  import.meta.env.MODE !== 'production' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const combinedReducer = combineReducers({
  apiKeys,
  app,
  compliance,
  documents,
  invitations,
  orgs,
  plans,
  programs,
  requestSpinner,
  routing,
  sidebar,
  subscriptions,
  users,
});

// NOTE: We aren't clearing the store on logout because the store will be empty
// once redirected here on auth

const store = createStore(
  combinedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);
const {dispatch} = store;

addSpinnerInterceptors(dispatch);

export {dispatch};
export default store;
