import React from 'react';
import {array, func, object, string} from 'prop-types';

import AOIConstraint from 'admin-ng/components/common/data-constraints/aoi';
import DataConstraints from 'admin-ng/components/common/data-constraints/data';
import DeliveryOptionsConstraint from 'admin-ng/components/common/data-constraints/delivery-options';
import Notification from 'admin-ng/components/common/notification';
import ToiConstraint from 'admin-ng/components/common/data-constraints/toi';
import {requiredDeliveryOptions} from 'admin-ng/util/item-types';

const Policy = props => {
  const policy = props.policy;

  const deliveryOptions =
    requiredDeliveryOptions(policy?.itemType?.ui?.[0].category) ?? {};

  let error = null;

  if (policy) {
    error = policy.aoiError || policy.toiError;
  }

  return (
    <div className="pl-plan-policies--content">
      {policy && (
        <div>
          <Notification
            message={error}
            title="Invalid Permission Data. Please correct all errors."
            type="error"
          />

          <DataConstraints
            availableItemTypes={props.availableItemTypes}
            itemTypesRequestError={props.itemTypesRequestError}
            onAddItemType={props.onAddItemType}
            onDeleteItemType={props.onDeleteItemType}
            onGetAssetTypes={props.onGetAssetTypes}
            onGetAvailableItemTypes={props.onGetAvailableItemTypes}
            onRequestBasemaps={props.onRequestBasemaps}
            onRequestSeries={props.onRequestSeries}
            onUpdateAssetTypes={props.onUpdateAssetTypes}
            onUpdateDeliveryOptions={props.onUpdateDeliveryOptions}
            onUpdateItemType={props.onUpdateItemType}
            onUpdateSelectedMosaics={props.onUpdateSelectedMosaics}
            policy={policy}
            toggleItemType={props.toggleItemType}
            toggleItemTypeAll={props.toggleItemTypeAll}
          />

          <div>
            {policy.aoi.ui.length > 0 ? (
              <AOIConstraint
                multipleAllowed
                onAddAOI={props.onAddAOI}
                onDeleteAOI={props.onDeleteAOI}
                onUpdateAOI={props.onUpdateAOI}
                onUploadAOIFile={props.onUploadAOIFile}
                policy={policy}
              />
            ) : null}

            {policy.toi.ui.length > 0 ? (
              <ToiConstraint
                onAddTOI={props.onAddTOI}
                onDeleteTOI={props.onDeleteTOI}
                onUpdateTOI={props.onUpdateTOI}
                policy={policy}
              />
            ) : null}

            {deliveryOptions && (
              <DeliveryOptionsConstraint
                deliveryOptions={props.policy.deliveryOptions.ui}
                onUpdateDeliveryOptions={props.onUpdateDeliveryOptions}
                showOptions={deliveryOptions}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Policy.propTypes = {
  availableItemTypes: array,
  itemTypesRequestError: string,
  onAddAOI: func.isRequired,
  onAddItemType: func.isRequired,
  onAddTOI: func.isRequired,
  onDeleteAOI: func.isRequired,
  onDeleteItemType: func.isRequired,
  onDeleteTOI: func.isRequired,
  onGetAssetTypes: func.isRequired,
  onGetAvailableItemTypes: func,
  onRequestBasemaps: func.isRequired,
  onRequestSeries: func.isRequired,
  onUpdateAOI: func.isRequired,
  onUpdateAssetTypes: func.isRequired,
  onUpdateDeliveryOptions: func,
  onUpdateItemType: func.isRequired,
  onUpdateSelectedMosaics: func.isRequired,
  onUpdateTOI: func.isRequired,
  onUploadAOIFile: func.isRequired,
  policy: object,
  toggleItemType: func.isRequired,
  toggleItemTypeAll: func.isRequired,
};

export default Policy;
