import * as constants from './constants';

export const updateStartDate = (
  date,
  isActiveFromToday = false,
  error = null
) => ({
  type: constants.UPDATE_START_DATE,
  payload: {
    date,
    isActiveFromToday,
    error,
  },
});

export const updateEndDate = (date, hasNoEndDate = false, error = null) => ({
  type: constants.UPDATE_END_DATE,
  payload: {
    date,
    hasNoEndDate,
    error,
  },
});

export const updateReference = (reference, error = null) => ({
  type: constants.UPDATE_REFERENCE,
  payload: {
    reference,
    error,
  },
});

export const updateDate = (params = {}) => {
  return dispatch => {
    const payload = params.payload || {};

    if (params.field) {
      switch (params.field) {
        case 'startDate':
          return dispatch(
            updateStartDate(
              payload.value,
              payload.isActiveFromToday,
              payload.error
            )
          );

        case 'endDate':
          return dispatch(
            updateEndDate(payload.value, payload.hasNoEndDate, payload.error)
          );

        default:
          return;
      }
    }
  };
};
