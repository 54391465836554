import * as constants from './constants';
import * as messages from './messages';
import {all, create} from 'admin-ng/api/organizations';
import {fetchOrgs} from 'admin-ng/components/orgs/orgs-index/actions';
import {fetchSuborgs} from '../suborgs-index/actions';

export const updateSuborgName = (name, error = null) => ({
  type: constants.NEW_SUBORG_UPDATE_NAME,
  name,
  error,
});

export const requestSuborgNameCheck = name => ({
  type: constants.NEW_SUBORG_NAME_CHECK_REQUEST,
  name,
});

export const completeSuborgNameCheck = (isNameUnique, error = null) => ({
  type: constants.NEW_SUBORG_NAME_CHECK_SUCCEESS,
  isNameUnique,
  error,
});

export const submitNewSuborg = (params = null) => ({
  type: constants.NEW_SUBORG_CREATE_REQUEST,
  params,
});

export const completeNewSuborgSubmit = () => ({
  type: constants.NEW_SUBORG_CREATE_SUCCESS,
});

export const newSuborgSubmitFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.NEW_SUBORG_CREATE_FAIL,
  error,
});

export const resetSuborgCreateState = () => ({
  type: constants.NEW_SUBORG_RESET_STATE,
});

export const checkSuborgName = (parentId, name) => {
  return function (dispatch) {
    const params = {
      parent_organization_id: parentId,
      name__ieq: name,
    };

    dispatch(requestSuborgNameCheck(name));

    return all(params)
      .then((res = {}) => {
        const items = res.body && res.body.results ? res.body.results : null;

        if (items && items.hasOwnProperty('length')) {
          if (items.length) {
            dispatch(
              completeSuborgNameCheck(
                false,
                messages.SUBORG_NAME_IS_NOT_UNIQUE_MSG
              )
            );
          } else {
            dispatch(completeSuborgNameCheck(true));
          }
        } else {
          dispatch(completeSuborgNameCheck(false, messages.DEFAULT_ERROR_MSG));
        }
      })
      .catch((err = {}) => {
        dispatch(
          completeSuborgNameCheck(
            false,
            err.message || messages.DEFAULT_ERROR_MSG
          )
        );
      });
  };
};

export const createNewSuborg = (parentId, parentProgramId) => {
  return function (dispatch, getState) {
    const state = getState(),
      suborgsCreateState = state.orgs.instance.suborgs.create;

    if (suborgsCreateState.name && suborgsCreateState.isNameUnique) {
      const params = {
        name: suborgsCreateState.name,
        parent_organization_id: parentId,
        program: {
          id: parentProgramId,
        },
        state: 'active',
      };

      dispatch(submitNewSuborg(params));

      return create(params)
        .then(() => {
          dispatch(completeNewSuborgSubmit());
          dispatch(fetchOrgs());
          dispatch(fetchSuborgs(parentId));
        })
        .catch((err = {}) => {
          dispatch(
            newSuborgSubmitFail(err.message || messages.DEFAULT_ERROR_MSG)
          );
        });
    }

    if (!suborgsCreateState.name) {
      dispatch(updateSuborgName('', messages.SUBORG_NAME_IS_NOT_SPECIFIED_MSG));
    }
  };
};
