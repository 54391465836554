import store from '../../../store';
import {SET_VISIBLE, SIDEBAR_LOCALSTORAGE_KEY} from './constants';

export const setVisibleAction = visible => ({
  type: SET_VISIBLE,
  payload: visible,
});

export const setVisible = visible => dispatch => {
  const payload = Boolean(visible);
  localStorage.setItem(SIDEBAR_LOCALSTORAGE_KEY, payload);
  dispatch(setVisibleAction(visible));
};

export const toggle = () => dispatch => {
  const visible = store.getState().sidebar.visible;
  dispatch(setVisible(!visible));
};

export const initData = () => dispatch => {
  try {
    const visible = JSON.parse(localStorage.getItem(SIDEBAR_LOCALSTORAGE_KEY));
    if (visible === true || visible === false) {
      dispatch(setVisible(visible));
    }
  } catch (err) {
    console.error('Initializing sidebar failed', err); // eslint-disable-line no-console
  }
};
