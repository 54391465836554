import importer from '@apps-common/importer';
import {createAction} from 'redux-actions';
import {isEqual, pickBy} from 'lodash';

import * as constants from './constants';
import * as messages from '../plan-common/messages';
import * as spinner from 'admin-ng/components/common/request-spinner/actions';
import {
  additionalItemTypes,
  doesRequireAoi,
  doesRequireAssetTypes,
  doesRequireMosaics,
  doesRequireToi,
} from 'admin-ng/util/item-types';
import {basemapsByName} from 'admin-ng/api/basemaps';
import {create, all as getPlans, update} from 'admin-ng/api/plans';
import {fetchPlan} from '../plan-common/actions';
import {getAssetTypes, getItemTypes} from 'admin-ng/api/item-types';
import {isAOI} from 'admin-ng/util/validation';
import {preparePolicies} from 'admin-ng/util/policies/preparers';
import {seriesByName} from 'admin-ng/api/series';

const MAX_FEATURES = 50;

const VALIDATION_MESSAGES = {
  'aoi.invalid': 'Your input is empty',
  'aoi.geo-json': 'Your input is not a valid GeoJSON',
  'geometry.invalid': 'Your input has an invalid geometry',
  'geometry.type': 'Your input has an unsupported type',
  'coordinates.invalid': 'Your input has invalid coordinates',
  'coordinates.exterior-ring': 'Your input has an invalid exterior ring',
  'coordinates.world-boundaries':
    'Your input has coordinates outside world boundaries',
};

// WORKFLOW
export const setGlobalError = (error = null) => ({
  type: constants.PLAN_DETAILS_SET_GLOBAL_ERROR,
  error,
});

export const resetGlobalError = () => ({
  type: constants.PLAN_DETAILS_RESET_GLOBAL_ERROR,
});

// PLANS
export const requestPlanSave = (params = null) => ({
  type: constants.PLAN_DETAILS_REQUEST_SAVE,
  params,
});

export const planSaveRequestCompleted = newSaveAsPlanId => ({
  type: constants.PLAN_DETAILS_SAVE_REQUEST_COMPLETED,
  newSaveAsPlanId,
});

export const planSaveRequestFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.PLAN_DETAILS_SAVE_REQUEST_FAILED,
  error,
});

export const resetSaved = () => ({
  type: constants.PLAN_DETAILS_RESET_SAVED_STATE,
});

export const planNameCheck = name => ({
  type: constants.PLAN_DETAILS_PLAN_NAME_CHECK,
  name,
});

export const completePlanNameCheck = (isCopyPlanNameUnique, error = null) => ({
  type: constants.PLAN_DETAILS_COMPLETE_PLAN_NAME_CHECK,
  isCopyPlanNameUnique,
  error,
});

const dispatchActions = (dispatch, params) => {
  return getPlans(params)
    .then((res = {}) => {
      const items = res.body && res.body.results ? res.body.results : [];

      if (items.length) {
        dispatch(
          completePlanNameCheck(false, messages.PLAN_NAME_IS_NOT_UNIQUE_MSG)
        );
      } else {
        dispatch(completePlanNameCheck(true));
      }
    })
    .catch(() => {
      dispatch(completePlanNameCheck(false, messages.DEFAULT_ERROR_MSG));
    });
};

let searchTimeout;

export const checkPlanName = name => dispatch => {
  clearTimeout(searchTimeout);
  return new Promise((resolve, reject) => {
    searchTimeout = setTimeout(() => {
      dispatch(planNameCheck(name));
      dispatchActions(dispatch, {name__ieq: name.trim()})
        .then(resolve)
        .catch(reject);
    }, 400);
  });
};

export const setPlanChanged = (isPlanChanged = false) => ({
  type: constants.PLAN_DETAILS_CHANGED,
  isPlanChanged,
});

const _hasOldAndNewChanged = (oldItems, newItems, relevantFields) => {
  // Only check fields we care about defined by 'relevantFields'
  const oldRelevantFields = oldItems.map(item => {
    return pickBy(item, (value, key) => {
      return relevantFields.indexOf(key) > -1;
    });
  });

  const newRelevantFields = newItems.map(item => {
    return pickBy(item, (value, key) => {
      return relevantFields.indexOf(key) > -1;
    });
  });

  // Check object structure, not reference
  return !isEqual(oldRelevantFields, newRelevantFields);
};

export const isPlanChanged = () => (dispatch, getState) => {
  const state = getState();
  const policies = state.plans.instance.policies.items;
  const policiesCount = policies.length;
  let isPlanChanged = false;
  let policy;
  let i;

  for (i = 0; i < policiesCount; i++) {
    policy = policies[i];

    if (policy.planName !== policy.originalPlanName) {
      isPlanChanged = true;
    }

    const itemTypeRelevantFields = ['value', 'selectedAssetTypes'];
    if (
      _hasOldAndNewChanged(
        policy.itemType.data,
        policy.itemType.ui,
        itemTypeRelevantFields
      )
    ) {
      isPlanChanged = true;
    }

    const aoiRelevantFields = ['value'];
    if (
      policy.aoi &&
      _hasOldAndNewChanged(policy.aoi.data, policy.aoi.ui, aoiRelevantFields)
    ) {
      isPlanChanged = true;
    }

    const toiRelevantFields = [
      'start',
      'hasNoStartDate',
      'end',
      'hasNoEndDate',
    ];
    if (
      policy.toi &&
      _hasOldAndNewChanged(policy.toi.data, policy.toi.ui, toiRelevantFields)
    ) {
      isPlanChanged = true;
    }

    if (policy.toi && policy.toi.delay !== policy.toi.originalDelay) {
      isPlanChanged = true;
    }
  }

  dispatch(setPlanChanged(isPlanChanged));
};

// ASSET TYPES
export const requestAssetTypes = (policyIndex, itemTypeIndex) => ({
  type: constants.PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES,
  policyIndex,
  itemTypeIndex,
});

export const receiveAssetTypes = (
  policyIndex,
  itemTypeIndex,
  response = {}
) => ({
  type: constants.PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES_SUCCESS,
  policyIndex,
  itemTypeIndex,
  items: response.asset_types || response.mosaics || [],
});

export const assetTypesRequestFailed = (
  policyIndex,
  itemTypeIndex,
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.PLAN_DETAILS_REQUEST_AVAILABLE_ASSETS_TYPES_FAIL,
  policyIndex,
  itemTypeIndex,
  error,
});

export const updateAssetTypes = (index, items) => ({
  type: constants.PLAN_DETAILS_UPDATE_SELECTED_ASSET_TYPES,
  items,
  index,
});

export const updateSelectedMosaics = (index, items) => ({
  type: constants.PLAN_DETAILS_UPDATE_SELECTED_MOSAICS,
  index,
  items,
});

export const fetchAssetTypes =
  (policyIndex, itemTypeIndex, itemTypeId) => (dispatch, getState) => {
    const availableItemTypes =
      getState().plans.instance.policies.availableItemTypes;
    dispatch(requestAssetTypes(policyIndex, itemTypeIndex));

    const actualItemType = availableItemTypes.find(
      itemType => itemType.id === itemTypeId
    );
    if (actualItemType && !doesRequireAssetTypes(actualItemType.category)) {
      dispatch(
        receiveAssetTypes(policyIndex, itemTypeIndex, {asset_types: []})
      );
      return;
    }

    return getAssetTypes(itemTypeId, {external_only: true})
      .then((response = {}) => {
        dispatch(receiveAssetTypes(policyIndex, itemTypeIndex, response.body));
      })
      .catch((error = {}) => {
        dispatch(
          assetTypesRequestFailed(policyIndex, itemTypeIndex, error.message)
        );
      });
  };

export const fetchAvailableItemTypesAction = createAction(
  constants.PLAN_DETAILS_FETCH_AVAILABLE_ITEM_TYPES
);

export const fetchAvailableItemTypes =
  (fetchAction = fetchAvailableItemTypesAction) =>
  dispatch => {
    dispatch(fetchAction());

    return getItemTypes({external_only: true})
      .then(response => {
        const itemTypes = response.body.item_types;
        itemTypes.forEach(itemType => {
          itemType.category = 'data_v1';
        });
        const items = itemTypes.concat(additionalItemTypes()) || [];
        dispatch(fetchAction({items}));
      })
      .catch(error => {
        dispatch(fetchAction(error));
      });
  };

export const addItemType = () => ({
  type: constants.PLAN_DETAILS_POLICY_ADD_ITEM_TYPE,
});

export const updateItemType = (index, itemType, error = null) => ({
  type: constants.PLAN_DETAILS_POLICY_UPDATE_ITEM_TYPE,
  index,
  itemType,
  error,
});

export const deleteItemType = index => ({
  type: constants.PLAN_DETAILS_POLICY_DELETE_ITEM_TYPE,
  index,
});

export const toggleItemType = index => ({
  type: constants.PLAN_DETAILS_POLICY_TOGGLE_ITEM_TYPE,
  index,
});

export const toggleItemTypeAll = toggle => ({
  type: constants.PLAN_DETAILS_POLICY_TOGGLE_ITEM_TYPE_ALL,
  toggle,
});

// AOI
export const addAOI = () => ({type: constants.PLAN_DETAILS_POLICY_ADD_AOI});

export const updateAOIAction = (index, aoi, isValid, error = null) => ({
  type: constants.PLAN_DETAILS_POLICY_UPDATE_AOI,
  index,
  aoi,
  isValid,
  error,
});

export const updateAOI =
  (index, aoi = null, isValid = true, error = null) =>
  dispatch => {
    if (error) {
      return dispatch(updateAOIAction(index, aoi, isValid, error));
    }
    const result = isAOI(aoi);
    if (result !== true) {
      return dispatch(
        updateAOIAction(index, aoi, false, VALIDATION_MESSAGES[result])
      );
    }
    return dispatch(updateAOIAction(index, aoi, true));
  };

export const deleteAOI = index => ({
  type: constants.PLAN_DETAILS_POLICY_DELETE_AOI,
  index,
});

export const setPolicyAOIError = (error = null) => ({
  type: constants.PLAN_DETAILS_POLICY_SET_AOI_ERROR,
  error,
});

export const uploadAOIFileStart = () => ({
  type: constants.PLAN_DETAILS_POLICY_UPLOAD_AOI_START,
});

// File and Tile ID upload use the same success and fail actions
export const uploadPolicyAOISuccess = () => ({
  type: constants.PLAN_DETAILS_POLICY_UPLOAD_AOI_SUCCESS,
});

export const uploadPolicyAOIFail = () => ({
  type: constants.PLAN_DETAILS_POLICY_UPLOAD_AOI_FAIL,
});

export const uploadAOIFile = (index, file) => async dispatch => {
  dispatch(spinner.start());
  dispatch(uploadAOIFileStart());
  try {
    const collection = await importer([file]);
    const features = collection.features
      .filter(
        feature =>
          feature.geometry &&
          (feature.geometry.type === 'Polygon' ||
            feature.geometry.type === 'MultiPolygon') &&
          feature.geometry.coordinates.length > 0
      )
      .slice(0, MAX_FEATURES);

    if (features.length === 0) {
      throw new Error('No valid features found.');
    }

    const result = JSON.stringify({
      features,
      type: 'FeatureCollection',
    });

    dispatch(spinner.success());
    dispatch(uploadPolicyAOISuccess());
    dispatch(updateAOI(index, result, true));
  } catch (err) {
    dispatch(spinner.fail());
    dispatch(uploadPolicyAOIFail());
    dispatch(updateAOI(index, null, false, err.message));
  }
};

// TOI
export const addTOI = (toi, error = null) => ({
  type: constants.PLAN_DETAILS_POLICY_ADD_TOI,
  toi,
  error,
});

export const deleteTOI = index => ({
  type: constants.PLAN_DETAILS_POLICY_DELETE_TOI,
  index,
});

export const updateTOIStart = (
  index,
  date,
  hasNoStartDate = false,
  error = null
) => ({
  type: constants.PLAN_DETAILS_POLICY_UPDATE_TOI_START,
  index,
  date,
  hasNoStartDate,
  error,
});

export const updateTOIEnd = (
  index,
  date,
  hasNoEndDate = false,
  error = null
) => ({
  type: constants.PLAN_DETAILS_POLICY_UPDATE_TOI_END,
  index,
  date,
  hasNoEndDate,
  error,
});

export const updateTOIDelay = (delay = 0, error = null) => ({
  type: constants.PLAN_DETAILS_POLICY_UPDATE_TOI_DELAY,
  delay,
  error,
});

export const updateTOI = (index, data = {}, error) => {
  return dispatch => {
    const payload = data.payload || {};

    switch (data.type) {
      case 'start':
        return dispatch(
          updateTOIStart(index, payload.value, payload.hasNoStartDate, error)
        );

      case 'end':
        return dispatch(
          updateTOIEnd(index, payload.value, payload.hasNoEndDate, error)
        );

      case 'delay':
        return dispatch(updateTOIDelay(data.value, error));

      default:
        return;
    }
  };
};

export const setPolicyTOIError = (error = null) => ({
  type: constants.PLAN_DETAILS_POLICY_SET_TOI_ERROR,
  error,
});

// DELIVERY OPTIONS
export const updateDeliveryOptions = (type, value) => {
  switch (type) {
    case 'geoTIFF':
      return {
        type: constants.PLAN_DETAILS_UPDATE_DELIVERY_GEOTIFF,
        payload: value,
      };
    case 'webtile':
      return {
        type: constants.PLAN_DETAILS_UPDATE_DELIVERY_WEBTILE,
        payload: value,
      };
    default:
      return;
  }
};

// POLICY VALIDATION
export const isPolicyAOIValid = (policy, dispatch) => {
  const aois = policy.aoi.ui.filter(aoi => aoi.value);

  // There must be at least one AOI with a value present
  if (aois.length === 0) {
    dispatch(setPolicyAOIError(messages.POLICY_AOI_NOT_SPECIFIED));
    dispatch(setGlobalError(messages.POLICY_AOI_NOT_SPECIFIED));
    return false;
  }

  // There can't be any AOIs that are invalid
  if (aois.some(aoi => !aoi.isValid)) {
    dispatch(setPolicyAOIError(messages.POLICY_INVALID_AOI_ERROR_MESSAGE));
    dispatch(setGlobalError(messages.POLICY_INVALID_AOI_ERROR_MESSAGE));
    return false;
  }

  return true;
};

export const isPolicyTOIValid = (policy, dispatch) => {
  const tois = policy.toi.ui;
  let counter = 0;
  let toi;

  for (const index in tois) {
    toi = tois[index];

    if (!toi.start && !toi.hasNoStartDate) {
      dispatch(
        updateTOIStart(
          index,
          toi.start,
          toi.hasNoStartDate,
          messages.START_DATE_NOT_SPECIFIED_ERROR_MESSAGE
        )
      );
      dispatch(setGlobalError(messages.POLICY_INVALID_TOI_ERROR_MESSAGE));
      return false;
    }

    if (!toi.end && !toi.hasNoEndDate) {
      dispatch(
        updateTOIEnd(
          index,
          toi.end,
          toi.hasNoEndDate,
          messages.END_DATE_NOT_SPECIFIED_ERROR_MESSAGE
        )
      );
      dispatch(setGlobalError(messages.POLICY_INVALID_TOI_ERROR_MESSAGE));
      return false;
    }

    if ((toi.start || toi.hasNoStartDate) && (toi.end || toi.hasNoEndDate)) {
      counter++;
      if (toi.isValid === false) {
        dispatch(setPolicyTOIError(messages.POLICY_INVALID_TOI_ERROR_MESSAGE));
        dispatch(setGlobalError(messages.POLICY_INVALID_TOI_ERROR_MESSAGE));
        return false;
      }
    }
  }

  if (counter === 0) {
    dispatch(setPolicyTOIError(messages.POLICY_TOI_NOT_SPECIFIED));
    dispatch(setGlobalError(messages.POLICY_TOI_NOT_SPECIFIED));
    return false;
  }

  return true;
};

export const setPolicyAssetTypeError = (
  itemTypeIndex,
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.PLAN_DETAILS_SET_ASSET_TYPE_ERROR,
  itemTypeIndex,
  error,
});

const isItemTypeAssetTypesValid = itemType => {
  const assetTypes = itemType.selectedAssetTypes.items;
  return assetTypes.length !== 0;
};

export const isPolicyValid = (policy, dispatch) => {
  let isValid = true;

  if (policy.itemType.ui.length < 1) {
    dispatch(setGlobalError(messages.ITEM_TYPE_ERROR_MESSAGE));
    return false;
  }

  for (
    let itemTypeIndex = 0;
    itemTypeIndex < policy.itemType.ui.length;
    itemTypeIndex++
  ) {
    const itemType = policy.itemType.ui[itemTypeIndex];

    if (!itemType.value) {
      dispatch(
        updateItemType(itemTypeIndex, null, messages.ITEM_TYPE_ERROR_MESSAGE)
      );
      dispatch(setGlobalError(messages.ITEM_TYPE_ERROR_MESSAGE));

      isValid = false;
      break;
    }

    if (doesRequireMosaics(itemType.category)) {
      if (!isItemTypeAssetTypesValid(itemType)) {
        dispatch(
          setPolicyAssetTypeError(itemTypeIndex, messages.MOSAICS_EMPTY)
        );
        dispatch(setGlobalError(messages.MOSAICS_EMPTY));

        isValid = false;
        break;
      }
    } else if (
      itemType.availableAssetTypes &&
      itemType.availableAssetTypes.length
    ) {
      if (!isItemTypeAssetTypesValid(itemType)) {
        dispatch(
          setPolicyAssetTypeError(itemTypeIndex, messages.PERMISSIONS_EMPTY)
        );
        dispatch(setGlobalError(messages.PERMISSIONS_EMPTY));

        isValid = false;
        break;
      }
    }
  }

  const doesPolicyRequireAoi = policy.itemType.ui.some(itemType =>
    doesRequireAoi(itemType.category)
  );
  if (doesPolicyRequireAoi && !isPolicyAOIValid(policy, dispatch)) {
    isValid = false;
  }

  const doesPolicyRequireToi = policy.itemType.ui.some(itemType =>
    doesRequireToi(itemType.category)
  );
  if (doesPolicyRequireToi && !isPolicyTOIValid(policy, dispatch)) {
    isValid = false;
  }

  return isValid;
};

// POLICY LIST
export const enableNewPolicyName = () => ({
  type: constants.PLAN_DETAILS_POLICY_GET_NEW_NAME,
});

export const getNewPolicyName = () => {
  return (dispatch, getState) => {
    const state = getState();
    const policiesState = state.plans.instance.policies;
    const activePolicy = policiesState.items[policiesState.selectedPolicy];

    if (isPolicyValid(activePolicy, dispatch)) {
      dispatch(enableNewPolicyName());
    }
  };
};

export const setNewPolicyNameRequested = name => ({
  type: constants.PLAN_DETAILS_POLICY_SET_NEW_NAME,
  name,
});

export const setNewPolicyName =
  (name, isExistingPolicy, planId, history) => (dispatch, getState) => {
    dispatch(setNewPolicyNameRequested(name));

    // If it's a new policy, we don't want to save it yet
    if (!isExistingPolicy) return;

    // If no planId is passed, you're on the create view and the permission doesn't
    // exist on the backend yet
    if (!planId) return;

    const state = getState(),
      _common = state.plans.instance.common,
      _policies = state.plans.instance.policies;

    const params = {
      name: _common.details.name,
      policies: preparePolicies(_policies.items),
    };

    return update(planId, params)
      .then(() => {
        dispatch(fetchPlan(planId, history));
      })
      .catch(err => {
        const error =
          err.body && err.body.message ? err.body.message : err.message;

        dispatch(setGlobalError(error));
        dispatch(planSaveRequestFailed(error));
      });
  };

export const renamePolicy = () => ({
  type: constants.PLAN_DETAILS_POLICY_RENAME,
});

export const selectPolicy = index => ({
  type: constants.PLAN_DETAILS_POLICY_SELECT,
  index,
});

export const setActivePolicy = index => (dispatch, getState) => {
  const policiesState = getState().plans.instance.policies;

  if (index === policiesState.selectedPolicy) {
    return;
  }

  if (policiesState.selectedPolicy !== null) {
    const activePolicy = policiesState.items[policiesState.selectedPolicy];

    if (isPolicyValid(activePolicy, dispatch)) {
      dispatch(selectPolicy(index));
    }
  } else {
    dispatch(selectPolicy(index));
  }
};

export const removePolicyRequested = () => ({
  type: constants.PLAN_DETAILS_POLICY_REMOVE,
});

export const removePolicy = (planId, history) => (dispatch, getState) => {
  dispatch(removePolicyRequested());

  // If no planId is passed, you're on the create view and the permission doesn't
  // exist on the backend yet
  if (!planId) {
    return;
  }

  const state = getState(),
    _common = state.plans.instance.common,
    _policies = state.plans.instance.policies;

  const params = {
    name: _common.details.name,
    policies: preparePolicies(_policies.items),
  };

  return update(planId, params)
    .then(() => {
      dispatch(fetchPlan(planId, history));
    })
    .catch(err => {
      const error =
        err.body && err.body.message ? err.body.message : err.message;

      dispatch(setGlobalError(error));
      dispatch(planSaveRequestFailed(error));
    });
};

export const fetchBasemaps = (index, name) => dispatch => {
  if (!name) {
    return;
  }

  return basemapsByName(name).then(response =>
    dispatch(
      receiveAssetTypes(null, index, {
        mosaics: response.body.mosaics ? response.body.mosaics : [],
      })
    )
  );
};

export const fetchSeries = (index, name) => dispatch => {
  if (!name) {
    return;
  }

  return seriesByName(name).then(response =>
    dispatch(
      receiveAssetTypes(null, index, {
        mosaics: response.body.series ? response.body.series : [],
      })
    )
  );
};

// SAVE AND SAVE AS ONLY USED IN EDIT
export const savePlan = (planId, history) => {
  return (dispatch, getState) => {
    const state = getState(),
      _common = state.plans.instance.common,
      _policies = state.plans.instance.policies,
      activePolicy = _policies.items[_policies.selectedPolicy];

    const params = {
      name: _common.details.name,
      policies: preparePolicies(_policies.items),
    };

    dispatch(requestPlanSave(params));

    if (isPolicyValid(activePolicy, dispatch)) {
      return update(planId, params)
        .then(() => {
          dispatch(planSaveRequestCompleted());
          dispatch(fetchPlan(planId, history));
        })
        .catch(err => {
          const error =
            err.body && err.body.message ? err.body.message : err.message;

          dispatch(setGlobalError(error));
          dispatch(planSaveRequestFailed(error));
        });
    }
  };
};

export const savePlanAs = (name = null) => {
  return (dispatch, getState) => {
    const state = getState(),
      _policies = state.plans.instance.policies,
      activePolicy = _policies.items[_policies.selectedPolicy];

    const params = {
      name: name.trim(),
      policies: preparePolicies(_policies.items),
    };

    dispatch(requestPlanSave(params));

    if (isPolicyValid(activePolicy, dispatch)) {
      return create(params)
        .then((res = {}) => {
          dispatch(planSaveRequestCompleted(res.body.id));
        })
        .catch(err => {
          const error =
            err.body && err.body.message ? err.body.message : err.message;

          dispatch(setGlobalError(error));
          dispatch(planSaveRequestFailed(error));
        });
    }
  };
};
