import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import React, {Component} from 'react';
import {bool, func, object} from 'prop-types';

import AOIItem from './aoi-item';
import FormCard from 'admin-ng/components/common/form-card';

class AOIConstraint extends Component {
  static propTypes = {
    multipleAllowed: bool,
    onAddAOI: func.isRequired,
    onDeleteAOI: func.isRequired,
    onUpdateAOI: func.isRequired,
    onUploadAOIFile: func.isRequired,
    policy: object.isRequired,
  };

  _isChanged(index) {
    const aoi = this.props.policy.aoi;
    if (!aoi.data[index].value) {
      return false;
    }
    return aoi.data[index].value !== aoi.ui[index].value;
  }

  render() {
    const {
      multipleAllowed,
      policy: {aoi, itemType},
    } = this.props;
    const aois = aoi.ui;
    const itemTypes = itemType.ui;
    const canDelete = aois.length > 1;
    const hasBasemapOrSeries = itemTypes.some(
      ({category}) => category === 'basemap' || category === 'series'
    );

    return (
      <div className="pl-data-constraint-section">
        <FormCard
          iconElementRight={
            multipleAllowed && (
              <IconButton color="inherit" onClick={this.props.onAddAOI}>
                <AddIcon />
              </IconButton>
            )
          }
          title="Area of Interest"
        >
          {hasBasemapOrSeries && (
            <div>
              <h3
                className="pl-download-types-title"
                style={{marginTop: '0px'}}
              >
                AOIs for Basemap and Series Mosaics
              </h3>
              <p className="pl-form--footnote">
                Specifying an AOI for this type allows zoom level 15 access in
                Planet Explorer, GeoTIFF downloads, and the Webtile service.
              </p>
            </div>
          )}

          {aois.map((item, index) => (
            <AOIItem
              canDelete={canDelete}
              isChanged={this._isChanged(index)}
              item={item}
              // eslint-disable-next-line react/no-array-index-key
              key={`aoi-${index}`}
              onDelete={this.props.onDeleteAOI.bind(this, index)}
              onFileUpload={this.props.onUploadAOIFile.bind(this, index)}
              onUpdate={this.props.onUpdateAOI.bind(this, index)}
              textareaQaId={`policy-aoi-${index}-geojson`}
            />
          ))}
        </FormCard>
      </div>
    );
  }
}

export default AOIConstraint;
