import React from 'react';
import {node} from 'prop-types';

import AppBar from 'admin-ng/components/common/app-bar';
import Card from 'admin-ng/components/common/card';

const FormCard = ({children, ...props}) => (
  <div className="pl-form-card">
    <AppBar {...props} />

    {children && <Card>{children}</Card>}
  </div>
);

FormCard.propTypes = {children: node};

export default FormCard;
