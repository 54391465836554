import Icon from '@mui/material/Icon';
import React from 'react';
import grey from '@mui/material/colors/grey';
import {string} from 'prop-types';

const grey600 = grey['600'];

const MenuIcon = props => (
  <Icon className="material-icons" color={grey600} sx={{marginRight: 1}}>
    {props.name}
  </Icon>
);

MenuIcon.propTypes = {
  name: string.isRequired,
};

export default MenuIcon;
