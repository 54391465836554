const NAME = 'DOCUMENT-EDIT';

// Prefill dialog fields with previously fetched data
export const INITIALIZE_DETAILS = `${NAME}/INITIALIZE_DETAILS`;

// Uniqueness check for document name
export const REQUEST_NAME_CHECK = `${NAME}/REQUEST_NAME_CHECK`;
export const REQUEST_NAME_CHECK_SUCCESS = `${NAME}/REQUEST_NAME_CHECK_SUCCESS`;
export const REQUEST_NAME_CHECK_FAIL = `${NAME}/REQUEST_NAME_CHECK_FAIL`;

// Update fields with user input
export const UPDATE_NAME = `${NAME}/UPDATE_NAME`;
export const UPDATE_EXTERNAL_NAME = `${NAME}/UPDATE_EXTERNAL_NAME`;
export const UPDATE_TEXT = `${NAME}/UPDATE_TEXT`;
export const TOGGLE_CHECKBOX = `${NAME}/TOGGLE_CHECKBOX`;

// Submit PUT request to save changes to the document
export const REQUEST_SAVE = `${NAME}/REQUEST_SAVE`;
export const REQUEST_SAVE_SUCCESS = `${NAME}/REQUEST_SAVE_SUCCESS`;
export const REQUEST_SAVE_FAIL = `${NAME}/REQUEST_SAVE_FAIL`;

export const RESET_STATE = `${NAME}/RESET_STATE`;
