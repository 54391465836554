import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import Switch from '@mui/material/Switch';
import grey from '@mui/material/colors/grey';
import {bool, func, number, object, oneOfType, string} from 'prop-types';
import {isFinite} from 'lodash';
import {styled} from '@mui/material/styles';

import Checkbox from 'admin-ng/components/common/checkbox';
import FormCard from 'admin-ng/components/common/form-card';
import SelectField from 'admin-ng/components/common/select-field';
import TextField from 'admin-ng/components/common/text-field';
import {formatLargeNumber, localizeNumber} from 'admin-ng/util/formatting';
import {intervals} from './intervals';

const PlSwitch = styled(Switch)(({theme}) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: theme.palette.primary.light,
    },
    '&.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: 'white',
    },
  },
}));

export const sanitizeAmount = amount => {
  if (amount || amount === 0) {
    if (parseInt(amount, 10) < 0) {
      return 0;
    } else {
      //remove everything that is not a digit, then transform back to integer
      const value = parseInt(String(amount).replace(/[\D]+/g, ''), 10);
      return value > 0 ? value : 0;
    }
  }
  return '';
};

export default class TileQuota extends Component {
  static propTypes = {
    data: object.isRequired,
    enabled: bool.isRequired,
    infinite: bool.isRequired,
    isLimitChanged: bool.isRequired,
    isOverageChanged: bool.isRequired,
    isPlanetAdmin: bool.isRequired,
    isRefreshChanged: bool.isRequired,
    limit: oneOfType([number, string]),
    onLimitChange: func.isRequired,
    onOverageChange: func.isRequired,
    onRefreshChange: func.isRequired,
    overage: bool,
    quotaUsage: number,
    refresh: string,
    title: string.isRequired,
    toggleEnable: func.isRequired,
    toggleInfinite: func.isRequired,
  };

  constructor(props) {
    super(props);

    this._remainingQuota = this._remainingQuota.bind(this);
    this._onRefreshChange = this._onRefreshChange.bind(this);
    this._onOverageChange = this._onOverageChange.bind(this);
    this._getInterval = this._getInterval.bind(this);
  }

  _remainingQuota() {
    if (isFinite(this.props.data.amount) && isFinite(this.props.quotaUsage)) {
      const remaining = this.props.data.amount - this.props.quotaUsage;
      return `${formatLargeNumber(remaining)} remaining`;
    }
    return '';
  }

  /**
   * We have an issue where the backend returns and accepts null for a
   * 'accessPeriod' quota, but null cannot be used as a key in the material-ui
   * dropdown. Therefore we map this value to a temporary string, and map it
   * back when the value is sent to the backend.
   */
  _transformInterval(interval) {
    if (interval === 'accessPeriod') {
      return null;
    }
    return interval;
  }

  _getInterval() {
    return this.props.refresh || 'accessPeriod';
  }

  _onRefreshChange(event) {
    const {value} = event.target;
    this.props.onRefreshChange(this._transformInterval(value));
  }

  _onOverageChange(event) {
    const {value} = event.target;
    const v = value === 'true';
    if (this.props.overage !== v) {
      this.props.onOverageChange(v);
    }
  }

  _getLocalizedAmount = amount => {
    if (amount) {
      return localizeNumber(amount);
    } else {
      if (amount === 0) {
        return '0';
      } else {
        return '';
      }
    }
  };

  render() {
    const {
      enabled,
      limit,
      infinite,
      isLimitChanged,
      isPlanetAdmin,
      isRefreshChanged,
      isOverageChanged,
      onLimitChange,
      overage,
      title,
      toggleEnable,
      toggleInfinite,
    } = this.props;

    const overageString = overage.toString();

    return (
      <FormCard
        iconElementRight={
          <FormControlLabel
            control={
              <PlSwitch
                checked={enabled}
                color="primary"
                onChange={toggleEnable.bind(this)}
                data-qa-id="quota-toggle"
              />
            }
            label={enabled ? 'Enabled' : 'Disabled'}
            labelPlacement="start"
          />
        }
        style={enabled ? {} : {backgroundColor: grey['400']}}
        title={title}
      >
        {enabled && (
          <div className="pl-subscription-grid">
            <div className="pl-subscription-grid-cell pl-quota-amount-wrapper">
              <TextField
                fullWidth
                helperText={this._remainingQuota()}
                isChanged={isLimitChanged}
                label="Amount (in # tiles)"
                onChange={newValue => onLimitChange(sanitizeAmount(newValue))}
                qaId="quota-amount"
                value={infinite ? 'Unlimited' : this._getLocalizedAmount(limit)}
                variant="standard"
              />

              {isPlanetAdmin && (
                <Checkbox
                  checked={infinite}
                  label="Unlimited Quota"
                  onChange={toggleInfinite}
                  qaId="unlimited-quota-checkbox"
                />
              )}
            </div>

            <div className="pl-subscription-grid-cell">
              <SelectField
                data-qa-id="quota-interval"
                isChanged={isRefreshChanged}
                label="Interval"
                onChange={this._onRefreshChange}
                style={{width: '100%'}}
                value={this._getInterval()}
              >
                {intervals.map((item, key) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <MenuItem key={key} value={item.value}>
                    {item.primaryText}
                  </MenuItem>
                ))}
              </SelectField>
            </div>

            <div className="pl-subscription-grid-cell">
              <SelectField
                qaId="quota-overage"
                disabled={infinite}
                isChanged={isOverageChanged}
                label="Allow Overage"
                onChange={this._onOverageChange}
                style={{width: '100%'}}
                value={overageString}
              >
                <MenuItem value="true">Enabled</MenuItem>
                <MenuItem value="false">Disabled</MenuItem>
              </SelectField>
            </div>
          </div>
        )}
      </FormCard>
    );
  }
}
