import moment from 'moment';

import * as constants from './constants';
import * as messages from './messages';
import * as quotaActions from 'admin-ng/components/common/subscription-details/tile-quota/actions';
import {ROLES} from 'admin-ng/constants';
import {authClient} from 'admin-ng/components/AuthStore';
import {orgsSelectNotCompleted} from '../plan-subscription-bulk-create-select-orgs/actions';
import {reset as resetQuota} from 'admin-ng/components/common/subscription-details/quota/actions';
import {
  updateEndDate,
  updateReference,
  updateStartDate,
} from '../plan-subscription-bulk-create-select-details/actions';

export const gotoNextStep = () => ({type: constants.GOTO_NEXT_STEP});

export const gotoPreviousStep = () => ({type: constants.GOTO_PREVIOUS_STEP});

export const openNextStep = () => (dispatch, getState) => {
  const state = getState(),
    bulkCreateState = state.plans.instance.subscriptions.bulkCreate,
    assignGeneralState = bulkCreateState.general,
    detailsState = bulkCreateState.details,
    selectOrgsState = bulkCreateState.selectOrgs,
    isPlanetAdmin =
      authClient.getState().user.claims.role_level >= ROLES.planetAdmin;

  switch (assignGeneralState.step) {
    case 0: {
      const {reference, referenceValid} = detailsState;
      let {startDate, endDate} = detailsState;
      let diff;

      if (isPlanetAdmin) {
        if (!reference) {
          return dispatch(
            updateReference(reference, messages.OPPORTUNITY_ID_IS_MISSING)
          );
        }

        if (!referenceValid) {
          return dispatch(
            updateReference(reference, messages.OPPORTUNITY_ID_INVALID)
          );
        }
      }

      if (!startDate) {
        return dispatch(
          updateStartDate(
            startDate,
            detailsState.isActiveFromToday,
            messages.START_DATE_NOT_SPECIFIED
          )
        );
      }

      if (!endDate && !detailsState.hasNoEndDate) {
        return dispatch(
          updateEndDate(
            endDate,
            detailsState.hasNoEndDate,
            messages.END_DATE_NOT_SPECIFIED
          )
        );
      }

      if (startDate && endDate) {
        startDate = moment(startDate);
        endDate = moment(endDate);
        diff = endDate.diff(startDate, 'days');

        if (diff <= 0) {
          return dispatch(
            updateEndDate(
              endDate,
              detailsState.hasNoEndDate,
              messages.TOI_RANGE_VALIDATION_ERROR_MESSAGE
            )
          );
        }
      }

      return dispatch(gotoNextStep());
    }

    case 1:
      if (!selectOrgsState.selectedOrgs.length) {
        return dispatch(orgsSelectNotCompleted());
      }

      return dispatch(gotoNextStep());

    case 2:
      return dispatch(gotoNextStep());

    default:
      return;
  }
};

export const openPreviousStep = () => dispatch => dispatch(gotoPreviousStep());

export const resetView = () => dispatch => {
  dispatch({type: constants.RESET_VIEW});
  dispatch(resetQuota());
  dispatch(quotaActions.basemapTileQuotaActions.reset());
  dispatch(quotaActions.sceneTileQuotaActions.reset());
};
