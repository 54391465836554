import Button from '@mui/material/Button';
import React, {Component} from 'react';
import {arrayOf, bool, func, object, string} from 'prop-types';

import Notification from 'admin-ng/components/common/notification';
import Table from 'admin-ng/components/common/table';

export default class PlanSubscriptionBulkCreateSummaryView extends Component {
  componentWillMount() {
    this.props.submitBulkCreate();
  }

  _getOrgDataSchema() {
    return [
      {
        field: 'id',
        label: 'ID',
        width: '10%',
        isSortable: false,
      },
      {
        field: 'name',
        label: 'Name',
        width: '90%',
        isSortable: false,
      },
    ];
  }

  render() {
    return (
      <div>
        {this.props.isBulkCreateSuccess ? (
          <div>
            <Notification
              message="The following organization(s) have been subscribed to the current plan:"
              title="Success"
              type="success"
            />

            <div className="pl-data-table-selectable">
              <Table
                className="pl-table-index"
                dataSchema={this._getOrgDataSchema()}
                qaId="plan-subscriptions-bulk-create-summary-table"
                srcData={this.props.selectedOrgs || []}
              />
            </div>
          </div>
        ) : (
          <div>
            {this.props.error ? (
              <div>
                <Notification
                  message={this.props.error}
                  title="Oops, plans request failed"
                  type="error"
                />
                <div className="pl-wizard-resubmit-btn">
                  <Button color="primary" onClick={this.props.submitBulkCreate}>
                    Try again
                  </Button>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

PlanSubscriptionBulkCreateSummaryView.propTypes = {
  error: string,
  isBulkCreateSuccess: bool,
  selectedOrgs: arrayOf(object),
  submitBulkCreate: func,
};
