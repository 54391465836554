export const DEFAULT_ERROR_MSG = 'Something went wrong';
export const PLAN_ID_IS_MISSING_ERROR_MSG = 'Plan id is missing in the request';

export const START_DATE_NOT_SPECIFIED = 'Please specify the start date';
export const END_DATE_NOT_SPECIFIED = 'Please specify the end date';
export const TOI_RANGE_VALIDATION_ERROR_MESSAGE =
  "End date can't be earlier than start date";

export const QUOTA_INTERVAL_NOT_SPECIFIED = 'Please select an interval';
export const OPPORTUNITY_ID_IS_MISSING = 'Please provide an opportunity ID';
export const OPPORTUNITY_ID_INVALID =
  'Invalid Opportunity ID. Please provide valid PL-Numbers or Theme.';
