import assign from 'deep-extend';
import {connect} from 'react-redux';

import SuborgMove from './suborg-move';
import {
  fetchExternalSuborgs,
  moveSuborg,
  resetState,
  selectSuborg,
  updateName,
} from './actions';

const mapStateToProps = (state, props) =>
  assign({}, state.orgs.instance.suborgs.move, {
    parentId: props.parentId,
    isOpen: props.isOpen,
  });

const mapDispatchToProps = (dispatch, props) => ({
  onSuborgNameChange: name => dispatch(updateName(name)),
  onExternalSuborgsRequest: suborgName =>
    dispatch(fetchExternalSuborgs(props.parentId, suborgName)),
  onSelectSuborg: selectedSuborg => dispatch(selectSuborg(selectedSuborg)),
  onSuborgMoveRequest: () => dispatch(moveSuborg(props.parentId)),
  onClose: () => {
    props.onClose();
    return dispatch(resetState());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuborgMove);
