import Button from '@mui/material/Button';
import React, {Component} from 'react';
import Stack from '@mui/material/Stack';
import {Link} from 'react-router-dom';
import {func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import Notification from 'admin-ng/components/common/notification';
import ORGANIZATION_FIELDS from './organization-fields';
import RecordStatus from '../record-status';
import USER_FIELDS from './user-fields';
import {FLAG_CONFIRMED_BAD, FLAG_FALSE_POSITIVE} from '../constants';

const RECORD_FIELDS = [
  {
    label: 'All Names',
    key: 'all_names',
    isLink: false,
  },
  {
    label: 'Error',
    key: 'error',
    isLink: false,
  },
  {
    label: 'Entity Number',
    key: 'entity_number',
    isLink: false,
  },
  {
    label: 'Programs',
    key: 'programs',
    isLink: false,
  },
  {
    label: 'Places of birth',
    key: 'places_of_birth',
    isLink: false,
  },
  {
    label: 'Dates of birth',
    key: 'dates_of_birth',
    isLink: false,
  },
  {
    label: 'Source',
    key: 'source',
    isLink: false,
  },
  {
    label: 'Type',
    key: 'type',
    isLink: false,
  },
  {
    label: 'Source Information URL',
    key: 'source_information_url',
    isLink: true,
  },
  {
    label: 'Source List URL',
    key: 'source_list_url',
    isLink: true,
  },
];
const STATUSES = {
  flagged: 'warning',
  confirmed: 'danger',
  false_positive: 'success',
};
const LABELS = {
  flagged: 'Flagged',
  confirmed: 'Confirmed',
  false_positive: 'False Positive',
};

class ComplianceRecord extends Component {
  _isMounted = false;

  constructor() {
    super();

    this.state = {
      confirmAs: null,
    };

    this._flag = this._flag.bind(this);
    this._closeConfirmation = this._closeConfirmation.bind(this);
    this._flagConfirmedBad = this._flagConfirmedBad.bind(this);
    this._flagFalsePositive = this._flagFalsePositive.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _flagConfirmedBad() {
    this.setState({
      confirmAs: FLAG_CONFIRMED_BAD,
    });
  }

  _flagFalsePositive() {
    this.setState({
      confirmAs: FLAG_FALSE_POSITIVE,
    });
  }

  _flag() {
    const {id} = this.props;

    const action =
      this.state.confirmAs === FLAG_CONFIRMED_BAD
        ? this.props.flagConfirmedBad
        : this.props.flagFalsePositive;

    action(id).then(() => {
      if (this._isMounted) {
        this.setState({
          confirmAs: null,
        });
      }
    });
  }

  _closeConfirmation() {
    if (this._isMounted) {
      this.setState({
        confirmAs: null,
      });
    }
  }

  _renderConfirmation() {
    const {confirmAs} = this.state;
    if (!confirmAs) {
      return;
    }
    const {name} = this.props.record;
    const messagesMap = {
      [FLAG_CONFIRMED_BAD]: `Do you want to deny ${name} access?`,
      [FLAG_FALSE_POSITIVE]: `Do you want to mark the record for ${name} as a false positive?`,
    };
    return (
      <ConfirmationMessage
        onClose={this._closeConfirmation}
        onSubmit={this._flag}
        open={this.state.confirmAs !== null}
        title="Please confirm"
      >
        {messagesMap[confirmAs]}
      </ConfirmationMessage>
    );
  }

  _renderError() {
    return (
      <Notification
        message={this.props.error}
        title="Compliance request has failed"
        type="error"
      />
    );
  }

  _renderFields() {
    const fields =
      this.props.entity === 'user' ? USER_FIELDS : ORGANIZATION_FIELDS;
    const data = this.props.fields;

    return (
      <div className="pl-compliance-record-record">
        <div className="pl-details-list clearfix pl-compliance-record-fields">
          {fields.map((field, index) => {
            const label = field.label;
            let value = data[field.key];
            if (value && field.key === 'email') {
              value = <a href={`mailto://${value}`}>{value}</a>;
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="pl-compliance-record-field" key={index}>
                <div className="item-fullwidth">
                  <div className="pl-details-list--title" title={label}>
                    {label}
                  </div>
                  <div
                    className="pl-details-list--value"
                    title={data[field.key]}
                  >
                    {value || '—'}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _renderComplianceFields() {
    return (
      <div className="pl-compliance-record-record">
        <h3 className="pl-compliance-record-title">Compliance Record</h3>
        <div className="pl-details-list clearfix pl-compliance-record-fields">
          {RECORD_FIELDS.map((field, index) => {
            const label = field.label;
            const rawValue = this.props.record[field.key];
            let title = '';
            let value = '—';
            if (rawValue) {
              if (Array.isArray(rawValue)) {
                title = rawValue.join(', ');
                value = title;
              } else {
                title = rawValue;
                value = field.isLink ? (
                  <a href={rawValue}>{rawValue}</a>
                ) : (
                  rawValue
                );
              }
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div className="pl-compliance-record-field" key={index}>
                <div className="item-fullwidth">
                  <div className="pl-details-list--title" title={label}>
                    {label}
                  </div>
                  <div className="pl-details-list--value" title={title}>
                    {value}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  _renderActions() {
    const isConfirmedBad = this.props.status === FLAG_CONFIRMED_BAD;
    const isFalsePositive = this.props.status === FLAG_FALSE_POSITIVE;

    return (
      <Stack className="pl-compliance-record-actions" direction="row" gap={2}>
        <Button
          className="pl-compliance-record-action"
          disabled={isConfirmedBad}
          onClick={!isConfirmedBad ? this._flagConfirmedBad : null}
          color="primary"
          variant="contained"
        >
          Deny Access
        </Button>

        <Button
          className="pl-compliance-record-action"
          disabled={isFalsePositive}
          onClick={!isFalsePositive ? this._flagFalsePositive : null}
          color="primary"
          variant="contained"
        >
          Mark as False Positive
        </Button>

        {this._renderConfirmation()}
      </Stack>
    );
  }

  render() {
    let name = this.props.fields.name;

    if (this.props.path) {
      name = <Link to={this.props.path}>{name}</Link>;
    }
    const status = STATUSES[this.props.status];
    const label = LABELS[this.props.status];
    return (
      <div>
        <Card className="pl-compliance-record" style={{cardText: {padding: 0}}}>
          {this._renderError()}
          <h2 className="pl-compliance-record--name">
            {name} <RecordStatus label={label} status={status} />
          </h2>
          <div className="pl-compliance-record-records">
            {this._renderFields()}
            {this._renderComplianceFields()}
          </div>
          {this._renderActions()}
        </Card>
      </div>
    );
  }
}

ComplianceRecord.propTypes = {
  entity: string.isRequired,
  error: string,
  fields: object.isRequired,
  flagConfirmedBad: func.isRequired,
  flagFalsePositive: func.isRequired,
  id: number.isRequired,
  path: string,
  record: object.isRequired,
  status: string.isRequired,
};

export default ComplianceRecord;
