import React from 'react';

import Index from '../orgs/orgs-index/orgs-index-container';

const Orgs = () => {
  return (
    <div className="pl-orgs-page">
      <Index />
    </div>
  );
};

export default Orgs;
