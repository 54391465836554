import {connect} from 'react-redux';

import * as actions from './actions';
import ProgramCreate from './program-create';

const mapStateToProps = state => state.programs.create;
const mapDispatchToProps = (dispatch, props) => ({
  onClose: () => {
    props.onClose();
    return dispatch(actions.resetState());
  },
  onDescriptionChange: description =>
    dispatch(actions.updateDescription(description)),
  onInvitationTextChange: invitationText =>
    dispatch(actions.updateInvitationText(invitationText)),
  onNameChange: name => dispatch(actions.updateName(name)),
  onNameCheck: name => dispatch(actions.checkName(name)),
  onSubmit: () => dispatch(actions.createNewProgram()),
  requestDocuments: name => dispatch(actions.searchDocuments(name)),
  updateDocuments: documents => dispatch(actions.updateDocuments(documents)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramCreate);
