import * as constants from './constants';
import * as inviteApi from 'admin-ng/api/invitations';
import * as messages from './messages';
import {getMembers} from 'admin-ng/api/organizations';

export const requestMembers = (params = {}) => ({
  type: constants.ORG_MEMBERS_REQUEST,
  params,
});

export const receiveMembers = (json = {}) => ({
  type: constants.ORG_MEMBERS_SUCCESS,
  items: json.results || [], // a set of orgs, limited by paging parameters
  itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0, // the total number of all orgs in the db
});

export const requestMembersFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.ORG_MEMBERS_FAIL,
  error,
});

export const requestMemberInvitationDelete = userId => ({
  type: constants.ORG_MEMBERS_INVITATION_DELETE_REQUEST,
  userId,
});

export const memberInvitationDeleteCompleted = () => ({
  type: constants.ORG_MEMBERS_INVITATION_SUCCESS,
});

export const memberInvitationDeleteFail = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.ORG_MEMBERS_INVITATION_FAIL,
  error,
});

export const fetchUsers = (id, params = {}) => {
  return function (dispatch, getState) {
    const state = getState(),
      orgUsersState = state.orgs.instance.users.index;

    dispatch(requestMembers(params));

    return getMembers(id, getRequestParams(orgUsersState, params))
      .then((res = {}) => {
        dispatch(receiveMembers(res.body));
      })
      .catch((err = {}) => {
        dispatch(requestMembersFail(err.message));
      });
  };
};

export const deleteInvitation = (orgId, userId) => dispatch => {
  dispatch(requestMemberInvitationDelete(userId));

  return inviteApi
    .del(userId)
    .then(() => {
      dispatch(memberInvitationDeleteCompleted());
      dispatch(fetchUsers(orgId));
    })
    .catch((err = {}) => {
      dispatch(memberInvitationDeleteFail(err.message));
    });
};

function getRequestParams(state, newParams) {
  const requestParams = Object.assign({}, state.viewParams, newParams);

  // if search is epmty, no need to send the parameter, otherwise it will activate
  // a redundant process on the back-end
  if (requestParams.hasOwnProperty('search') && !requestParams.search) {
    delete requestParams.search;
  }

  if (requestParams.filters) {
    requestParams.state__in = requestParams.filters;
  }
  delete requestParams.filters;

  return requestParams;
}

export const resetViewState = () => ({
  type: constants.ORG_MEMBERS_RESET_VIEW_STATE,
});

export const resetView = organizationId => dispatch => {
  dispatch(resetViewState());
  return dispatch(fetchUsers(organizationId));
};
