import * as constants from './constants';
import * as messages from '../plan-assign-general/messages';
import {all} from 'admin-ng/api/plans';

export const updateSourcePlan = (id, name, error = null) => {
  return {
    type: constants.UPDATE_SOURCE_PLAN,
    id,
    name,
    error,
  };
};

export const updateDestinationPlan = (id, name, error = null) => {
  return {
    type: constants.UPDATE_DESTINATION_PLAN,
    id,
    name,
    error,
  };
};

export const selectPlan = (type, id, name) => {
  return dispatch => {
    switch (type) {
      case 'source':
        return dispatch(updateSourcePlan(id, name));

      case 'destination':
        return dispatch(updateDestinationPlan(id, name));

      default:
        break;
    }
  };
};

export const receiveDestinationPlans = (json = {}) => {
  return {
    type: constants.PLANS_ASSIGN_RECEIVE_DESTINATION_PLANS,
    items: json.results || [],
    itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
  };
};

export const receiveSourcePlans = (json = {}) => {
  return {
    type: constants.PLANS_ASSIGN_RECEIVE_SOURCE_PLANS,
    items: json.results || [],
    itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
  };
};

export const requestPlansFailed = (error = messages.DEFAULT_ERROR_MSG) => {
  return {
    type: constants.PLANS_ASSIGN_PLANS_REQUEST_FAILED,
    error,
  };
};

export const fetchPlans = (type, planQuery) => {
  return function (dispatch) {
    const params = {
      limit: 999,
      sort: 'name',
    };

    if (planQuery) {
      params.search = planQuery;
    }

    return all(params)
      .then((res = {}) => {
        switch (type) {
          case 'destination':
            dispatch(receiveDestinationPlans(res.body));
            break;

          case 'source':
            dispatch(receiveSourcePlans(res.body));
            break;

          default:
            break;
        }
      })
      .catch((err = {}) => {
        dispatch(requestPlansFailed(err.message));
      });
  };
};
