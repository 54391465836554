import React from 'react';

import PlansIndex from './plans-index/plans-index-container';

const Plans = () => (
  <div className="pl-plans-page">
    <PlansIndex />
  </div>
);

export default Plans;
