import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {arrayOf, bool, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import DataState from 'admin-ng/components/common/data-state';
import IconButton from 'admin-ng/components/common/icon-button';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import SceneQuotaCell from 'admin-ng/components/common/subscription-details/quota-table-cell/scene';
import Sidebar from 'admin-ng/components/common/sidebar';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import TileQuotaCell from 'admin-ng/components/common/subscription-details/quota-table-cell/tile';

export default class Subscriptions extends Component {
  static propTypes = {
    error: string,
    errorQuota: string,
    filters: string.isRequired,
    history: object.isRequired,
    isFetching: bool.isRequired,
    limit: number.isRequired,
    offset: number.isRequired,
    onChange: func.isRequired,
    onDelete: func.isRequired,
    onMount: func.isRequired,
    quotaUsages: object,
    sort: string.isRequired,
    subscriptions: arrayOf(object),
    total: number.isRequired,
  };

  static defaultProps = {
    error: null,
    errorQuota: null,
  };

  state = {
    toDelete: null,
  };

  componentDidMount() {
    this.props.onMount();
  }

  _handleEdit = ({id, organization: {id: organizationId}}) =>
    this.props.history.push(
      `/organizations/${organizationId}/plans/${id}/edit`
    );

  _handleDelete = ({id, state}) => {
    if (state !== 'deleted') {
      this.setState({toDelete: id});
    }
  };

  _handleDeleteClose = () => {
    this.setState({toDelete: null});
  };

  _handleDeleteConfirmation = () => {
    this.props.onDelete(this.state.toDelete);
  };

  get schema() {
    const schema = [
      {
        field: 'id',
        label: 'Plan',
        width: '5%',
        contentCreator: ({id, organization: {id: organizationId}}) => (
          <Link
            data-qa-id="subscriptions-view"
            to={`/organizations/${organizationId}/plans/${id}`}
          >
            {id}
          </Link>
        ),
      },
      {
        field: 'planName',
        sortKey: 'plan.name',
        label: 'Package',
        width: '10%',
        contentCreator: ({plan: {id, name}}) => (
          <Link data-qa-id="subscriptions-view-plan" to={`/packages/${id}`}>
            {name}
          </Link>
        ),
      },
      {
        field: 'organizationName',
        sortKey: 'organization.name',
        label: 'Organization',
        width: '10%',
        contentCreator: ({organization: {id, name}}) => (
          <Link
            data-qa-id="subscriptions-view-organization"
            to={`/organizations/${id}`}
          >
            {name}
          </Link>
        ),
      },
      {
        field: 'state',
        label: 'State',
        width: '4%',
        contentCreator: ({state}) =>
          state ? (
            <DataState
              label={state === 'inactive_plan' ? 'Deleted Package' : null}
              state={state}
            />
          ) : (
            '-'
          ),
      },
      {
        field: 'active_from',
        label: 'Access',
        width: '5%',
        contentCreator: ({active_from: from, active_to: to}) => (
          <div>
            <div>{from ? from.slice(0, from.indexOf('T')) : '∞'}</div>
            <div>{to ? to.slice(0, to.indexOf('T')) : '∞'}</div>
          </div>
        ),
      },
      {
        field: 'scene_quota',
        label: 'Scene Quota',
        width: '5%',
        contentCreator: sub => (
          <SceneQuotaCell sub={sub} usages={this.props.quotaUsages} />
        ),
      },
      {
        field: 'basemap_tile_quota',
        label: 'Basemap Tiles',
        width: '5%',
        contentCreator: sub => (
          <TileQuotaCell tileQuota={sub.basemap_tile_quota} />
        ),
      },
      {
        field: 'scene_tile_quota',
        label: 'Scene Tiles',
        width: '5%',
        contentCreator: sub => (
          <TileQuotaCell tileQuota={sub.scene_tile_quota} />
        ),
      },
      {
        field: 'reference',
        label: 'Opportunity ID',
        isSortable: false,
        width: '5%',
        contentCreator: ({reference}) => reference || '-',
      },
      {
        label: '',
        width: '5%',
        contentCreator: subscription => (
          <div>
            <IconButton
              icon="edit"
              isDisabled={subscription.state === 'deleted'}
              onClick={this._handleEdit.bind(this, subscription)}
              qaId="plan-subscription-edit"
            />
            <IconButton
              icon="delete"
              isDisabled={subscription.state === 'deleted'}
              onClick={this._handleDelete.bind(this, subscription)}
              qaId="org-subscription-delete"
            />
          </div>
        ),
      },
    ];
    return schema.map(({label, ...data}) => ({label, ...data}));
  }

  render() {
    const {
      error,
      errorQuota,
      filters: activeFilters,
      isFetching,
      limit,
      offset,
      onChange,
      sort,
      subscriptions,
      total,
    } = this.props;
    const {toDelete} = this.state;
    const filters = [
      'active',
      'expired',
      'inactive',
      'inactive_plan',
      'deleted',
    ];
    const labels = [
      'Active',
      'Expired',
      'Inactive',
      'Deleted Package',
      'Deleted',
    ];
    return (
      <div className="pl-flex-wrapper">
        <Sidebar />
        <div className="pl-app--content">
          <p style={{fontSize: 13}}>
            Note: <Link to="/plans">/plans</Link> now lists Plans. See{' '}
            <Link to="/packages">/packages</Link> for Packages
          </p>
          <Card>
            <div className="pl-table-card-content">
              <TableControlsPanel
                activeFilters={activeFilters}
                filters={filters}
                itemsPerPageLimit={limit}
                labels={labels}
                onFilter={onChange}
              />
              <Notification
                message={error}
                title="Unable to fetch plans"
                type="error"
              />
              <Notification
                message={errorQuota}
                title="Unable to fetch quota usages"
                type="error"
              />
              <Table
                className="pl-table-index"
                dataSchema={this.schema}
                isRequestPending={isFetching}
                onSort={onChange}
                qaId="subscriptions-table"
                sortBy={sort}
                srcData={subscriptions}
              />
              <Pagination
                itemsLimit={limit}
                itemsOffset={offset}
                itemsTotal={total}
                onPageChange={onChange}
              />
              <ConfirmationMessage
                onClose={this._handleDeleteClose}
                onSubmit={this._handleDeleteConfirmation}
                open={!!toDelete}
                title="Delete plan"
              >
                {`Are you sure you want to delete the plan ${toDelete}?`}
              </ConfirmationMessage>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}
