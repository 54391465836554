import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, {Component} from 'react';
import Stack from '@mui/material/Stack';
import {bool, func, string} from 'prop-types';

import Checkbox from 'admin-ng/components/common/checkbox';
import Notification from 'admin-ng/components/common/notification';
import TextField from 'admin-ng/components/common/text-field';
import Textarea from 'admin-ng/components/common/textarea';

export default class DocumentCreate extends Component {
  static propTypes = {
    documentCreateError: string,
    externalName: string.isRequired,
    isCheckingName: bool.isRequired,
    isCreateRequestPending: bool.isRequired,
    isNameUnique: bool,
    isNewDocumentCreated: bool,
    isOpen: bool.isRequired,
    name: string.isRequired,
    nameError: string,
    onCheckboxChange: func.isRequired,
    onClose: func.isRequired,
    onExternalNameChange: func.isRequired,
    onNameChange: func.isRequired,
    onNameCheck: func.isRequired,
    onSubmit: func.isRequired,
    onTextChange: func.isRequired,
    requiredForSignup: bool.isRequired,
    separate: bool.isRequired,
    text: string.isRequired,
  };

  constructor(props) {
    super(props);

    this._isSubmitAllowed = this._isSubmitAllowed.bind(this);
    this._renderCreateDialog = this._renderCreateDialog.bind(this);
    this._renderSuccessDialog = this._renderSuccessDialog.bind(this);
  }

  // Only allow submission if internal & external name are valid and the request isn't already in progress.
  _isSubmitAllowed() {
    const {
      isCheckingName,
      isNameUnique,
      name,
      externalName,
      isCreateRequestPending,
    } = this.props;
    const isNameValid = !isCheckingName && isNameUnique && name.length;
    const isExternalNameValid = externalName.length;
    return isNameValid && isExternalNameValid && !isCreateRequestPending;
  }

  _renderCreateDialog() {
    const {
      documentCreateError,
      externalName,
      isNameUnique,
      isOpen,
      name,
      nameError,
      onCheckboxChange,
      onClose,
      onExternalNameChange,
      onNameChange,
      onNameCheck,
      onSubmit,
      onTextChange,
      requiredForSignup,
      separate,
      text,
    } = this.props;

    const allowed = this._isSubmitAllowed();
    const nameErrorText =
      isNameUnique === false
        ? 'A document with this name already exists.'
        : nameError;

    return (
      <Dialog onClose={onClose} open={isOpen}>
        <DialogTitle>Create a new document</DialogTitle>

        <DialogContent dividers>
          <Notification
            message={documentCreateError}
            title="Oops, could not create a document"
            type="error"
          />

          <TextField
            error={!!nameErrorText}
            helperText={nameErrorText}
            label="Internal name *"
            isInputValid={isNameUnique}
            onChange={onNameChange}
            onChangeCompleted={onNameCheck}
            qaId="internal-name"
            value={name}
          />

          <TextField
            label="External name *"
            onChange={onExternalNameChange}
            qaId="external-name"
            value={externalName}
          />

          <Textarea
            label="Document text"
            maxRows={4}
            minRows={4}
            onChange={onTextChange}
            qaId="text"
            value={text}
          />

          <Stack direction="row" gap={2} marginTop={4}>
            <Checkbox
              checked={requiredForSignup}
              fieldInstructions="This has to be signed in order to sign up"
              label="Required for signup"
              name="requiredForSignup"
              onChange={onCheckboxChange}
              qaId="checkbox-required"
            />

            <Checkbox
              checked={separate}
              fieldInstructions="This has to be shown in a separate step during sign up"
              label="Separate"
              name="separate"
              onChange={onCheckboxChange}
              qaId="checkbox-separate"
              wrapperStyle={{marginLeft: 20}}
            />
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={onClose}>
            Cancel
          </Button>

          <Button
            disabled={!allowed}
            focusRipple
            onClick={allowed ? onSubmit : null}
            variant="contained"
          >
            Create document
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  _renderSuccessDialog() {
    return (
      <Dialog open={this.props.isOpen}>
        <DialogTitle>Create a new document</DialogTitle>

        <DialogContent>
          <DialogContentText>
            A new document has been created.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={this.props.onClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return this.props.isNewDocumentCreated
      ? this._renderSuccessDialog()
      : this._renderCreateDialog();
  }
}
