export default [
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'first_name',
    label: 'First Name',
  },
  {
    key: 'last_name',
    label: 'Last Name',
  },
  {
    key: 'country',
    label: 'Country',
  },
];
