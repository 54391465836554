export const REQUEST_USER_DETAILS = 'REQUEST_USER_DETAILS';
export const RECEIVE_USER_DETAILS = 'RECEIVE_USER_DETAILS';
export const USER_DETAILS_REQUEST_FAILED = 'USER_DETAILS_REQUEST_FAILED';

export const REQUEST_USER_EMAIL_CHECK = 'REQUEST_USER_EMAIL_CHECK';
export const USER_EMAIL_CHECK_REQUEST_COMPLETED =
  'USER_EMAIL_CHECK_REQUEST_COMPLETED';

export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';
export const UPDATE_USER_FIRST_NAME = 'UPDATE_USER_FIRST_NAME';
export const UPDATE_USER_LAST_NAME = 'UPDATE_USER_LAST_NAME';
export const UPDATE_USER_COUNTRY = 'UPDATE_USER_COUNTRY';
export const UPDATE_MEMBER_ROLE = 'UPDATE_MEMBER_ROLE';
export const UPDATE_MEMBER_STATE = 'UPDATE_MEMBER_STATE';

export const UPDATE_MEMBER_PASSWORD = 'UPDATE_MEMBER_PASSWORD';
export const UPDATE_MEMBER_PASSWORD_REPEAT = 'UPDATE_MEMBER_PASSWORD_REPEAT';

export const CANCEL_USER_DETAILS_UPDATES = 'CANCEL_USER_DETAILS_UPDATES';
export const RESET_STATE = 'RESET_STATE';

export const REQUEST_API_KEY = 'REQUEST_API_KEY';
export const RECEIVE_API_KEY = 'RECEIVED_API_KEY';
export const REQUEST_API_KEY_RESET = 'REQUEST_API_KEY_RESET';
export const API_KEY_RESET_COMPLETED = 'API_KEY_RESET_COMPLETED';
export const API_KEY_RESET_FAILED = 'API_KEY_RESET_FAILED';

export const REQUEST_USER_DETAILS_UPDATE = 'REQUEST_USER_DETAILS_UPDATE';
export const USER_DETAILS_UPDATE_REQUEST_COMPLETED =
  'USER_DETAILS_UPDATE_REQUEST_COMPLETED';
export const USER_DETAILS_UPDATE_REQUEST_FAILED =
  'USER_DETAILS_UPDATE_REQUEST_FAILED';

export const USER_DETAILS_REQUEST_ORGANIZATIONS =
  'USER_DETAILS_REQUEST_ORGANIZATIONS';
export const USER_DETAILS_RECEIVE_ORGANIZATIONS =
  'USER_DETAILS_RECEIVE_ORGANIZATIONS';
export const USER_DETAILS_ORGANIZATIONS_REQUEST_FAIL =
  'USER_DETAILS_ORGANIZATIONS_REQUEST_FAIL';

export const USER_DETAILS_ORG_CHANGE_REQUESTED =
  'USER_DETAILS_ORG_CHANGE_REQUESTED';
export const USER_DETAILS_ORG_CHANGE_SUCCESS =
  'USER_DETAILS_ORG_CHANGE_SUCCESS';
export const USER_DETAILS_ORG_CHANGE_FAIL = 'USER_DETAILS_ORG_CHANGE_FAIL';

export const CHECK_PASSWORD_VALIDITY = 'CHECK_PASSWORD_VALIDITY';
export const CHECK_PASSWORD_MATCHING = 'CHECK_PASSWORD_MATCHING';
export const PASSWORD_SAVE_REQUEST = 'PASSWORD_SAVE_REQUEST';
export const PASSWORD_SAVE_REQUEST_FAILED = 'PASSWORD_SAVE_REQUEST_FAILED';
export const PASSWORD_SAVE_REQUEST_SUCCESS = 'PASSWORD_SAVE_REQUEST_SUCCESS';

export const RESET_PASSWORD = 'RESET_PASSWORD';
