import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {arrayOf, func, number, object, string} from 'prop-types';

import Card from 'admin-ng/components/common/card';
import ConfirmationMessage from 'admin-ng/components/common/confirmation-message';
import DataState from 'admin-ng/components/common/data-state';
import IconButton from 'admin-ng/components/common/icon-button';
import Notification from 'admin-ng/components/common/notification';
import Pagination from 'admin-ng/components/common/pagination';
import SuborgCreate from '../suborg-create/suborg-create-container';
import SuborgMove from '../suborg-move/suborg-move-container';
import Table from 'admin-ng/components/common/table';
import TableControlsPanel from 'admin-ng/components/common/table-controls-panel';
import {ROLES} from 'admin-ng/constants';

export default class SuborgsIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCreateOpen: false,
      isMoveOpen: false,
      isAddSuborgPopoverOpen: false,
      showUnlinkConfirmation: false,
      suborgToBeUnlinked: null,
      addSuborgPopoverAnchorEl: null,
    };

    this._openCreateSuborg = this._openCreateSuborg.bind(this);
    this._openMoveSuborg = this._openMoveSuborg.bind(this);
    this._handleSuborgCreateCancel = this._handleSuborgCreateCancel.bind(this);
    this._handleSuborgMoveCancel = this._handleSuborgMoveCancel.bind(this);
    this._openAddSuborgPopover = this._openAddSuborgPopover.bind(this);
    this._closeAddSuborgPopover = this._closeAddSuborgPopover.bind(this);
    this._renderAddButton = this._renderAddButton.bind(this);

    this._onCloseUnlinkConfirmation =
      this._onCloseUnlinkConfirmation.bind(this);
    this._unlinkSuborg = this._unlinkSuborg.bind(this);

    this._orgNameColumnContentCreator =
      this._orgNameColumnContentCreator.bind(this);
    this._stateColumnContentCreator =
      this._stateColumnContentCreator.bind(this);
  }

  componentDidMount() {
    !this.props.parentOrg.details && this.props.onParentOrgDataRequest();
    this.props.onSuborgsRequest();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.orgId !== this.props.match.params.orgId) {
      nextProps.onParentOrgDataRequest();
      nextProps.onSuborgsRequest();
    }
  }

  _handleSuborgCreateCancel() {
    this.setState({
      isCreateOpen: false,
    });
  }

  _handleSuborgMoveCancel() {
    this.setState({
      isMoveOpen: false,
    });
  }

  _openCreateSuborg() {
    this.setState({
      isCreateOpen: true,
      isAddSuborgPopoverOpen: false,
    });
  }

  _openMoveSuborg() {
    this.setState({
      isMoveOpen: true,
      isAddSuborgPopoverOpen: false,
    });
  }

  _onShowUnlinkConfirmation(item) {
    this.setState({
      showUnlinkConfirmation: true,
      suborgToBeUnlinked: item,
    });
  }

  _onCloseUnlinkConfirmation() {
    this.setState({
      showUnlinkConfirmation: false,
      suborgToBeUnlinked: null,
    });
  }

  _unlinkSuborg() {
    this.props.onSuborgUnlink(this.state.suborgToBeUnlinked);
  }

  /**
   * This function returns data structure for organizaiton to be correctly
   * displayed in the a reusable component Table
   *
   * A single object represents a table column.
   *
   *     field - (required) property name in the data object (the same which is returned by api)
   *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
   *                          different from the field name.
   *     label - (required) column name.
   *     width - (required) column width to be set in the styles in the table header and table body
   *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
   *     contentCreator - [optional] - func - can be used for creating custom column content
   */
  _getOrgDataSchema() {
    return [
      {
        field: 'id',
        label: 'ID',
        width: '8%',
      },
      {
        field: 'name',
        label: 'Name',
        width: '23%',
        contentCreator: this._orgNameColumnContentCreator,
      },
      {
        field: 'state',
        label: 'State',
        width: '10%',
        contentCreator: this._stateColumnContentCreator,
      },
      {
        field: 'program',
        sortKey: 'program_name',
        label: 'Program',
        width: '16%',
        contentCreator: this._programColumnContentCreator,
      },
      {
        field: 'subscriptions_active',
        label: 'Active Plans #',
        width: '14%',
        isSortable: false,
      },
      {
        field: 'subscriptions_total',
        label: 'Total Plans #',
        width: '14%',
        isSortable: false,
      },
      {
        field: 'suborgs_count',
        label: 'Suborgs #',
        width: '10%',
        isSortable: false,
      },
      {
        field: null,
        label: '',
        width: '5%',
        contentCreator: item => {
          return this.props.userRoleLevel >= ROLES.planetAdmin ? (
            <IconButton
              icon="cancel"
              onClick={this._onShowUnlinkConfirmation.bind(this, item)}
              title="Remove suborg"
            />
          ) : null;
        },
      },
    ];
  }

  _orgNameColumnContentCreator(item) {
    const orgParent = item.parent_organization;
    if (orgParent && orgParent.name) {
      return (
        <span className="pl-org-name pl-flex-wrapper">
          <span className="pl-org-name--container">
            <span className="pl-org-name--label">
              <Link title={item.name} to={`/organizations/${item.id}`}>
                {item.name}
              </Link>
            </span>
          </span>
          <span className="pl-suborg" title={orgParent.name}>
            <span className="pl-suborg--name">
              <Link
                title={`Suborg of ${orgParent.name}`}
                to={`/organizations/${orgParent.id}`}
              >
                Suborg of {orgParent.name}
              </Link>
            </span>
          </span>
        </span>
      );
    } else {
      return (
        <Link title={item.name} to={`/organizations/${item.id}`}>
          {item.name}
        </Link>
      );
    }
  }

  _stateColumnContentCreator(item) {
    return item.state ? <DataState state={item.state} /> : '—';
  }

  _programColumnContentCreator(item) {
    return item.program && item.program.name ? (
      <span title={item.program.name}>{item.program.name}</span>
    ) : (
      '—'
    );
  }

  _openAddSuborgPopover(event) {
    // This prevents ghost click.
    event.preventDefault();

    this.setState({
      isAddSuborgPopoverOpen: true,
      addSuborgPopoverAnchorEl: event.currentTarget,
    });
  }

  _closeAddSuborgPopover() {
    this.setState({
      isAddSuborgPopoverOpen: false,
    });
  }

  /**
   * Render a button that opens a menu with options to
   * 1) create a new suborganization or
   * 2) add an existing one (which means to move it from its old parent to the current organization).
   *    The second options is only available for planet admins, since it requires access to all organizations.
   */
  _renderAddButton() {
    const disabled =
      this.props.parentOrg.details &&
      this.props.parentOrg.details.parent_organization;

    const isUserPlanetAdmin = this.props.userRoleLevel >= ROLES.planetAdmin;

    return [
      <Button
        className="pl-table-controls--add-btn"
        color="primary"
        data-qa-id="org-add-suborg"
        disabled={disabled}
        key={1}
        onClick={!disabled ? this._openAddSuborgPopover : null}
        variant="contained"
      >
        Add
      </Button>,
      <Menu
        anchorEl={this.state.addSuborgPopoverAnchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        key={2}
        onClose={this._closeAddSuborgPopover}
        open={this.state.isAddSuborgPopoverOpen}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuItem
          data-qa-id="suborg-create-new"
          onClick={this._openCreateSuborg}
        >
          Create new
        </MenuItem>

        {isUserPlanetAdmin ? (
          <MenuItem
            data-qa-id="suborg-add-existing"
            onClick={this._openMoveSuborg}
          >
            Add existing
          </MenuItem>
        ) : null}
      </Menu>,
    ];
  }

  render() {
    let parentOrgProgramId = null;
    try {
      parentOrgProgramId = this.props.parentOrg.details.initDetails.program.id;
    } catch (e) {
      //
    }
    const orgFilterNames = ['active', 'disabled', 'deleted'];
    return (
      <div className="pl-orgs-page">
        <Card>
          <div className="pl-table-card-content">
            <TableControlsPanel
              actions={this._renderAddButton()}
              activeFilters={this.props.viewParams.filters}
              filters={orgFilterNames}
              itemsPerPageLimit={this.props.viewParams.limit}
              onFilter={this.props.onSuborgsRequest}
            />

            <Notification
              message={this.props.error}
              title="Oops, organizations request has failed"
              type="error"
            />

            <SuborgCreate
              isOpen={this.state.isCreateOpen}
              onClose={this._handleSuborgCreateCancel}
              parentId={this.props.match.params.orgId}
              parentOrgProgramId={parentOrgProgramId}
            />

            <SuborgMove
              isOpen={this.state.isMoveOpen}
              onClose={this._handleSuborgMoveCancel}
              parentId={this.props.match.params.orgId}
            />

            <Table
              className="pl-table-index"
              dataSchema={this._getOrgDataSchema()}
              onSort={this.props.onSuborgsRequest}
              qaId="suborganizations-table"
              sortBy={this.props.viewParams.sort}
              srcData={this.props.suborgs}
            />

            <Pagination
              itemsLimit={this.props.viewParams.limit}
              itemsOffset={this.props.viewParams.offset}
              itemsTotal={this.props.suborgsTotalCount}
              onPageChange={this.props.onSuborgsRequest}
            />

            <ConfirmationMessage
              onClose={this._onCloseUnlinkConfirmation}
              onSubmit={this._unlinkSuborg}
              open={this.state.showUnlinkConfirmation}
              title="Remove suborg from the parent organization"
            >
              {`The organization “${this.state.suborgToBeUnlinked ? this.state.suborgToBeUnlinked.name : ''}” will be removed from the parent organization. This will potentially change data access. Please review the plans.`}
            </ConfirmationMessage>
          </div>
        </Card>
      </div>
    );
  }
}

SuborgsIndex.propTypes = {
  error: string,
  match: object,
  onParentOrgDataRequest: func,
  onSuborgUnlink: func,
  onSuborgsRequest: func,
  parentOrg: object,
  suborgs: arrayOf(object),
  suborgsTotalCount: number,
  userRoleLevel: number.isRequired,
  viewParams: object,
};
