/**
 * This module returns a data structure for documents to be correctly displayed in the a reusable component Table.
 *
 * A single object represents a table column.
 *
 *     field - (required) property name in the data object (the same which is returned by api)
 *     sortKey - [optional] parameter name to be used for sorting. Use only if this parameter name is
 *                          different from the field name.
 *     label - (required) column name.
 *     width - (required) column width to be set in the styles in the table header and table body
 *     isSortable - [optional] - bool - default: true - determines whether the column should be enabled for sorting.
 *     contentCreator - [optional] - func - can be used for creating custom column content
 */
import React from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import {DATE_FORMAT} from 'admin-ng/constants';

const renderDate = (document, key) => {
  const date = document[key] ? moment(document[key]) : false;
  const title = date ? date.toISOString() : '';
  const displayedDate = date ? date.format(DATE_FORMAT) : '';
  return <span title={title}>{displayedDate}</span>;
};

export default () => [
  {
    field: 'name',
    label: 'Internal name',
    width: '25%',
    contentCreator: document => (
      <Link title={document.name} to={`/documents/${document.id}`}>
        {document.name}
      </Link>
    ),
  },
  {
    field: 'external_name',
    label: 'External name',
    width: '25%',
    contentCreator: document => document.external_name,
  },
  {
    field: 'updated_at',
    label: 'Updated',
    width: '10%',
    contentCreator: document => renderDate(document, 'updated_at'),
  },
  {
    field: 'created_at',
    label: 'Created',
    width: '10%',
    contentCreator: document => renderDate(document, 'created_at'),
  },
  {
    field: 'deprecated',
    label: 'Deprecated',
    width: '10%',
    contentCreator: document => (
      <Checkbox
        checked={document.deprecated}
        disabled
        data-qa-id={`checkbox-deprecated-${document.id}`}
      />
    ),
  },
  {
    field: 'required_for_signup',
    label: 'Required',
    width: '10%',
    contentCreator: document => (
      <Checkbox
        checked={document.required_for_signup}
        disabled
        data-qa-id={`checkbox-requiredForSignup-${document.id}`}
      />
    ),
  },
  {
    field: 'separate',
    label: 'Separate',
    width: '10%',
    contentCreator: document => (
      <Checkbox
        checked={document.separate}
        disabled
        data-qa-id={`checkbox-separate-${document.id}`}
      />
    ),
  },
];
