import ContentFilter from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, {Component} from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import {arrayOf, func, string} from 'prop-types';

export default class QuickFilter extends Component {
  static propTypes = {
    activeFilters: string,
    filters: arrayOf(string).isRequired,
    labels: arrayOf(string),
    onFilter: func.isRequired,
  };

  static defaultProps = {
    activeFilters: '',
    labels: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      mobileValue: [],
      anchorEl: null,
    };

    this._openQuickFilters = this._openQuickFilters.bind(this);
    this._toggleMobileFilter = this._toggleMobileFilter.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const stateFilters = nextProps.activeFilters.split(',');
    const mobileValue = [];
    let index;

    stateFilters.forEach(filter => {
      index = nextProps.filters.indexOf(filter);
      if (index !== -1) {
        mobileValue.push(index);
      }
    });

    this.setState({mobileValue});
  }

  _toggleFilter(filterType) {
    if (filterType) {
      let stateFilters = [];

      if (this.props.activeFilters.length > 0) {
        stateFilters = this.props.activeFilters.split(',');
      }

      const valueIndex = stateFilters.indexOf(filterType);

      if (valueIndex !== -1) {
        stateFilters.splice(valueIndex, 1);
      } else {
        stateFilters.push(filterType);
      }

      const filters = stateFilters.join(',');

      this.props.onFilter({filters});
    }
  }

  _toggleMobileFilter(value) {
    const filters = this.state.mobileValue.map(mv => this.props.filters[mv]);
    const index = this.state.mobileValue.indexOf(value);

    if (index >= 0) {
      //deselect
      filters.splice(index, 1);
    } else {
      filters.push(this.props.filters[value]);
    }

    this.props.onFilter({filters: filters.join(',')});
    this.setState({anchorEl: null});
  }

  _openQuickFilters() {
    this.setState({
      isFiltersMenuOpen: true,
    });
  }

  _renderMobileFilters() {
    const {filters, labels} = this.props;
    if (filters.length) {
      return filters.map((filter, i) => (
        <MenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          onClick={() => this._toggleMobileFilter(i)}
          selected={this.state.mobileValue.includes(i)}
          sx={{textTransform: 'capitalize'}}
          value={i}
        >
          {(!!labels.length && labels[i]) || filter.replace(/_/, ' ')}
        </MenuItem>
      ));
    }
  }

  _renderFilters() {
    const {filters, labels} = this.props;

    if (filters && filters.length) {
      const activeFilters = this.props.activeFilters
        .split(',')
        .filter(filter => filter !== '');

      const quickFilters = filters.map((filter, i) => {
        const label = get(labels, `[${i}]`, filter.replace(/_/, ' '));

        const activeClassName = classNames({
          'pl-quick-filter--active': activeFilters.includes(filter),
        });

        return (
          <li
            className={activeClassName}
            data-qa-id={`pl-quick-filter-${filter}`}
            // eslint-disable-next-line react/no-array-index-key
            key={i}
            onClick={this._toggleFilter.bind(this, filter)}
          >
            {label}
          </li>
        );
      });

      return <ul>{quickFilters}</ul>;
    }
  }

  render() {
    return (
      <div className="pl-quick-filter">
        <div className="pl-quick-filter--desktop">
          <span>Quick filters:</span>

          {this._renderFilters()}
        </div>

        <div className="pl-quick-filter--mobile">
          <IconButton
            onClick={event =>
              this.setState({
                anchorEl: event.currentTarget,
              })
            }
          >
            <ContentFilter />
          </IconButton>

          <Menu
            anchorEl={this.state.anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            getContentAnchorEl={null}
            onClose={() => {
              this.setState({
                anchorEl: null,
              });
            }}
            open={Boolean(this.state.anchorEl)}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {this._renderMobileFilters()}
          </Menu>
        </div>
      </div>
    );
  }
}
