import * as constants from './constants';
import * as messages from './messages';
import {all, update} from 'admin-ng/api/organizations';
import {fetchSuborgs} from '../suborgs-index/actions';

export const updateName = name => ({
  type: constants.SUBORG_MOVE_UPDATE_NAME,
  name,
});

export const requestSuborgs = () => ({
  type: constants.SUBORG_MOVE_REQUEST_SUBORGS,
});

export const requestSuborgsSuccess = (payload = {}) => ({
  type: constants.SUBORG_MOVE_REQUEST_SUBORGS_SUCCESS,
  searchResults: payload.results ?? [],
  searchResultsTotal:
    payload.meta && payload.meta.count ? payload.meta.count : 0,
});

export const requestSuborgsFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.SUBORG_MOVE_REQUEST_SUBORGS_FAILED,
  error,
});

export const selectSuborg = selectedSuborg => ({
  type: constants.SUBORG_MOVE_SELECT_SUBORG,
  selectedSuborg,
});

export const resetState = () => ({
  type: constants.SUBORG_MOVE_RESET_STATE,
});

export const fetchExternalSuborgs = (parentId, name) => {
  return function (dispatch) {
    dispatch(requestSuborgs());

    const requestParams = {
      limit: 100,
      parent_organization_id__ne: parentId,
      id__ne: parentId,
      name__icontains: `%${name}%`,
    };

    return all(requestParams)
      .then((response = {}) => {
        dispatch(requestSuborgsSuccess(response.body));
      })
      .catch((error = {}) => {
        dispatch(
          requestSuborgsFailed(error.message || messages.DEFAULT_ERROR_MSG)
        );
      });
  };
};

export const requestMove = () => ({
  type: constants.SUBORG_MOVE_REQUEST_MOVE,
});

export const moveSuborgSuccess = () => ({
  type: constants.SUBORG_MOVE_REQUEST_MOVE_SUCCESS,
});

export const moveSuborgFailed = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.SUBORG_MOVE_REQUEST_MOVE_FAILED,
  error,
});

export const moveSuborg = parentId => {
  return function (dispatch, getState) {
    const state = getState();
    const selectedSuborg = state.orgs.instance.suborgs.move.selectedSuborg;

    dispatch(requestMove());

    selectedSuborg.parent_organization_id = parentId;

    return update(selectedSuborg.id, selectedSuborg)
      .then(() => {
        dispatch(moveSuborgSuccess());
        dispatch(fetchSuborgs(parentId));
      })
      .catch(error => {
        dispatch(moveSuborgFailed(error.message || messages.DEFAULT_ERROR_MSG));
      });
  };
};
