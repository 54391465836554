import * as constants from './constants';
import {all} from 'admin-ng/api/programs';

export const DEFAULT_ERROR_MSG = 'Something went wrong';

export const requestPrograms = (params = {}) => ({
  type: constants.REQUEST_PROGRAMS,
  params,
});

export const receivePrograms = (json = {}) => ({
  type: constants.REQUEST_PROGRAMS_SUCCESS,
  programs: json.results || [],
  programsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
});

export const requestProgramsFail = (error = DEFAULT_ERROR_MSG) => ({
  type: constants.REQUEST_PROGRAMS_FAIL,
  error,
});

export const fetchPrograms = (params = {}) => {
  return (dispatch, getState) => {
    dispatch(requestPrograms(params));

    const {viewParams} = getState().programs.index;

    // if search is empty, no need to send the parameter, otherwise it will activate
    // a redundant process on the back-end
    if (viewParams?.hasOwnProperty('search') && !viewParams.search) {
      delete viewParams.search;
    }

    return all(viewParams)
      .then((res = {}) => {
        dispatch(receivePrograms(res.body));
      })
      .catch((err = {}) => {
        dispatch(requestProgramsFail(err.message));
      });
  };
};

export const resetViewState = () => ({
  type: constants.RESET_VIEW_STATE,
});

export const resetView = () => dispatch => {
  dispatch(resetViewState());
  return dispatch(fetchPrograms());
};

export const resetState = () => ({
  type: constants.RESET_STATE,
});
