import Button from '@mui/material/Button';
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {array, bool, func, number, oneOf, string} from 'prop-types';

import AccessPeriod from '../access-period/access-period-container';
import Autocomplete from 'admin-ng/components/common/autocomplete';
import Card from 'admin-ng/components/common/card';
import Notification from 'admin-ng/components/common/notification';
import Quota from '../quota/quota-container';
import ReferenceInput from 'admin-ng/components/common/reference-input';
import {BasemapTileQuota, SceneTileQuota} from '../tile-quota/quota-container';

class OrgSubscriptionForm extends Component {
  state = {
    dialogOpen: false,
  };

  componentDidMount() {
    this.props.onMount();
  }

  componentWillReceiveProps({id, onMount}) {
    const {id: oldId} = this.props;
    if (oldId && id !== oldId) {
      onMount();
    }
  }

  _handleAutocompleteSelect = (event, plan = {}) => {
    this.props.onPlanIdUpdate(plan?.id ?? null);
  };

  _handleAutocompleteInputChange = name => {
    this.props.onPlanNameUpdate(name);

    if (!name) {
      this._handleAutocompleteSelect();
    }
  };

  _handleAutocompleteInputCompleted = () => {
    const {newPlanName, onPlanAutocomplete} = this.props;

    if (newPlanName && newPlanName.length > 2) {
      onPlanAutocomplete(newPlanName.trim());
    }
  };

  render() {
    const {
      entity,
      entityId,
      id,
      isFetchingPlans,
      isPlanetAdmin,
      onCancel,
      onReferenceChange,
      onSave,
      plans,
      plansError,
      reference,
      referenceValid,
      saveError,
      subscriptionError,
      type,
    } = this.props;

    const isCreate = type === 'create';
    const isEdit = type === 'edit';

    return (
      <div>
        <Card style={{marginBottom: '25px'}}>
          <Notification
            message={saveError || subscriptionError}
            title={`Oops, could not ${isEdit ? 'update' : 'create a new'} subscription`}
            type="error"
          />

          <div
            className="pl-form--section-header"
            style={{position: 'relative'}}
          >
            <h3 className="pl-form--section-title">
              {isEdit ? 'Edit plan ' : 'Add a new plan '}
              {isEdit && (
                <Link to={`/${entity}s/${entityId}/plans/${id}`}>{id}</Link>
              )}
            </h3>
          </div>

          <div className="pl-subscription-details-row">
            {isCreate && (
              <Autocomplete
                error={!!plansError}
                freeSolo
                helperText={plansError}
                label="Package *"
                loading={isFetchingPlans}
                onInputChange={this._handleAutocompleteInputChange}
                onInputCompleted={this._handleAutocompleteInputCompleted}
                onChange={this._handleAutocompleteSelect}
                options={plans}
                userHintBottomText={
                  <>
                    Start typing a name of a package (min 3 characters).{' '}
                    <Link target="_blank" to="/packages">
                      See all packages
                    </Link>
                  </>
                }
              />
            )}
          </div>

          <div style={{marginTop: 16}}>
            {isPlanetAdmin && (
              <ReferenceInput
                onReferenceChange={onReferenceChange}
                reference={reference}
                referenceValid={referenceValid}
              />
            )}

            <AccessPeriod type={type} />

            <Quota type={type} />

            <BasemapTileQuota title="Basemap Tile Quota" />

            <SceneTileQuota title="Scene Tile Quota" />
          </div>

          <div style={{textAlign: 'right'}}>
            <Button
              color="inherit"
              data-qa-id="subscription-details-cancel-button"
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button
              data-qa-id="subscription-details-save-button"
              onClick={onSave}
              color="primary"
              variant="contained"
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

OrgSubscriptionForm.propTypes = {
  entity: oneOf(['plan', 'organization']).isRequired,
  entityId: number.isRequired,
  id: number,
  isFetchingPlans: bool,
  isPlanetAdmin: bool.isRequired,
  newPlanName: string.isRequired,
  onCancel: func.isRequired,
  onMount: func.isRequired,
  onPlanAutocomplete: func.isRequired,
  onPlanIdUpdate: func.isRequired,
  onPlanNameUpdate: func.isRequired,
  onReferenceChange: func.isRequired,
  onSave: func.isRequired,
  plans: array.isRequired,
  plansError: string,
  reference: string,
  referenceValid: bool.isRequired,
  saveError: string,
  subscriptionError: string,
  type: oneOf(['create', 'edit']).isRequired,
};

OrgSubscriptionForm.defaultProps = {
  entityName: '',
  id: null,
  plansError: null,
  saveError: null,
  subscriptionError: null,
};

export default OrgSubscriptionForm;
