import CircularProgress from '@mui/material/CircularProgress';
import React, {Component} from 'react';
import titleCase from 'title-case';
import {arrayOf, bool, object, string} from 'prop-types';
import {toPairs} from 'lodash';

import Card from 'admin-ng/components/common/card';
import Notification from 'admin-ng/components/common/notification';
import RecordStatus from '../record-status';

export default class BulkScreeningRecord extends Component {
  _renderResults() {
    if (this.props.results.length > 0) {
      return (
        <div>
          {this.props.results.map((result, index) => (
            <div
              className="pl-details-list pl-details-list--compact pl-compliance-record-fields"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {toPairs(result).map((pair, key) => {
                const label = titleCase(pair[0]);
                let value = pair[1];
                if (Array.isArray(value)) {
                  value = value.join(', ');
                }
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="pl-compliance-record-field" key={key}>
                    <div className="item-fullwidth">
                      <div className="pl-details-list--title" title={label}>
                        {label}
                      </div>
                      <div className="pl-details-list--value" title={value}>
                        {value || '—'}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      );
    }
  }

  _renderError() {
    if (this.props.error) {
      return (
        <Notification
          message={this.props.error}
          title="Error checking name"
          type="error"
        />
      );
    }
  }

  render() {
    const status = this.props.results.length > 0 ? 'danger' : 'success';
    const label = this.props.results.length > 0 ? 'Flagged' : 'Passed';
    return (
      <Card
        className="pl-compliance-bulk-record"
        style={{paddingTop: 0, marginBottom: '1.5rem'}}
      >
        <h3 className="pl-compliance-bulk-record-title">
          {this.props.name}{' '}
          {!this.props.isLoading && !this.props.error && (
            <RecordStatus label={label} status={status} />
          )}
        </h3>
        {this.props.isLoading ? (
          <CircularProgress />
        ) : (
          <div>
            {this._renderResults()}
            {this._renderError()}
          </div>
        )}
      </Card>
    );
  }
}

BulkScreeningRecord.propTypes = {
  error: string,
  isLoading: bool.isRequired,
  name: string.isRequired,
  results: arrayOf(object).isRequired,
};
