import * as constants from './constants';

const initialState = {
  error: null,
  isUserDetailsRequestPending: false,
  isUserDetailsUpdateRequestPending: false,
  isApiResetRequestPending: false,
  isEmailUnique: null,
  isEmailCheckRequestPending: false,
  details: null,
  newDetails: null,
  emailError: null,
  firstNameError: null,
  lastNameError: null,
  countryError: null,
  passwordError: null,
  passwordRepeat: null,
  passwordRepeatError: null,
  isPasswordChanged: false,
  passwordRequestError: null,
  apiKey: null,
  orgsError: null,
  organizations: null,
  isOrgChangePending: false,
  isOrgsRequestPending: false,
  isOrgChanged: false,
};

export default function orgMemberInstanceReducer(
  state = initialState,
  action = {}
) {
  const userDetails =
    state.newDetails && state.newDetails.user ? state.newDetails.user : {};

  switch (action.type) {
    case constants.REQUEST_USER_DETAILS:
      return Object.assign({}, state, {
        error: null,
        isUserDetailsRequestPending: true,
      });

    case constants.RECEIVE_USER_DETAILS:
      return Object.assign({}, state, {
        isUserDetailsRequestPending: false,
        details: action.details,
      });

    case constants.USER_DETAILS_REQUEST_FAILED:
      return Object.assign({}, state, {
        isUserDetailsRequestPending: false,
        error: action.error,
      });

    case constants.USER_DETAILS_REQUEST_ORGANIZATIONS:
      return Object.assign({}, state, {
        orgsError: null,
        isOrgsRequestPending: true,
      });

    case constants.USER_DETAILS_RECEIVE_ORGANIZATIONS:
      return Object.assign({}, state, {
        isOrgsRequestPending: false,
        organizations: action.items,
      });

    case constants.USER_DETAILS_ORGANIZATIONS_REQUEST_FAIL:
      return Object.assign({}, state, {
        isOrgsRequestPending: false,
        orgsError: action.error,
      });

    case constants.REQUEST_USER_EMAIL_CHECK:
      return Object.assign({}, state, {
        emailError: null,
        isEmailCheckRequestPending: true,
      });

    case constants.USER_EMAIL_CHECK_REQUEST_COMPLETED:
      return Object.assign({}, state, {
        emailError: action.error,
        isEmailCheckRequestPending: false,
        isEmailUnique: action.isEmailUnique,
      });

    case constants.UPDATE_USER_EMAIL:
      return Object.assign({}, state, {
        emailError: action.error,
        isEmailUnique: null,
        newDetails: Object.assign({}, state.newDetails, {
          user: Object.assign({}, userDetails, {
            email: action.email,
          }),
        }),
      });

    case constants.UPDATE_USER_FIRST_NAME:
      return Object.assign({}, state, {
        firstNameError: action.error,
        newDetails: Object.assign({}, state.newDetails, {
          user: Object.assign({}, userDetails, {
            first_name: action.firstName,
          }),
        }),
      });

    case constants.UPDATE_USER_LAST_NAME:
      return Object.assign({}, state, {
        lastNameError: action.error,
        newDetails: Object.assign({}, state.newDetails, {
          user: Object.assign({}, userDetails, {
            last_name: action.lastName,
          }),
        }),
      });

    case constants.UPDATE_USER_COUNTRY:
      return Object.assign({}, state, {
        countryError: action.error,
        newDetails: Object.assign({}, state.newDetails, {
          user: Object.assign({}, userDetails, {
            country: action.country,
          }),
        }),
      });

    case constants.UPDATE_MEMBER_ROLE:
      return Object.assign({}, state, {
        newDetails: Object.assign({}, state.newDetails, {
          role_level: action.role,
        }),
      });

    case constants.UPDATE_MEMBER_STATE: {
      const newUser = (state.newDetails || {}).user || {};
      return Object.assign({}, state, {
        newDetails: Object.assign({}, state.newDetails, {
          state: action.state,
          user: Object.assign({}, newUser, {
            state: action.state,
          }),
        }),
      });
    }

    case constants.UPDATE_MEMBER_PASSWORD:
      return Object.assign({}, state, {
        newDetails: Object.assign({}, state.newDetails, {
          user: Object.assign({}, userDetails, {
            password: action.password,
          }),
        }),
        passwordError: action.error,
      });

    case constants.UPDATE_MEMBER_PASSWORD_REPEAT:
      return Object.assign({}, state, {
        passwordRepeat: action.passwordRepeat,
        passwordRepeatError: action.error,
      });

    case constants.REQUEST_USER_DETAILS_UPDATE:
      return Object.assign({}, state, {
        error: null,
        isUserDetailsUpdateRequestPending: true,
      });

    case constants.USER_DETAILS_UPDATE_REQUEST_COMPLETED:
      return Object.assign({}, state, {
        error: null,
        isUserDetailsUpdateRequestPending: false,
        details: Object.assign({}, state.details, state.newDetails, {
          user: Object.assign({}, state.details.user, userDetails),
        }),
        newDetails: null,
      });

    case constants.USER_DETAILS_UPDATE_REQUEST_FAILED:
      return Object.assign({}, state, {
        error: action.error,
        isUserDetailsUpdateRequestPending: false,
      });

    case constants.RECEIVE_API_KEY:
      return Object.assign({}, state, {
        apiKey: action.apiKey,
      });

    case constants.REQUEST_API_KEY_RESET:
      return Object.assign({}, state, {
        isApiResetRequestPending: true,
      });

    case constants.API_KEY_RESET_COMPLETED:
      return Object.assign({}, state, {
        isApiResetRequestPending: false,
        apiKey: action.apiKey,
      });

    case constants.API_KEY_RESET_FAILED:
      return Object.assign({}, state, {
        isApiResetRequestPending: false,
        error: action.error,
      });

    case constants.CANCEL_USER_DETAILS_UPDATES:
      return Object.assign({}, state, {
        newDetails: null,
        error: null,
        emailError: null,
        firstNameError: null,
        lastNameError: null,
        countryError: null,
        isEmailUnique: null,
      });

    case constants.USER_DETAILS_ORG_CHANGE_REQUESTED:
      return Object.assign({}, state, {
        isOrgChangePending: true,
        isOrgChanged: false,
      });

    case constants.USER_DETAILS_ORG_CHANGE_SUCCESS:
      return Object.assign({}, state, {
        isOrgChanged: true,
        isOrgChangePending: false,
        error: null,
      });

    case constants.USER_DETAILS_ORG_CHANGE_FAIL:
      return Object.assign({}, state, {
        error: action.error,
        isOrgChanged: false,
        isOrgChangePending: false,
      });

    case constants.CHECK_PASSWORD_VALIDITY:
      return Object.assign({}, state, {
        passwordError: action.payload.message,
      });

    case constants.CHECK_PASSWORD_MATCHING:
      return Object.assign({}, state, {
        passwordRepeatError: action.payload.message,
      });

    case constants.PASSWORD_SAVE_REQUEST:
      return Object.assign({}, state, {
        passwordRequestError: null,
      });

    case constants.PASSWORD_SAVE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isPasswordChanged: true,
      });

    case constants.PASSWORD_SAVE_REQUEST_FAILED:
      return Object.assign({}, state, {
        passwordRequestError: action.error,
      });

    case constants.RESET_PASSWORD:
      return Object.assign({}, state, {
        newDetails: Object.assign({}, state.newDetails, {
          user: Object.assign({}, userDetails, {
            password: '',
          }),
        }),
        passwordRepeat: '',
        passwordError: null,
        passwordRepeatError: null,
        passwordRequestError: null,
        isPasswordChanged: false,
      });

    case constants.RESET_STATE:
      return Object.assign({}, state, initialState);

    default:
      return Object.assign({}, state);
  }
}
