import * as api from 'admin-ng/api/programs';
import * as constants from './constants';
import * as messages from './messages';
import {fetchPrograms} from '../programs-index/actions';
import {all as getDocuments} from 'admin-ng/api/documents';

export const updateName = name => ({
  type: constants.UPDATE_NAME,
  name,
});

export const updateDescription = description => ({
  type: constants.UPDATE_DESCRIPTION,
  description,
});

export const updateInvitationText = invitationText => ({
  type: constants.UPDATE_INVITATION_TEXT,
  invitationText,
});

export const updateDocuments = documents => ({
  type: constants.UPDATE_DOCUMENTS,
  documents,
});

export const requestNameCheck = (params = {}) => ({
  type: constants.REQUEST_NAME_CHECK,
  params,
});

export const requestNameCheckSuccess = isNameUnique => ({
  type: constants.REQUEST_NAME_CHECK_SUCCESS,
  isNameUnique,
});

export const requestNameCheckFail = (error = messages.DEFAULT_ERROR_MSG) => ({
  type: constants.REQUEST_NAME_CHECK_FAIL,
  error,
});

export function checkName(name) {
  return function (dispatch) {
    const params = {
      name__ieq: name,
    };

    dispatch(requestNameCheck(params));

    return api
      .all(params)
      .then((res = {}) => {
        if (res.body && res.body.results) {
          if (res.body.results.length >= 1) {
            dispatch(requestNameCheckSuccess(false));
          } else {
            dispatch(requestNameCheckSuccess(true));
          }
        } else {
          dispatch(requestNameCheckFail());
        }
      })
      .catch((err = {}) => {
        dispatch(requestNameCheckFail(err.message));
      });
  };
}

export const requestDocumentSearch = (params = {}) => ({
  type: constants.REQUEST_DOCUMENT_SEARCH,
  params,
});

export const requestDocumentSearchSuccess = documents => ({
  type: constants.REQUEST_DOCUMENT_SEARCH_SUCCESS,
  documents,
});

export const requestDocumentSearchFail = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_DOCUMENT_SEARCH_FAIL,
  error,
});

export const searchDocuments = name => dispatch => {
  const params = {
    fields: 'id,name',
    search: name,
  };

  dispatch(requestDocumentSearch(params));

  return getDocuments(params)
    .then(response =>
      dispatch(requestDocumentSearchSuccess(response.body.results))
    )
    .catch((error = {}) => dispatch(requestDocumentSearchFail(error.message)));
};

export const requestProgramCreate = (params = {}) => ({
  type: constants.REQUEST_PROGRAM_CREATE,
  params,
});

export const requestProgramCreateSuccess = () => ({
  type: constants.REQUEST_PROGRAM_CREATE_SUCCESS,
});

export const requestProgramCreateFail = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_PROGRAM_CREATE_FAIL,
  error,
});

export const createNewProgram = () => {
  return (dispatch, getState) => {
    const state = getState().programs.create;
    const params = {
      name: state.name,
      description: state.description,
      invite_email_text: state.invitationText,
      documents: state.documents.map(doc => doc.id),
    };

    dispatch(requestProgramCreate(params));

    return api
      .create(params)
      .then(() => {
        dispatch(requestProgramCreateSuccess());
        dispatch(fetchPrograms());
      })
      .catch((error = {}) => {
        dispatch(requestProgramCreateFail(error.message));
      });
  };
};

export const resetState = () => ({
  type: constants.RESET_STATE,
});
