import {connect} from 'react-redux';

import PlanAssignConfirmOrgs from './plan-assign-confirm-orgs';

const mapStateToProps = state => {
  const assignState = state.plans.assign;

  return {
    selectedOrgs: assignState.selectOrgs.selectedOrgs,
    sourcePlanName: assignState.selectPlan.sourcePlanName,
    destinationPlanName: assignState.selectPlan.destinationPlanName,
  };
};

export default connect(mapStateToProps, undefined, undefined, {pure: false})(
  PlanAssignConfirmOrgs
);
