import * as constants from './constants';
import * as messages from './messages';
import {all} from 'admin-ng/api/organizations';

export const requestProgramOrganizations = (params = {}) => ({
  type: constants.REQUEST_PROGRAM_ORGANIZATIONS,
  payload: params,
});

export const requestProgramOrganizationsSuccess = (json = {}) => ({
  type: constants.REQUEST_PROGRAM_ORGANIZATIONS_SUCCESS,
  payload: {
    items: json.results || [],
    itemsTotalCount: json.meta && json.meta.count ? json.meta.count : 0,
  },
});

export const requestProgramOrganizationsFailed = (
  error = messages.DEFAULT_ERROR_MSG
) => ({
  type: constants.REQUEST_PROGRAM_ORGANIZATIONS_FAILED,
  payload: error,
  error: true,
});

export const reset = () => ({
  type: constants.RESET,
});

export const fetchOrganizations = (programId, params) => {
  return (dispatch, getState) => {
    const state = getState();
    const programOrgsState = state.programs.organizations;

    dispatch(requestProgramOrganizations(params));

    const requestParams = Object.assign(
      {},
      programOrgsState.viewParams,
      params,
      {
        program_id: programId,
      }
    );

    // if search is epmty, no need to send the parameter, otherwise it will activate
    // a redundant process on the back-end
    if (requestParams?.hasOwnProperty('search') && !requestParams.search) {
      delete requestParams.search;
    }

    if (requestParams.filters) {
      requestParams.state__in = requestParams.filters;
      delete requestParams.filters;
    }

    return all(requestParams)
      .then((res = {}) => {
        dispatch(requestProgramOrganizationsSuccess(res.body));
      })
      .catch((err = {}) => {
        dispatch(requestProgramOrganizationsFailed(err.message));
      });
  };
};
