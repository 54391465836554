import {connect} from 'react-redux';
import {withAuth} from '@apps-common/auth-react';

import Users from './users';
import {ROLES} from 'admin-ng/constants';
import {download, fetch, reset, setParams} from './actions';

const mapStateToProps = ({users}, {auth}) => {
  const isPlanetAdmin = auth.user.claims.role_level >= ROLES.planetAdmin;
  return {
    ...users,
    hasDeletedFilter: isPlanetAdmin,
    hasDownload: isPlanetAdmin,
  };
};
const mapDispatchToProps = (dispatch, {auth}) => {
  return {
    onMount: () => {
      dispatch(reset());
      dispatch(fetch());
    },
    onChange: params => {
      dispatch(setParams(params));
      dispatch(fetch());
    },
    onDownload: () => dispatch(download(auth)),
  };
};

export default withAuth(connect(mapStateToProps, mapDispatchToProps)(Users));
