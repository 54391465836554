import AppBar from '@mui/material/AppBar';
import PropTypes from 'prop-types';
import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

const PlAppBar = ({iconElementRight, iconElementLeft, title, ...props}) => (
  <AppBar position="relative" {...props}>
    <Toolbar>
      {iconElementLeft && iconElementLeft}

      <Typography
        color="inherit"
        style={{flexGrow: 1, marginLeft: iconElementLeft ? 8 : 0, fontSize: 18}}
      >
        {title}
      </Typography>

      {iconElementRight && iconElementRight}
    </Toolbar>
  </AppBar>
);

PlAppBar.propTypes = {
  iconElementLeft: PropTypes.node,
  iconElementRight: PropTypes.node,
  title: PropTypes.node,
};

export default PlAppBar;
