import {connect} from 'react-redux';

import * as actions from './actions';
import PlanGeneral from './plan-general';
import {fetchPlan, resetState} from '../plan-common/actions';

const mapStateToProps = state => state.plans.instance.general;

const mapDispatchToProps = (dispatch, props) => ({
  onCancel: () => dispatch(actions.resetGeneralPlanDetails()),
  onReset: () => dispatch(resetState()),
  onOpen: () => dispatch(fetchPlan(props.match.params.planId, props.history)),
  onPlanNameUpdate: name => dispatch(actions.updatePlanName(name)),
  onPlanNameCheck: () => dispatch(actions.checkPlanName()),
  onSave: () => dispatch(actions.savePlanName(props.match.params.planId)),
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
})(PlanGeneral);
