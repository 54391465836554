import * as constants from './constants';
import {ERROR_MESSAGE} from 'admin-ng/constants';
import {getOrganizations, getUsers} from 'admin-ng/api/compliance';

export const fetchStart = params => ({
  type: constants.FETCH_START,
  payload: params,
});

export const fetchSuccess = (records, totalCount) => ({
  type: constants.FETCH_SUCCESS,
  payload: {
    records,
    totalCount,
  },
});

export const fetchFail = (error = ERROR_MESSAGE) => ({
  type: constants.FETCH_FAIL,
  payload: error,
  error: true,
});

export const fetch =
  (entity = 'user', rawParams = {}) =>
  (dispatch, getState) => {
    dispatch(fetchStart(rawParams));

    const state = getState();
    const params = {...state.compliance.index.params, ...rawParams};

    if (params.search === '') {
      delete params.search;
    }
    if (params.filters !== '') {
      params.status__in = params.filters;
      // Replacing to circumvent DB enum error
      params.status__in = params.status__in.replace(
        'false positive',
        'false_positive'
      );
    }
    delete params.filters;
    const promise = entity === 'user' ? getUsers : getOrganizations;

    return promise(params)
      .then(
        ({
          body: {
            results,
            meta: {count},
          },
        }) => {
          dispatch(fetchSuccess(results, count));
        }
      )
      .catch((error = {}) => {
        dispatch(fetchFail(error.message));
      });
  };

export const reset = () => ({type: constants.RESET});
