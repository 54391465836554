import * as constants from './constants';
import * as messages from './messages';
import {create} from 'admin-ng/api/plans';
import {isPolicyValid} from '../plan-policies/actions';
import {preparePolicies} from 'admin-ng/util/policies/preparers';
import {updatePlanName} from '../plan-general/actions';

export const requestPlanCreate = (params = null) => {
  return {
    type: constants.REQUEST_PLAN_CREATE,
    params,
  };
};

export const planCreateRequestCompleted = id => {
  return {
    type: constants.PLAN_CREATE_REQUEST_COMPLETED,
    payload: {
      id,
    },
  };
};

export const planCreateRequestFailed = (error = messages.DEFAULT_ERROR_MSG) => {
  return {
    type: constants.PLAN_CREATE_REQUEST_FAILED,
    error,
  };
};

export const setGlobalError = (error = messages.DEFAULT_ERROR_MSG) => {
  return {
    type: constants.PLAN_CREATE_SET_GLOBAL_ERROR,
    error,
  };
};

export const resetGlobalError = () => {
  return {
    type: constants.PLAN_CREATE_RESET_GLOBAL_ERROR,
  };
};

export const createNewPlan = () => {
  return (dispatch, getState) => {
    const state = getState(),
      newPlanStateGeneral = state.plans.instance.general,
      policiesState = state.plans.instance.policies,
      activePolicy = policiesState.items[policiesState.selectedPolicy];

    if (!newPlanStateGeneral.planName) {
      dispatch(setGlobalError(messages.PLAN_NAME_NOT_SPECIFIED));
      return dispatch(
        updatePlanName(
          newPlanStateGeneral.planName,
          messages.PLAN_NAME_NOT_SPECIFIED
        )
      );
    }

    const params = {
      name: newPlanStateGeneral.planName.trim(),
      policies: preparePolicies(policiesState.items),
    };

    dispatch(requestPlanCreate(params));

    if (isPolicyValid(activePolicy, dispatch)) {
      return create(params)
        .then(res => {
          dispatch(planCreateRequestCompleted(res.body.id));
        })
        .catch(err => {
          const error = err.message;

          dispatch(setGlobalError(error));
          dispatch(planCreateRequestFailed(error));
        });
    }
  };
};

export const resetState = () => {
  return {
    type: constants.PLAN_RESET_STATE,
  };
};
