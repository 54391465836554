import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import React, {Component} from 'react';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import {arrayOf, bool, func, object, string} from 'prop-types';

import BulkScreeningRecord from '../bulk-screening-record';
import Card from 'admin-ng/components/common/card';

export default class BulkScreening extends Component {
  componentDidMount() {
    this.props.reset();
  }

  render() {
    return (
      <div>
        <Card className="pl-form" style={{marginBottom: '1.5em'}}>
          <div className="pl-form--section">
            <h3 className="pl-form--section-title">Regulatory checks</h3>
            <p>
              Check for compliance must be specified exactly as: Name,
              Affiliation,
              <a href="https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2">
                Country Code
              </a>
            </p>
            <p>
              Note that Affiliation and Country are both optional. Any other
              information such as date of birth or title is not used.
            </p>
            <p>Valid Examples:</p>
            <p>
              Just name - John Smith
              <br />
              Name and country - Alice Smith,,US
              <br />
              Name and affiliation, country left off - Bob Smith,Facebook
              <br />
              Affiliation and country - ,Facebook,US
              <br />
              Name, affiliation, and country - John Smith,Facebook,US
              <br />
              Just name with explicit empty affiliation - Alice Smith,
              <br />
              Just name with explicit empty affiliation and country - Bob
              Smith,,
              <br />
            </p>
            <p>Not Valid:</p>
            <p>
              Last name, first name - Gadet,Peter
              <br />
              Country name - Iran
              <br />
            </p>
            <div className="pl-form--control">
              <TextField
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(this.props.error)}
                fullWidth
                helperText={this.props.error && this.props.error}
                label="Names"
                multiline
                onChange={event => this.props.updateNames(event.target.value)}
                placeholder={
                  'Bonnie Tucker,Amgen,CA\nRandy Edgar,Facebook,US\nOsama Jones,,'
                }
                rows={3}
                value={this.props.names}
                variant="standard"
              />
            </div>
            <div className="pl-form--control">
              <label className="pl-form--field-label">
                Check against lists of:
              </label>
              <div className="pl-compliance-bulk-filters">
                {['USA', 'Canada', 'EU'].map((country, key) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="pl-compliance-bulk-filter" key={key}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.props[`isWith${country}`]}
                          color="primary"
                          onChange={this.props[`toggle${country}`]}
                        />
                      }
                      label={country}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Button
              color="primary"
              onClick={this.props.check}
              variant="contained"
            >
              Check
            </Button>
          </div>
        </Card>

        {this.props.groups.map((group, key) => (
          // eslint-disable-next-line react/no-array-index-key
          <BulkScreeningRecord {...group} key={`group${key}`} />
        ))}
      </div>
    );
  }
}

BulkScreening.propTypes = {
  check: func.isRequired,
  error: string,
  groups: arrayOf(object).isRequired,
  isWithCanada: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  isWithEU: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  isWithUSA: bool.isRequired, // eslint-disable-line react/no-unused-prop-types
  names: string,
  reset: func.isRequired,
  toggleCanada: func.isRequired, // eslint-disable-line react/no-unused-prop-types
  toggleEU: func.isRequired, // eslint-disable-line react/no-unused-prop-types
  toggleUSA: func.isRequired, // eslint-disable-line react/no-unused-prop-types
  updateNames: func.isRequired,
};
