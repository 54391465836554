import {connect} from 'react-redux';

import ProgramOrganizations from './program-organizations';
import {fetchOrganizations} from './actions';

const mapStateToProps = ({programs: {organizations}}) => organizations;
const mapDispatchToProps = (dispatch, props) => ({
  onDataRequest: params => {
    dispatch(fetchOrganizations(props.match.params.programId, params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps, undefined, {
  pure: false,
})(ProgramOrganizations);
