import * as constants from './constants';
import {RESET_ASSIGN_STATE} from '../plan-assign-general/constants';

const initialState = {
  error: null,
  isReassigned: false,
};

export default function planAssignSummaryReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case constants.REQUEST_ORGS_REASSIGN:
      return Object.assign({}, initialState, {
        error: null,
      });

    case constants.ORGS_REASSIGN_REQUEST_COMPLETED:
      return Object.assign({}, initialState, {
        isReassigned: true,
      });

    case constants.ORGS_REASSIGN_REQUEST_FAILED:
      return Object.assign({}, initialState, {
        isReassigned: false,
        error: action.error,
      });

    case RESET_ASSIGN_STATE:
      return Object.assign({}, initialState);

    default:
      return state;
  }
}
