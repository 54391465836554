import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import {bool, func, node, string} from 'prop-types';

const ErrorDialog = props => (
  <Dialog onClose={props.onClose} open={props.open}>
    <DialogTitle>Error</DialogTitle>
    <DialogContent>{props.children}</DialogContent>

    <DialogActions>
      <Button
        color="primary"
        data-qa-id={props.qaId}
        key={1}
        onClick={props.onClose}
        variant="contained"
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

ErrorDialog.propTypes = {
  children: node,
  onClose: func,
  open: bool.isRequired,
  qaId: string,
};

export default ErrorDialog;
