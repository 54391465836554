import assign from 'deep-extend';

import * as constants from './constants';

const initialState = {
  error: null,
  isDataRequestPending: false,
  subscriptions: [],
  subscriptionsTotalCount: 0,
  quotaUsages: {},
  viewParams: {
    filters: 'active,expired,inactive',
    limit: 20,
    offset: 0,
    sort: 'organization.name',
    search: '',
  },
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case constants.REQUEST_PLAN_SUBSCRIPTIONS:
      return assign({}, state, {
        error: null,
        isDataRequestPending: true,
        viewParams: action.params,
      });

    case constants.PLAN_SUBSCRIPTIONS_REQUEST_FAILED:
      return assign({}, state, {
        error: action.error,
        isDataRequestPending: false,
      });

    case constants.RECEIVED_PLAN_SUBSCRIPTIONS:
      return assign({}, state, {
        isDataRequestPending: false,
        subscriptions: action.items,
        subscriptionsTotalCount: action.itemsTotalCount,
      });

    case constants.UPDATE_SUBSCRIPTIONS_QUOTA_USAGE:
      return Object.assign({}, state, {
        quotaUsages: action.payload,
      });

    default:
      return assign({}, state);
  }
};
